import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

function getWageDistribution(dataObject: tComparisonstatisticsTableViewObject) {
    let wageDistribution = "-"

    if (dataObject.FirstTenthPercentile !== 0) {
        wageDistribution = (dataObject.LastTenthPercentile / dataObject.FirstTenthPercentile)
            .toFixed(2)
            .toString()
            .replace(".", ",")
    }

    return wageDistribution
}

export default getWageDistribution
