/* eslint-disable react/no-this-in-sfc, no-param-reassign  */

import { tComparisonScatterDiagramData } from "types/sharedTypes"

import { useEffect, useState } from "react"

import getSsnAndNameFromChartData from "utils/Highcharts/getSsnAndNameFromChartData"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

type tScatterDiagramProps = {
    diagramData: tComparisonScatterDiagramData
    diagramTitle: string
    showMinorGridLines: boolean
}

const ComparisonScatterDiagram = ({ diagramData, diagramTitle, showMinorGridLines }: tScatterDiagramProps) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    diagramData.P10.forEach((a) => {
        if (a[1] === 0) a[1] = null
    })
    diagramData.P25.forEach((a) => {
        if (a[1] === 0) {
            a[1] = null
        }
    })
    diagramData.P75.forEach((a) => {
        if (a[1] === 0) {
            a[1] = null
        }
    })
    diagramData.P90.forEach((a) => {
        if (a[1] === 0) {
            a[1] = null
        }
    })

    const amounts = diagramData.Amounts

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "scatter",
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: "Lön",
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            categories: diagramData.Catagories,
            title: {
                text: diagramData.xAxisTitle,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const x = this.x as number
                const y = this.y as number

                const seriesDef = [{ data: diagramData.SalariesMen }, { data: diagramData.SalariesWomen }]
                const categories = diagramData.Catagories

                const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
            },
        },
        series: [
            {
                id: "1",
                type: "scatter",
                name: `Man ${amounts.Men}%`,
                data: diagramData.SalariesMen,
            },
            {
                id: "2",
                type: "scatter",
                name: `Kvinna ${amounts.Women}%`,
                data: diagramData.SalariesWomen,
                // marker: {
                //     symbol: "circle",
                //     fillColor: "#000",
                // },
            },
            {
                id: "3",
                name: "P10",
                type: "line",
                data: diagramData.P10,
                marker: {
                    enabled: false,
                },
                // color: "#9ad6dd",
                color: "#d2c6eb",
                // color: "#acd5bd",
                enableMouseTracking: false,
                // lineWidth: 0.7,
            },
            {
                id: "4",
                name: "P25",
                type: "line",
                dashStyle: "Dash",
                data: diagramData.P25,
                // color: "#6fafb6",
                color: "#a290ce",
                // color: "#77a78b",
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
            },
            {
                id: "5",
                name: "P75",
                type: "line",
                dashStyle: "DashDot",
                data: diagramData.P75,
                // color: "#428991",
                color: "#715db2",
                // color: "#437a5c",
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
            },
            {
                id: "6",
                name: "P90",
                type: "line",
                dashStyle: "ShortDot",
                data: diagramData.P90,
                // color: "#07656d",
                color: "#3a2d95",
                // color: "#065031",
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
            },
        ],
        credits: {
            enabled: false,
        },
    })

    // Uppdate data dynamically
    useEffect(() => {
        // get pervios options from setState, return copy of it with only property yAxis updated
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default ComparisonScatterDiagram
