import "./DeleteRegisterWarning.css"

import GENERAL_TEXTS, { REGISTER_TEXTS } from "constants/text.constants"

import Button from "components/atoms/button/Button"

import { ReactComponent as TrashIcon } from "assets/trash-solid.icon.svg"
import { useLayoutEffect, useRef } from "react"

type IDeleteRegisterWarning = {
    children: string
    onClick: (deleteAll: boolean) => void
    showWarning: React.Dispatch<React.SetStateAction<boolean>>
}

// This is a specific use case for the Warning component (components->atoms->Warning) so use regular Warning in other places!
const DeleteRegisterWarning = ({ children, onClick, showWarning }: IDeleteRegisterWarning) => {
    const deleteRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        deleteRef.current?.focus()
    }, [])

    return (
        <div className="deleteRegWarningContainer" tabIndex={-1} ref={deleteRef} aria-live="polite">
            <div className="deleteRegWtextAndIconContainer">
                <TrashIcon width="1rem" height="1rem" fill="#9b0a00" className="icon" />
                <p>{children}</p>
            </div>
            <div className="flex gap8 jc-flex-end margin8">
                <Button variant="delete" onClick={() => onClick(false)}>
                    {REGISTER_TEXTS.WARNING_BUTTON_DELETE_ONLY_REGISTER}
                </Button>
                <Button variant="delete" onClick={() => onClick(true)}>
                    {REGISTER_TEXTS.WARNING_BUTTON_DELETE_ALL}
                </Button>
                <Button onClick={() => showWarning(false)}>{GENERAL_TEXTS.BUTTON_ABORT}</Button>
            </div>
        </div>
    )
}

export default DeleteRegisterWarning
