import "./ShowReferenceStatisticsPage.css"

import pageIds from "constants/pageIds.constants.json"
import { REFERENCESTATISTICS_TEXTS } from "constants/text.constants"
import pageTitles from "constants/pageTitles.constants.json"

import { useEffect, useState } from "react"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { fetchComparisonstatistics, fetchTableView } from "services/fetchers"

import { Icomparisonstatistic, ITableView, ITableViewRow } from "types/sharedTypes"

import { ReactComponent as ChevronDownIcon } from "components/menu/icons/chevron-down.icon.svg"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion"

import Error from "components/atoms/error/Error"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import ReferenceStatisticsContent from "./components/ReferenceStatisticsContent"

const ShowReferenceStatisticsPage = () => {
    const [noStatistics, setNoStatistics] = useState(false)
    const [fetchingComparisonstatistics, setFetchingComparisonstatistics] = useState(true)
    const [fetchError, setFetchError] = useState("")

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [fetchingTableView, setFetchingTableView] = useState(true)
    const [fetchTableViewError, setFetchTableViewError] = useState("")
    const [tableView, setTableView] = useState<ITableView[]>()
    const [tableViewRows, setTableViewRows] = useState<ITableViewRow[]>()

    const [comparisonstatistics, setComparisonstatistics] = useState<Icomparisonstatistic[]>()

    const [expandedId, setExpandedId] = useState("")

    const handleFetchTable = (id: string) => {
        if (expandedId === id) setExpandedId("")
        else setExpandedId(id)
        fetchTableView(id, setTableView, setTableViewRows, setFetchingTableView, setFetchTableViewError)
    }

    useEffect(
        () =>
            fetchComparisonstatistics(
                setComparisonstatistics,
                setNoStatistics,
                setFetchingComparisonstatistics,
                setFetchError
            ),
        []
    )

    useUpdatePageTitle(pageTitles.SHOW_REFERENCESTATISTICS_PAGE_TITLE)
    useScrollToTop()

    return (
        <>
            <PageHeader pageId={pageIds.SHOW_REFERENCESTATISTICS_PAGE_ID} />
            {fetchingComparisonstatistics && !fetchError && <ContentSkeleton />}
            {noStatistics && !fetchingComparisonstatistics && <p>{REFERENCESTATISTICS_TEXTS.NO_STATISTICS}</p>}
            {fetchError && <Error>{fetchError}</Error>}
            {showDeleteConfirmation && (
                <div className="marginBottom32">
                    <Confirmation>{REFERENCESTATISTICS_TEXTS.CONFIRMATION_DELETED}</Confirmation>
                </div>
            )}
            {!fetchingComparisonstatistics && !noStatistics && !fetchError && comparisonstatistics && (
                <div className="maxWidth1000">
                    <Accordion allowZeroExpanded>
                        {comparisonstatistics.map((statistic) => (
                            <AccordionItem key={statistic.Id}>
                                <AccordionItemHeading
                                    onClick={() => handleFetchTable(statistic.Id)}
                                    onKeyDown={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            handleFetchTable(statistic.Id)
                                        }
                                    }}
                                >
                                    <AccordionItemButton>
                                        <div className="flex jc-space-between">
                                            <div className="responsiveHeadingTextContainer gap32">
                                                <p className="headingTextRef">
                                                    <strong>{REFERENCESTATISTICS_TEXTS.PERIOD}</strong>
                                                    {statistic.ValidFor}
                                                </p>
                                                <p className="headingTextRef">
                                                    <strong>{REFERENCESTATISTICS_TEXTS.EMPLOYEE_TYPE}</strong>
                                                    {statistic.StatisticFor}
                                                </p>
                                                <p className="headingTextRef">
                                                    <strong>{REFERENCESTATISTICS_TEXTS.IMPORTED_BY}</strong>
                                                    {statistic.ImportedBy}
                                                </p>
                                                {statistic && statistic.ImportedBy !== "Arbetsgivarverket" && (
                                                    <p className="headingTextRef">
                                                        <strong>{REFERENCESTATISTICS_TEXTS.FILENAME}</strong>
                                                        {statistic.FileName}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="iconContainer">
                                                {expandedId === statistic.Id ? (
                                                    <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" />
                                                ) : (
                                                    <ChevronDownIcon
                                                        width="1rem"
                                                        height="1rem"
                                                        fill="#333333"
                                                        transform="rotate(180)"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ReferenceStatisticsContent
                                        tableView={tableView}
                                        tableViewRows={tableViewRows}
                                        importedBy={statistic.ImportedBy}
                                        fetchingTableView={fetchingTableView}
                                        fetchError={fetchTableViewError}
                                        setComparisonstatistics={setComparisonstatistics}
                                        setShowConfirmation={setShowDeleteConfirmation}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            )}
        </>
    )
}

export default ShowReferenceStatisticsPage
