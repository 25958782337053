import "./FilterButtonsRow.css"

import React, { useState } from "react"

import FilterButton from "components/atoms/filterButton/FilterButton"

type FilterButtonsRowProps = {
    children: string[]
    maxSelectionAllowed: number
    selectedFilters: string[]
    setSelection: React.Dispatch<React.SetStateAction<string[]>>
}

const FilterButtonsRow = ({ children, maxSelectionAllowed, setSelection, selectedFilters }: FilterButtonsRowProps) => {
    const [disabled, setDisabled] = useState(selectedFilters.length >= maxSelectionAllowed)

    const handleOnClick = (identifier: string) => {
        if (!selectedFilters.some((element: string) => identifier === element)) {
            selectedFilters.push(identifier)
        } else {
            // Find the index of the array element you want to remove using indexOf, and then remove that index with splice.
            const index = selectedFilters.indexOf(identifier)
            // only splice array when item is found
            if (index > -1) {
                selectedFilters.splice(index, 1) // 2nd parameter means remove one item only
            }
        }
        if (selectedFilters.length >= maxSelectionAllowed) setDisabled(true)
        else if (selectedFilters.length < maxSelectionAllowed) setDisabled(false)

        setSelection([...selectedFilters])
    }

    return (
        <ul className="filterButtonsRow">
            {children.map((filterButtonName) => (
                <li key={filterButtonName}>
                    <FilterButton
                        key={filterButtonName}
                        preselected={selectedFilters.indexOf(filterButtonName) > -1}
                        onClick={() => handleOnClick(filterButtonName)}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        clearSelection={!selectedFilters.length}
                    >
                        {filterButtonName}
                    </FilterButton>
                </li>
            ))}
        </ul>
    )
}

export default FilterButtonsRow
