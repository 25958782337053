import "./SelectionsTable.css"

import { dispatchNumber, wageGapSavedSelection } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import Button from "components/atoms/button/Button"

type SelectionsTableProps = {
    selection: string
    created: string
    administrate: string
    savedSelections: wageGapSavedSelection[]
    chooseCurrentSelection: (id: string, state: number, salaryType: number) => void
    removeCurrentSelection: (id: string) => void
}

// It would be great to have this as a single component for all the saved selections for analyze flows
// If cooperating with BE during their restructure make sure to ask for same datastructure in all the
// selections. And not only datastructures but also how delete / add method works.
const SelectionsTable = ({
    selection,
    created,
    administrate,
    savedSelections,
    chooseCurrentSelection,
    removeCurrentSelection,
}: SelectionsTableProps) => {
    sessionStorage.removeItem("analysisId") // destroys the delete method

    return (
        <div>
            <table className="selectionsTable">
                <thead>
                    <tr>
                        <th className="th-bb-1">{selection}</th>
                        <th className="th-bb-1">{created}</th>
                        <th className="th-bb-1 selectionsTableCellWidth160">{administrate}</th>
                    </tr>
                </thead>
                <tbody>
                    {savedSelections.map((savedSelection) => (
                        <tr key={savedSelection.Id}>
                            <td>{savedSelection.Name}</td>
                            <td>{savedSelection.LastModified}</td>
                            <td>
                                <div className="flex gap8">
                                    <Button
                                        small
                                        onClick={() =>
                                            chooseCurrentSelection(
                                                savedSelection.Id,
                                                savedSelection.State,
                                                savedSelection.SalaryType
                                            )
                                        }
                                    >
                                        {ANALYZE_WAGE_GAP.S1_BUTTON_CHOOSE}
                                    </Button>
                                    <Button
                                        variant="delete"
                                        small
                                        onClick={() => removeCurrentSelection(savedSelection.Id)}
                                    >
                                        {ANALYZE_WAGE_GAP.S1_BUTTON_DELETE}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default SelectionsTable
