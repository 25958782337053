let apiEndpoint = null
let idpApiEndpoint = null

export const setapiendpoint = (url, urlIdp) => {
  apiEndpoint = url
  idpApiEndpoint = urlIdp
}

export const getapiendpoint = ()=> {
      console.log("getapiendpoint: ", apiEndpoint)
  return apiEndpoint
}
export const getIdpApiendpoint = ()=> {
  console.log("idpApiEndpoint: ", idpApiEndpoint)
  return idpApiEndpoint
}