import { GROUPS_TEXTS } from "constants/text.constants"

import { AgGridReact } from "ag-grid-react"
import { ICellRendererParams } from "ag-grid-community"
import { EmployeesTableData } from "types/sharedTypes"

import Button from "components/atoms/button/Button"

interface IRemoveEmployeeButton extends ICellRendererParams {
    gridRef: React.RefObject<AgGridReact<EmployeesTableData>>
}

const RemoveEmployeeButton = (props: IRemoveEmployeeButton) => {
    const { data, gridRef } = props

    const removeCurrentRowFromSelected = () => {
        gridRef.current?.api.forEachNode((node) => {
            if (!!node.data && node.data.Pnr === data.Pnr) node.setSelected(false)
        })
    }

    return (
        <span>
            <Button variant="delete" onClick={removeCurrentRowFromSelected}>
                {GROUPS_TEXTS.BUTTON_REMOVE_EMPLOYEE}
            </Button>
        </span>
    )
}

export default RemoveEmployeeButton
