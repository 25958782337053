import "./ChooseReferencestatisticsSelection.css"

import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import { useState, useEffect, useMemo } from "react"

import { fetchAllRegionsAndBusinessFocus, fetchComparisonstatisticsForAnalysis } from "services/fetchers"

import {
    dispatchString,
    IAllRegionsAndBusinessFocus,
    IComparisonstatisticsForAnalysis,
    tComparisonstatisticsAnalysisObject,
} from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Select from "components/atoms/select/Select"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

interface IChooseReferencestatisticsSelection {
    selectedReferenceRegisterId: string
    setSelectedReferenceRegisterId: dispatchString
    analysisObject: tComparisonstatisticsAnalysisObject
    setAnalysisObject: React.Dispatch<React.SetStateAction<tComparisonstatisticsAnalysisObject>>
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const ChooseReferencestatisticsSelection = ({
    selectedReferenceRegisterId,
    setSelectedReferenceRegisterId,
    analysisObject,
    setAnalysisObject,
    setActiveSteps,
}: IChooseReferencestatisticsSelection) => {
    const ComparisonstatisticsAnalysisTypes = useMemo(
        () => ["Partsgemensam referensstatistik", "Medlemsanpassad referensstatistik"],
        []
    )
    const CompareWithTypes = useMemo(() => ["Region", "Verksamhetsinriktning"], [])

    const [fetchError, setFetchError] = useState("")

    const [fetchingComparisonstatistics, setFetchingComparisonstatistics] = useState(true)
    const [comparisonstatistics, setComparisonstatistics] = useState<IComparisonstatisticsForAnalysis[]>([])

    const [fetchingRegionsAndBusinessFocus, setFetchingRegionsAndBusinessFocus] = useState(true)
    const [regionsAndBusinessFocus, setRegionsAndBusinessFocus] = useState<IAllRegionsAndBusinessFocus>({
        BusinessFocus: [],
        Regions: [],
    })

    const [selectedReferencestatisticsType, setSelectedReferencestatisticsType] = useState<string[]>([])
    const [selectedCompareWithType, setSelectedCompareWithType] = useState<string[]>([])
    const [ComparisonstatisticsOptions, setComparisonstatisticsOptions] = useState<string[]>([])
    const [compareWithRegionsOptions, setCompareWithRegionOptions] = useState<string[]>([])
    const [compareWithBusinessAreaOptions, setCompareWithBusinessAreaOptions] = useState<string[]>([])

    const [selectedReferenceRegister, setSelectedReferenceRegister] = useState("")
    const [selectedCompareWith, setSelectedCompareWith] = useState("")

    const generealStatistics = ComparisonstatisticsAnalysisTypes[0].includes(selectedReferencestatisticsType[0])

    const noValidBusinessAreaOrRegion =
        !fetchingRegionsAndBusinessFocus &&
        !!selectedCompareWithType.length &&
        !regionsAndBusinessFocus.Regions.length &&
        !regionsAndBusinessFocus.BusinessFocus.length

    const convertSelectedTypeToFullName = (referenceStatisticType: string) => {
        const newReferenceStatisticType = ComparisonstatisticsAnalysisTypes.find((type) =>
            type.includes(referenceStatisticType)
        )

        if (newReferenceStatisticType) return newReferenceStatisticType
        return ""
    }

    // Update states when you click filterButtons / select from dropdown
    useEffect(() => {
        if (selectedReferencestatisticsType[0])
            setAnalysisObject({
                ...analysisObject,
                statisticsType: convertSelectedTypeToFullName(selectedReferencestatisticsType[0]),
            })
        setSelectedReferenceRegister("")
        const index = ComparisonstatisticsAnalysisTypes.findIndex((alternative) =>
            alternative.includes(selectedReferencestatisticsType[0])
        )
        const referencestatisticsType = index.toString()
        fetchComparisonstatisticsForAnalysis(
            referencestatisticsType,
            setComparisonstatistics,
            setComparisonstatisticsOptions,
            setFetchingComparisonstatistics,
            setFetchError
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ComparisonstatisticsAnalysisTypes, selectedReferencestatisticsType])

    useEffect(() => {
        setFetchingRegionsAndBusinessFocus(true)
        if (
            fetchingRegionsAndBusinessFocus &&
            selectedReferenceRegister &&
            selectedCompareWithType.length &&
            (compareWithRegionsOptions.length === 0 || compareWithBusinessAreaOptions.length === 0)
        ) {
            fetchAllRegionsAndBusinessFocus(
                selectedReferenceRegisterId,
                setRegionsAndBusinessFocus,
                setCompareWithBusinessAreaOptions,
                setCompareWithRegionOptions,
                setFetchingRegionsAndBusinessFocus,
                setFetchError
            )
        }
    }, [
        fetchingRegionsAndBusinessFocus,
        selectedCompareWithType,
        selectedReferenceRegisterId,
        selectedReferenceRegister,
        selectedCompareWith,
        compareWithRegionsOptions,
        compareWithBusinessAreaOptions,
    ])

    useEffect(() => {
        if (comparisonstatistics.length && selectedReferenceRegister) {
            setSelectedCompareWithType([])
            setCompareWithRegionOptions([])
            setCompareWithBusinessAreaOptions([])
            setSelectedCompareWith("")
            const index = ComparisonstatisticsOptions.findIndex((el) => el === selectedReferenceRegister)
            // first option is an empty string so we have to remove a 1 from index
            if (comparisonstatistics[index - 1]) {
                setSelectedReferenceRegisterId(comparisonstatistics[index - 1].Id)
                setAnalysisObject({
                    ...analysisObject,
                    name: comparisonstatistics[index - 1].Filename,
                    period: comparisonstatistics[index - 1].ValidFor,
                    individualsType: comparisonstatistics[index - 1].EmployeeType,
                })
            }
        } else if (!selectedReferenceRegister) {
            // clears all reference register data if the user select the empty reference register
            setAnalysisObject({
                ...analysisObject,
                businessArea: { Code: 0, Value: "Alla" },
                individualsType: "",
                name: "",
                period: "",
                region: { Code: 0, Value: "Alla" },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ComparisonstatisticsOptions, comparisonstatistics, selectedReferenceRegister, setSelectedReferenceRegisterId])

    useEffect(() => {
        if (compareWithBusinessAreaOptions && compareWithRegionsOptions && selectedCompareWith.length) {
            const compareWithDataArray =
                selectedCompareWithType[0] === "Region"
                    ? regionsAndBusinessFocus.Regions
                    : regionsAndBusinessFocus.BusinessFocus
            const index = compareWithDataArray.findIndex((el) => el.Value === selectedCompareWith)

            setAnalysisObject({
                ...analysisObject,
                region:
                    selectedCompareWithType[0] === "Region" ? compareWithDataArray[index] : { Code: 0, Value: "Alla" },
                businessArea:
                    selectedCompareWithType[0] === "Region" ? { Code: 0, Value: "Alla" } : compareWithDataArray[index],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompareWith, selectedCompareWithType]) // selectedCompareWithType

    useEffect(() => {
        if (selectedReferenceRegister && selectedReferencestatisticsType.length) setActiveSteps([1, 2, 3])
        else setActiveSteps([1, 2])
    }, [selectedReferenceRegister, selectedReferencestatisticsType, setActiveSteps])

    return (
        <>
            {fetchError && (
                <div className="mb24">
                    <Error>{fetchError}</Error>
                </div>
            )}
            <div>
                <div className="mb48">
                    <h2>{ANALYZE_REFERENCESTATISTICS.S2_H2}</h2>
                </div>
                <div className="chooseReferencestatisticsContainer">
                    <div className="flex wrap column">
                        <div>
                            <p className="labelAlike">{ANALYZE_REFERENCESTATISTICS.S2_LABEL}</p>
                            <FilterRadioButtonsRow
                                selectedFilter={selectedReferencestatisticsType}
                                setSelection={setSelectedReferencestatisticsType}
                            >
                                {ANALYZE_REFERENCESTATISTICS.S2_STATISTICS_TYPE_1}
                                {ANALYZE_REFERENCESTATISTICS.S2_STATISTICS_TYPE_2}
                            </FilterRadioButtonsRow>
                        </div>
                        {!!selectedReferencestatisticsType.length && (
                            <div className="paddingBottom24">
                                {!!ComparisonstatisticsOptions.length && !fetchingComparisonstatistics && (
                                    // Referensregister dropdown
                                    <Select
                                        optionsArray={ComparisonstatisticsOptions}
                                        setSelectedOption={setSelectedReferenceRegister}
                                        label={ANALYZE_REFERENCESTATISTICS.S2_SELECT_LABEL_STATISTICS}
                                        maxWidthPx={390}
                                    />
                                )}
                                {(!ComparisonstatisticsOptions.length || fetchingComparisonstatistics) && (
                                    <ContentSkeleton /> // would be cool to have own skeleton for this
                                )}
                            </div>
                        )}
                        {!!selectedReferencestatisticsType.length &&
                            !setFetchingComparisonstatistics &&
                            !comparisonstatistics.length && (
                                <p>{ANALYZE_REFERENCESTATISTICS.S2_NO_SLECTABLE_STATISTICS}</p>
                            )}
                    </div>
                    {generealStatistics && !fetchingComparisonstatistics && selectedReferenceRegister && (
                        <div className="flex wrap column">
                            <div>
                                <p className="labelAlike">{ANALYZE_REFERENCESTATISTICS.S2_LABEL_COMPARE_WITH}</p>
                                {/** Region or Verksamhetsinriktning buttons */}
                                <FilterRadioButtonsRow
                                    selectedFilter={selectedCompareWithType}
                                    setSelection={setSelectedCompareWithType}
                                >
                                    {ANALYZE_REFERENCESTATISTICS.S2_LABEL_REGION}
                                    {ANALYZE_REFERENCESTATISTICS.S2_LABEL_BUSINESS_FOCUSAREA}
                                </FilterRadioButtonsRow>
                            </div>
                            {!!selectedCompareWithType.length && !noValidBusinessAreaOrRegion && (
                                <div className="paddingBottom24">
                                    {/** Region or Verksamhetsinriktnings dropdown */}
                                    <Select
                                        optionsArray={
                                            selectedCompareWithType[0] === "Region"
                                                ? compareWithRegionsOptions
                                                : compareWithBusinessAreaOptions
                                        }
                                        setSelectedOption={setSelectedCompareWith}
                                        label={
                                            selectedCompareWithType[0] === "Region"
                                                ? ANALYZE_REFERENCESTATISTICS.S2_SELECT_LABEL_REGION
                                                : ANALYZE_REFERENCESTATISTICS.S2_SELECT_LABEL_BUSINESS_FOCUSAREA
                                        }
                                        maxWidthPx={390}
                                    />
                                </div>
                            )}
                            {noValidBusinessAreaOrRegion && (
                                <p className="maxWidth390">
                                    {ANALYZE_REFERENCESTATISTICS.S2_NO_SLECTABLE_BUSINESS_OR_REGIONS}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ChooseReferencestatisticsSelection
