import "./ManageReferencestatisticsSelection.css"

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { useMemo, useState, useEffect, useCallback, useRef } from "react"
import { FirstDataRenderedEvent, RowClickedEvent, ValueGetterParams } from "ag-grid-community"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import prepareCsrfToken from "utils/prepareCsrfToken"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { postWithBodyAndCsrf } from "services/apiService"

import { dispatchNumber, tComparisonstatisticsAnalysisObject, tComparisonstatisticsGroup } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

interface IManageReferencestatisticsSelection {
    registryId: string
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    selectedGroups: string[]
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>
    diagramType: number
    setDiagramType: dispatchNumber
    comparisonStaticsticsId: string
    analysisObject: tComparisonstatisticsAnalysisObject
}

const ManageReferencestatisticsSelection = ({
    registryId,
    // preselectedGroups,
    setActiveSteps,
    selectedGroups,
    setSelectedGroups,
    diagramType,
    setDiagramType,
    comparisonStaticsticsId,
    analysisObject,
}: // savedSelection,
IManageReferencestatisticsSelection) => {
    const { S3_TH1, S3_TH2, S3_TH3 } = ANALYZE_REFERENCESTATISTICS

    const diagramTypes = ["", "Lådagram", "Punktdiagram"]

    const diagramTypeInitial = diagramType ? [diagramTypes[diagramType]] : []

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")
    const [savingSelection, setSavingSelection] = useState(false)

    const [groups, setGroups] = useState<tComparisonstatisticsGroup[]>([])
    const [fetchingGroups, setFetchingGroups] = useState(true)

    const [selectedDiagramType, setSelectedDiagramType] = useState(diagramTypeInitial)
    const [selectionName, setSelectionName] = useState(analysisObject.selectionName)
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(selectedGroups)

    const gridRef = useRef<AgGridReact<tComparisonstatisticsGroup>>(null)
    const [columnDefs] = useState([
        {
            field: "GroupName",
            headerName: S3_TH1,
            flex: 1,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            sortable: true,
            filter: true,
        },
        {
            field: "GroupType",
            headerName: S3_TH2,
            valueGetter: (params: ValueGetterParams) => params.data.GroupType.Value,
            flex: 1,
            sortable: true,
            filter: true,
        },
        { field: "Count", headerName: S3_TH3, flex: 0.5, sortable: true, filter: true },
    ])
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const onSelectionChanged = (event: RowClickedEvent) => {
        const groupIds: string[] = []

        const selectedRows = event.api.getSelectedRows()
        selectedRows.forEach((group: tComparisonstatisticsGroup) => {
            groupIds.push(group.GroupId)
        })

        setSelectedGroupIds(groupIds)
        setSelectedGroups(groupIds)
    }

    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<tComparisonstatisticsGroup>) => {
            // If we come back from step 4
            if (selectedGroupIds.length) {
                gridRef.current?.api.forEachNode((node) =>
                    node.setSelected(!!node.data && selectedGroupIds.includes(node.data.GroupId))
                )
            } else {
                // If we come from step 1 by saved selection
                gridRef.current?.api.forEachNode((node) =>
                    node.setSelected(!!node.data && selectedGroups.includes(node.data.GroupId))
                )
            }
        },
        [selectedGroupIds, selectedGroups]
    )

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))

    const saveSelection = () => {
        setSavingSelection(true)

        const body = {
            Id: comparisonStaticsticsId,
            RegistryId: registryId,
            Region: analysisObject.region.Code || 0,
            BusinessFocus: analysisObject.businessArea.Code || 0,
            Name: selectionName,
            DiagramType: diagramType,
            GroupIds: selectedGroupIds,
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeSaveComparisonStatistics, csrfToken, JSON.stringify(body))
                .then(() => {
                    setSavingSelection(false)
                    setShowConfirmation(true)
                    setPostError("")
                })
                .catch((err) => {
                    setSavingSelection(false)
                    setPostError("")
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName")
                                setPostError(ANALYZE_REFERENCESTATISTICS.S3_SAVE_SELECTION_UNVALID_NAME)
                            else setPostError(ANALYZE_REFERENCESTATISTICS.S3_SAVE_SELECTION_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const notChanged = () => !selectionName || !selectedDiagramType[0] || !selectedGroupIds[0]

    // Update states when you click filterButtons
    useEffect(() => {
        const diagType = diagramTypes.findIndex((element) => element === selectedDiagramType[0])
        setDiagramType(diagType)
        if (diagType === 2) deselectAllRows()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDiagramType])
    useEffect(() => {
        if (selectedDiagramType.length && selectedGroupIds.length) setActiveSteps([1, 2, 3, 4])
        else setActiveSteps([1, 2, 3])
    }, [selectedDiagramType, selectedGroupIds.length, setActiveSteps])

    useEffect(() => {
        const body = {
            RegistryId: registryId,
            ComparisonStatisticId: comparisonStaticsticsId,
            Region:
                analysisObject.statisticsType !== "Medlemsanpassad referensstatistik" ? analysisObject.region.Code : "",
            BusinessFocus:
                analysisObject.statisticsType !== "Medlemsanpassad referensstatistik"
                    ? analysisObject.businessArea.Code
                    : "",
        }
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeGetComparisonStatisticsGroups, csrfToken, JSON.stringify(body))
                .then((response: tComparisonstatisticsGroup[]) => {
                    setGroups(response)
                    setFetchingGroups(false)
                    setFetchError("")
                })
                .catch(() => {
                    setFetchingGroups(false)
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }, [comparisonStaticsticsId, registryId, analysisObject])

    return (
        <>
            {fetchingGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingGroups && !groups.length && <p>{ANALYZE_REFERENCESTATISTICS.S3_NO_GROUPS}</p>}
            {!fetchingGroups && !fetchError && !!groups.length && (
                <div>
                    <h2>{ANALYZE_REFERENCESTATISTICS.S3_H2}</h2>
                    <div className="mb40">
                        <p>{ANALYZE_REFERENCESTATISTICS.S3_EXPLANATION}</p>
                        <p>{ANALYZE_REFERENCESTATISTICS.S3_SELECTION_DETAILS}</p>
                        <ul>
                            <li className="bulletlist">
                                {ANALYZE_REFERENCESTATISTICS.S3_SELECTION_COMPARISONDATA}
                                {analysisObject.statisticsType}
                            </li>
                            <li className="bulletlist">
                                {ANALYZE_REFERENCESTATISTICS.S3_SELECTION_INDIVIDUAL_TYPE}
                                {analysisObject.individualsType}
                            </li>
                            <li className="bulletlist">
                                {ANALYZE_REFERENCESTATISTICS.S3_SELECTION_PERIOD}
                                {analysisObject.period}
                            </li>
                            {analysisObject.statisticsType !== "Medlemsanpassad referensstatistik" && (
                                <>
                                    {analysisObject.region.Value && (
                                        <li className="bulletlist">
                                            {ANALYZE_REFERENCESTATISTICS.S3_SELECTION_REGION}
                                            {analysisObject.region.Value}
                                        </li>
                                    )}
                                    {analysisObject.businessArea.Value && (
                                        <li className="bulletlist">
                                            {ANALYZE_REFERENCESTATISTICS.S3_SELECTION_BUSINESSAREA}
                                            {analysisObject.businessArea.Value}
                                        </li>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                    {showConfirmation && (
                        <div className="mb24">
                            <Confirmation>{ANALYZE_REFERENCESTATISTICS.S3_CONFIRMATION_SAVED_SELECTION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <div>
                        <p className="labelAlike">{ANALYZE_REFERENCESTATISTICS.S3_LABEL_DIAGRAM_TYPE}</p>
                        <FilterRadioButtonsRow
                            setSelection={setSelectedDiagramType}
                            selectedFilter={selectedDiagramType}
                        >
                            {ANALYZE_REFERENCESTATISTICS.S3_DIAGRAM_TYPE_1}
                            {ANALYZE_REFERENCESTATISTICS.S3_DIAGRAM_TYPE_2}
                        </FilterRadioButtonsRow>
                    </div>
                    <div className="maxWidth300">
                        <label className="gray" htmlFor="createSelection">
                            {ANALYZE_REFERENCESTATISTICS.S3_LABEL_NAME}
                        </label>
                        <Input
                            type="text"
                            id="createSelection"
                            name="createSelection"
                            value={selectionName}
                            onChange={(e) => setSelectionName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <div
                            className="ag-theme-alpine"
                            style={{ width: "100%", height: "600px", marginBottom: "64px" }}
                        >
                            <h3 className="hInsideAgGrid">{ANALYZE_REFERENCESTATISTICS.S3_CAPTION_GROUPS}</h3>
                            <AgGridReact
                                ref={gridRef}
                                rowData={groups}
                                columnDefs={columnDefs}
                                localeText={localeText}
                                rowSelection={diagramType === 1 ? "multiple" : "single"}
                                onFirstDataRendered={onFirstDataRendered}
                                onSelectionChanged={onSelectionChanged}
                                rowMultiSelectWithClick
                                alwaysShowHorizontalScroll
                                alwaysShowVerticalScroll
                                suppressMenuHide
                            />
                        </div>
                    </div>
                    <div className="flex jc-space-between">
                        <Button onClick={deselectAllRows} disabled={selectedGroupIds.length === 0} Icon={CleanIcon}>
                            {ANALYZE_REFERENCESTATISTICS.S3_BUTTON_CLEAR_SELECTION}
                        </Button>
                        <Button
                            Icon={SaveIcon}
                            disabled={notChanged()}
                            onClick={saveSelection}
                            isLoading={savingSelection}
                        >
                            {ANALYZE_REFERENCESTATISTICS.S3_BUTTON_SAVE_SELECTION}
                        </Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default ManageReferencestatisticsSelection
