import "./Select.css"

type SelectProps = {
    optionsArray: string[]
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>
    label?: string
    maxWidthPx?: number
    selected?: string
    noDefaultValue?: boolean
}

const Select = ({
    optionsArray,
    setSelectedOption,
    label,
    maxWidthPx = 260,
    noDefaultValue,
    selected,
}: SelectProps) => (
    <div className="generalSelect" style={{ maxWidth: maxWidthPx }}>
        {label && <label htmlFor="select">{label}</label>}
        <select
            onChange={(e) => setSelectedOption(e.target.value)}
            name="select"
            id="select"
            className="selectDropdown"
            value={selected}
        >
            {noDefaultValue && <option value="none" />}
            {optionsArray.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    </div>
)

export default Select
