import "./AdministerAccountPage.css"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import pageTitles from "constants/pageTitles.constants.json"
import { MAIN_ACCOUNT_TEXTS } from "constants/text.constants"

import { useEffect, useState, useMemo } from "react"

import { AgGridReact } from "ag-grid-react"

import { IMainAccount, tMainAccountRegisterHealth } from "types/sharedTypes"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { fetchMainAccounts, fetchMainAccountsRegistersHealth } from "services/fetchers"

import Error from "components/atoms/error/Error"
import Skeleton from "components/atoms/skeleton/Skeleton"
import Confirmation from "components/atoms/confirmation/Confirmation"
import SelectMainAccount from "components/cores/selectMainAccount/SelectMainAccount"

import RebuildRegisterButton from "./rebuildRegister/RebuildRegisterButton"

const AdministerAccountPage = () => {
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)

    const [mainAccounts, setMainAccounts] = useState<IMainAccount[]>()
    const [fetchingMainAccounts, setFetchingMainAccounts] = useState(true)

    const [registers, setRegisters] = useState<tMainAccountRegisterHealth[]>([])
    const [fetchingRegistersHealth, setFetchingRegistersHealth] = useState(false)

    const [selectedAccountId, setSelectedAccountId] = useState("")

    const [columnDefs] = useState([
        {
            field: "Name",
            headerName: MAIN_ACCOUNT_TEXTS.ADMINISTRATE_TH1,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        {
            field: "ViewState",
            headerName: MAIN_ACCOUNT_TEXTS.ADMINISTRATE_TH2,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        {
            headerName: MAIN_ACCOUNT_TEXTS.ADMINISTRATE_TH3,
            width: 200,
            cellRenderer: RebuildRegisterButton,
            cellRendererParams: {
                setShowConfirmation,
                setPostError,
            },
        },
    ])
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    useEffect(() => fetchMainAccounts(setMainAccounts, setFetchingMainAccounts, setFetchError), [])

    useEffect(() => {
        if (selectedAccountId && selectedAccountId !== "none") {
            setFetchingRegistersHealth(true)
            fetchMainAccountsRegistersHealth(selectedAccountId, setRegisters, setFetchingRegistersHealth, setFetchError)
        }
    }, [selectedAccountId])

    useUpdatePageTitle(pageTitles.ADMINISTER_MAIN_ACCOUNT_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <div className="administratePageHeader">
                <h1>{MAIN_ACCOUNT_TEXTS.ADMINISTRATE_H1}</h1>
                <p>{MAIN_ACCOUNT_TEXTS.ADMINISTRATE_EXPLANATION}</p>
            </div>
            {fetchingMainAccounts && <Skeleton />}
            {fetchError && <div>{fetchError}</div>}
            {!fetchingMainAccounts && !fetchError && (
                <div className="maxWidth1000">
                    {mainAccounts && (
                        <div className="paddingBottom40">
                            <SelectMainAccount
                                mainAccountMembers={mainAccounts}
                                setSelectedMainAccountId={setSelectedAccountId}
                                labelText={MAIN_ACCOUNT_TEXTS.ADMINISTRATE_SELECT_LABEL}
                            />
                        </div>
                    )}
                    {showConfirmation && (
                        <div className="mb24">
                            <Confirmation>{MAIN_ACCOUNT_TEXTS.CONFIRMATION_DELETED}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {fetchingRegistersHealth && <Skeleton />}
                    {!fetchingRegistersHealth && !registers.length && !!selectedAccountId && (
                        <p>{MAIN_ACCOUNT_TEXTS.ADMINISTRATE_NO_REGISTERS}</p>
                    )}
                    {!fetchingRegistersHealth && !!registers.length && selectedAccountId !== "none" && (
                        <div className="ag-theme-alpine" style={{ width: "100%", height: "60vh" }}>
                            <AgGridReact
                                rowData={registers}
                                columnDefs={columnDefs}
                                localeText={localeText}
                                alwaysShowHorizontalScroll
                                alwaysShowVerticalScroll
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AdministerAccountPage
