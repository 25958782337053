/* eslint-disable no-return-assign, no-param-reassign */

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { ColDef, RowHeightParams } from "ag-grid-community"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"
import { useMemo, useRef, useState } from "react"

import { dispatchBool, tEquivalentWork } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"

import EquivalentWorkReviewTableButton from "./EquivalentWorkReviewTableButton"
import EquivalentWorkReviewTableObserve from "./EquivalentWorkReviewTableObserve"

interface IEquivalentWorkReviewGroupsTable {
    equivalentWorkGroups: tEquivalentWork[]
    setShowCommentsModal: dispatchBool
    setCurrentGroupInfo: React.Dispatch<React.SetStateAction<{ Name: string; Id: string }>>
}

const EquivalentWorkReviewGroupsTable = ({
    equivalentWorkGroups,
    setShowCommentsModal,
    setCurrentGroupInfo,
}: IEquivalentWorkReviewGroupsTable) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const defaultColDef = KeyboardNavigation()

    const gridRef = useRef<AgGridReact<tEquivalentWork>>(null)

    const { S6_TH1, S6_TH2, S6_TH3, S6_TH4, S6_TH5, S6_TH6, S6_TH7, S6_TH8, S6_TH9, S6_TH10 } = ANALYZE_WAGE_GAP

    const [columnDefs] = useState<ColDef[]>([
        { field: "GroupName", headerName: S6_TH1, width: 100, sortable: true, resizable: true, pinned: "left" },
        { field: "Count", headerName: S6_TH2, width: 80, sortable: true, resizable: true },
        { field: "NumberOfWomanPercent", headerName: S6_TH3, width: 128, sortable: true, resizable: true },
        { field: "WomanSalaryShare", headerName: S6_TH4, width: 160, sortable: true, resizable: true },
        {
            field: "AverageSalary",
            headerName: S6_TH5,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()), // insane how simple and smooth
        },
        {
            field: "MedianPercentile",
            headerName: S6_TH6,
            width: 110,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastFirstTenthPercentile",
            headerName: S6_TH7,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()),
        },
        { field: "FormattedSumPoints", headerName: S6_TH8, width: 168, sortable: true, resizable: true },
        {
            headerName: S6_TH9,
            width: 120,
            resizable: true,
            cellRenderer: EquivalentWorkReviewTableObserve,
        },
        {
            headerName: S6_TH10,
            width: 170,
            cellRenderer: EquivalentWorkReviewTableButton,
            cellRendererParams: {
                setShowCommentsModal,
                setCurrentGroupInfo,
            },
        },
    ])

    const IdsOflastGroupsInBestaGrouping = [equivalentWorkGroups[0].GroupId]
    const bestaGroupLevels = [equivalentWorkGroups[0].GroupLevel]
    equivalentWorkGroups.forEach((group) => {
        if (group.GroupLevel === bestaGroupLevels.at(-1)) {
            IdsOflastGroupsInBestaGrouping[IdsOflastGroupsInBestaGrouping.length - 1] = group.GroupId
        } else {
            IdsOflastGroupsInBestaGrouping.push(group.GroupId)
            bestaGroupLevels.push(group.GroupLevel)
        }
    })
    // adding a decimal to sumpoints value and giving it to the new formatted version formattedSumPoints
    equivalentWorkGroups.forEach((group) => {
        group.FormattedSumPoints = group.SumPoints.toFixed(1)
    })
    // eslint-disable-next-line consistent-return
    const getRowStyle = (params: any) => {
        if (IdsOflastGroupsInBestaGrouping.includes(params.data.GroupId)) {
            return { borderBottom: "10px solid #e6e6e6" }
        }
    }
    const getRowHeight = (params: RowHeightParams) => {
        let rowContentHeight = 50
        if (IdsOflastGroupsInBestaGrouping.includes(params.data.GroupId)) rowContentHeight = 60
        return rowContentHeight
    }

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "50vh", marginTop: "16px" }}>
            <AgGridReact
                ref={gridRef}
                rowData={equivalentWorkGroups}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                localeText={localeText}
                getRowStyle={getRowStyle}
                getRowHeight={getRowHeight}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default EquivalentWorkReviewGroupsTable
