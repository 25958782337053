import "./ManualPage.css"

import apiEndpoints  from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import GENERAL_TEXTS, { HELP_TEXTS } from "constants/text.constants"

import { ISupportItem, ISupportModalTextObject } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { useContext, useEffect, useState } from "react"

import { fetchSupportItem } from "services/fetchers"
import { postWithBodyAndCsrf, putWithBodyAndCsrf } from "services/apiService"

import RolesContext from "context/RolesContext"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

import SearchBar from "../components/searchBar/SearchBar"
import HelpAccordion from "../components/helpAccordion/HelpAccordion"
import HelpAndSupportModal from "../components/helpAndSupportModal/HelpAndSupportModal"
import editBodyDataToSave from "../components/tinymceEditor/editBodyDataToSave"

const ManualPage = () => {
    const { agvAdmin } = useContext(RolesContext)

    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [manualData, setManualData] = useState<ISupportItem[]>([])
    const [fetchingManualData, setFetchingManualData] = useState(true)

    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    const [originalManualTitle, setOriginalManualTitle] = useState("")
    const [fieldsChanged, setFieldsChanged] = useState(false)

    const [postingCreateManualChapter, setPostingCreateManualChapter] = useState(false)
    const [postingEditContent, setPostingEditContent] = useState(false)

    const textsObjectInitialValues = { modalContent: "", h1: "", labelTitle: "", labelContent: "", submit: "" }
    const [textsObject, setTextsObject] = useState<ISupportModalTextObject>(textsObjectInitialValues)

    const [manualChapterId, setManualChapterId] = useState("")
    const [manualChapterTitle, setManualChapterTitle] = useState("")
    const [manualChapterContent, setManualChapterContent] = useState("")

    const [filter, setFilter] = useState("")
    const [filteredData, setFilteredData] = useState<ISupportItem[]>([])

    const updateTitleChanged = (changed: boolean) => {
        setFieldsChanged(changed)
    }

    const createManualChapter = (ChapterTitle: string, Content: string) => {
        setPostingCreateManualChapter(true)
        const body = { ChapterTitle, Content }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().getManual, csrfToken, JSON.stringify(body))
                .then((response) => {
                    setPostingCreateManualChapter(false)
                    setPostError("")

                    manualData.unshift({
                        Id: response,
                        Title: ChapterTitle,
                        Body: editBodyDataToSave(Content),
                        SortBy: ChapterTitle,
                    })

                    setShowCreateModal(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingCreateManualChapter(false)
                    setShowCreateModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const updateManualContent = (Id: string, ChapterTitle: string, Content: string) => {
        setPostingEditContent(true)

        const body = { Id, ChapterTitle, Content: editBodyDataToSave(Content) }
        prepareCsrfToken().then((csrfToken) =>
            putWithBodyAndCsrf(idMapper(apiEndpoints().editManualById, Id), csrfToken, JSON.stringify(body))
                .then((response: string) => {
                    setPostingEditContent(false)
                    setPostError("")
                    const indexOfUpdatedManualContent = manualData.findIndex(
                        (manualObject) => manualObject.Id === response
                    )

                    // Mutate manualData since we have updated it
                    manualData[indexOfUpdatedManualContent] = {
                        ...manualData[indexOfUpdatedManualContent],
                        Title: ChapterTitle,
                        Body: editBodyDataToSave(Content),
                    }
                    setShowEditModal(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setPostingEditContent(false)
                    setShowEditModal(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const handleCreateManualContent = () => {
        const { MANUAL_MODAL_CONTENT_CREATE, HEADING_MANUAL_CREATE, LABEL_MANUAL_TITLE, LABEL_MANUAL_CONTENT } =
            HELP_TEXTS
        const { BUTTON_ADD } = GENERAL_TEXTS

        setManualChapterTitle("")
        setManualChapterContent("")

        setTextsObject({
            modalContent: MANUAL_MODAL_CONTENT_CREATE,
            h1: HEADING_MANUAL_CREATE,
            labelTitle: LABEL_MANUAL_TITLE,
            labelContent: LABEL_MANUAL_CONTENT,
            submit: BUTTON_ADD,
        })
        setShowCreateModal(true)
    }

    const handleEditManualContent = (Id: string, ChapterTitle: string, Content: string) => {
        const { MANUAL_MODAL_CONTENT_EDIT, HEADING_MANUAL_UPDATE, LABEL_MANUAL_TITLE, LABEL_MANUAL_CONTENT } =
            HELP_TEXTS
        const { BUTTON_CHANGE } = GENERAL_TEXTS

        setManualChapterId(Id)
        setManualChapterTitle(ChapterTitle)
        setManualChapterContent(Content)
        setTextsObject({
            modalContent: MANUAL_MODAL_CONTENT_EDIT,
            h1: HEADING_MANUAL_UPDATE,
            labelTitle: LABEL_MANUAL_TITLE,
            labelContent: LABEL_MANUAL_CONTENT,
            submit: BUTTON_CHANGE,
        })
        setShowEditModal(true)

        setOriginalManualTitle(ChapterTitle)

        setFieldsChanged(false)
    }

    useEffect(() => fetchSupportItem(apiEndpoints().getManual, setManualData, setFetchingManualData, setFetchError), [])

    useUpdatePageTitle(pageTitles.MANUAL_PAGE_TITLE)
    useScrollToTop()

    return (
        <div>
            <PageHeader pageId={pageIds.MANUAL_PAGE_ID} />
            {fetchingManualData && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingManualData && !fetchError && manualData && (
                <div className="maxWidth800">
                    <div className="searchAndAddButtonContainer mb32">
                        {/* <SearchBar
                            originalData={manualData}
                            label={HELP_TEXTS.SEARCH_LABEL}
                            filter={filter}
                            setFilter={setFilter}
                            setFilteredData={setFilteredData}
                        /> */}
                        <div />
                        {agvAdmin && (
                            <Button Icon={CirclePlusIcon} onClick={handleCreateManualContent}>
                                {GENERAL_TEXTS.BUTTON_ADD}
                            </Button>
                        )}
                    </div>
                    <HelpAccordion
                        supportItemArray={filter ? filteredData : manualData}
                        setSupportItemArray={setManualData}
                        agvAdmin={agvAdmin}
                        handleEditSupportItem={handleEditManualContent}
                        setShowConfirmation={setShowConfirmation}
                        setPostError={setPostError}
                    />
                    {showConfirmation && <Confirmation>{GENERAL_TEXTS.CONFIRMATION_SUCCESSFUL}</Confirmation>}
                    {postError && <Error>{postError}</Error>}
                    {showCreateModal && (
                        <HelpAndSupportModal
                            showModal={showCreateModal}
                            setShowModal={setShowCreateModal}
                            posting={postingCreateManualChapter}
                            modalTexts={textsObject}
                            title={manualChapterTitle}
                            setTitle={setManualChapterTitle}
                            content={manualChapterContent}
                            onClick={(content: string) => createManualChapter(manualChapterTitle, content)}
                        />
                    )}
                    {showEditModal && (
                        <HelpAndSupportModal
                            showModal={showEditModal}
                            setShowModal={setShowEditModal}
                            posting={postingEditContent}
                            modalTexts={textsObject}
                            title={manualChapterTitle}
                            setTitle={setManualChapterTitle}
                            content={manualChapterContent}
                            onClick={(content: string) =>
                                updateManualContent(manualChapterId, manualChapterTitle, content)
                            }
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default ManualPage
