import { IboxplotDiagramData } from "types/sharedTypes"

function prepareBoxplotDiagramData(unpreparedData: string[], chartTitle: string, yAxisTitle: string) {
    const preparedDiagramData: IboxplotDiagramData = {
        ChartTitle: chartTitle,
        YAxisTitle: yAxisTitle,
        Data: [],
        Categories: [],
    }

    // type tSeries = { Categories: string; Data: number[][]; ID: string; YAxisTitle: string }

    // unpreparedData.forEach((row, index) => {
    //     const series: tSeries = JSON.parse(row)[0]
    //     console.log("parse result fe row", series)
    //     preparedDiagramData.Categories.push(series.Categories[0])

    //     const data: number[] = []
    //     series.Data.forEach((salaryArr: number[]) => {
    //         salaryArr.unshift(index)
    //         data.push(salaryArr)
    //     })
    //     preparedDiagramData.Data.push(data)
    // })

    const diagData: any[] = []
    unpreparedData.forEach((row) => {
        const result = JSON.parse(row)
        diagData.push({
            Name: result[0].Categories[0],
            Data: result[0].Data,
        })
    })
    // Don't ask me how it works. I broke my brain trying to move / simplify
    // the logic from old code but ended up just moving 1-for-1
    diagData.forEach((group, index) => {
        preparedDiagramData.Categories.push(group.Name)
        group.Data.forEach((arr: any) => {
            arr.splice(0, 0, index)
            preparedDiagramData.Data.push(arr)
        })
    })

    // console.log("prepared data bro", preparedDiagramData)

    preparedDiagramData.Data.forEach((d) => {
        d.sort((a, b) => a - b)
    })

    return preparedDiagramData
}

export default prepareBoxplotDiagramData
