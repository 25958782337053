import "./Input.css"

import { ReactComponent as CircleExclamationIcon } from "./circle-exclamation-solid.icon.svg"

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    isErroneous?: boolean
    errorMessage?: string
    noArrows?: boolean
}

const Input = ({
    type,
    id,
    name,
    placeholder,
    onChange,
    onClick,
    required,
    isErroneous,
    errorMessage,
    value = undefined,
    noArrows = false,
}: IInput) => {
    const inputClassName = `input ${isErroneous ? "erroneous" : ""} ${noArrows ? "noArrows" : ""}`

    return (
        <div className="marginBottom32">
            <input
                className={inputClassName}
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onClick={onClick}
                value={value}
                required={required}
            />
            {isErroneous && (
                <div className="errorMessage">
                    <CircleExclamationIcon width="0.75rem" height="0.75rem" fill="#fff" className="icon" />
                    {errorMessage}
                </div>
            )}
        </div>
    )
}

export default Input
