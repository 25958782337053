import { IboxplotDiagramData } from "types/sharedTypes"

function prepareBoxplotDiagramDataSalaryDifference(
    unpreparedData: string[],
    chartTitle: string,
    yAxisTitle: string,
    groupWithMajorityWomen: string
) {
    const preparedDiagramData: IboxplotDiagramData = {
        ChartTitle: chartTitle,
        YAxisTitle: yAxisTitle,
        Data: [],
        Categories: [],
    }

    const diagData: any[] = []
    unpreparedData.forEach((row) => {
        const result = JSON.parse(row)
        diagData.push({
            Name: result[0].Categories[0],
            Data: result[0].Data,
        })
    })
    // sorting the data so the women dominating group are positioned first in the array
    diagData.sort((a, b) => {
        if (a.Name === groupWithMajorityWomen) return -1 // move rows with the dominating group data to the front
        if (b.Name === groupWithMajorityWomen) return 1
        return 0 // maintain the original order for other rows
    })

    diagData.forEach((group, index) => {
        preparedDiagramData.Categories.push(group.Name)
        group.Data.forEach((arr: any) => {
            arr.splice(0, 0, index)
            preparedDiagramData.Data.push(arr)
        })
    })

    return preparedDiagramData
}

export default prepareBoxplotDiagramDataSalaryDifference
