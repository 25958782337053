import "./EquivalentWork.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect } from "react"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchAllAssessmentGroups, fetchAssessments } from "services/fetchers"

import { dispatchNumber, tGapMenWomenAllAssessmentGroup, tGapMenWomenAssessments } from "types/sharedTypes"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import Select from "components/atoms/select/Select"
import idMapper from "utils/idMapper"

interface IEquivalentWork {
    analysisId: string
    setActiveStep: dispatchNumber
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

type tAssessmentsArray = { name: string; weight: string; id: string }[]

const EquivalentWork = ({ analysisId, setActiveStep, setActiveSteps }: IEquivalentWork) => {
    const [fetchError, setFetchError] = useState("")
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [assessments, setAssessments] = useState<tGapMenWomenAssessments>()
    const [noAssessments, setNoAssessments] = useState(false)
    const [fetchingAssessments, setFetchingAssessments] = useState(true)

    const [allAssessmentGroups, setAllAssessmentGroups] = useState<tGapMenWomenAllAssessmentGroup[]>([])
    const [fetchingAllAssessmentGroups, setFetchingAllAssessmentGroups] = useState(true)
    const [allAssessmentGroupsNames, setAllAssessmentGroupsNames] = useState<string[]>([])
    const [selectedAssessmentName, setSelectedAssessmentName] = useState("")

    const [savingAssessment, setSavingAssessment] = useState(false)
    const [assessmentIndex, setAssessmentIndex] = useState(3)

    const [assessmentsArray, setAssessmentsArray] = useState<tAssessmentsArray>([
        { name: "", weight: "", id: "0" },
        { name: "", weight: "", id: "1" },
        { name: "", weight: "", id: "2" },
    ])

    const [selectionName, setSelectionName] = useState("")
    const [invalidSelectionName, setInvalidSelectionName] = useState(false)
    const [submitAndContinue, setSubmitAndContinue] = useState(false)

    const removeAssessmentRow = (ix: number) => {
        const newAssessments = [...assessmentsArray]
        newAssessments.splice(ix, 1)
        setAssessmentsArray(newAssessments)
    }
    const addAssessmentRow = () => {
        const newAssessments = [...assessmentsArray]
        newAssessments.push({ name: "", weight: "", id: assessmentIndex.toString() })
        setAssessmentIndex(assessmentIndex + 1)
        setAssessmentsArray(newAssessments)
    }
    const totalAssesmentCount = assessmentsArray
        .map((assessment) => parseFloat(assessment.weight)) // 0.1 + 0.2 !== 0.3 in JS!
        // .map((assessment) => parseInt(assessment.weight, 10))
        // .map((assessment) => Math.round((+Number(assessment.weight) + Number.EPSILON) * 100000000) / 100000000)
        // .map((assessment) => +Number(assessment.weight))
        .reduce((prev, next) => prev + next)
        .toFixed(10)
        .toString()
        .slice(0, 4)

    const checkFieldsNonEmpty = (assessmentsArr: tAssessmentsArray) => {
        let nonEmpty = false
        assessmentsArr.forEach((assessment) => {
            if (!!assessment.name && !!assessment.weight) nonEmpty = true
            else nonEmpty = false
        })
        return nonEmpty
    }
    const validForSaving = checkFieldsNonEmpty(assessmentsArray) && !!selectionName && Number(totalAssesmentCount) === 1

    const validAssessmentsArray = () => {
        let valid = true

        if (selectionName === assessments?.Name) {
            assessments.Assessments?.forEach((assessment, ix) => {
                if (ix < assessmentsArray.length) {
                    if (
                        assessments.Assessments &&
                        (assessment.Id !== assessmentsArray[ix].id ||
                            assessment.Text !== assessmentsArray[ix].name ||
                            assessment.Weight.toString() !== assessmentsArray[ix].weight)
                    ) {
                        valid = false
                    }
                }
            })
        }

        if (allAssessmentGroupsNames.includes(selectionName) && selectionName !== assessments?.Name) {
            const index = allAssessmentGroups.findIndex((allAssessments) => allAssessments.Name === selectionName)
            allAssessmentGroups[index].Assessments?.forEach((assessment, ix) => {
                if (ix < assessmentsArray.length) {
                    if (
                        assessment.Id !== assessmentsArray[ix].id ||
                        assessment.Text !== assessmentsArray[ix].name ||
                        assessment.Weight.toString() !== assessmentsArray[ix].weight
                    ) {
                        valid = false
                    }
                }
            })
        }
        return valid
    }

    const submitSelection = (saveAndContinue: boolean) => {
        setShowWarning(false)
        if (!validAssessmentsArray()) {
            setInvalidSelectionName(true)
            return
        }

        const submitAssessmentsArray = assessmentsArray.map((el) => ({ Id: el.id, Text: el.name, Weight: el.weight }))
        const body = { Id: analysisId, AssessmentGroup: { Name: selectionName, Assessments: submitAssessmentsArray } }
        const url = saveAndContinue
            ? idMapper(apiEndpoints().analyzeSaveAssessmentGroup, analysisId)
            : idMapper(apiEndpoints().analyzeSaveAssessmentGroupDraft, analysisId)

        setShowConfirmation(false)
        setPostError("")

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body))
                .then(() => {
                    fetchAllAssessmentGroups(
                        analysisId,
                        setAllAssessmentGroups,
                        setFetchingAllAssessmentGroups,
                        setFetchError
                    )
                    fetchAssessments(
                        analysisId,
                        setAssessments,
                        setNoAssessments,
                        setFetchingAssessments,
                        setFetchError
                    )

                    setSelectedAssessmentName(selectionName)
                    setSavingAssessment(false)
                    if (!saveAndContinue) setShowConfirmation(true)
                    if (saveAndContinue) setActiveStep(5)
                    setActiveSteps([1, 2, 3, 4, 5])
                })
                .catch((err) => {
                    setSavingAssessment(false)
                    if (err.text) {
                        err.text().then((text: string) => {
                            if (text.slice(0, 11) === "UnvalidName")
                                setPostError(`${ANALYZE_WAGE_GAP.S4_POST_ERROR_UNVALID_NAME} ${text.slice(13)}`)
                        })
                    } else setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }

    const handleSubmitSelection = (saveAndContinue = false) => {
        setShowConfirmation(false)
        setInvalidSelectionName(false)
        setPostError("")
        setSubmitAndContinue(saveAndContinue)

        if (noAssessments) {
            submitSelection(saveAndContinue)
        } else setShowWarning(true)
    }

    useEffect(() => {
        fetchAssessments(analysisId, setAssessments, setNoAssessments, setFetchingAssessments, setFetchError)
    }, [analysisId])

    useEffect(() => {
        fetchAllAssessmentGroups(analysisId, setAllAssessmentGroups, setFetchingAllAssessmentGroups, setFetchError)
    }, [analysisId])

    // Update options in select(dropdown) component if allAssessmentGroups is not empty
    useEffect(() => {
        if (allAssessmentGroups.length) {
            const fetchedAssessmentGroupNames = allAssessmentGroups.map((group) => {
                if (group.Name !== null) return group.Name
                return ""
            })
            if (fetchedAssessmentGroupNames[0]) setAllAssessmentGroupsNames(fetchedAssessmentGroupNames)
        }
    }, [allAssessmentGroups, allAssessmentGroups.length])

    // State updates based on what you choose in dropdownlist
    useEffect(() => {
        const index = allAssessmentGroups.findIndex((assessment) => assessment.Name === selectedAssessmentName)
        if (index > -1 && selectedAssessmentName && selectedAssessmentName !== "none") {
            setSelectionName(selectedAssessmentName)
            const savedAssessments: tAssessmentsArray = []
            allAssessmentGroups[index].Assessments?.forEach((assessment) =>
                savedAssessments.push({
                    name: assessment.Text,
                    weight: assessment.Weight.toString(),
                    id: assessment.Id,
                })
            )
            setAssessmentsArray(savedAssessments)
        }
        // else {
        //     setSelectionName("")
        //     setAssessmentsArray([
        //         { name: "", weight: "" },
        //         { name: "", weight: "" },
        //         { name: "", weight: "" },
        //     ])
        // }
        // }, [allAssessmentGroups, selectedAssessmentName])
    }, [allAssessmentGroups, selectedAssessmentName])

    // State update if you have a saved assessment with weights
    useEffect(() => {
        if (assessments && !!assessments.Assessments && !!assessments.Name) {
            setSelectionName(assessments.Name)
            const savedAssessment: tAssessmentsArray = []
            assessments.Assessments.forEach((assessment) =>
                savedAssessment.push({ name: assessment.Text, weight: assessment.Weight.toString(), id: assessment.Id })
            )
            setAssessmentsArray(savedAssessment)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessments])

    return (
        <>
            {(fetchingAssessments || fetchingAllAssessmentGroups) && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {(!fetchingAssessments || !fetchingAllAssessmentGroups) && !fetchError && (
                <div>
                    <h2>{ANALYZE_WAGE_GAP.S4_H2}</h2>
                    {showConfirmation && (
                        <Confirmation>{ANALYZE_WAGE_GAP.S4_CONFIRMATION_SAVED_SELECTION}</Confirmation>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {showWarning && (
                        <Warning onClick={() => submitSelection(submitAndContinue)} showWarning={setShowWarning}>
                            {ANALYZE_WAGE_GAP.S4_WARNING}
                        </Warning>
                    )}
                    <div>
                        <p>{ANALYZE_WAGE_GAP.S4_STEP_EXPLANATION}</p>
                        <p>{ANALYZE_WAGE_GAP.S4_STEP_EXPLANATION_2}</p>
                        <p>{ANALYZE_WAGE_GAP.S4_STEP_EXPLANATION_3}</p>
                        <div className="flex gap32">
                            <div>
                                <div>
                                    <label className="gray rem-1 mb4" htmlFor="createSelection">
                                        {ANALYZE_WAGE_GAP.S4_LABEL_NAME}
                                    </label>
                                    <Input
                                        type="text"
                                        id="createSelection"
                                        name="createSelection"
                                        value={selectionName}
                                        onChange={(e) => setSelectionName(e.target.value)}
                                        required
                                        isErroneous={invalidSelectionName}
                                        errorMessage="Kan inte ha samma namn som någon av redan sparade"
                                    />
                                </div>
                                <div className="flex jc-space-between">
                                    <p>{ANALYZE_WAGE_GAP.S4_ASSESSMENT_NAME}</p>
                                    <p>{ANALYZE_WAGE_GAP.S4_WEIGHT}</p>
                                </div>
                                {assessmentsArray.map((column, index) => (
                                    <div className="flex gap8 ai-flex-start" key={column.id}>
                                        <div className="flex1">
                                            <Input
                                                type="text"
                                                id={column.name}
                                                value={column.name}
                                                onChange={(e) => {
                                                    const newAssessments = [...assessmentsArray]
                                                    newAssessments[index].name = e.target.value
                                                    setAssessmentsArray(newAssessments)
                                                }}
                                                required
                                                // isErroneous={validationError.email}
                                                // errorMessage={GENERAL_TEXTS.VALIDATION_EMAIL}
                                            />
                                        </div>
                                        <div className="flex0pt3">
                                            <Input
                                                type="number"
                                                value={column.weight}
                                                onChange={(e) => {
                                                    const newAssessments = [...assessmentsArray]
                                                    newAssessments[index].weight = e.target.value
                                                    setAssessmentsArray(newAssessments)
                                                }}
                                                required
                                                noArrows
                                                // isErroneous={validationError.email}
                                                // errorMessage={GENERAL_TEXTS.VALIDATION_EMAIL}
                                            />
                                        </div>
                                        {index > 2 && (
                                            <div className="mt6">
                                                <Button variant="delete" onClick={() => removeAssessmentRow(index)}>
                                                    {ANALYZE_WAGE_GAP.S4_BUTTON_REMOVE}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="mb24">
                                    <Button onClick={addAssessmentRow} Icon={CirclePlusIcon}>
                                        {ANALYZE_WAGE_GAP.S4_BUTTON_ADD_ROW}
                                    </Button>
                                </div>
                                <div className="flex jc-space-between bg-lightblue mb24 ai-center paddingTotalSum">
                                    <strong>{ANALYZE_WAGE_GAP.S4_WEIGTH_SUM}</strong>
                                    <p>{totalAssesmentCount}</p>
                                </div>
                            </div>
                            <div>
                                <p className="labelALike margin0 mb8">{ANALYZE_WAGE_GAP.S4_LABEL_SAVED_ASSESSMENTS}</p>
                                <Select
                                    optionsArray={allAssessmentGroupsNames}
                                    setSelectedOption={setSelectedAssessmentName}
                                    noDefaultValue
                                    selected={selectedAssessmentName}
                                    maxWidthPx={390}
                                />
                            </div>
                        </div>
                        <div className="flex gap8">
                            <Button
                                disabled={!validForSaving}
                                onClick={() => handleSubmitSelection(false)}
                                Icon={SaveIcon}
                                isLoading={savingAssessment}
                            >
                                {ANALYZE_WAGE_GAP.S4_BUTTON_SAVE_SELECTION}
                            </Button>
                            <Button
                                disabled={!validForSaving}
                                onClick={() => handleSubmitSelection(true)}
                                Icon={SaveIcon}
                                isLoading={savingAssessment}
                            >
                                {ANALYZE_WAGE_GAP.S4_BUTTON_SAVE_SELECTION_N_CONTINUE}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default EquivalentWork
