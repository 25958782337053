/* eslint-disable react/destructuring-assignment */
import "./ChangeCustomColumnsForm.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { REGISTER_TEXTS } from "constants/text.constants"

import { useEffect, useState } from "react"

import { ICustomColumns } from "types/sharedTypes"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"
import { postWithBodyAndCsrf, getJSONrequest } from "services/apiService"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

import Input from "components/atoms/input/Input"
import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Skeleton from "components/atoms/skeleton/Skeleton"
import Confirmation from "components/atoms/confirmation/Confirmation"

const ChangeCustomColumnsForm = ({ id, closeModal }: { id: string; closeModal: () => void }) => {
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")
    const [fetchingCustomColumns, setFetchingCustomColumns] = useState(true)
    const [updatingCustomColumns, setUpdatingCustomColumns] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [cc, setCC] = useState(["Fri1", "Fri2", "Fri3", "Fri4", "Fri5", "Fri6", "Fri7", "Fri8", "Fri9", "Fri10"])

    useEffect(() => {
        const dd = {
            Id: id,
            RegistryId: "00000000-0000-0000-0000-000000000000",
            CustomColumn1: "Fri1",
            CustomColumn2: "Fri2",
            CustomColumn3: "Fri3",
            CustomColumn4: "Fri4",
            CustomColumn5: "Fri5",
            CustomColumn6: "Fri6",
            CustomColumn7: "Fri7",
            CustomColumn8: "Fri8",
            CustomColumn9: "Fri9",
            CustomColumn10: "Fri10",
        }

        const processCustomColumnsData = (customColumns: ICustomColumns) => {
            const {
                CustomColumn1,
                CustomColumn2,
                CustomColumn3,
                CustomColumn4,
                CustomColumn5,
                CustomColumn6,
                CustomColumn7,
                CustomColumn8,
                CustomColumn9,
                CustomColumn10,
            } = customColumns

            const prevCC = cc
            prevCC[0] = CustomColumn1
            prevCC[1] = CustomColumn2
            prevCC[2] = CustomColumn3
            prevCC[3] = CustomColumn4
            prevCC[4] = CustomColumn5
            prevCC[5] = CustomColumn6
            prevCC[6] = CustomColumn7
            prevCC[7] = CustomColumn8
            prevCC[8] = CustomColumn9
            prevCC[9] = CustomColumn10
            setCC(prevCC)
        }

        setFetchError("")

        getJSONrequest(idMapper(apiEndpoints().getCustomColumnsById, id))
            .then((customColumns: ICustomColumns) => {
                if (customColumns === null) processCustomColumnsData(dd)
                else processCustomColumnsData(customColumns)
                setFetchingCustomColumns(false)
            })
            .catch(() => {
                setFetchError(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                setFetchingCustomColumns(false)
            })

        // Wants cc deps but then we get a inifinite loop and not able to change text values
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const CC_LABEL = "Bytt namn på fritt fält "

    const handelSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const requestBody = {
            Id: id,
            CustomColumn1: cc[0],
            CustomColumn2: cc[1],
            CustomColumn3: cc[2],
            CustomColumn4: cc[3],
            CustomColumn5: cc[4],
            CustomColumn6: cc[5],
            CustomColumn7: cc[6],
            CustomColumn8: cc[7],
            CustomColumn9: cc[8],
            CustomColumn10: cc[9],
        }

        setShowConfirmation(false)
        setPostError("")
        setUpdatingCustomColumns(true)

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(idMapper(apiEndpoints().updateCustomColumnsById, id), csrfToken, JSON.stringify(requestBody), true)
                .then(() => {
                    setShowConfirmation(true)
                    setUpdatingCustomColumns(false)
                })
                .catch(() => {
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setUpdatingCustomColumns(false)
                })
        )
    }

    return (
        <>
            <div className="flex ai-center jc-space-between">
                <h1>{REGISTER_TEXTS.MODAL_CUSTOM_COLUMNS_H1}</h1>
                <Button Icon={xMark} iconRight onClick={closeModal}>
                    {GENERAL_TEXTS.BUTTON_CLOSE}
                </Button>
            </div>
            {fetchingCustomColumns && <Skeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingCustomColumns && !fetchError && (
                <form onSubmit={handelSubmitForm}>
                    <div className="customColumnsContainer">
                        <div>
                            {cc.slice(0, 5).map((column, index) => (
                                <>
                                    <label htmlFor={column}>{`${CC_LABEL}${1 + index}`}</label>
                                    <Input
                                        type="text"
                                        id={column}
                                        name={column}
                                        value={column}
                                        required
                                        onChange={(e) => {
                                            const prevCC = [...cc]
                                            prevCC[index] = e.target.value
                                            setCC(prevCC)
                                        }}
                                    />
                                </>
                            ))}
                        </div>
                        <div>
                            {cc.slice(5, 10).map((column, index) => (
                                <>
                                    <label htmlFor={column}>{`${CC_LABEL}${6 + index}`}</label>
                                    <Input
                                        type="text"
                                        id={column}
                                        name={column}
                                        required
                                        value={column}
                                        onChange={(e) => {
                                            const prevCC = [...cc]
                                            prevCC[5 + index] = e.target.value
                                            setCC(prevCC)
                                        }}
                                    />
                                </>
                            ))}
                        </div>
                    </div>
                    <Button type="submit" isLoading={updatingCustomColumns}>
                        {REGISTER_TEXTS.MODAL_CUSTOM_COLUMNS_BUTTON_CHANGE}
                    </Button>

                    {showConfirmation && (
                        <div className="mt32 maxWidth600">
                            <Confirmation>{REGISTER_TEXTS.MODAL_CUSTOM_COLUMNS_CONFIRMATION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mt32 maxWidth600">
                            <Error>{postError}</Error>
                        </div>
                    )}
                </form>
            )}
        </>
    )
}

export default ChangeCustomColumnsForm
