export const ALT_TEXTS = {
    TO_HOMEPAGE_OF_ANALYSKRAFT: "Till startsidan av Analyskraftverk",
    LOG_OUT: "Logga ut",
}

export const LOGIN_TEXTS = {
    LOGIN_H1: "Välkommen till Analyskraft",
    USERNAME_LABEL: "Ange ditt användarnamn",
    PASSWORD_LABEL: "Ange ditt lösenord",
    FORGOT_PASSWORD_PLACEHOLDER: "Skriv ditt mail har",
    FORGOT_PASSWORD_H1: "Återställ användare",
    FORGOT_PASSWORD_EXPLANATION: "Fyll i din e-postadress så skickar vi en länk för återställning av lösenord.",
    FORGOT_PASSWORD_EMAIL_SENT: "Ett e-postmeddelande har skickats till:",
    FORGOT_PASSWORD_EMAIL_SENT_EXPL_P1:
        "Meddelandet borde dyka upp inom några minuter. Om du inte kan hitta det i din inkorg kan du kontrollera om det hamnat i mappen för skräppost.",
    FORGOT_PASSWORD_EMAIL_SENT_EXPL_P2:
        "Har du lagt till analyskraft@knowit.se i din kontaktlista eller som betrodd avsändare och det fortfarande inte kommer något meddelande kan du behöva kontakta din IT-support för att undersöka om meddelandet fastnat i ett spamfilter.",
    FORGOT_PASSWORD_EMAIL_SENT_EXPL_P3:
        "Om du efter kontakt med din organisations IT-support fortfarande inte får något meddelande kan du kontakta Analyskrafts tekniska support för att få hjälp, kontaktuppgifterna återfinns nedan.",
    FORGOT_PASSWORD_HEADING_TECHNICAL_SUPPORT: "Tekniska problem (Telefontider 9-17 vardagar)",
    FORGOT_PASSWORD_CONTACT_KNOWIT: "Kontakta Knowit",
    FORGOT_PASSWORD_KNOWIT_TEL: "Telefon: 076-141 25 10",
    FORGOT_PASSWORD_KNOWIT_EMAIL: "E-post: analyskraft@knowit.se",
    FORGOT_PASSWORD_POST_ERROR: "Något gick fel när du försökte återställa lösenord, prova igen senare.",
    FORGOT_PASSWORD_LABEL: "E-post:",
    FORGOT_PASSWORD_INFOTEXT:
        "För att vara säker på att du kan ta emot e-postmeddelandet rekommenderas du att lägga till analyskraft@knowit.se i din kontaktlista eller som betrodd avsändare i din e-postklient.",
    OTP_LABEL:
        "Du kommer att få en engångskod via sms som du skall ange nedan för att logga in. Koden är giltig i 5 minuter.",
    FORGOT_PASSWORD_LINK: "Glömt lösenord?",
    BUTTON_SEND: "Skicka",
    BUTTON_LOGIN: "Logga in",
    BUTTON_RESEND_OTP: "Skicka ny kod",
    ERROR_CREDENTIALS: "Inloggningsuppgifter som du angav är fel. Försök igen, du har",
    ERROR_CREDENTIALS_2: "försök kvar.",
    ERROR_WRONG_OTP: "Koden matchar inte det förväntade värdet. Försök igen, du har",
    ERROR_GENERIC: "Något gick fel under inloggningen, försök igen senare.",
    ERROR_LOCKED: "För många misslyckade inloggningsförsök. Kontot är låst t.o.m. ",
    ERROR_EMAIL: "Inloggningsuppgifter som du angav är fel. Försök igen.",
    RESET_PASSWORD_REMINDER: "Du kan låsa upp kontot genom att återställa lösenordet via länken 'Glömt Lösenord?'",
}

export const MAIN_ACCOUNT_TEXTS = {
    BUTTON_DELETE: "Radera huvudanvändarkonto",
    BUTTON_CREATE: "Skapa huvudanvändarkonto",
    WARNING_DELETE: "Bekräfta att du vill radera ",
    CONFIRMATION_DELETED: "Huvudanvändarkontot har raderats.",
    CONFIRMATION_CREATED: "Huvudanvändarkontot har skapats.",
    CREATE_NAME_LABEL: "Namn på huvudanvändarkonto",
    CREATE_NAME_PLACEHOLDER: "Namn på medlem",
    CREATE_USERNAME: "Förnamn på lokal administratör",
    CREATE_LASTNAME: "Efternamn på lokal administratör ",
    CREATE_EMAIL: "E-post till lokal administratör",
    CREATE_MOBILE: "Mobiltelefon till lokal administratör",
    CHECKBOX_LABEL: "Personuppgiftsbiträdesavtal finns påskrivet",
    ERROR_CREATE: "Något gick fel när du försökte skapa ett huvudanvändarkonto, prova igen senare.",
    ADMINISTRATE_H1: "Administrera konto",
    ADMINISTRATE_EXPLANATION: "Här kan du bygga om ett register",
    ADMINISTRATE_SELECT_LABEL: "Välj huvudanvändarkonto",
    ADMINISTRATE_NO_REGISTERS: "Huvudanvändarkonto saknar register.",
    ADMINISTRATE_TH1: "Register",
    ADMINISTRATE_TH2: "Hälsostatus",
    ADMINISTRATE_TH3: "Administrera",
    ADMINISTRATE_BUTTON_REBUILD: "Bygg om register",
    UNATHORIZED: "Du är obehörig att utföra handlingen",
}
export const REGISTER_TEXTS = {
    EXPLANATION: "Välj en fil att ladda upp och vilken period den gäller för.",
    UPLOAD: "Ladda upp register:",
    UPLOADAREA: "Klicka här eller drag hit en fil",
    CHOOSE_REGISTER: "",
    CHOOSE_MEMBER: "Välj en medlem:",
    CHECKBOX_FILTERS: "Skapa tidigare skapade manuella -och filtergrupper",
    DATE_IMPORT: "Datum för register:",
    REFERENCEREGISTER: "Välj jämförelseregister",
    BUTTON_IMPORT: "Importera register",
    BUTTON_CHECK_ERRORS: "Granska fel",
    BUTTON_SHOW_REGISTER_OVERVIEW: "Visa registeröversikt",
    CHECK_ERRORS: "Valideringsfel, se granska fel",
    NO_REGISTERS: "Det finns inga importerade register.",
    ERROR_FETCH_REGISTRIES: "Fel vid hämtning av register.",
    SELECT_REGISTRY: "Välj register:",
    REGISTER: "Register:",
    CREATED: "Skapades:",
    IMPORTED_BY: "Importerad av:",
    IN_SALARY_SIMULATION: "Detta register används i en pågående lönerevision",
    TH_AMOUNT_INDIVIDUALS: "Antal individer i registret:",
    TH_AMOUNT_INDIVIDUALS_NO_BESTA: "Antal individer utan BESTA:",
    TH_AMOUNT_INDIVIDUALS_NO_ORG: "Antal individer utan ORG:",
    TH_DIFF_PREV_REGISTER: "Antalsdifferens mot föregående register:",
    TB_GROUPS: "Grupper",
    TB_MIN: "Min",
    TB_P10: "P10",
    TB_P25: "P25",
    TB_P50: "P50",
    TB_P75: "P75",
    TB_P90: "P90",
    TB_MAX: "Max",
    TB_MEDIAN: "Medel",
    TB_P90_FRAC_10: "90/10",
    TB_P90_FRAC_50: "90/50",
    TB_P50_FRAC_10: "50/10",
    EMPTY_CELL: "",
    T_BESTA_TH1: "Arbetsområde",
    T_BESTA_TH2: "Grupperingsnivå",
    T_BESTA_TH3: "Summa",
    T_BESTA_EMPLOYEES_CAPTION: "Antal BESTA-kod - Medarbetare",
    T_BESTA_EMPLOYEES_ONE: "1",
    T_BESTA_EMPLOYEES_TWO: "2",
    T_BESTA_EMPLOYEES_THREE: "3",
    T_BESTA_EMPLOYEES_FOUR: "4",
    T_BESTA_EMPLOYEES_FIVE: "5",
    T_BESTA_EMPLOYEES_SIX: "6",
    T_BESTA_EMPLOYEES_GENERAL_COUNT: "1000 Myndighetschef",
    T_BESTA_EMPLOYEES_NO_CLASSIFICATION: "8000 ej klassningsbar",
    T_BESTA_EMPLOYEES_NOT_SUITABLE_CODE: "9000 ingen lämplig kod",
    T_BESTA_MANAGERS_CAPTION: "Antal BESTA-kod - Chefer",
    T_BESTA_MANAGERS_ONE: "A",
    T_BESTA_MANAGERS_TWO: "Q",
    T_BESTA_MANAGERS_THREE: "B",
    T_BESTA_MANAGERS_FOUR: "C",
    T_QUAD_LOCAL_CAPTION: "Lokal komplettering",
    T_QUAD_SAM_CAPTION: "Samråd",
    T_QUAD_JOB_SEC_CAPTION: "Yrkesspecifiering",
    T_QUAD_COMPETENCE_CAPTION: "Kompetenskategori",
    T_QUAD_HEADER: "Antal",
    IMPORT_CHECKBOX_LABEL: "Skapa tidigare skapade manuella- och filtergrupper",
    IMPORT_CHOOSE_MAIN_ACCOUNT: "Välj medlem",
    IMPORT_CHOOSE_COMPARISON_REGISTERY: "Välj jämförelseregister",
    CHOOSE_DATE: "Datum för register:",
    COMPARISON_REGISTERY_EXPLANATION:
        "Här kan ett tidigare inläst register väljas, det är inte obligatoriskt. Detta används enbart för att visa antalsförändring mellan register i funktionen Visa register.",
    CUSTOM_COLUMNS: "changeCustomColumns",
    SHOW_REGISTERY: "showRegistery",
    MODAL_CUSTOM_COLUMNS_H1: "Namnge fria fält",
    CUSTOM_COLUMNS_CONTENT:
        "Fönstret som innehåller formuläret som tillåter dig att ändra namn för dem fria fälten i ett register.",
    MODAL_CUSTOM_COLUMNS_BUTTON_CHANGE: "Bytt visuellt namn på fria fält",
    MODAL_CUSTOM_COLUMNS_CONFIRMATION: "Kolumnnamnen har uppdaterats.",
    MODAL_EMPLOYEES_H1: "Registerdata för",
    SHOW_REGISTERY_CONTENT: "Fönstret som innehåller en tabell med registerinnehåll.",
    BUTTON_SHOW_REGISTERY: "Visa registerdata",
    BUTTON_RENAME_CC: "Namnge fria fält",
    A_EXPORT_REGISTERY: "Exportera Registerdata",
    A_EXPORT_REGISTERY_PDF: "Exportera som PDF",
    BUTTON_REMOVE: "Ta bort register",
    WARNING_DELETE_1: "Vill du ta bort registret:",
    WARNING_DELETE_2: "och kommentarer till Löneskillnader kvinnor & män?",
    WARNING_BUTTON_DELETE_ONLY_REGISTER: "Ta bort register",
    WARNING_BUTTON_DELETE_ALL: "Ta bort register och kommentarer",
    ERROR_IS_IN_SALARY_SIMULATION: "Detta register finns i en pågående lönerevision och kan ej tas bort.",
    CONFIRMATION_DELETED: "Registret har tagits bort.",
    MINOR_GRID: "Stödlinjer",
    SHOW_MAX_MIN: "Visa min/max",
}

export const RESET_PASSWORD_TEXTS = {
    H1_TITLE: "Sätt lösenord",
    H1_PARAGRAPH: (
        <>
            <p>Fyll i lösenordet två gånger.</p>
            <ul>
                <li>Lösenordet måste vara minst 6 tecken långt</li>
                <li>Lösenordet får inte innehålla ord från eposten</li>
            </ul>
            
            <p>Lösenordet måste innehålla minst 3 av 4 från följande kriterier:</p>
            <ol>
                <li>Minst en stor bokstav A-Z</li>
                <li>Minst en liten bokstav a-z</li>
                <li>Minst en siffra 0-9</li>
                <li>Minst ett specialtecken (t.ex. !, #, %, *)</li>
            </ol>
        </>
    ),
    NEW_PASSWORD: "Nytt lösenord: ",
    REPEAT_NEW_PASSWORD: "Upprepa nytt lösenord: ",
    EMAIL: "Epost: ",
    PASSWORD_IS_CHANGED: "Lösenord ändrat",
    TRUSTED_SENDER: "För att vara säker på att du kan ta emot epost-meddelandet rekommenderas du att lägga till analyskraft@knowit.se i din kontaktlista eller som betrodd avsändare i din epostklient.",
    PASSWORD_DO_NOT_MATCH: "Det upprepade lösenordet matchar inte.",
    PASSWORD_MIN_CHARACTERS: "Lösenordet måste innehålla minst 6 tecken.",
    PASSWORD_MAX_CHARACTERS: "Lösenordet får max innehålla 255 tecken.",
    PASSWORD_NOT_CONTAIN_ANALYSKRAFT: "Lösenordet får inte innehålla ordet analyskraft.",
    PASSWORD_NOT_CONTAIN_EMAIL: "Lösenordet får inte innehålla delar av epost-adressen (3 tecken i rad eller mer).",
    PASSWORD_MUST_MEET_CTITERIA: "Lösenordet måste uppfylla minst 3 av 4 kriterier (stor bokstav, liten bokstav, siffra, specialtecken).",
    NEW_PASSWORD_PLACEHOLDER: "Ange nytt lösenord",
    REPEAT_PASSWORD_PLACEHOLDER: "Upprepa nytt lösenord",
    BUTTON_SEND: "Skicka",
    POST_ERROR: "Något gick fel när du försökte sätta det nya lösenordet, prova igen senare.",
}

export const REFERENCESTATISTICS_TEXTS = {
    EXPLANATION: "Ange perioden som referensstatistiken avser samt om den omfattar medarbetare eller chefer.",
    LABEL_STATISTIC_TYPE: "Typ av statistik:",
    LABEL_EMPLOYEE_TYPE: "Typ av referensstatistik:",
    UPLOAD: "Ladda upp referensstatistik:",
    UPLOADAREA: "Klicka här eller drag hit en fil",
    CHOOSE_PERIOD: "Välj period:",
    BUTTON_IMPORT: "Importera referensstatistik",
    BUTTON_SHOW_IMPORTED: "Visa all referensstatistik",
    BUTTON_CHECK_ERRORS: "Granska fel",
    CHECK_ERRORS: "Valideringsfel, se granska fel",
    IMPORT_TYPE_GENERAL: "Importera Partsgemensam",
    IMPORT_TYPE_MEMBERS: "Importera Medlemsanpassad",
    IMPORT_TYPE_LABEL: "Medarbetare eller chefer:",
    EMPLOYEES: "Medarbetare",
    MANAGERS: "Chefer",
    ALL: "Samtliga",
    NO_STATISTICS: "Det finns ingen referensstatistik ännu.",
    PERIOD: "Period:",
    EMPLOYEE_TYPE: "Medarbetare/chefer:",
    IMPORTED_BY: "Importerad av:",
    FILENAME: "Filnamn:",
    TH_BESTA: "BESTA",
    TH_AGEGROUP: "Åldersgrupp",
    TH_AMOUNT: "Antal",
    TH_P10: "P10",
    TH_P25: "P25",
    TH_P50: "P50",
    TH_P75: "P75",
    TH_P90: "P90",
    TH_REGION: "Region",
    TH_VI: "VI",
    BUTTON_EXPORT_TABLE: "Exportera tabell",
    BUTTON_EXPORT_TABLE_IMPORT: "Exportera tabell för import",
    BUTTON_REMOVE: "Radera referensstatistik",
    WARNING_DELETE:
        "Om det finns analyser som är beroende av denna referensstatistik kommer vid borttagning även eventuell analys att försvinna.",
    CONFIRMATION_DELETED: "Referensstatistiken har raderats",
    AGV: "Arbetsgivarverket",
}

export const VAL_ERRORS_MODAL_TEXTS = {
    HEADING: "Valideringsresultat",
    MODAL_CONTENT: "En modal som innehåller en tabell med alla valideringsfel som upptäcktes vid uppladdningen.",
    BUTTON_CLOSE: "Stäng",
    COLUMN1_HEADING: "Radnummer",
    COLUMN2_HEADING: "Felmeddelande",
    COLUMN3_HEADING: "Raden från filen",
}

export const MORE_INFO_MODAL_TEXTS = {
    HEADING: "Information om urval",
    MODAL_CONTENT: "En modal som innehåller information om urvalets valda register och grupper.",
    BUTTON_CLOSE: "Stäng",
    LABEL_SELECTION: "Urval: ",
    LABEL_CREATED: "Skapad: ",
    REGISTERS_TH1: "Register",
    GROUPS_TH1: "Grupper",
    GROUPS_TH2: "Finns i",
}

export const HELP_TEXTS = {
    HEADING_SUPPORT: "Ämnessupport",
    HEADING_TECHNICAL_SUPPORT: "Tekniska problem (Telefontider 9-17 vardagar)",
    EPOST: "E-post:",
    TELEPHONE: "Telefon:",
    HEADING_KAPITEL: "Lägg till nytt kapitel",
    MODAL_CONTENT_CREATE:
        "En modal som innehåller en editor för att du ska kunna skapa ny vanlig fråga och svar på den.",
    MODAL_CONTENT_EDIT:
        "En modal som innehåller en editor för att du ska kunna editera en vanlig fråga och svar på den.",
    HEADING_FAQ_CREATE: "Lägg till ny fråga och svar",
    HEADING_FAQ_UPDATE: "Ändra fråga och svar",
    LABEL_FAQ_TITLE: "Fråga:",
    LABEL_FAQ_CONTENT: "Svar:",
    HEADING_CONCEPTS_CREATE: "Lägg till begrepp och definition",
    HEADING_CONCEPTS_UPDATE: "Ändra Begrepp och definitioner",
    LABEL_CONCEPTS_TITLE: "Begrepp:",
    LABEL_CONCEPTS_CONTENT: "Definition:",
    HEADING_CONCEPT: "Lägg till begrepp och definition",
    HEADING_MANUAL_CREATE: "Lägg till nytt kapitel",
    HEADING_MANUAL_UPDATE: "Ändra kapitel",
    LABEL_MANUAL_TITLE: "Kapitel",
    LABEL_MANUAL_CONTENT: "Innehåll",
    MANUAL_MODAL_CONTENT_EDIT:
        "En modal som innehåller en editor för att du ska kunna editera ett kapitel under hjälp och support - handbok.",
    MANUAL_MODAL_CONTENT_CREATE:
        "En modal som innehåller en editor för att du ska kunna skapa ett kapitel under hjälp och support - handbok.",
    DELETE_MANUAL_WARNING: "Är du säker på att du vill ta bort ",
    ALL_FIELDS_REQ: "Alla fält är obligatoriska.",
    SEARCH_LABEL: "Sök:",
}

export const GROUPS_TEXTS = {
    GROUPS_TH1: "Grupp",
    GROUPS_TH2: "Grupptyp",
    GROUPS_TH3: "Antal",
    GROUPS_TH4: "Administrera",
    GROUPS_REGISTRIES: "Automatskapade grupper",
    GROUPS_MANUAL: "Manuella grupper",
    GROUPS_FILTER: "Filtergenererade grupper",
    ERROR_FETCH_GROUPS: "Fel vid hämtning av grupper.",
    ERROR_FETCH_FILTERS: "Fel vid hämtning av filter.",
    SELECT_FILTER: "Välj 1-5 st kolumnnamn att gruppera på:",
    PREVIEW: "Förhandsgranska",
    CLEAR_FILTER_SELECTION: "Rensa filter",
    SELECT_FILTER_VALUES: "Välj värden för ",
    SELECT_FILTER_INTERVAL: "Välj intervall för ",
    SELECT_ALL_VALUES: "Välj alla",
    MIN_VALUE: "Min:",
    MAX_VALUE: "Max:",
    BUTTON_SHOW_GROUP: "Visa",
    BUTTON_MODIFY_GROUP: "Ändra",
    BUTTON_DELETE_GROUPS: "Radera valda grupper",
    CONFIRMATION_DELETED: "Valda grupper har raderats",
    MODAL_HEADER: "Medarbetare inom gruppen ",
    MODAL_CONTENT: "En modal som innehåller en tabell med alla medarbetare som tillhör den valda grupperingen.",
    FILTER_GROUPS_CREATED: "Filtergrupper har skapats",
    FILTER_GROUP_UNVALID_NAME:
        "Filtergruppen kunde inte skapas, gruppen är identisk med grupp som redan finns för registret: ",
    FILTER_GROUPS_UNVALID_NAME:
        "Filtergruppen kunde inte skapas, grupperna är identiska med grupper som redan finns för registret: ",
    NO_FILTER_GROUPS_PREVIEW: "Det aktuella filterurvalet genererade inga grupper.",
    ERROR_FILTER_GROUPS_PREVIEW: "Det gick inte att hämta förhandsvisning: ",
    ERROR_CREATING_FILTER_GROUPS: "Filtergruppgenereringen misslyckades: ",
    BUTTON_CREATE_FILTER_1: "Skapa",
    BUTTON_CREATE_FILTER_2: "st grupper",
    CREATE_MANUAL_GROUP: "Skapa grupp",
    SAVE_MANUAL_GROUP: "Spara grupp",
    CANCEL_CHANGE_MANUAL_GROUP: "Avbryt",
    CLEAR_GROUP_SELECTION: "Rensa alla rader",
    SELECT_ALL_EMPLOYEES: "Välj alla rader",
    MANUAL_GROUP_NAME: "Namnge grupp: ",
    MANUAL_GROUP_CREATED: "Gruppen har skapats.",
    MANUAL_GROUP_UPDATED: "Gruppen har uppdaterats.",
    MANUAL_GROUP_BACK_TO_SHOW_GROUPS: "Tillbaka till visa grupper",
    MANUAL_GROUP_UNVALID_NAME: "Det finns redan en grupp med samma namn, var vänlig och välj ett annat.",
    ERROR_CREATING_MANUAL_GROUP: "Något gick fel när gruppen skulle skapas:",
    MANUAL_GROUP_NAME_LIMITED: "Namnet får vara max 50 tecken.",
    CHANGE_MANUAL_GROUP_WARNING:
        "Om gruppens individer har förändrats och det finns analyser som är beroende av gruppen kommer även eventuell analys att försvinna.",
    BUTTON_REMOVE_EMPLOYEE: "Ta bort",
    SELECT_EMPLOYEES: "Lägg till individer:",
    PREVIEW_MANUAL_GROUP: "Förhandsgranska grupp:",
    LABEL_MODIFY_GROUP: "Ändra manuellt skapad grupp",
    LABEL_FROM_REGISTRY: "från",
    WARNING_DELETE: "Bekräfta att du vill radera ",
}

export const ANALYZE_SALARY_DISTRIBUTION = {
    FLOWSTEP_1: "Välj register/urval",
    FLOWSTEP_2: "Hantera urval",
    FLOWSTEP_3: "Analysera urval",
    // S stands for step
    S1_H2: "Register och urval",
    S1_H3: "Sparade urval",
    S1_NO_SELECTIONS: "Det finns inga sparade urval, du kan skapa ett i nästa steg",
    S1_TH1: "Urval",
    S1_TH2: "Administrera",
    S1_BUTTON_CHOOSE: "Välj",
    S1_BUTTON_DELETE: "Ta bort",
    S1_WARNING_DELETE_SELECTION: "Bekräfta att du vill ta bort urvalet",
    S1_CONFIRMATION_DELETED_SELECTION: "Urvalet har tagits bort",
    S2_NO_GROUPS: "Det finns inga Besta grupper i detta register och därför kan inte denna analys skapas.",
    S2_CONFIRMATION_SAVED_SELECTION: "Urvalet har sparats",
    S2_TH1: "Grupp",
    S2_TH2: "Grupptyp",
    S2_TH3: "Antal",
    S2_H2: "Hantera urval",
    S2_EXPLANATION:
        "I detta steg kan du välja grupper, lönebegrepp och diagramtyp som du vill basera din analys på. Väljer du lådagram som diagramtyp kan du välja flera grupper. Om du väljer punktdiagram kan enbart en grupp markeras. Du kan spara ditt urval eller fortsätta till nästa steg när du har gjort dina val.",
    S2_LABEL: "Välj lönebegrepp:",
    S2_LABEL_DIAGRAM_TYPE: "Välj diagramtyp:",
    S2_LABEL_NAME: "Namnge ditt urval:",
    S2_DIAGRAM_TYPE_1: "Lådagram",
    S2_DIAGRAM_TYPE_2: "Punktdiagram",
    S2_SALARY_TYPE_1: "Grundlön",
    S2_SALARY_TYPE_4: "Fast lön",
    S2_SALARY_TYPE_5: "Totallön",
    S2_CAPTION_GROUPS: "Välj grupper att lägga till i urvalet:",
    S2_BUTTON_CLEAR_SELECTION: "Avmarkera alla rader",
    S2_BUTTON_SAVE_SELECTION: "Spara nytt urval",
    S2_BUTTON_UPDATE_SELECTION: "Uppdatera urval",
    S2_SAVE_SELECTION_INVALID_NAME: "Det finns redan ett urval med samma namn. Var vänlig välj ett annat namn.",
    S2_SAVE_SELECTION_ERROR: "Det gick inte att spara urvalet.",
    S3_H2: "Analysera urval",
    S3_BUTTON_EXPORT_PDF: "Exportera som pdf",
    S3_TH1: "Grupp",
    S3_TH2: "Lönebegrepp",
    S3_TH3: "Antal",
    S3_TH_MIN: "Min",
    S3_TH4: "P10",
    S3_TH5: "P25",
    S3_TH6: "P50",
    S3_TH7: "P75",
    S3_TH8: "P90",
    S3_TH_MAX: "Max",
    S3_TH9: "Medel",
    S3_TH10: "90/10",
    S3_TH11: "90/50",
    S3_TH12: "50/10",
    S3_DIAGRAM_TITLE: "Löneläge och Lönespridning",
    S3_DIAGRAM_REGISTER: "Register:",
    S3_DIAGRAM_GROUP: "Grupp:",
    S3_DIAGRAM_SALARY_TYPE: "Lönebegrepp:",
    S3_SLIDER_MAN_WOMEN: "Visa kvinnor och män",
    S3_SLIDER_MIN_MAX: "Visa min/max",
    S3_SLIDER_MINOR_GRID: "Stödlinjer",
    S3_SLIDER_SHOW_GROUPED: "Visa grupperat",
}

export const ANALYZE_WAGE_GAP = {
    FLOWSTEP_1: "Välj register/urval",
    FLOWSTEP_2: "Skapa urval",
    FLOWSTEP_3: "Lika arbeten",
    FLOWSTEP_4: "Likvärdiga arbeten: Bedömningsgrunder",
    FLOWSTEP_5: "Likvärdiga arbeten: Värdera grupper",
    FLOWSTEP_6: "Likvärdiga arbeten: Granska grupper",
    FLOWSTEP_6_EMPLOYEE: "Likvärdiga arbeten: Granska medarbetargrupper",
    FLOWSTEP_6_MANAGERS: "Likvärdiga arbeten: Granska chefsgrupper",
    FLOWSTEP_7: "Likvärdiga arbeten: Jämför signalerade grupper",
    // S stands for step
    S1_H2: "Register och urval",
    S1_H3: "Sparade urval och tillhörande analyser",
    S1_NO_SELECTIONS: "Det finns inga sparade urval, du kan skapa ett i nästa steg",
    S1_TH1: "Urval",
    S1_TH2: "Skapad",
    S1_TH3: "Administrera",
    S1_BUTTON_CHOOSE: "Välj",
    S1_BUTTON_DELETE: "Ta bort",
    S1_WARNING_DELETE_SELECTION: "Vill du ta bort urvalet?",
    S1_CONFIRMATION_DELETED_SELECTION: "Urvalet har tagits bort",
    S2_H2: "Hantera urval",
    S2_STEP_EXPLANATION: "Glöm ej att spara. Analys baseras på det senast sparade urvalet.",
    S2_LABEL: "Välj lönebegrepp:",
    S2_LABEL_NAME: "Namnge ditt urval:",
    S2_SALARY_TYPE_1: "Grundlön",
    S2_SALARY_TYPE_4: "Fast lön",
    S2_SALARY_TYPE_5: "Totallön",
    S2_CAPTION_GROUPS: "Välj grupper att lägga till i urvalet (Alla BESTA-grupper är förvalda):",
    S2_CAPTION_GROUPS_SHORT: "Välj grupper att lägga till i urvalet:",
    S2_CAPTION_SELECTION: "Tillagda grupper för urvalet:",
    S2_TH1: "Grupp",
    S2_TH2: "Administrera",
    S2_BUTTON_REMOVE: "Ta bort",
    S2_BUTTON_CLEAR_SELECTION: "Avmarkera alla rader",
    S2_BUTTON_SAVE_SELECTION: "Spara urval",
    S2_BUTTON_SAVE_SELECTION_N_CONTINUE: "Spara urval och gå vidare",
    S2_CONFIRMATION_SAVED_SELECTION: "Urvalet har sparats",
    S2_WARNING_NOT_ALL_INCLUDED:
        "Det finns individer i valt register som inte inkluderas i urvalet. Du kan gå vidare till analysfunktionen utan att åtgärda detta.",
    S2_WARNING_DOUBLES:
        "Det finns dubletter av individer i urval. Åtgärda problemet för att kunna gå vidare till analysfunktionen.",
    S2_POST_ERROR_INVALID_NAME: "Det finns redan ett urval med samma namn, var vänlig och välj ett annat.",
    S2_POST_ERROR: "Det gick inte att spara urvalet.",
    S3_H2: "Lika arbeten",
    S3_STEP_EXPLANATION: "I analysen av lika arbeten ingår enbart de grupper i urvalet som innehåller båda könen.",
    S3_NO_RELEVANT_INDIVIDUALS: "Urvaler saknar relevanta individer för att utföra analysen.",
    S3_BUTTON_GET_ALL_COMMENTS: "Hämta senaste kommentarer",
    S3_BUTTON_EXPORT_TABLE: "Exportera tabell (.csv)",
    S3_FILENAME: "Lika arbeten.csv",
    S3_TH1: "Grupp",
    S3_TH2: "Antal",
    S3_TH3: "Andel kvinnor",
    S3_TH4: "Löneandel kvinnor",
    S3_TH5: "Kommentar",
    S3_TH6: "Administrera",
    S3_COMMENTS_EXIST: "Finns",
    S3_NO_COMMENTS: "-",
    S3_TABLE_BUTTON_COMMENT: "Kommentera",
    S3_TABLE_BUTTON_SHOW_DIAGRAM: "Visa diagram och tabell",
    S3_DIAGRAM_TITLE: "Löneskillnader kvinnor & män - Lika arbete",
    S3_DIAGRAM_Y_AXIS_TITLE: "Lön",
    S3_DIAGRAM_FETCH_SALARY_REGISTERS_ERROR: "Det gick inte att hämta referensregister.",
    S4_H2: "Likvärdiga arbeten - bedömningsgrunder",
    S4_STEP_EXPLANATION:
        "Ange de bedömningsgrunder med tillhörande vikter som bestämts ska användas. Enligt diskrimineringslagen måste minst tre stycken bedömningsgrunder anges vid analys av löneskillnader mellan kvinnor och män.",
    S4_STEP_EXPLANATION_2:
        "Du kan välja en av de tidigare sparade bedömningsgrupperna, se rullistan, eller skapa en ny grupp. Om du väljer att skapa en ny bedömningsgrupp måste namnet vara unikt. Det går inte att ändra tidigare skapade bedömningsgrupper och summan av vikterna för angivna bedömningsgrunder måste summera till ett (1,0).",
    S4_STEP_EXPLANATION_3: "Glöm ej att spara. Analys baseras på de senast sparade bedömningsgrunderna.",
    S4_LABEL_NAME: "Namnge din grupp",
    S4_LABEL_SAVED_ASSESSMENTS: "Tidigare sparade bedömningsgrupper",
    S4_ASSESSMENT_NAME: "Bedömningsgrunder",
    S4_WEIGHT: "Vikt",
    S4_BUTTON_REMOVE: "Ta bort",
    S4_BUTTON_ADD_ROW: "Lägg till bedömningsgrund",
    S4_WEIGTH_SUM: "Summa vikt",
    S4_BUTTON_SAVE_SELECTION: "Spara",
    S4_BUTTON_SAVE_SELECTION_N_CONTINUE: "Spara och gå vidare",
    S4_WARNING:
        "Du är på väg att spara nya bedömningsgrunder, om du gör detta så kommer alla kommande steg att nollställas.",
    S4_POST_ERROR_UNVALID_NAME: "Kan inte ha två likadana bedömningsgrunder, se",
    S4_CONFIRMATION_SAVED_SELECTION: "Bedömningsgrunder har sparats.",
    S5_H2: "Likvärdiga arbeten - värdera grupper",
    S5_STEP_EXPLANATION:
        "I detta steg ska valda bedömningsgrunder värderas. Skalan kan vara mellan 1 till 10. Tabellen är sorterad efter grupperingsnivån i BESTA och därefter gruppnamn. Tidigare sparade värderingar för grupper laddas in automatiskt och kan skrivas över av användaren genom att klicka på knappen RENSA VÄRDERINGAR I TABELL eller manuellt välja ny värdering i rullistan.",
    S5_STEP_EXPLANATION_2:
        "Alla grupper måste vara värderade innan användaren kan fortsätta till nästa steg. En grupp anses vara värderad när alla dess bedömningsgrunder har en värdering som är större än 0.",
    S5_STEP_EXPLANATION_3:
        "Glöm ej att spara. Analys baseras på senast sparade värderingar och är de som exporteras med tabellen.",
    S5_BUTTON_CLEAN_TABLE_VALUES: "Rensa värderingar i tabell",
    S5_BUTTON_EXPORT_CSV: "Exportera tabell (.CSV)",
    S5_EXPORT_FILENAME: "Likvärdiga Arbeten: Värdera grupper.csv",
    S5_LABEL_VALUE_ASSESSMENTS: "Värdera grupper:",
    S5_WARNING:
        "Du är på väg att spara nya värderingar för grupperna i det här urvalet. Om du gör detta så kommer tidigare sparade värderingar för dessa grupper att ersättas.",
    S5_POST_ERROR_VALUES_MISSING: "Var vänlig och fylla i alla bedömningsgrunder.",
    S5_CONFIRMATION_SAVED_VALUES: "Värderingar har sparats.",
    S5_BUTTON_SAVE: "Spara värderingar",
    S5_BUTTON_SAVE_N_CONTINUE: "Spara värderingar och gå vidare",
    S5_TH1: "Grupp",
    S5_TH2: "Antal",
    S5_TH3: "Andel kvinnor",
    S5_THLAST: "Sammanvägd poäng",
    S6_H2_ALL: "Likvärdiga arbeten - granska grupper",
    S6_H2_NON_MANAGERS: "Likvärdiga arbeten - granska medarbetargrupper",
    S6_H2_MANAGERS: "Likvärdiga arbeten - granska chefsgrupper",
    S6_NO_MANAGERS: "Det finns inga grupper som innehåller valda chefer.",
    S6_NO_EMPLOYEES: "Det finns inga grupper som innehåller valda medarbetare.",
    S6_STEP_EXPLANATION:
        "Tabellen visar de värderade grupperna som är sorterade efter grupperingsnivå i BESTA och den sammanlagda värderingspoängen.",
    S6_STEP_EXPLANATION_2: "Kolumnen OBSERVERA kan innehålla följande fyra ikoner: ",
    S6_VENUS_ICON_EXPLANATION: "Kvinnodominerad grupp (mer än 60 procent kvinnor i gruppen)",
    S6_COMMENTS_ICON_EXPLANATION: "Det finns tidigare kommentarer för gruppen",
    S6_IS_HIGH_GRP_LVL_ICON_EXPLANATION:
        "Icke-kvinnodominerad grupp där medellönen är högre än medellönen för kvinnodominerade grupper i högre grupperingsnivåer",
    S6_IS_LOW_GRP_LVL_ICON_EXPLANATION:
        "Gruppen där värderingspoängen är lika med eller högre än för grupper inom ovanliggande grupperingsnivåer",
    S6_VENUS_ICON_ALT: "Kvinnodominerad grupp (mer än 60 procent kvinnor i gruppen)",
    S6_COMMENTS_ICON_ALT: "Det finns tidigare kommentarer för gruppen",
    S6_IS_HIGH_GRP_LVL_ICON_ALT:
        "Icke-kvinnodominerad grupp där medellönen är högre än medellönen för kvinnodominerade grupper i högre grupperingsnivåer",
    S6_IS_LOW_GRP_LVL_ICON_ALT:
        "Gruppen där värderingspoängen är lika med eller högre än för grupper inom ovanliggande grupperingsnivåer",
    S6_LABEL_REVIEW_ASSESSMENTS: "Granska grupper:",
    S6_TH1: "Grupp",
    S6_TH2: "Antal",
    S6_TH3: "Andel kvinnor",
    S6_TH4: "Löneandel kvinnor",
    S6_TH5: "Medellön",
    S6_TH6: "Medianlön",
    S6_TH7: "P90/10",
    S6_TH8: "Sammanvägd poäng",
    S6_TH9: "Observera",
    S6_TH10: "Administrera",
    S6_COMMENTS_EXIST: "Finns",
    S6_NO_COMMENTS: "-",
    S6_TABLE_BUTTON_COMMENT: "Kommentera",
    S6_BUTTON_EXPORT_CSV: "Exportera tabell (.CSV)",
    S6_EXPORT_FILENAME_ALL: "Likvärdiga Arbeten: Granska Grupper.csv",
    S6_EXPORT_FILENAME_MANAGERS: "Likvärdiga Arbeten: Granska Chefsgrupper.csv",
    S6_EXPORT_FILENAME_EMPLOYEES: "Likvärdiga Arbeten: Granska Medarbetargrupper.csv",
    S7_H2: "Likvärdiga arbeten - jämför signalerade grupper",
    S7_H2_NO_MATCHINGS: "Inga osakliga löneskillnader hittades.",
    S7_STEP_EXPLANATION:
        "Matchningar på de kvinnodominerade grupper som ska jämföras med och analyseras gentemot sådana icke- kvinnodominerade som har fått samma eller lägre värderingspoäng men samtidigt har en högre medellön än den kvinnodominerade gruppen. Grupperna presenteras i kluster där den kvinnodominerade gruppen ligger överst i varje kluster.",
    S7_STEP_EXPLANATION_2:
        " Det är möjligt att spara grupper i en matchning för att kunna ta upp och analysera urvalet i en annan analysfunktion, till exempel i Löneläge och Lönespridning.",
    S7_STEP_EXPLANATION_3: "Kolumnen OBSERVERA kan innehålla följande ikoner: ",
    S7_TH1: "Grupp",
    S7_TH2: "Antal",
    S7_TH3: "Andel kvinnor",
    S7_TH4: "Löneandel kvinnor",
    S7_TH5: "Medellön",
    S7_TH6: "Medianlön",
    S7_TH7: "P90/10",
    S7_TH8: "Sammanvägd poäng",
    S7_TH9: "Observera",
    S7_TH10: "Administrera",
    S7_COMMENTS_EXIST: "Finns",
    S7_NO_COMMENTS: "-",
    S7_EXPORT_FILENAME: "Likvärdiga Arbeten: Jämnför Signalerade Grupper.csv",
    S7_BUTTON_EXPORT_CSV: "Exportera tabell (.CSV)",
    S7_BUTTON_SHOW_DIAGRAM: "Visa diagram",
    S7_BUTTON_SAVE_MATCHING: "Spara matchning",
    S7_POST_ERROR_INVALID_NAME: "Det finns redan ett urval med samma namn, var vänlig och välj ett annat.",
    S7_POST_ERROR: "Något gick fel när en matchning skulle sparas.",
    S7_LABEL_MATCHING: "Namnge matchningen:",
    S7_VENUS_ICON_ALT: "Kvinnodominerad grupp (mer än 60 procent kvinnor i gruppen)",
    S7_COMMENTS_ICON_ALT: "Det finns tidigare kommentar för gruppen",
    S7_VENUS_ICON_EXPLANATION: "Kvinnodominerad grupp (mer än 60 procent kvinnor i gruppen)",
    S7_COMMENTS_ICON_EXPLANATION: "Det finns tidigare kommentar för gruppen",
    S7_DIAGRAM_MODAL_HEADING: "Diagram",
    S7_DIAGRAM_TITLE: "Löneskillnader kvinnor & män - Likvärdiga arbeten",
    S7_DIAGRAM_REGISTER: "Register:",
    S7_DIAGRAM_SALARY_TYPE: "Lönebegrepp:",
    S7_DIAGRAM_MODAL_CONTENT:
        "En modal som innehåller ett diagram som visar löneskillnader mellan kvinnor och män avseende likvärdiga arbeten",
    S7_DIAGRAM_MODAL_ERROR: "Det gick inte att hämta informationen för att rendera diagrammet. ",
    COMMENTS_MODAL_CONTENT: "En modal som tillåter dig skriva och editera kommentarer för gruppen.",
    COMMENTS_MODAL_HEADING: "Skriv kommentar för grupp ",
    COMMENTS_MODAL_EXPLANATION:
        "I rutan nedan kan du skapa en ny kommentar för gruppen eller uppdatera den befintliga. Du kan läsa kommentarer som har tidigare skapats genom att välj en i rullistan.",
    COMMENTS_MODAL_LABEL: "Tidigare sparade kommentarer:",
    COMMENTS_MODAL_CONFIRMATION: "Kommentaren har sparats.",
    COMMENTS_MODAL_ERROR: "Det gick inte att spara kommentaren ",
    DIAGRAM_MODAL_CONTENT:
        "En modal som innehåller en diagram som visar spridningen av löneskillnader mellan kvinnor och män inom den valda gruppen. Du har några kontrollknappar för att styra vilken data som visas i diagrammet och en tabell med inkluderade medarbetare.",
    DIAGRAM_MODAL_HEADING: "Diagram och tillhörande datatabell",
    DIAGRAM_MODAL_LABEL: "Välj jämförelseregister:",
    DIAGRAM_MODAL_EXPORT_PDF: "Exportera som pdf",
    // DIAGRAM_MODAL_ERROR: "Det gick inte att exportera tabellen. ",
    DIAGRAM_MODAL_MINOR_GRID: "Stödlinjer",
    DIAGRAM_MODAL_SHOW_GROUPED: "Visa grupperat",
    ERROR_DOWNLOADING_CSV:
        "Ett fel inträffade när dokumentet skulle sparas. Prova att ladda om sidan och utföra analysen på nytt.",
    BUTTON_CLOSE: "Stäng",
    BUTTON_SAVE: "Spara",
    S3_DIAGRAM_REGISTER: "Register:",
    S3_DIAGRAM_GROUP: "Grupp:",
    S3_DIAGRAM_SALARY_TYPE: "Lönebegrepp:",
}

export const ANALYZE_REFERENCESTATISTICS = {
    FLOWSTEP_1: "Välj register/urval",
    FLOWSTEP_2: "Välj referensstatistik",
    FLOWSTEP_3: "Hantera urval",
    FLOWSTEP_4: "Analysera urval",
    // S stands for step
    S1_H2: "Register och urval",
    S1_CURRENT_SALARY_SIMULATION_REGISTER: "Register i pågående lönerevision:",
    S1_NO_REGISER_SALARY_SIMULATION: "Det finns ingen pågående lönerevision.",
    S1_H3: "Sparade urval",
    S1_NO_SELECTIONS: "Det finns inga sparade urval, du kan skapa ett i nästa steg",
    S1_TH1: "Urval",
    S1_TH2: "Skapad",
    S1_TH3: "Administrera",
    S1_BUTTON_CHOOSE: "Välj",
    S1_BUTTON_DELETE: "Ta bort",
    S1_WARNING_DELETE_SELECTION: "Bekräfta att du vill ta bort urvalet",
    S1_CONFIRMATION_DELETED_SELECTION: "Urvalet har tagits bort",
    S2_H2: "Välj referensstatistik",
    S2_EXPLANATION: "I detta steg kan du ... ",
    S2_LABEL: "Välj typ av referensstatistik:",
    S2_LABEL_COMPARE_WITH: "Jämför med (Valfritt):",
    S2_STATISTICS_TYPE_1: "Partsgemensam",
    S2_STATISTICS_TYPE_2: "Medlemsanpassad",
    S2_LABEL_REGION: "Region",
    S2_LABEL_BUSINESS_FOCUSAREA: "Verksamhetsinriktning:",
    S2_SELECT_LABEL_STATISTICS: "Referensregister:",
    S2_SELECT_LABEL_REGION: "Region:",
    S2_SELECT_LABEL_BUSINESS_FOCUSAREA: "Verksamhetsinriktning:",
    S2_NO_SLECTABLE_STATISTICS: "Det finns ingen referensstatistik som matchar dina val.",
    S2_NO_SLECTABLE_BUSINESS_OR_REGIONS: "Det finns inga regioner/verksamhetsinriktningar som matchar dina val.",
    S3_NO_GROUPS: "Inga bestagrupper kunde hittas som matchar vald referensstatistik och roll.",
    S3_H2: "Hantera urval",
    S3_EXPLANATION:
        "I detta steg kan du välja grupper och diagramtyp som du vill basera din analys på. Väljer du lådagram som diagramtyp kan du välja flera grupper. Om du väljer punktdiagram kan enbart en grupp markeras. Du kan spara ditt urval eller fortsätta till nästa steg när du har gjort dina val.",
    S3_SELECTION_DETAILS: "Urvalet kommer att jämföras utifrån vald referensstatistik: ",
    S3_SELECTION_COMPARISONDATA: "Jämförelsedata: ",
    S3_SELECTION_INDIVIDUAL_TYPE: "Typ av individer: ",
    S3_SELECTION_PERIOD: "Period: ",
    S3_SELECTION_REGION: "Region: ",
    S3_SELECTION_BUSINESSAREA: "Verksamhetsinriktning: ",
    S3_LABEL_DIAGRAM_TYPE: "Välj diagramtyp:",
    S3_LABEL_NAME: "Namnge ditt urval:",
    S3_DIAGRAM_TYPE_1: "Lådagram",
    S3_DIAGRAM_TYPE_2: "Punktdiagram",
    S3_CAPTION_GROUPS: "Välj grupper att lägga till i urvalet:",
    S3_BUTTON_CLEAR_SELECTION: "Avmarkera alla rader",
    S3_BUTTON_SAVE_SELECTION: "Spara nytt urval",
    S3_SAVE_SELECTION_UNVALID_NAME: "Det finns redan ett urval med samma namn. Var vänlig välj ett annat namn.",
    S3_SAVE_SELECTION_ERROR: "Det gick inte att spara urvalet.",
    S3_CONFIRMATION_SAVED_SELECTION: "Urvalet har sparats",
    S3_TH1: "Grupp",
    S3_TH2: "Grupptyp",
    S3_TH3: "Antal",
    S4_H2: "Analysera urval",
    S4_BUTTON_EXPORT_PDF: "Exportera som pdf",
    S4_TH1: "Grupp",
    S4_TH2: "Typ",
    S4_TH3: "Antal",
    S4_TH_AGE_GRP: "Åldersgrupp",
    S4_TH4: "P10",
    S4_TH5: "P25",
    S4_TH6: "P50",
    S4_TH7: "P75",
    S4_TH8: "P90",
    S4_DIAGRAM_TITLE: "Referensstatistik",
    S4_DIAGRAM_YAXIS_TITLE: "Fast lön",
    S4_DIAGRAM_XAXIS_TITLE: "Åldersgrupp",
    S4_DIAGRAM_REGISTER: "Register:",
    S4_DIAGRAM_GROUP: "Grupp:",
    S4_DIAGRAM_SALARY_TYPE: "Lönebegrepp:",
    S4_DIAGRAM_PERIOD: "Period:",
    S4_DIAGRAM_EMPLOYEE_TYPE: "Medarbetare/chefer:",
    S4_DIAGRAM_REGION: "Region:",
    S4_DIAGRAM_BUSINESS_FOCUS: "Verksamhetsinriktning:",
    S4_SLIDER_MINOR_GRID: "Stödlinjer",
    S4_EMPLOYEES_COUNT: "Antal anställda",
    S4_MEN_AMOUNT: "Andel män",
    S4_WOMEN_AMOUNT: "Andel kvinnor",
    S4_SALARY_DISTRIBUTION: "Lönespridning",
    S4_REFERENCESTATISTICS_COUNT: "Antal i referensstatistik",
    S4_RELATIVE_WAGESTATUS: "Relativ löneläge",
}

export const ANALYZE_NEW_SALARIES = {
    INFO_PROGRESS:
        "Sidor Nya löner och Löneorganisation är under pågående revision för en omstrukturering eller eventuell avveckling. Vill du använda dig av funktionen kan du logga in med samma lösenord och användarnamn på den äldre versionen av Analyskraft. Sidan är inte tillgänglighetsanpassad.",
    INFO_REDIRECT: "Omdirigering kan ta längre tid än förväntat, var god och vänta tills sidan har laddat klart.",
    LINK_TEXT: "Till gamla Analyskraft",
}

export const ANALYZE_TIMESERIES = {
    FLOWSTEP_1: "Välj register/urval",
    FLOWSTEP_2: "Hantera urval",
    FLOWSTEP_3: "Analysera urval",
    // S stands for step
    S1_H2: "Register och urval",
    S1_TOO_FEW_REGISTRIES: "Det behövs minst två inlästa register för att kunna använda analysfunktionen",
    S1_MULTISELECT_LABEL: "Välj minst 2 och max 20 register",
    S1_H3: "Sparade urval (alla register)",
    S1_NO_SELECTIONS: "Det finns inga sparade urval, du kan skapa ett i nästa steg",
    S1_TH1: "Urval",
    S1_TH2: "Skapad",
    S1_TH3: "Administrera",
    S1_BUTTON_MORE_INFO: "Mer info",
    S1_BUTTON_CHOOSE: "Välj",
    S1_BUTTON_DELETE: "Ta bort",
    S1_WARNING_DELETE_SELECTION: "Bekräfta att du vill ta bort urvalet",
    S1_CONFIRMATION_DELETED_SELECTION: "Urvalet har tagits bort",
    S2_NO_GROUPS: "Det finns inga Besta grupper i detta register och därför kan inte denna analys skapas.",
    S2_CONFIRMATION_SAVED_SELECTION: "Urvalet har sparats",
    S2_TH1: "Grupp",
    S2_TH2: "Grupptyp",
    S2_TH3: "Antal",
    S2_TH4: "Finns i",
    S2_H2: "Hantera urval",
    S2_EXPLANATION:
        "I detta steg kan du välja grupper, lönebegrepp och diagramtyp som du vill basera din analys på. Väljer du lådagram som diagramtyp kan du välja flera grupper. Om du väljer punktdiagram kan enbart en grupp markeras. Du kan spara ditt urval eller fortsätta till nästa steg när du har gjort dina val.",
    S2_MAX_GROUPS_P1: "Du har valt",
    S2_MAX_GROUPS_P2: "register och kan max välja",
    S2_MAX_GROUPS_P3: "grupper. Grupperna är baserade på huvudregistret.",
    S2_LABEL: "Välj lönebegrepp:",
    S2_LABEL_DIAGRAM_TYPE: "Välj diagramtyp:",
    S2_LABEL_NAME: "Namnge ditt urval:",
    S2_DIAGRAM_TYPE_1: "Lådagram",
    S2_DIAGRAM_TYPE_2: "Punktdiagram",
    S2_SALARY_TYPE_1: "Grundlön",
    S2_SALARY_TYPE_4: "Fast lön",
    S2_SALARY_TYPE_5: "Totallön",
    S2_CAPTION_GROUPS: "Välj grupper att lägga till i urvalet:",
    S2_BUTTON_CLEAR_SELECTION: "Avmarkera alla rader",
    S2_BUTTON_SAVE_SELECTION: "Spara nytt urval",
    S2_BUTTON_UPDATE_SELECTION: "Uppdatera urval",
    S2_MAIN_REGISTERY_IS: "Huvudregister är:",
    S2_MAIN_REGISTERY_EXPLANATION: "Ett huvudregister är det register som har den senast valda perioden.",
    S2_SAVE_SELECTION_UNVALID_NAME: "Det finns redan ett urval med samma namn. Var vänlig välj ett annat namn.",
    S2_SAVE_SELECTION_ERROR: "Det gick inte att spara urvalet.",
    S3_H2: "Analysera urval",
    S3_TH1: "Grupp",
    S3_TH2: "Period",
    S3_TH3: "Antal",
    S3_TH_MIN: "Min",
    S3_TH4: "P10",
    S3_TH5: "P25",
    S3_TH6: "P50",
    S3_TH7: "P75",
    S3_TH8: "P90",
    S3_TH_MAX: "Max",
    S3_TH9: "Medel",
    S3_TH10: "90/10",
    S3_TH11: "90/50",
    S3_TH12: "50/10",
    S3_CHOSEN_REGISTRIES: "Valda register:",
    S3_DIAGRAM_TITLE: "Tidsserier",
    S3_DIAGRAM_GROUP: "Grupp:",
    S3_DIAGRAM_MAIN_REGISTER: "Huvudregister:",
    S3_DIAGRAM_SALARY_TYPE: "Lönebegrepp:",
    S3_SLIDER_MAN_WOMEN: "Visa kvinnor och män",
    S3_SLIDER_MIN_MAX: "Visa min/max",
    S3_SLIDER_MINOR_GRID: "Stödlinjer",
    S3_SLIDER_SHOW_GROUPED: "Visa grupperat",
}

export const ANALYZE_SALARY_EVOLUTION = {
    FLOWSTEP_1: "Välj analystyp",
    FLOWSTEP_2: "Välj register/urval",
    FLOWSTEP_3: "Hantera urval",
    FLOWSTEP_4: "Analysera urval",
    // S stands for step
    S1_LABEL: "Välj en analystyp:",
    S2_LABEL: "Register och urval",
    S2_LABEL_ANALYSIS_TYPE: "Analystyp:",
    S2_LABEL_SELECT_REGISTRIES: "Välj minst 2 och max 10 register:",
    S2_LABEL_SELECT_2_REGISTRIES: "Välj 2 register:",
    S2_H3: "Sparade urval (alla register)",
    S2_TH1: "Urval",
    S2_TH2: "Skapad",
    S2_TH3: "Administrera",
    S2_BUTTON_MORE_INFO: "Mer info",
    S2_BUTTON_CHOOSE: "Välj",
    S2_BUTTON_CLEAR_SELECTION: "Avmarkera alla rader",
    S2_BUTTON_DELETE: "Ta bort",
    S2_WARNING_DELETE_SELECTION: "Är du säker på att du vill radera detta urval?",
    S2_CONFIRMATION_DELETED_SELECTION: "Urvalet har tagits bort",
    ADD_REGISTRIES_MODAL_CONTENT:
        "En modal som tillåter dig skapa analysen baserat på ett urval som kommer från funktioner Löneskillnader kvinnor och män.",
    ADD_REGISTRIES_MODAL_HEADING: "Skapa analys baserat på tidigare urval",
    ADD_REGISTRIES_MODAL_EXPLANATION:
        "Här kan du skapa en analys utifrån tidigare sparat urval som kommer från funktionen löneskilllnader kvinnor och män. Register som urvalet baserar sig på blir huvudregister och du väljer fler register för att genomföra analysen.",
    ADD_REGISTRIES_MODAL_LABEL_MATCHING: "Matching: ",
    ADD_REGISTRIES_MODAL_LABEL_CREATED: "Skapad: ",
    ADD_REGISTRIES_MODAL_LABEL_MULTISELECT: "Välj 1-9 register:",
    ADD_REGISTRIES_MODAL_TOO_MANY_SELECTED: "OBS! Du har valt för många register.",
    ADD_REGISTRIES_MODAL_REGISTERS_TH1: "Register",
    ADD_REGISTRIES_MODAL_ERROR_SEQUENCE: "Hittar inga matchande element i urvalet av regiser.",
    NO_SELECTIONS: "Det finns inga sparade urval.",
    ERROR_FETCHING_ANALYSES_TYPES: "Något gick fel när analystyperna skulle hämtas, prova igen senare.",
    ERROR_FETCHING_SELECTIONS: "Något gick fel när urvalen skulle hämtas, prova igen senare.",
    S3_LABEL: "Hantera urval",
    S3_LABEL_ANALYSIS_TYPE: "Analystyp:",
    S3_LABEL_MAIN_REGISTRY: "Huvudregister är:",
    S3_MAIN_REGISTRY_DESCRIPTION: "Ett huvudregister är det register som har den senast valda perioden.",
    S3_SELECT_SALARY_TYPE: "Välj lönebegrepp (fast lön rekommenderas):",
    S3_SALARY_TYPE_1: "Grundlön",
    S3_SALARY_TYPE_2: "Fast lön",
    S3_SALARY_TYPE_3: "Totallön",
    S3_SELECT_POPULATION: "Välj population för löneutveckling:",
    S3_POPULATION_TYPE_1: "Kollektiv",
    S3_POPULATION_TYPE_2: "Identiska individer",
    S3_LABEL_NAME: "Namnge ditt urval:",
    S3_SELECT_GROUPS: "Välj grupper att lägga till urvalet:",
    S3_SELECT_GROUP: "Välj grupp att lägga till urvalet:",
    S3_GROUPS_DESCRIPTION: "Grupperna är baserade på huvudregistret.",
    S3_GROUPS_DESCRIPTION_2: "De grupper som syns är grupper som finns i båda registren. Övriga grupper exkluderas.",
    S3_TH1: "Grupp",
    S3_TH2: "Grupptyp",
    S3_TH3: "Antal",
    S3_TH4: "Finns i",
    S3_BUTTON_SAVE_SELECTION: "Spara nytt urval",
    S3_CONFIRMATION_SAVED_SELECTION: "Urvalet har sparats",
    S3_SAVE_SELECTION_UNVALIDNAME: "Det finns redan ett urval med samma namn. Var vänlig välj ett annat namn.",
    S3_SAVE_SELECTION_ERROR: "Det gick inte att spara urvalet.",
    S3_NO_GROUPS: "Det finns inga grupper med identiska individer i valda register",
    S4_H2: "Analysera urval",
    S4_LABEL_ANALYSIS_TYPE: "Analystyp:",
    S4_LABEL_REGISTRIES: "Valda register:",
    S4_DIAGRAM_TITLE_ANALYSISTYPE1: "Löneutveckling per grupp",
    S4_DIAGRAM_TITLE_ANALYSISTYPE2: "Löneutvecklingsspridning",
    S4_DIAGRAM_TITLE_ANALYSISTYPE3: "Löneläge och Löneutveckling",
    S4_DIAGRAM_SALARY_TYPE: "Lönebegrepp: ",
    S4_DIAGRAM_POPULATION_TYPE: "Population: ",
    S4_DIAGRAM_GROUP: "Grupp: ",
    S4_DIAGRAM_PERIOD: "Period: ",
    S4_COUNT: "Antal: ",
    S4_MINOR_GRID: "Stödlinjer",
    S4_SELECT_SHOW_INDIVIDUAL: "Visa löneutveckling per individ",
    S4_SELECT_INTERVAL_KR: "Visa intervall i kronor",
    S4_INTERVAL_UNIT: "Intervall ",
    S4_UNIT_PERCENT: "(%)",
    S4_UNIT_KR: "(Kr)",
    S4_NO_DIAGRAM_DATA: "Matchningar/analys saknas för valt urval",
    S4_TABLE2_TH1: "Intervall",
    S4_TABLE2_TH2: "Antal individer",
    S4_TABLE2_TH3: "Andel individer",
    S4_TABLE2_TH1_SUFFIX_KR: "kronor",
    S4_TABLE2_TH1_SUFFIX_PERCENT: "procent",
    S4_TABLE3_TH1: "Pnr",
    S4_TABLE3_TH2: "Namn",
    S4_TABLE3_TH3: "Anst",
    S4_TABLE3_TH4: "Omf",
    S4_TABLE3_TH7: "Δ Kr",
    S4_TABLE3_TH8: "Δ %",
}

export const USER_ADMINISTRATION_TEXTS = {
    BUTTON_CREATE_USER: "Skapa användare",
    NO_USERS: "Det finns inga registrerade användare.",
    CONFIRMATION_DELETED: "Användaren har tagits bort.",
    CONFIRMATION_CREATED: "Användaren har lagts till.",
    CONFIRMATION_UPDATED: "Behörigheter aktiveras vid användarens nästa inloggning.",
    ERROR_USER_EMAIL_EXISTS: "E-postadressen är upptagen.",
    ERROR_CANNOT_REMOVE_LOCAL_ADMIN: "Rollen lokal administratör går ej att ta bort från eget användarkonto.",
    ERROR_DELETING_USER: "Det gick inte att ta bort användaren:",
    // FIRSTNAME: "Förnamn",
    // LASTNAME: "Efternamn",
    NAME: "Namn",
    EMAIL: "E-post",
    MOBILE: "Mobilnummer",
    ROLE: "Roll",
    ADMINISTER: "Administrera",
    H2_CREATE_USER: "Skapa användare",
    H2_UPDATE_USER: "Redigera användare",
    ALL_FIELDS_ARE_MANDATORY: "Samtliga fält är obligatoriska, samt minst en roll måste tilldelas.",
    WARNING_REMOVE: "Vill du ta bort användare:",
    LABEL_FIRSTNAME: "Förnamn:",
    LABEL_LASTNAME: "Efternamn:",
    LABEL_EMAIL: "E-post:",
    LABEL_MOBILE: "Mobilnummer:",
    LEGEND_ROLES: "Tilldela roller:",
    BUTTON_CHANGE: "Ändra",
    BUTTON_REMOVE: "Ta bort",
    BUTTON_CREATE: "Skapa användare",
    BUTTON_UPDATE: "Spara",
    BUTTON_ABORT: "Avbryt",
}

export const ORG_ADMINISTRATION_TEXTS = {
    INFO_PROGRESS:
        "Sidor Nya löner och Löneorganisation är under pågående revision för en omstrukturering eller eventuell avveckling. Vill du använda dig av funktionen kan du logga in med samma lösenord och användarnamn på den äldre versionen av Analyskraft. Sidan är inte tillgänglighetsanpassad.",
    LINK_TEXT: "Till gamla Analyskraft",
    INFO_REDIRECT: "Omdirigering kan ta längre tid än förväntat, var god och vänta tills sidan har laddat klart.",
}

const GENERAL_TEXTS = {
    LOGIN: "Ange ditt förnamn",
    FOOTER_TEXT_OLD:
        "Kakor (cookies) På idp.analyskraft.arbetsgivarverket.se använder vi kakor. Kakor är små textfiler som lagras på den enhet du surfar på, till exempel dator eller mobiltelefon. Textfilen som skickas är inte skadlig och innehåller ingen programkod. En kaka eller cookie, är en liten textfil som webbplatsen sparar på din dator när du besöker den. Kakan innehåller ingen information som kan kopplas ihop med dig som enskild person. På den här webbplatsen använder vi kakor för att förenkla för dig som besökare. Läs mer om kakor (cookies) på Post- och telestyrelsens webbplats SFS: Lagen om elektronisk kommunikation (2003:389)",
    FOOTER_TEXT:
        "Kakor (cookies) På idp.analyskraft.arbetsgivarverket.se använder vi kakor. Kakor är små textfiler som lagras på den enhet du surfar på, till exempel dator eller mobiltelefon. Textfilen som skickas är inte skadlig och innehåller ingen programkod. En kaka eller cookie, är en liten textfil som webbplatsen sparar på din dator när du besöker den. Kakan innehåller ingen information som kan kopplas ihop med dig som enskild person. På den här webbplatsen använder vi kakor för att garantera säker inloggning.",
    COPYRIGHT_TEXT: "Arbetsgivarverket",
    FOOTER_2: "Vi använder kakor för att hålla reda på din inloggningssession.",
    FOOTER_3: "Vi använder kakor för att garantera en säker inloggning.",
    FOOTER_4: "För att garantera en säker inloggning använder vi oss av kakor på Analyskraft.",
    CONFIRMATION_SUCCESSFUL: "Dina ändringar har sparats.",
    ERROR_TEXT_SAVING: "Tyvärr kunde inte dina ändringar genomföras, prova igen senare!",
    FETCH_ERROR_GENERIC: "Något gick fel när data skulle hämtas, prova igen senare.",
    POST_ERROR_GENERIC:
        "Något gick fel när din förfråga skulle genomföras, kontrollera internetanslutning och prova igen senare.",
    BUTTON_SAVE: "Spara",
    BUTTON_ABORT: "Avbryt",
    BUTTON_CLOSE: "Stäng",
    BUTTON_CHANGE: "Ändra",
    BUTTON_ADD: "Lägg till",
    BUTTON_YES: "Ja",
    BUTTON_NO: "Nej",
    BUTTON_BACK: "Tillbaka",
    BUTTON_FORWARD: "Fortsätt",
    BUTTON_FORWARD2: "Nästa steg",
    VALIDATION_EMAIL: "E-post har fel format.",
    VALIDATION_MOBILE: "Telefonnummret har fel format. Ett telefonnummer får enbart innehålla tio siffror.",
    ARIA_DESCRIBEDBY_MULTISELECT_DROPDOWN:
        "Tryck på ytan för att öppna eller stänga flervalsfönstret för val av register.",
}

export default GENERAL_TEXTS
