import { Dispatch, SetStateAction } from "react"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { GROUPS_TEXTS } from "constants/text.constants"

import idMapper from "utils/idMapper"
import menuBuilder from "utils/menuBuilder"

import { rolesInitialValue } from "context/RolesContext"

import {
    analysisSelection,
    dispatchBool,
    dispatchString,
    Employees,
    groupTable,
    Header,
    Icomparisonstatistic,
    IGetEmployees,
    IMainAccount,
    ISupportItem,
    IRegister,
    IRegisterGroup,
    ITableView,
    ITableViewRow,
    menuAccordion,
    mixedSelcetions,
    rolesObject,
    textObject,
    wageGapSalaryGroups,
    wageGapSavedSelection,
    tWorkGroupComment,
    workGroupComments,
    IRegisterOverview,
    IFilter,
    salarySavedSelection,
    ICurrentSalarySimulation,
    IcomparisonstatisticAnalysis,
    IComparisonstatisticsForAnalysis,
    IAllRegionsAndBusinessFocus,
    tSavedComparisonAnalysisObject,
    tComparisonstatisticsAnalysisObject,
    dispatchNumber,
    tMainAccountRegisterHealth,
    tUser,
    tTimeseriesSavedSelection,
    tTimeseriesAnalysis,
    tTimeseriesPreselectedGroup,
    tGapMenWomenAssessments,
    tGapMenWomenAllAssessmentGroup,
    tGapMenWomenAssessmentGroup,
    tEquivalentWork,
    tGapMenWomenMatchingGroup,
    tUserRoles,
} from "types/sharedTypes"

import { getJSONrequest } from "./apiService"

export const fetchMainAccounts = (
    setter: Dispatch<SetStateAction<IMainAccount[] | undefined>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getMainAccounts)
        .then((accountList) => {
            setter(accountList.filter((mainAccount: IMainAccount) => mainAccount.Deleted === false))
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchMainAccountsRegistersHealth = (
    id: string,
    setter: Dispatch<SetStateAction<tMainAccountRegisterHealth[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().getMainAccountsRegistersHealthById, id))
        .then((registers) => {
            setter(registers)
            if (errorSetter) errorSetter("")
            if (fetching) fetching(false)
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchSupportItem = (
    url: string,
    setter: Dispatch<SetStateAction<ISupportItem[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(url)
        .then((manualSupportList) => {
            setter(manualSupportList)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchRegistries = (
    setter: (r: IRegister[]) => void,
    noData: dispatchBool,
    setLatestRegistryId?: dispatchString,
    fetching?: dispatchBool,
    contextSet?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getRegistries)
        .then((registries) => {
            if (!registries.length) noData(true)
            if (setLatestRegistryId) setLatestRegistryId(registries[0].Id)
            setter(registries)
            if (fetching) fetching(false)
            if (contextSet) contextSet(true)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchRegisterOverview = (
    id: string,
    // setter: Dispatch<SetStateAction<IRegisterOverview | undefined>>,
    setRegistryOverview: Dispatch<SetStateAction<IRegisterOverview | undefined>>,
    setDiagramData: Dispatch<SetStateAction<IRegisterOverview | undefined>>,
    // setRows: Dispatch<SetStateAction<ITableViewRow[] | undefined>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().getRegisterById, id))
        .then((overview: IRegisterOverview) => {
            setRegistryOverview(JSON.parse(overview.RegistryOverview))
            setDiagramData(JSON.parse(overview.DiagramData))

            // console.log(
            //     "parsed data, regi, diagram",
            //     JSON.parse(overview.RegistryOverview),
            //     JSON.parse(overview.DiagramData)
            // )
            // const dataObject: ITableViewRow[] = []

            // // eslint-disable-next-line array-callback-return
            // overview.map((tableRow) => {
            //     dataObject.push({
            //         BESTA: tableRow.BestaGroup,
            //         Åldersgrupp: tableRow.AgeGroup,
            //         Antal: tableRow.Count,
            //         P10: tableRow.FirstTenthPercentile,
            //         P25: tableRow.FirstQuarterPercentile,
            //         P50: tableRow.MedianPercentile,
            //         P75: tableRow.LastQuarterPercentile,
            //         P90: tableRow.LastTenthPercentile,
            //     })
            // })
            // setRows(dataObject)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchRoles = (
    setter: Dispatch<SetStateAction<rolesObject>>,
    menuSetter: Dispatch<SetStateAction<menuAccordion[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    const canSee = rolesInitialValue
    getJSONrequest(apiEndpoints().getUserresources)
        .then((resources: string[]) => {
            canSee.createmainaccount = resources.some((role) => role === "AdministrateMainAccount")
            canSee.deletemainaccount = resources.some((role) => role === "DeleteMainAccount")
            canSee.importregistry = resources.some((role) => role === "ImportRegister")
            canSee.importcomparissonfile = resources.some((role) => role === "ImportRegister")
            canSee.showreport = resources.some((role) => role === "ShowStandardReport")
            canSee.analyzewagegap = resources.some((role) => role === "AnalyzeSalaryDifference")
            canSee.analyzesalaryanddistribution = resources.some((role) => role === "AnalyzeSalaryAndDistribution")
            canSee.analyzecomparisonstatistic = resources.some((role) => role === "Analyzecomparisonstatistic")
            canSee.groups = resources.some((role) => role === "AdministrateGroups")
            canSee.administratesalarytree = resources.some((role) => role === "CreateSalaryTree")
            canSee.analyzenewsalary = resources.some((role) => role === "ParticipateInSalarySimulation")
            canSee.administrateusers =
                resources.some((role) => role === "LocalAdministration") ||
                resources.some((role) => role === "CentralAdministration") ||
                resources.some((role) => role === "AgvAdministration")

            if (canSee.createmainaccount || canSee.deletemainaccount) canSee.mainaccount = true
            if (canSee.importregistry || canSee.showreport) canSee.registry = true
            if (
                canSee.analyzewagegap ||
                canSee.analyzesalaryanddistribution ||
                canSee.analyzecomparisonstatistic ||
                canSee.analyzenewsalary
            ) {
                canSee.analyze = true
            }
            if (canSee.administrateusers || canSee.administratesalarytree) canSee.administrate = true

            canSee.rolesFetched = true

            setter(canSee)
            const menu = menuBuilder(canSee)
            menuSetter(menu)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchAgvAdmin = (
    setter: dispatchBool,
    fetching?: dispatchBool,
    contextSet?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().agvAdministrator)
        .then((isAdministrator: boolean) => {
            setter(isAdministrator)
            if (fetching) fetching(false)
            if (contextSet) contextSet(true)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchTexts = (
    setter: (r: textObject[]) => void,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getTexts)
        .then((textArray: textObject[]) => {
            setter(textArray)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchGroups = (
    id: string,
    setter: Dispatch<SetStateAction<IRegisterGroup[]>>,
    setDefaultGroups?: Dispatch<SetStateAction<groupTable[]>>,
    setFilterGroups?: Dispatch<SetStateAction<groupTable[]>>,
    setManualGroups?: Dispatch<SetStateAction<groupTable[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().getGroupsForRegistryById, id))
        .then((groupTableData: IRegisterGroup[]) => {
            setter(groupTableData)

            // We are actually interesed in those 3 groups
            const defaultGroups: groupTable[] = []
            const filterGroups: groupTable[] = []
            const manualGroups: groupTable[] = []

            groupTableData.forEach((groupObject) => {
                if (groupObject.GroupType.Value === "Manuell") {
                    manualGroups.push({
                        [GROUPS_TEXTS.GROUPS_TH1]: groupObject.Name,
                        [GROUPS_TEXTS.GROUPS_TH2]: groupObject.GroupType.Value,
                        [GROUPS_TEXTS.GROUPS_TH3]: groupObject.Count,
                        GroupId: groupObject.Id,
                    })
                } else if (groupObject.GroupType.Value === "Filter") {
                    filterGroups.push({
                        [GROUPS_TEXTS.GROUPS_TH1]: groupObject.Name,
                        [GROUPS_TEXTS.GROUPS_TH2]: groupObject.GroupType.Value,
                        [GROUPS_TEXTS.GROUPS_TH3]: groupObject.Count,
                        GroupId: groupObject.Id,
                    })
                } else {
                    defaultGroups.push({
                        [GROUPS_TEXTS.GROUPS_TH1]: groupObject.Name,
                        [GROUPS_TEXTS.GROUPS_TH2]: groupObject.GroupType.Value,
                        [GROUPS_TEXTS.GROUPS_TH3]: groupObject.Count,
                        GroupId: groupObject.Id,
                    })
                }
            })
            if (setDefaultGroups) setDefaultGroups(defaultGroups)
            if (setFilterGroups) setFilterGroups(filterGroups)
            if (setManualGroups) setManualGroups(manualGroups)

            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchValidFilters = (
    id: string,
    setter: Dispatch<SetStateAction<IFilter[]>>,
    setFilterNames: Dispatch<SetStateAction<string[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().getValidFiltersForRegistryById, id))
        .then((validFilters: IFilter[]) => {
            setter(validFilters)

            const filterNames: string[] = []
            validFilters.map((filter) => filterNames.push(filter.Name))
            setFilterNames(filterNames)

            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchEmployeesInAGroup = (
    groupId: string,
    setEmployees: Dispatch<SetStateAction<Employees[]>>,
    setHeaders: Dispatch<SetStateAction<Header>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().getGroupEmployeesById, groupId))
        .then((employeesObject: IGetEmployees) => {
            setEmployees(employeesObject.Employees)
            setHeaders(employeesObject.Header)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchComparisonstatistics = (
    setter: Dispatch<SetStateAction<Icomparisonstatistic[] | undefined>>,
    noData: dispatchBool,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getComparisonstatistics)
        .then((comparisonstatisticList: Icomparisonstatistic[]) => {
            if (!comparisonstatisticList.length) noData(true)
            setter(comparisonstatisticList)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchTableView = (
    id: string,
    setter: Dispatch<SetStateAction<ITableView[] | undefined>>,
    setRows: Dispatch<SetStateAction<ITableViewRow[] | undefined>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().getTableViewById, id))
        .then((tableView: ITableView[]) => {
            setter(tableView)
            const dataObject: ITableViewRow[] = []

            // eslint-disable-next-line array-callback-return
            tableView.map((tableRow) => {
                dataObject.push({
                    BESTA: tableRow.BestaGroup,
                    Åldersgrupp: tableRow.AgeGroup,
                    Antal: tableRow.Count,
                    P10: tableRow.FirstTenthPercentile,
                    P25: tableRow.FirstQuarterPercentile,
                    P50: tableRow.MedianPercentile,
                    P75: tableRow.LastQuarterPercentile,
                    P90: tableRow.LastTenthPercentile,
                    Region: tableRow.Region,
                    VI: tableRow.BusinessFocus,
                })
            })
            setRows(dataObject)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchSalarySavedSelections = (
    id: string,
    setter: Dispatch<SetStateAction<salarySavedSelection[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().analyzeGetSalarySelectionsById, id))
        .then((savedSelections: salarySavedSelection[]) => {
            setter(savedSelections)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchSavedSelections = (
    id: string,
    setter: Dispatch<SetStateAction<wageGapSavedSelection[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().analyzeGetSavedSelectionsById, id))
        .then((savedSelections: wageGapSavedSelection[]) => {
            setter(savedSelections)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchSalaryGapGroups = (
    id: string,
    setter: Dispatch<SetStateAction<wageGapSalaryGroups[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetSalaryGapGroupsById, id))
        .then((savedSelections: wageGapSalaryGroups[]) => {
            setter(savedSelections)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchAnalysisSelection = (
    id: string,
    setSelectedGroups: Dispatch<SetStateAction<string[]>>,
    setName: dispatchString,
    setSalaryType: Dispatch<SetStateAction<string[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetAnalysisSelectionById, id))
        .then((savedSelection: analysisSelection) => {
            setSelectedGroups(savedSelection.GroupIds)
            setName(savedSelection.Name)

            const SalaryTypes = ["", "Grundlön", "", "", "Fast lön", "Totallön"]
            setSalaryType([SalaryTypes[savedSelection.SalaryType]])
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchMixedGroups = (
    id: string,
    setter: Dispatch<SetStateAction<mixedSelcetions[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
): Promise<void> =>
    new Promise<void>((resolve, reject) => {
        getJSONrequest(idMapper(apiEndpoints().analyzeGetMixedGroupsById, id))
            .then((savedSelection: mixedSelcetions[]) => {
                setter(savedSelection)
                if (fetching) fetching(false)
                if (errorSetter) errorSetter("")
                resolve()
            })
            .catch((error) => {
                if (error.statusText && errorSetter) errorSetter(error.statusText)
                if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                reject(error)
            })
    })

// Can be equal or equivalent work, different api
export const fetchWorkGroupComment = (
    analysisId: string,
    groupId: string,
    workType: string,
    setter: Dispatch<SetStateAction<tWorkGroupComment>>,
    setCommentText: Dispatch<SetStateAction<string>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    const equalWorkType = workType === "equal"
    const commentType = equalWorkType ? "1" : "2"

    const url = apiEndpoints().analyzeGetCommentForGroup
        .replace(":analysisId", analysisId)
        .replace(":groupId", groupId)
        .replace(":commentType", commentType)

    getJSONrequest(url)
        .then((commentObject: tWorkGroupComment) => {
            setter(commentObject)
            if (commentObject.Comment) setCommentText(commentObject.Comment)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchAllWorkGroupComments = (
    groupId: string,
    workType: string,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
): Promise<workGroupComments> => {
    const equalWorkType = workType === "equal"
    const commentType = equalWorkType ? "1" : "2"

    const url = apiEndpoints().analyzeGetAllCommentsForGroup.replace(":groupId", groupId).replace(":commentType", commentType)

    return new Promise<workGroupComments>((resolve, reject) => {
        getJSONrequest(url)
            .then((comments: workGroupComments) => {
                if (fetching) fetching(false)
                if (errorSetter) errorSetter("")
                resolve(comments)
            })
            .catch((error) => {
                if (error.statusText && errorSetter) errorSetter(error.statusText)
                if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
                reject(error)
            })
    })
}

export const fetchAssessments = (
    analysisId: string,
    setter: Dispatch<SetStateAction<tGapMenWomenAssessments | undefined>>,
    setNoAssessments: dispatchBool,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetAssessmentsById, analysisId))
        .then((assessments: tGapMenWomenAssessments) => {
            if (assessments.Name === null) setNoAssessments(true)
            setter(assessments)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (fetching) fetching(false)
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
        })
}

export const fetchAllAssessmentGroups = (
    analysisId: string,
    setter: Dispatch<SetStateAction<tGapMenWomenAllAssessmentGroup[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetAllAssessmentGroupsById, analysisId))
        .then((allAssessmentGroups: tGapMenWomenAllAssessmentGroup[]) => {
            setter(allAssessmentGroups)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchAssessmentGroups = (
    analysisId: string,
    setter: Dispatch<SetStateAction<tGapMenWomenAssessmentGroup[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetAssessmentGroupById, analysisId))
        .then((assessmentGroups: tGapMenWomenAssessmentGroup[]) => {
            setter(assessmentGroups)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchMatchingGroups = (
    analysisId: string,
    setter: Dispatch<SetStateAction<tGapMenWomenMatchingGroup[][]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeWageGapGetMatchingGroupsById, analysisId))
        .then((matchingGroups: tGapMenWomenMatchingGroup[][]) => {
            setter(matchingGroups)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchEquivalentWorkGroups = (
    analysisId: string,
    isNewBesta: boolean,
    employeeType: "ExcludingManagers" | "Managers" | "All",
    setter: Dispatch<SetStateAction<tEquivalentWork[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    const url = apiEndpoints().analyzeGetEquivalentWork
        .replace(":analysisId", analysisId)
        .replace(":isNewBesta", isNewBesta.toString())
        .replace(":employeeType", employeeType)

    getJSONrequest(url)
        .then((groups: tEquivalentWork[]) => {
            setter(groups)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchCurrentSalarySimulation = (
    setter: Dispatch<SetStateAction<ICurrentSalarySimulation>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(apiEndpoints().analyzeGetCurrentSalarySimulation)
        .then((salarySimulationObject: ICurrentSalarySimulation) => {
            setter(salarySimulationObject)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchReferencestatisticSavedSelections = (
    id: string,
    setter: Dispatch<SetStateAction<IcomparisonstatisticAnalysis[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeComparisonSavedAnalysis, id))
        .then((savedSelection: IcomparisonstatisticAnalysis[]) => {
            setter(savedSelection)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchComparisonstatisticsForAnalysis = (
    id: string,
    setter: Dispatch<SetStateAction<IComparisonstatisticsForAnalysis[]>>,
    optionsSetter?: Dispatch<SetStateAction<string[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().analyzeGetComparisonStatisticsForAnalysisById, id))
        .then((comparisonstatistics: IComparisonstatisticsForAnalysis[]) => {
            setter(comparisonstatistics)
            if (optionsSetter) {
                /* {{file.ValidFor}} - {{file.EmployeeType}} - {{file.Filename} */
                const options: string[] = [""]
                comparisonstatistics.forEach((statistic) => {
                    options.push(`${statistic.ValidFor} - ${statistic.EmployeeType} - ${statistic.Filename}`)
                })
                optionsSetter(options)
            }

            if (errorSetter) errorSetter("")
            if (fetching) fetching(false)
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchAllRegionsAndBusinessFocus = (
    id: string,
    setter: Dispatch<SetStateAction<IAllRegionsAndBusinessFocus>>,
    businessAreaOptionsSetter?: Dispatch<SetStateAction<string[]>>,
    regionOptionsSetter?: Dispatch<SetStateAction<string[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    if (fetching) fetching(true)
    getJSONrequest(idMapper(apiEndpoints().analyzeGetAllRegionsAndBusinessFocusById, id))
        .then((allRegionsAndBusinessFocus: IAllRegionsAndBusinessFocus) => {
            setter(allRegionsAndBusinessFocus)
            const businessAreas = allRegionsAndBusinessFocus.BusinessFocus
            const regions = allRegionsAndBusinessFocus.Regions

            if (businessAreaOptionsSetter) {
                const options: string[] = []
                businessAreas.forEach((businessArea) => options.push(businessArea.Value))
                businessAreaOptionsSetter(options)
            }
            if (regionOptionsSetter) {
                const options: string[] = []
                regions.forEach((region) => options.push(region.Value))
                regionOptionsSetter(options)
            }
            if (errorSetter) errorSetter("")
            if (fetching) fetching(false)
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchSavedComparisonAnalysis = (
    id: string,
    setter: Dispatch<SetStateAction<tSavedComparisonAnalysisObject | undefined>>,
    setAnalysisObject?: Dispatch<SetStateAction<tComparisonstatisticsAnalysisObject>>,
    setDiagType?: dispatchNumber,
    setPreselectedGroups?: Dispatch<SetStateAction<string[]>>,
    setSelectedReferenceRegisterId?: dispatchString,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetComparisonStatisticsAnalysisById, id))
        .then((savedSelection: tSavedComparisonAnalysisObject) => {
            setter(savedSelection)
            if (setAnalysisObject) {
                const findRegOrBA = (code: string, region: boolean) => {
                    // when medlemsanpassad is choosen there is no match so findIndex returns -1, therefor we need to set default value to 0.
                    let ix = 0
                    if (code) {
                        ix = region
                            ? savedSelection.RegionsAndBusinessFocus.Regions.findIndex((el) => el.Code === code)
                            : savedSelection.RegionsAndBusinessFocus.BusinessFocus.findIndex((el) => el.Code === code)

                        const result = region
                            ? savedSelection.RegionsAndBusinessFocus.Regions[ix].Value
                            : savedSelection.RegionsAndBusinessFocus.BusinessFocus[ix].Value

                        return { Code: code, Value: result }
                    }

                    return { Code: 0, Value: "Alla" }
                }

                setAnalysisObject((state) => ({
                    ...state,
                    statisticsType: savedSelection.selectedComparisonType.Value,
                    period: savedSelection.selectedComparisonFile.ValidFor,
                    name: savedSelection.selectedComparisonFile.Filename,
                    individualsType: savedSelection.selectedEmployeeType.Value,
                    region: findRegOrBA(savedSelection.selectedRegion, true),
                    businessArea: findRegOrBA(savedSelection.selectedBusinessFocus, false),
                }))
            }
            if (setDiagType) setDiagType(savedSelection.selectedDiagramType.Key)
            if (setPreselectedGroups) setPreselectedGroups(savedSelection.selectedGroups)
            if (setSelectedReferenceRegisterId) setSelectedReferenceRegisterId(savedSelection.selectedComparisonFile.Id)
            if (errorSetter) errorSetter("")
            if (fetching) fetching(false)
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchUsers = (
    setter: Dispatch<SetStateAction<tUser[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getUsers)
        .then((users: tUser[]) => {
            setter(users)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchAllRoles = (
    setter: Dispatch<SetStateAction<tUserRoles[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().getRoles)
        .then((roles: tUserRoles[]) => {
            setter(roles)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchTimeseriesSavedSelections = (
    setter: Dispatch<SetStateAction<tTimeseriesSavedSelection[]>>,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(apiEndpoints().analyzeGetTimeseriesSavedSelections)
        .then((selections: tTimeseriesSavedSelection[]) => {
            setter(selections)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}

export const fetchTimeseriesSavedAnalysis = (
    id: string,
    setter: Dispatch<SetStateAction<tTimeseriesAnalysis | undefined>>,
    setPreselectedGroups: React.Dispatch<React.SetStateAction<tTimeseriesPreselectedGroup[]>>,
    setSelectedRegistries: React.Dispatch<React.SetStateAction<Map<string, string>>>,
    setSelectedDiagramType: dispatchNumber,
    setSelectedSalaryType: dispatchNumber,
    fetching?: dispatchBool,
    errorSetter?: dispatchString
) => {
    getJSONrequest(idMapper(apiEndpoints().analyzeGetTimeseriesSavedAnalysisById, id))
        .then((analysisObject: tTimeseriesAnalysis) => {
            setter(analysisObject)

            const selectedRegistriesMap = new Map()
            analysisObject.Registries.forEach((register) => {
                selectedRegistriesMap.set(register.Id, register.Name)
            })
            setSelectedRegistries(selectedRegistriesMap)
            setPreselectedGroups(analysisObject.Groups)
            setSelectedDiagramType(analysisObject.DiagramType.Key)
            setSelectedSalaryType(analysisObject.SalaryType.Key)
            if (fetching) fetching(false)
            if (errorSetter) errorSetter("")
        })
        .catch((error) => {
            if (error.statusText && errorSetter) errorSetter(error.statusText)
            if (errorSetter) errorSetter(GENERAL_TEXTS.FETCH_ERROR_GENERIC)
            if (fetching) fetching(false)
        })
}
