import "./CreateSelection.css"

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { FirstDataRenderedEvent, RowClickedEvent } from "ag-grid-community"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect, useCallback, useRef, useMemo } from "react"
import prepareCsrfToken from "utils/prepareCsrfToken"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchAnalysisSelection, fetchSalaryGapGroups } from "services/fetchers"

import { dispatchNumber, dispatchString, salaryGapGroupsData, wageGapSalaryGroups } from "types/sharedTypes"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"
import TableButtonRemove from "./TableButtonRemove"

interface ISavedSelectionsTable {
    registryId: string
    isNewBesta: boolean
    analysisId: string
    setAnalysisId: dispatchString
    selectedSalaryType: number
    setSelectedSalaryType: dispatchNumber
    setActiveStep: dispatchNumber
}

const CreateSelection = ({
    registryId,
    isNewBesta,
    analysisId,
    setAnalysisId,
    selectedSalaryType,
    setSelectedSalaryType,
    setActiveStep,
}: ISavedSelectionsTable) => {
    const { S2_TH1, S2_TH2 } = ANALYZE_WAGE_GAP

    const [fetchError, setFetchError] = useState("")
    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [salaryGapGroups, setSalaryGapGroups] = useState<wageGapSalaryGroups[]>([])
    const [fetchingSalaryGapGroups, setFetchingSalaryGapGroups] = useState(true)
    const [fetchingAnalysisSelection, setFetchingAnalysisSelection] = useState(true)

    const [savingDraft, setSavingDraft] = useState(false)
    const [savingSelection, setSavingSelection] = useState(false)

    const SalaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])
    const [selectedTypeOfSalary, setSelectedTypeOfSalary] = useState<string[]>(
        selectedSalaryType ? [SalaryTypes[selectedSalaryType] || ""] : []
    )
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([])

    const [salaryGapGroupsRows, setSalaryGapGroupsRows] = useState<salaryGapGroupsData[]>([])
    const [selectedSalaryGapGroupsRows, setSelectedSalaryGapGroupsRows] = useState<salaryGapGroupsData[]>([])

    const [preselectedGroups, setPreselectedGroups] = useState<string[]>([])
    const [analysisPreselectedGroupIds, setAnalysisPreselectedGroups] = useState<string[]>([])

    const [doublesWarning, setDoublesWarning] = useState("")
    const [showDoublesWarning, setShowDoublesWarning] = useState(false)

    const [selectionName, setSelectionName] = useState("")

    const gridRef = useRef<AgGridReact<salaryGapGroupsData>>(null)
    const defaultColDef = KeyboardNavigation()

    const [columnDefs] = useState([{ field: S2_TH1, flex: 1, checkboxSelection: true, showDisabledCheckboxes: true }])
    const [columnDefsSelected] = useState([
        { field: S2_TH1, flex: 1 },
        {
            field: S2_TH2,
            flex: 0.5,
            cellRenderer: TableButtonRemove,
            cellRendererParams: {
                gridRef,
            },
        },
    ])
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const onSelectionChanged = (event: RowClickedEvent) => {
        const groupIds: string[] = []

        setSelectedSalaryGapGroupsRows(event.api.getSelectedRows())

        const selectedRows = event.api.getSelectedRows()
        selectedRows.forEach((group: salaryGapGroupsData) => {
            groupIds.push(group.GroupId)
        })

        setSelectedGroupIds(groupIds)
    }

    // https://www.ag-grid.com/react-data-grid/row-selection/
    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<salaryGapGroupsData>) => {
            gridRef.current?.api.forEachNode((node) =>
                node.setSelected(!!node.data && preselectedGroups.includes(node.data.Grupp))
            )
        },
        [preselectedGroups]
    )

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))

    const saveSelectionDraft = () => {
        setSavingDraft(true)

        const body = {
            RegistryId: registryId,
            Name: selectionName,
            GroupIds: selectedGroupIds,
            SalaryType: selectedSalaryType,
            SalaryAnalysisId: analysisId || "",
            isNewBesta,
        }
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeSelectionDraft, csrfToken, JSON.stringify(body))
                .then(() => {
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setSavingDraft(false)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    setSavingDraft(false)
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName") setPostError(ANALYZE_WAGE_GAP.S2_POST_ERROR_INVALID_NAME)
                            else setPostError(ANALYZE_WAGE_GAP.S2_POST_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const submitSelectionDraft = (checkCount: boolean) => {
        setSavingSelection(true)
        setDoublesWarning("")

        const body = {
            CheckRegistryCount: checkCount,
            RegistryId: registryId,
            Name: selectionName,
            GroupIds: selectedGroupIds,
            SalaryType: selectedSalaryType,
            SalaryAnalysisId: analysisId || "",
            isNewBesta,
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeAnalysisSelection, csrfToken, JSON.stringify(body))
                .then((newAnalysisId) => {
                    setAnalysisId(newAnalysisId)
                    setPostError("")
                    setShowWarning(false)
                    setSavingSelection(false)
                    setActiveStep(3)
                })
                .catch((err) => {
                    setPostError("")
                    setSavingSelection(false)
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName") setPostError(ANALYZE_WAGE_GAP.S2_POST_ERROR_INVALID_NAME)
                            else if (text === "WageGapStepTwoAllEmployeesNotIncluded") setShowWarning(true)
                            else if (text.slice(0, 24) === "WageGapStepTwoHasDoubles") {
                                setDoublesWarning(text.slice(25))
                                setShowDoublesWarning(true)
                            } else setPostError(ANALYZE_WAGE_GAP.S2_POST_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const notChanged = () => !selectionName || !selectedTypeOfSalary[0] || !selectedGroupIds[0]

    useEffect(() => {
        fetchSalaryGapGroups(registryId, setSalaryGapGroups, setFetchingSalaryGapGroups, setFetchError)
    }, [registryId])

    useEffect(() => {
        // Get predefined values and populate the page if there is an ongoing analysis
        if (analysisId) {
            fetchAnalysisSelection(
                analysisId,
                setAnalysisPreselectedGroups,
                setSelectionName,
                setSelectedTypeOfSalary,
                setFetchingAnalysisSelection,
                setFetchError
            )
        } else setFetchingAnalysisSelection(false)
    }, [analysisId])

    useEffect(() => {
        setSelectedSalaryType(SalaryTypes.findIndex((element) => element === selectedTypeOfSalary[0]))
    }, [SalaryTypes, selectedTypeOfSalary, setSelectedSalaryType])

    useEffect(() => {
        const rows: salaryGapGroupsData[] = []
        const selectedRowsNames: string[] = []

        salaryGapGroups.forEach((group) => {
            if (group.GroupType.Value === "BESTA") {
                selectedRowsNames.push(group.GroupName)
            }
            rows.push({ Grupp: group.GroupName, GroupId: group.GroupId })
        })
        setSalaryGapGroupsRows(rows)

        if (analysisId && salaryGapGroups.length) {
            const analysisPreselectedRows: string[] = []

            salaryGapGroups.forEach((group) => {
                if (analysisPreselectedGroupIds.includes(group.GroupId)) {
                    analysisPreselectedRows.push(group.GroupName)
                }
            })
            setPreselectedGroups(analysisPreselectedRows)
        } else setPreselectedGroups(selectedRowsNames)
    }, [salaryGapGroups, analysisId, analysisPreselectedGroupIds])

    return (
        <>
            {fetchingSalaryGapGroups && fetchingAnalysisSelection && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingSalaryGapGroups && !fetchingAnalysisSelection && !fetchError && (
                <div>
                    <h2>{ANALYZE_WAGE_GAP.S2_H2}</h2>
                    <p>{ANALYZE_WAGE_GAP.S2_STEP_EXPLANATION}</p>
                    {showConfirmation && (
                        <div className="mb24">
                            <Confirmation>{ANALYZE_WAGE_GAP.S2_CONFIRMATION_SAVED_SELECTION}</Confirmation>
                        </div>
                    )}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    {showWarning && (
                        <div className="mb24">
                            <Warning onClick={() => submitSelectionDraft(false)} showWarning={setShowWarning}>
                                {ANALYZE_WAGE_GAP.S2_WARNING_NOT_ALL_INCLUDED}
                            </Warning>
                        </div>
                    )}
                    {showDoublesWarning && (
                        <div className="mb24">
                            <Warning
                                onClick={() => setShowDoublesWarning(false)}
                                showWarning={setShowDoublesWarning}
                                warningTitle={ANALYZE_WAGE_GAP.S2_WARNING_DOUBLES}
                            >
                                {doublesWarning}
                            </Warning>
                        </div>
                    )}
                    <div className="responsiveSelectionTablesContainer">
                        <div className="flex1">
                            <div>
                                <p className="labelAlike">{ANALYZE_WAGE_GAP.S2_LABEL}</p>
                                <FilterRadioButtonsRow
                                    setSelection={setSelectedTypeOfSalary}
                                    selectedFilter={selectedTypeOfSalary}
                                >
                                    {ANALYZE_WAGE_GAP.S2_SALARY_TYPE_1}
                                    {ANALYZE_WAGE_GAP.S2_SALARY_TYPE_4}
                                    {ANALYZE_WAGE_GAP.S2_SALARY_TYPE_5}
                                </FilterRadioButtonsRow>
                            </div>
                            <div
                                className="ag-theme-alpine"
                                style={{ width: "100%", height: "600px", marginBottom: "64px" }}
                            >
                                {!analysisId && <h3 className="hInsideAgGrid">{ANALYZE_WAGE_GAP.S2_CAPTION_GROUPS}</h3>}
                                {analysisId && (
                                    <h3 className="hInsideAgGrid">{ANALYZE_WAGE_GAP.S2_CAPTION_GROUPS_SHORT}</h3>
                                )}
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={salaryGapGroupsRows}
                                    columnDefs={columnDefs}
                                    localeText={localeText}
                                    rowSelection="multiple"
                                    onFirstDataRendered={onFirstDataRendered}
                                    onSelectionChanged={onSelectionChanged}
                                    rowMultiSelectWithClick
                                    alwaysShowHorizontalScroll
                                    alwaysShowVerticalScroll
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <div>
                                <label className="gray" htmlFor="createSelection">
                                    {ANALYZE_WAGE_GAP.S2_LABEL_NAME}
                                </label>
                                <Input
                                    type="text"
                                    id="createSelection"
                                    name="createSelection"
                                    value={selectionName}
                                    onChange={(e) => setSelectionName(e.target.value)}
                                    required
                                />
                            </div>
                            <div
                                className="ag-theme-alpine"
                                style={{ width: "100%", height: "600px", marginBottom: "64px" }}
                            >
                                <h3 className="hInsideAgGrid">{ANALYZE_WAGE_GAP.S2_CAPTION_SELECTION}</h3>
                                <AgGridReact
                                    rowData={selectedSalaryGapGroupsRows}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefsSelected}
                                    localeText={localeText}
                                    alwaysShowHorizontalScroll
                                    alwaysShowVerticalScroll
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap8 jc-space-between">
                        <Button onClick={deselectAllRows} Icon={CleanIcon}>
                            {ANALYZE_WAGE_GAP.S2_BUTTON_CLEAR_SELECTION}
                        </Button>
                        <div className="flex gap8">
                            <Button
                                disabled={notChanged()}
                                onClick={saveSelectionDraft}
                                Icon={SaveIcon}
                                isLoading={savingDraft}
                            >
                                {ANALYZE_WAGE_GAP.S2_BUTTON_SAVE_SELECTION}
                            </Button>
                            <Button
                                disabled={notChanged()}
                                onClick={() => submitSelectionDraft(true)}
                                Icon={SaveIcon}
                                isLoading={savingSelection}
                            >
                                {ANALYZE_WAGE_GAP.S2_BUTTON_SAVE_SELECTION_N_CONTINUE}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CreateSelection
