import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"

import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import getWages from "utils/Highcharts/getWages"
import thousandSeparator from "utils/thousandSeparator"
import getWageDistribution from "utils/Highcharts/getWageDistribution"

// import thousandSeparator from "utils/thousandSeparator"

interface IRefStatScatterTable {
    tableData: tComparisonstatisticsTableViewObject[]
    amounts: { Men: number; Women: number }
    salaries: { Men: number; Women: number }
}

const RefStatScatterTable = ({ tableData, amounts, salaries }: IRefStatScatterTable) => {
    const dataRow = [
        {
            employeeCount: tableData[0].Count,
            amountsMen: `${amounts.Men}%`,
            amountsWomen: `${amounts.Women}%`,
            salaryDistribution: getWageDistribution(tableData[0]),
            referenceStatisticCount: thousandSeparator(tableData[1].Count.toString()),
            relativeWage: getWages(tableData, salaries.Men, salaries.Women),
        },
    ]

    const defaultColumnDefs = [
        {
            field: "employeeCount",
            headerName: ANALYZE_REFERENCESTATISTICS.S4_EMPLOYEES_COUNT,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        {
            field: "amountsMen",
            headerName: ANALYZE_REFERENCESTATISTICS.S4_MEN_AMOUNT,
            flex: 0.8,
            sortable: true,
            resizable: true,
        },
        {
            field: "amountsWomen",
            headerName: ANALYZE_REFERENCESTATISTICS.S4_WOMEN_AMOUNT,
            flex: 0.8,
            sortable: true,
            resizable: true,
        },
        {
            field: "referenceStatisticCount",
            headerName: ANALYZE_REFERENCESTATISTICS.S4_REFERENCESTATISTICS_COUNT,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        {
            field: "relativeWage",
            headerName: ANALYZE_REFERENCESTATISTICS.S4_RELATIVE_WAGESTATUS,
            flex: 1.2,
            sortable: true,
            resizable: true,
        },
    ]

    const [columnDefs] = useState(defaultColumnDefs)
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    return (
        // ag grid got min hight of 4 rows, this is cutting the rest of the table to only show 1 row
        <div className="ag-theme-alpine" style={{ width: "100%", height: "108px" }}>
            <AgGridReact
                rowData={dataRow}
                columnDefs={columnDefs}
                localeText={localeText}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default RefStatScatterTable
