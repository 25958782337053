/* eslint-disable react/no-this-in-sfc */

import { tScatterDiagramData } from "types/sharedTypes"

import { useEffect, useState } from "react"

import getAmountMenWomen from "utils/Highcharts/getAmountMenWomen"
import getSsnAndNameFromChartData from "utils/Highcharts/getSsnAndNameFromChartData"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

// import { ReactComponent as xMark } from "./xmark.icon.svg"

type tScatterDiagramProps = {
    diagramData: tScatterDiagramData[]
    diagramTitle: string
    showMinorGridLines: boolean
    showGender: boolean
    diagramGrouping: { Key: number; Value: string }[]
    selectedGrouping: string
}

const ScatterDiagram = ({
    diagramData,
    diagramTitle,
    showMinorGridLines,
    showGender,
    diagramGrouping,
    selectedGrouping,
}: tScatterDiagramProps) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const index = diagramGrouping.findIndex((el) => el.Value === selectedGrouping)

    const amounts = getAmountMenWomen(diagramData[3])

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "scatter",
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: "Lön",
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            categories: diagramData[diagramGrouping[index].Key].Catagories,
            title: {
                text: diagramGrouping[index].Value,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                // const x = typeof this.x === "number" ? this.x : -1
                // const y = typeof this.y === "number" ? this.y : -1
                const x = this.x as number
                const y = this.y as number

                const seriesDef = [
                    { data: diagramData[diagramGrouping[index].Key].SalariesMen },
                    { data: diagramData[diagramGrouping[index].Key].SalariesWomen },
                ]
                const categories = diagramData[diagramGrouping[index].Key].Catagories

                const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
            },
        },
        series: [
            {
                id: "1",
                type: "scatter",
                name: `Man ${amounts.Men}%`,
                data: diagramData[diagramGrouping[index].Key].SalariesMen,
                // default in this diagram is to "hide" the gender, so we mask it by providing
                // same symbol and same color to both data series, we update it on slider toggle
                marker: {
                    symbol: "circle",
                    fillColor: "#000",
                },
            },
            {
                id: "2",
                type: "scatter",
                name: `Kvinna ${amounts.Women}%`,
                data: diagramData[diagramGrouping[index].Key].SalariesWomen,
                marker: {
                    symbol: "circle",
                    fillColor: "#000",
                },
            },
        ],
        credits: {
            enabled: false,
        },
    })

    // Uppdate data dynamically
    useEffect(() => {
        // get pervios options from setState, return copy of it with only property yAxis updated
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])
    useEffect(() => {
        setChartOptions((options) => ({
            ...options,
            series: [
                {
                    type: "scatter",
                    marker: showGender
                        ? { symbol: undefined, fillColor: undefined }
                        : { symbol: "circle", fillColor: "#000" },
                },
                {
                    type: "scatter",
                    marker: showGender
                        ? { symbol: undefined, fillColor: undefined }
                        : { symbol: "circle", fillColor: "#000" },
                },
            ],
        }))
    }, [showGender])
    useEffect(() => {
        // get pervios options from setState, return copy of it with only dynamic property updated
        setChartOptions((options) => ({
            ...options,
            xAxis: {
                categories: diagramData[diagramGrouping[index].Key].Catagories,
                title: { text: selectedGrouping },
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    // const x = typeof this.x === "number" ? this.x : -1
                    // const y = typeof this.y === "number" ? this.y : -1
                    const x = this.x as number
                    const y = this.y as number

                    const seriesDef = [
                        { data: diagramData[diagramGrouping[index].Key].SalariesMen },
                        { data: diagramData[diagramGrouping[index].Key].SalariesWomen },
                    ]
                    const categories = diagramData[diagramGrouping[index].Key].Catagories

                    const { ssn, name } = getSsnAndNameFromChartData(seriesDef, x, y, categories)
                    return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
                },
            },
            series: [
                { type: "scatter", data: diagramData[diagramGrouping[index].Key].SalariesMen },
                {
                    type: "scatter",
                    data: diagramData[diagramGrouping[index].Key].SalariesWomen,
                },
            ],
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramData, selectedGrouping])

    // TODO: Add skeleton, fetch error, post error

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default ScatterDiagram
