import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

const getSumOfRegisterSalaries = (salariesMen: [], salariesWomen: []) => {
    const salaries: number[] = []

    salariesMen.forEach((m: number[]) => {
        salaries.push(m[1])
    })

    salariesWomen.forEach((w: number[]) => {
        salaries.push(w[1])
    })

    const sum = 0
    const sumWithInitial = salaries.reduce(
        (accumulator: number, currentValue: number) => accumulator + currentValue,
        sum
    )
    return sumWithInitial
}
const getSumOfCalculationPerAgeGroup = (data: tComparisonstatisticsTableViewObject[]) => {
    const refGroups: Array<typeof data> = []

    let registerAgeGroups: []

    data.forEach((group: any) => {
        if (group.Type === "Ref.statistik") {
            refGroups.push(group)
        }

        if (group.Type === "Register") {
            registerAgeGroups = group.RegisterAgeGroups
        }
    })

    let sum = 0

    refGroups.forEach((refGroup: any) => {
        Object.keys(registerAgeGroups).forEach((ageGroup: any) => {
            if (Number(ageGroup) === refGroup.AgeGroup) {
                const count = registerAgeGroups[ageGroup]

                const median = refGroup.MedianPercentile

                const productOfRefMAndRegAgeGroupCount = median * count

                sum += productOfRefMAndRegAgeGroupCount
            }
        })
    })

    return sum
}

// eslint-disable-next-line default-param-last
function getWages(data: tComparisonstatisticsTableViewObject[], salariesMen: any, salariesWomen: any) {
    let wages = "-"

    const sumOfRegisterSalaries = getSumOfRegisterSalaries(salariesMen, salariesWomen)

    const sumOfCalculationPerAgeGroup = getSumOfCalculationPerAgeGroup(data)

    wages = `${((sumOfRegisterSalaries / sumOfCalculationPerAgeGroup) * 100).toFixed(2).toString().replace(".", ",")}%`

    return wages
}

export default getWages
