import "./SliderButton.css"

import { dispatchBool } from "types/sharedTypes"

type SliderButtonProps = {
    children: string
    checked: boolean
    setChecked: dispatchBool
    uniqueIdentifier?: string
    disabled?: boolean
}

const SliderButton = ({
    children,
    checked,
    setChecked,
    uniqueIdentifier = "",
    disabled = false,
}: SliderButtonProps) => (
    <div className="container">
        <div
            className="switchWrapper"
            role="checkbox"
            aria-checked={checked}
            tabIndex={0}
            onClick={() => setChecked(!checked)}
            onKeyDown={(e) => {
                if (e.key === " " || e.code === "Space") {
                    e.preventDefault()
                    setChecked(!checked)
                }
            }}
        >
            <input
                className="hideInput"
                id={`switch ${children} ${uniqueIdentifier}`}
                name={`switch ${children} ${uniqueIdentifier}`}
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                disabled={disabled}
            />
            <span className={`switch-body ${checked ? "active" : ""}`} />
            <span className={`switch-handle ${checked ? "active" : ""}`} />
        </div>
        <label className="sliderLabel" htmlFor={`switch ${children} ${uniqueIdentifier}`}>
            {children}
        </label>
    </div>
)

export default SliderButton
