import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./fonts/OpenSans/OpenSans-Bold.ttf";
import "./fonts/OpenSans/OpenSans-BoldItalic.ttf";
import "./fonts/OpenSans/OpenSans-ExtraBold.ttf";
import "./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf";
import "./fonts/OpenSans/OpenSans-Italic.ttf";
import "./fonts/OpenSans/OpenSans-LightItalic.ttf";
import "./fonts/OpenSans/OpenSans-Medium.ttf";
import "./fonts/OpenSans/OpenSans-MediumItalic.ttf";
import "./fonts/OpenSans/OpenSans-Regular.ttf";
import "./fonts/OpenSans/OpenSans-SemiBold.ttf";
import "./fonts/OpenSans/OpenSans-SemiBoldItalic.ttf";
import { setapiendpoint } from "./config/config";


// Asynkron funktion för att ladda konfiguration och sedan rendera appen
const startApp = async () => {
  try {
    // Ladda konfigurationsfilen
    const getApiConfig = async () => {
      const timestamp = new Date().getTime()
      const response = await fetch(`/config.json?t=${timestamp}`);
     const configData = await response.json();
     setapiendpoint(configData.api_endpoint, configData.idp_api_endpoint)
  };

    // Rendera applikationen efter att config är laddad
    getApiConfig().then (() => {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );});
  } catch (error) {
    console.error("Failed to load config:", error);
  }
};

// Starta applikationen
startApp();
