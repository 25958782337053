import "./ImportRegisterPage.css"

import apiEndpoints  from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import { REGISTER_TEXTS } from "constants/text.constants"
import pageTitles from "constants/pageTitles.constants.json"

import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import { IMainAccount } from "types/sharedTypes"

import prepareCsrfToken from "utils/prepareCsrfToken"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { postFormdataWithCsrf } from "services/apiService"
import { fetchMainAccounts, fetchRegistries } from "services/fetchers"

import RolesContext from "context/RolesContext"
import { RegistriesContext } from "context/RegistriesContext"

import { ReactComponent as uploadIcon } from "assets/upload.icon.svg"
import { ReactComponent as DocumentIcon } from "assets/file-lines.icon.svg"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Skeleton from "components/atoms/skeleton/Skeleton"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import SelectMainAccount from "components/cores/selectMainAccount/SelectMainAccount"
import ValidationErrorsModal from "components/cores/validationErrorsModal/ValidationErrorsModal"

const ImportRegisterPage = () => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const { agvAdmin, agvAdminFetched } = useContext(RolesContext)

    const [mainAccountMembers, setMainAccountMembers] = useState<IMainAccount[]>()
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [showCheckErrorsButton, setShowCheckErrorsButton] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [unparsedValidationError, setUnparsedValidationError] = useState("")

    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [fetchingMainAccounts, setFetchingMainAccounts] = useState(agvAdminFetched)

    const [showConfirmation, setShowConfirmation] = useState("")
    const [selectedAccountId, setSelectedAccountId] = useState("")
    const [registryId, setRegistryId] = useState("")
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)
    const [importGroups, setImportGroups] = useState(false)
    const [uploadingRegister, setUploadingRegister] = useState(false)

    const [dropzoneKey, setDropzoneKey] = useState(Date.now())

    const getCurrentMonth = () => {
        const today = new Date()
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, "0")

        return `${year}-${month}`
    }

    const [currentFile, setCurrentFile] = useState(REGISTER_TEXTS.UPLOADAREA)
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10))

    const [file, setFile] = useState<File>()

    const resetDropzone = () => {
        setDropzoneKey(Date.now())
        setCurrentFile(REGISTER_TEXTS.UPLOADAREA)
        setFile(undefined)
        setDate(new Date().toISOString().slice(0, 10))
    }

    const handelUploadFile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formdata = new FormData()

        // const selectedFile = e.currentTarget.fileUpload.files[0]
        // formdata.append("fileUpload", selectedFile)

        if (!file) return
        formdata.append("fileUpload", file)
        formdata.append("ValidForDate", date)
        if (selectedAccountId) formdata.append("selectedAccount", selectedAccountId)
        if (registryId) formdata.append("PreviousRegisteryId", registryId)
        if (importGroups) formdata.append("createGroups", "true")

        setUploadingRegister(true)
        prepareCsrfToken().then((csrfToken) =>
            postFormdataWithCsrf(apiEndpoints().importRegistries, csrfToken, formdata)
                .then((respons) => {
                    setShowConfirmation(respons)
                    setShowCheckErrorsButton(false)
                    setRegistriesFetched(false)
                    setRegistries([]) // this will make register page to re-fetch registries
                    setPostError("")
                    setUnparsedValidationError("")
                    setUploadingRegister(false)
                    resetDropzone()
                })
                .catch((err) => {
                    setUploadingRegister(false)
                    if (err)
                        err.then((errorText: string) => {
                            if (errorText.split(":errors:")[0] === REGISTER_TEXTS.CHECK_ERRORS) {
                                setPostError(REGISTER_TEXTS.CHECK_ERRORS)
                                setUnparsedValidationError(errorText.split(":errors:")[1])
                                setShowCheckErrorsButton(true)
                            } else {
                                setPostError(errorText)
                            }
                        })
                })
        )
    }

    const checkDisabled = () => currentFile === REGISTER_TEXTS.UPLOADAREA

    useEffect(() => {
        if (agvAdmin) fetchMainAccounts(setMainAccountMembers, setFetchingMainAccounts, setFetchError)
        if (!agvAdmin) setFetchingMainAccounts(false)
    }, [agvAdmin, fetchError])

    useEffect(() => {
        if (!registries.length)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                undefined,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries.length, setRegistries, setRegistriesFetched])

    useUpdatePageTitle(pageTitles.IMPORT_REGISTER_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.IMPORT_REGISTER_PAGE_ID} />
            {(fetchingRegistries || fetchingMainAccounts) && <Skeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingRegistries && !fetchingMainAccounts && !fetchError && (
                <>
                    <form id="form" onSubmit={handelUploadFile}>
                        <p>{REGISTER_TEXTS.EXPLANATION}</p>
                        <div className="fileUploadArea">
                            <div>
                                <label className="inputLabel">{REGISTER_TEXTS.UPLOAD}</label>
                                <Dropzone
                                    key={dropzoneKey}
                                    multiple={false}
                                    accept={{ "text/csv": [".csv"] }}
                                    onDrop={(acceptedFiles) => {
                                        setPostError("")
                                        setShowConfirmation("")
                                        setCurrentFile(acceptedFiles[0].name)
                                        setFile(acceptedFiles[0])
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="dragNdropSection">
                                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                            <div {...getRootProps()} className="dragNdrop">
                                                <input
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...getInputProps()}
                                                    name="fileUpload"
                                                    id="fileUpload"
                                                    accept=".csv"
                                                    // onChange={(e) => {
                                                    //     if (!e.target.files) return
                                                    //     setCurrentFile(e.target.files[0].name)
                                                    //     setFile(e.target.files[0])
                                                    // }}
                                                />
                                                <p className="dragNdropText">{currentFile}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div>
                                <label className="inputLabel">{REGISTER_TEXTS.CHOOSE_DATE}</label>
                                <input
                                    className="monthInput"
                                    type="month"
                                    lang="sv-SE"
                                    id="date"
                                    name="date"
                                    value={date.slice(0, 7)}
                                    onChange={(e) => setDate(e.target.value)}
                                    max={getCurrentMonth()}
                                />
                            </div>
                        </div>
                        {!noRegisters && (
                            <div className="checkboxContainerImport">
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    onChange={() => setImportGroups((prevState) => !prevState)}
                                />
                                <label className="checkboxLabel" htmlFor="checkbox">
                                    {REGISTER_TEXTS.IMPORT_CHECKBOX_LABEL}
                                </label>
                            </div>
                        )}
                        {mainAccountMembers && (
                            <div className="paddingBottom40">
                                <SelectMainAccount
                                    mainAccountMembers={mainAccountMembers}
                                    setSelectedMainAccountId={setSelectedAccountId}
                                    labelText={REGISTER_TEXTS.IMPORT_CHOOSE_MAIN_ACCOUNT}
                                />
                            </div>
                        )}
                        {!noRegisters && (
                            <div className="paddingBottom24">
                                <div className="maxWidth260">
                                    <SelectRegistry
                                        registries={registries}
                                        setRegistryId={setRegistryId}
                                        name={REGISTER_TEXTS.IMPORT_CHOOSE_COMPARISON_REGISTERY}
                                        flexDirection="column"
                                        noDefaultValue
                                    />
                                </div>
                                <div className="maxWidth600">
                                    <p>{REGISTER_TEXTS.COMPARISON_REGISTERY_EXPLANATION}</p>
                                </div>
                            </div>
                        )}
                        <div className="buttonsContainer">
                            <Button
                                type="submit"
                                disabled={checkDisabled()}
                                isLoading={uploadingRegister}
                                Icon={uploadIcon}
                            >
                                {REGISTER_TEXTS.BUTTON_IMPORT}
                            </Button>
                            {showCheckErrorsButton && (
                                <Button onClick={() => setShowModal(true)}>{REGISTER_TEXTS.BUTTON_CHECK_ERRORS}</Button>
                            )}
                            {showConfirmation && (
                                <Link to="/register/show" className="buttonAlike">
                                    <DocumentIcon className="iconLeft" fill="#FFFFFF" width="16px" height="16px" />
                                    {REGISTER_TEXTS.BUTTON_SHOW_REGISTER_OVERVIEW}
                                </Link>
                            )}
                        </div>
                    </form>
                    {showConfirmation && <Confirmation>{showConfirmation}</Confirmation>}
                    {postError && <Error>{postError}</Error>}
                    {showModal && (
                        <ValidationErrorsModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            errors={unparsedValidationError}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default ImportRegisterPage
