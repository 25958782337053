import "./FilterButton.css"

import { useEffect, useState } from "react"

type FilterButtonProps = {
    children: string
    onClick: () => void
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>
    preselected?: boolean
    disabled?: boolean
    tooltip?: string
    clearSelection?: boolean
}

const FilterButton = ({
    children,
    onClick,
    tooltip,
    setDisabled,
    preselected = false,
    disabled = false,
    clearSelection = false,
}: FilterButtonProps) => {
    const [clicked, setClicked] = useState(preselected)
    const clickedStyle = clicked ? "clicked" : ""

    useEffect(() => {
        if (clearSelection) {
            setClicked(false)
            setDisabled(false)
        }
    }, [clearSelection, setDisabled])

    const handleOnClick = () => {
        if (!disabled || clicked) {
            setClicked((prevState) => !prevState)
            onClick()
        }
    }

    return (
        <button
            className={`filterButton ${clickedStyle}`}
            type="button"
            disabled={clicked ? false : disabled}
            onClick={handleOnClick}
            title={tooltip}
        >
            {children}
        </button>
    )
}

export default FilterButton
