import { CSSProperties } from "react";
import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

interface IExportTablePercentiles {
    tableRows: string[][]
    showKr?: boolean
}

const SalaryEvolutionType2ExportTable = ({ tableRows, showKr = false }: IExportTablePercentiles) => {

    const { S4_TABLE2_TH1, S4_TABLE2_TH2, S4_TABLE2_TH3, S4_TABLE2_TH1_SUFFIX_KR, S4_TABLE2_TH1_SUFFIX_PERCENT } =
        ANALYZE_SALARY_EVOLUTION;

    const TH1_SUFFIX = showKr ? S4_TABLE2_TH1_SUFFIX_KR : S4_TABLE2_TH1_SUFFIX_PERCENT;

    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold",
            width: "100%",
        }
    };

    return (
        <table className="invisible" style={styles.tableStyle}>
        <thead>
            <tr style={styles.theadTrTh}>
                <th style={styles.theadTrTh}>{`${S4_TABLE2_TH1} ${TH1_SUFFIX}`}</th>
                <th style={styles.theadTrTh}>{S4_TABLE2_TH2}</th>
                <th style={styles.theadTrTh}>{S4_TABLE2_TH3}</th>
            </tr>
        </thead>
        <tbody>
            {tableRows.map((row) => (
                <tr key={row.join("-") + Math.random()}>
                    <td style={styles.tbodyTd}>{row[0]}</td>
                    <td style={styles.tbodyTd}>{row[1]}</td>
                    <td style={styles.tbodyTd}>{row[2]}</td>
                </tr>
            ))}
        </tbody>
    </table>
    )
}

export default SalaryEvolutionType2ExportTable
