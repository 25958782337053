import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useMemo, useState } from "react"

import { dispatchBool, mixedSelcetions, tSavedSelectionsRow } from "types/sharedTypes"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"

import EqualWorkTableButtons from "./EqualWorkTableButtons"

interface IEqualWorkTable {
    equalWorkGroups: mixedSelcetions[]
    // commentedGroupsIds: string[]
    setCurrentGroupInfo: React.Dispatch<React.SetStateAction<{ Name: string; Id: string }>>
    setShowCommentsModal: dispatchBool
    setShowDiagramModal: dispatchBool
}

const EqualWorkTable = ({
    equalWorkGroups,
    // commentedGroupsIds,
    setCurrentGroupInfo,
    setShowCommentsModal,
    setShowDiagramModal,
}: IEqualWorkTable) => {
    const { S3_TH1, S3_TH2, S3_TH3, S3_TH4, S3_TH5, S3_TH6 } = ANALYZE_WAGE_GAP
    const defaultColDef = KeyboardNavigation()

    const [columnDefs] = useState([
        { field: S3_TH1, width: 100, sortable: true, resizable: true },
        { field: S3_TH2, width: 100, sortable: true, resizable: true },
        { field: S3_TH3, width: 130, sortable: true, resizable: true },
        { field: S3_TH4, width: 160, sortable: true, resizable: true },
        { field: "Comment", headerName: S3_TH5, width: 130, sortable: true, resizable: true },
        {
            field: S3_TH6,
            width: 360,
            cellRenderer: EqualWorkTableButtons,
            cellRendererParams: {
                setShowCommentsModal,
                setShowDiagramModal,
                setCurrentGroupInfo,
            },
        },
    ])
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    // before it was haveComment || commentedGroupsIds.includes(groupId) in the if statement
    const checkIfCommented = (groupId: string, haveComment: boolean) => {
        if (haveComment) return ANALYZE_WAGE_GAP.S3_COMMENTS_EXIST
        return ANALYZE_WAGE_GAP.S3_NO_COMMENTS
    }

    // groupName -> count -> NumberOfWomanPercent -> WomenSalaryShare -> buttons
    const equalWorkGroupsRows: tSavedSelectionsRow[] = []

    equalWorkGroups.forEach((group) => {
        equalWorkGroupsRows.push({
            Grupp: group.GroupName,
            Antal: group.Count,
            "Andel kvinnor": group.NumberOfWomanPercent,
            "Löneandel kvinnor": group.WomanSalaryShare,
            Comment: checkIfCommented(group.Id, group.HaveComment),
            Id: group.Id,
        })
    })

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "50vh", marginTop: "16px" }}>
            <AgGridReact
                rowData={equalWorkGroupsRows}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                localeText={localeText}
                rowHeight={50}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default EqualWorkTable
