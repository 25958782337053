import { tSeries } from "types/sharedTypes"

// Get all info for the tooltip for each point in a scatterdiagram.
// This method is used for when we only have 2 series, one for women, and one for men
function getSsnAndNameFromChartData(seriesArray: tSeries[], x: number, y: number, categories: string[]) {
    const result = { ssn: "", name: "" }

    if (x === -1 || y === -1) return result // something went wrong
    const indexOfCategory = categories.findIndex((a) => a === x.toString())

    seriesArray.forEach((series) =>
        series.data.forEach((salariesArray) => {
            if (salariesArray[0] === indexOfCategory && salariesArray[1] === y) {
                // console.log("found you", salariesArray)
                result.ssn = salariesArray[2] as string
                result.name = salariesArray[3] as string
            }
        })
    )

    return result
}

export default getSsnAndNameFromChartData
