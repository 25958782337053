import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { FirstDataRenderedEvent, RowClickedEvent, ValueFormatterParams } from "ag-grid-community"
import { useMemo, useState } from "react"

import { Employees, EmployeesTableData, Header } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

const SelectableEmployeesTable = ({
    gridRef,
    onFirstDataRendered,
    header,
    employees,
    rowMultiSelectWithClick,
    onSelectionChanged,
}: {
    gridRef: React.RefObject<AgGridReact<EmployeesTableData>>
    onFirstDataRendered: (params: FirstDataRenderedEvent<EmployeesTableData>) => void
    header: Header
    employees: Employees[]
    rowMultiSelectWithClick: boolean
    onSelectionChanged: (event: RowClickedEvent) => void
}) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const {
        Ssn,
        Name,
        Number,
        EmploymentScope,
        WorkCode,
        GroupLevel,
        Manager,
        LokalKomplettering,
        Samråd,
        YrkesSpecificering,
        KompetensKategori,
        EmploymentStartDate,
        SalaryBasic,
        SalaryProvision,
        SalaryFlexibleProvision,
        SalaryOrganizationalAffiliation,
        CustomizableC1,
        CustomizableC2,
        CustomizableC4,
        CustomizableC5,
        CustomizableC3,
        CustomizableC6,
        CustomizableC7,
        CustomizableC8,
        CustomizableC9,
        CustomizableC10,
        IndividualAgreementsK70,
        IndividualAgreementsK71,
        IndividualAgreementsK72,
        IndividualAgreementsK73,
        // Seem not to be used
        // Age,
        // EmploymentLength,
        // Sex,
    } = header

    const [columnDefs] = useState([
        { field: Ssn, sortable: true, filter: true, resizable: true },
        { field: Name, sortable: true, filter: true, resizable: true },
        { field: Number, sortable: true, filter: true, resizable: true },
        { field: EmploymentScope, sortable: true, filter: true, resizable: true },
        { field: WorkCode, sortable: true, filter: true, resizable: true },
        { field: GroupLevel, sortable: true, filter: true, resizable: true },
        { field: Manager, sortable: true, filter: true, resizable: true },
        { field: LokalKomplettering, sortable: true, filter: true, resizable: true },
        { field: Samråd, sortable: true, filter: true, resizable: true },
        { field: YrkesSpecificering, sortable: true, filter: true, resizable: true },
        { field: KompetensKategori, sortable: true, filter: true, resizable: true },
        { field: EmploymentStartDate, sortable: true, filter: true, resizable: true },
        {
            field: SalaryBasic,
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        { field: SalaryProvision, sortable: true, filter: true, resizable: true },
        { field: SalaryFlexibleProvision, sortable: true, filter: true, resizable: true },
        { field: SalaryOrganizationalAffiliation, sortable: true, filter: true, resizable: true },
        { field: CustomizableC1, sortable: true, filter: true, resizable: true },
        { field: CustomizableC2, sortable: true, filter: true, resizable: true },
        { field: CustomizableC3, sortable: true, filter: true, resizable: true },
        { field: CustomizableC4, sortable: true, filter: true, resizable: true },
        { field: CustomizableC5, sortable: true, filter: true, resizable: true },
        { field: CustomizableC6, sortable: true, filter: true, resizable: true },
        { field: CustomizableC7, sortable: true, filter: true, resizable: true },
        { field: CustomizableC8, sortable: true, filter: true, resizable: true },
        { field: CustomizableC9, sortable: true, filter: true, resizable: true },
        { field: CustomizableC10, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK70, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK71, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK72, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK73, sortable: true, filter: true, resizable: true },
        // { field: Age, sortable: true, filter: true },
        // { field: EmploymentLength, sortable: true, filter: true },
        // { field: Sex, sortable: true, filter: true },
    ])

    const registerTableRows: any = []

    employees.forEach((employee: Employees) => {
        registerTableRows.push({
            [Ssn]: employee.Ssn,
            [Name]: employee.Name,
            [Number]: employee.EmployeeNumber,
            [EmploymentScope]: employee.EmploymentScope,
            [WorkCode]: employee.WorkCode,
            [GroupLevel]: employee.GroupLevel,
            [Manager]: employee.Manager,
            [LokalKomplettering]: employee.LokalKomplettering,
            [Samråd]: employee.Samråd,
            [YrkesSpecificering]: employee.YrkesSpecificering,
            [KompetensKategori]: employee.KompetensKategori,
            [EmploymentStartDate]: employee.EmploymentStartDate,
            [SalaryBasic]: employee.SalaryBasic,
            [SalaryProvision]: employee.SalaryProvision,
            [SalaryFlexibleProvision]: employee.SalaryFlexibleProvision,
            [SalaryOrganizationalAffiliation]: employee.SalaryOrganizationalAffiliation,
            [CustomizableC1]: employee.FreeC1,
            [CustomizableC2]: employee.FreeC2,
            [CustomizableC3]: employee.FreeC3,
            [CustomizableC4]: employee.FreeC4,
            [CustomizableC5]: employee.FreeC5,
            [CustomizableC6]: employee.FreeC6,
            [CustomizableC7]: employee.FreeC7,
            [CustomizableC8]: employee.FreeC8,
            [CustomizableC9]: employee.FreeC9,
            [CustomizableC10]: employee.FreeC10,
            [IndividualAgreementsK70]: employee.IndividualAgreementsK70,
            [IndividualAgreementsK71]: employee.IndividualAgreementsK71,
            [IndividualAgreementsK72]: employee.IndividualAgreementsK72,
            [IndividualAgreementsK73]: employee.IndividualAgreementsK73,
        })
    })

    return (
        <div
            className="ag-theme-alpine"
            style={{ width: "100%", height: "50vh", marginTop: "16px", marginBottom: "24px" }}
        >
            <AgGridReact
                ref={gridRef}
                onFirstDataRendered={onFirstDataRendered}
                rowData={registerTableRows}
                columnDefs={columnDefs}
                localeText={localeText}
                animateRows
                rowSelection="multiple"
                rowMultiSelectWithClick={rowMultiSelectWithClick}
                onSelectionChanged={onSelectionChanged}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
                suppressMenuHide
            />
        </div>
    )
}

export default SelectableEmployeesTable
