import "./EqualWork.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect } from "react"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchMixedGroups } from "services/fetchers"

import { mixedSelcetions } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import CommentsModal from "components/cores/commentsModal/CommentsModal"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import DiagramModal from "./diagramModal/DiagramModal"
import EqualWorkTable from "./equalWorkTable/EqualWorkTable"

interface IEqualWork {
    analysisId: string
    registryName: string
    salaryType: number
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const EqualWork = ({ analysisId, registryName, salaryType, setActiveSteps }: IEqualWork) => {
    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [equalWorkGroups, setEqualWorkGroups] = useState<mixedSelcetions[]>([])
    const [fetchingEqualWorkGroups, setFetchingEqualWorkGroups] = useState(true)
    const [noRelevantIndividuals, setNoRelevantIndividuals] = useState(false)

    const [exportingTable, setExportingTable] = useState(false)

    // const [commentedGroupsIds, setCommentedGroupsIds] = useState<string[]>([])
    const [fetchingAllComments, setFetchingAllComments] = useState(true)

    const [hasCommentChanged, setHasCommentChanged] = useState(false)

    const [showCommentsModal, setShowCommentsModal] = useState(false)
    const [showDiagramModal, setShowDiagramModal] = useState(false)

    const [currentGroupInfo, setCurrentGroupInfo] = useState({ Name: "", Id: "" })

    const exportTableData = () => {
        setExportingTable(true)
        const body = { AnalyseId: analysisId }
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeExportTableStep3, csrfToken, JSON.stringify(body), true)
                .then((exportData) => {
                    setPostError("")
                    setExportingTable(false)

                    exportData.blob().then((blob: any) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement("a")
                        link.setAttribute("href", url)
                        link.setAttribute("download", ANALYZE_WAGE_GAP.S3_FILENAME)
                        document.body.appendChild(link)
                        link.click()
                    })
                })
                .catch(() => {
                    setExportingTable(false)
                    setPostError(ANALYZE_WAGE_GAP.ERROR_DOWNLOADING_CSV)
                })
        )
    }

    useEffect(() => {
        if (fetchingAllComments || hasCommentChanged) {
            fetchMixedGroups(analysisId, setEqualWorkGroups, setFetchingEqualWorkGroups, setFetchError).then(() => {
                setHasCommentChanged(false)
                setFetchingAllComments(false)
                setFetchError("")
            })
        }
    }, [analysisId, hasCommentChanged, fetchingAllComments])

    // useEffect(() => {
    //     if (equalWorkGroups.length) {
    //         const body = { AnalyseId: analysisId, CommentType: 1, Groups: equalWorkGroups }

    //         prepareCsrfToken().then((csrfToken) =>
    //             postWithBodyAndCsrf(api.analyzeCommentsForAllGroups, csrfToken, JSON.stringify(body))
    //                 .then((commentedGroupsArray) => {
    //                     setCommentedGroupsIds(commentedGroupsArray) // den här verkar inte användas alls sen i equalworktable
    //                     setFetchingAllComments(false)
    //                     setFetchError("")
    //                 })
    //                 .catch(() => {
    //                     setFetchingAllComments(false)
    //                     setFetchError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
    //                 })
    //         )
    //     }
    //     setFetchingAllComments(false)
    // }, [analysisId, equalWorkGroups])

    useEffect(() => {
        if (equalWorkGroups.length) {
            setActiveSteps([1, 2, 3, 4])
            setNoRelevantIndividuals(false)
        } else {
            setActiveSteps([1, 2, 3])
            setNoRelevantIndividuals(true)
        }
    }, [equalWorkGroups, setActiveSteps])

    return (
        <>
            {(fetchingEqualWorkGroups || fetchingAllComments) && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {postError && (
                <div className="mb16">
                    <Error>{postError}</Error>
                </div>
            )}
            {!fetchingEqualWorkGroups && !fetchingAllComments && !fetchError && (
                <div>
                    {!noRelevantIndividuals && (
                        <div className="flex gap8 jc-flex-end">
                            <Button isLoading={exportingTable} onClick={exportTableData}>
                                {ANALYZE_WAGE_GAP.S3_BUTTON_EXPORT_TABLE}
                            </Button>
                        </div>
                    )}
                    <h2>{ANALYZE_WAGE_GAP.S3_H2}</h2>
                    <div>
                        <p>{ANALYZE_WAGE_GAP.S3_STEP_EXPLANATION}</p>
                        {noRelevantIndividuals && <p>{ANALYZE_WAGE_GAP.S3_NO_RELEVANT_INDIVIDUALS}</p>}
                        {!noRelevantIndividuals && (
                            <EqualWorkTable
                                equalWorkGroups={equalWorkGroups}
                                // commentedGroupsIds={commentedGroupsIds}
                                setCurrentGroupInfo={setCurrentGroupInfo}
                                setShowCommentsModal={setShowCommentsModal}
                                setShowDiagramModal={setShowDiagramModal}
                            />
                        )}
                    </div>
                    {showCommentsModal && (
                        <CommentsModal
                            analysisId={analysisId}
                            groupInfo={currentGroupInfo}
                            showModal={showCommentsModal}
                            setShowModal={setShowCommentsModal}
                            setHasCommentChanged={setHasCommentChanged}
                        />
                    )}
                    {showDiagramModal && (
                        <DiagramModal
                            groupInfo={currentGroupInfo}
                            registryName={registryName}
                            salaryType={salaryType}
                            showModal={showDiagramModal}
                            setShowModal={setShowDiagramModal}
                            setShowCommentsModal={setShowCommentsModal}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default EqualWork
