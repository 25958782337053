import "./RegisterTable.css"

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"
import { ValueFormatterParams } from "ag-grid-community"

import { AgGridReact } from "ag-grid-react"
import { useMemo, useState } from "react"

import { Employees, Header } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

const RegisterTable = ({ header, employees }: { header: Header; employees: Employees[] }) => {
    const caption = "Registeröversikt"
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    // I found that quite late but you can use property "headerName" instead of mapping througth and rewriting row data
    // Ex below. I wont be refactoring due to time pressure but it is better to, lowers the complexity.
    // { field: 'medals.gold', headerName: 'Gold' },

    const {
        Ssn,
        Name,
        Number,
        EmploymentScope,
        WorkCode,
        GroupLevel,
        Manager,
        LokalKomplettering,
        Samråd,
        YrkesSpecificering,
        KompetensKategori,
        EmploymentStartDate,
        SalaryBasic,
        SalaryProvision,
        SalaryFlexibleProvision,
        SalaryOrganizationalAffiliation,
        CustomizableC1,
        CustomizableC2,
        CustomizableC4,
        CustomizableC5,
        CustomizableC3,
        CustomizableC6,
        CustomizableC7,
        CustomizableC8,
        CustomizableC9,
        CustomizableC10,
        IndividualAgreementsK70,
        IndividualAgreementsK71,
        IndividualAgreementsK72,
        IndividualAgreementsK73,
        // Seem not to be used
        // Age,
        // EmploymentLength,
        // Sex,
    } = header

    const [columnDefs] = useState([
        { field: Ssn, width: 140, sortable: true, filter: true, resizable: true },
        { field: Name, width: 160, sortable: true, filter: true, resizable: true },
        { field: Number, width: 110, sortable: true, filter: true, resizable: true },
        { field: EmploymentScope, width: 110, sortable: true, filter: true, resizable: true },
        { field: WorkCode, width: 100, sortable: true, filter: true, resizable: true },
        { field: GroupLevel, width: 100, sortable: true, filter: true, resizable: true },
        { field: Manager, width: 100, sortable: true, filter: true, resizable: true },
        { field: LokalKomplettering, width: 100, sortable: true, filter: true, resizable: true },
        { field: Samråd, width: 100, sortable: true, filter: true, resizable: true },
        { field: YrkesSpecificering, width: 100, sortable: true, filter: true, resizable: true },
        { field: KompetensKategori, width: 100, sortable: true, filter: true, resizable: true },
        { field: EmploymentStartDate, width: 125, sortable: true, filter: true, resizable: true },
        {
            field: SalaryBasic,
            width: 125,
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        { field: SalaryProvision, width: 100, sortable: true, filter: true, resizable: true },
        { field: SalaryFlexibleProvision, width: 100, sortable: true, filter: true, resizable: true },
        { field: SalaryOrganizationalAffiliation, sortable: true, filter: true, resizable: true },
        { field: CustomizableC1, sortable: true, filter: true, resizable: true },
        { field: CustomizableC2, sortable: true, filter: true, resizable: true },
        { field: CustomizableC3, sortable: true, filter: true, resizable: true },
        { field: CustomizableC4, sortable: true, filter: true, resizable: true },
        { field: CustomizableC5, sortable: true, filter: true, resizable: true },
        { field: CustomizableC6, sortable: true, filter: true, resizable: true },
        { field: CustomizableC7, sortable: true, filter: true, resizable: true },
        { field: CustomizableC8, sortable: true, filter: true, resizable: true },
        { field: CustomizableC9, sortable: true, filter: true, resizable: true },
        { field: CustomizableC10, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK70, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK71, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK72, sortable: true, filter: true, resizable: true },
        { field: IndividualAgreementsK73, sortable: true, filter: true, resizable: true },
        // { field: Age, sortable: true, filter: true },
        // { field: EmploymentLength, sortable: true, filter: true },
        // { field: Sex, sortable: true, filter: true },
    ])
    const registerTableRows: any = []

    employees.forEach((employee: Employees) => {
        registerTableRows.push({
            [Ssn]: employee.Ssn,
            [Name]: employee.Name,
            [Number]: employee.EmployeeNumber,
            [EmploymentScope]: employee.EmploymentScope,
            [WorkCode]: employee.WorkCode,
            [GroupLevel]: employee.GroupLevel,
            [Manager]: employee.Manager,
            [LokalKomplettering]: employee.LokalKomplettering,
            [Samråd]: employee.Samråd,
            [YrkesSpecificering]: employee.YrkesSpecificering,
            [KompetensKategori]: employee.KompetensKategori,
            [EmploymentStartDate]: employee.EmploymentStartDate,
            [SalaryBasic]: employee.SalaryBasic,
            [SalaryProvision]: employee.SalaryProvision,
            [SalaryFlexibleProvision]: employee.SalaryFlexibleProvision,
            [SalaryOrganizationalAffiliation]: employee.SalaryOrganizationalAffiliation,
            [CustomizableC1]: employee.FreeC1,
            [CustomizableC2]: employee.FreeC2,
            [CustomizableC3]: employee.FreeC3,
            [CustomizableC4]: employee.FreeC4,
            [CustomizableC5]: employee.FreeC5,
            [CustomizableC6]: employee.FreeC6,
            [CustomizableC7]: employee.FreeC7,
            [CustomizableC8]: employee.FreeC8,
            [CustomizableC9]: employee.FreeC9,
            [CustomizableC10]: employee.FreeC10,
            [IndividualAgreementsK70]: employee.IndividualAgreementsK70,
            [IndividualAgreementsK71]: employee.IndividualAgreementsK71,
            [IndividualAgreementsK72]: employee.IndividualAgreementsK72,
            [IndividualAgreementsK73]: employee.IndividualAgreementsK73,
        })
    })

    // const sideBar = useMemo(() => {
    //     toolPanels: ["filters", "columns"]
    // }, [])

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "90%", marginTop: "16px" }}>
            <AgGridReact
                rowData={registerTableRows}
                columnDefs={columnDefs}
                animateRows
                rowSelection="multiple"
                localeText={localeText}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
                suppressMenuHide
            />
        </div>
    )
}

export default RegisterTable
