import { ICellRendererParams } from "ag-grid-community"

import { tEquivalentWork } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { ReactComponent as CircleIcon } from "assets/circle-exclamation.icon.svg"
import { ReactComponent as TriangleIcon } from "assets/triangle-exclamation.icon.svg"
import { ReactComponent as VenusIcon } from "assets/venus.icon.svg"
import { ReactComponent as CommentsIcon } from "assets/comment-dots.icon.svg"

interface IEquivalentWorkReviewTableObserve extends ICellRendererParams {
    data: tEquivalentWork
}

const EquivalentWorkReviewTableObserve = (props: IEquivalentWorkReviewTableObserve) => {
    const { data } = props
    const { S6_VENUS_ICON_ALT, S6_COMMENTS_ICON_ALT, S6_IS_HIGH_GRP_LVL_ICON_ALT, S6_IS_LOW_GRP_LVL_ICON_ALT } =
        ANALYZE_WAGE_GAP

    return (
        <div className="flex gap4 pt16 pb16">
            {data.IsMajorityWomen && <VenusIcon width="1rem" height="1rem" fill="#003a64" title={S6_VENUS_ICON_ALT} />}
            {data.HaveComment && (
                <CommentsIcon width="1rem" height="1rem" fill="#003a64" title={S6_COMMENTS_ICON_ALT} />
            )}
            {data.IsHighGroupLevel && (
                <CircleIcon width="1rem" height="1rem" fill="#003a64" title={S6_IS_HIGH_GRP_LVL_ICON_ALT} />
            )}
            {data.IsLowGroupLevel && (
                <TriangleIcon width="1rem" height="1rem" fill="#003a64" title={S6_IS_LOW_GRP_LVL_ICON_ALT} />
            )}
        </div>
    )
}

export default EquivalentWorkReviewTableObserve
