import "./Confirmation.css"
import { useLayoutEffect, useRef } from "react"
import { ReactComponent as SaveIcon } from "./save.icon.svg"

const Confirmation = ({ children }: { children: string }) => {
    const confirmationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        confirmationRef.current?.focus()
    }, [])

    return (
        <div className="confirmationContainer" tabIndex={-1} ref={confirmationRef} aria-live="polite">
            <SaveIcon width="1rem" height="1rem" fill="#6FB079" className="iconSave" />
            <p>{children}</p>
        </div>
    )
}

export default Confirmation
