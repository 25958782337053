import "./AlphabetFilter.css"

import { dispatchString, ISupportItem } from "types/sharedTypes"

import AlphabetButton from "./alphabetButton/AlphabetButton"

const letterCollection = [
    { letter: "A", Id: 1 },
    { letter: "B", Id: 2 },
    { letter: "C", Id: 3 },
    { letter: "D", Id: 4 },
    { letter: "E", Id: 5 },
    { letter: "F", Id: 6 },
    { letter: "G", Id: 7 },
    { letter: "H", Id: 8 },
    { letter: "I", Id: 9 },
    { letter: "J", Id: 10 },
    { letter: "K", Id: 11 },
    { letter: "L", Id: 12 },
    { letter: "M", Id: 13 },
    { letter: "N", Id: 14 },
    { letter: "O", Id: 15 },
    { letter: "P", Id: 16 },
    { letter: "Q", Id: 17 },
    { letter: "R", Id: 18 },
    { letter: "S", Id: 19 },
    { letter: "T", Id: 20 },
    { letter: "U", Id: 21 },
    { letter: "V", Id: 22 },
    { letter: "W", Id: 23 },
    { letter: "X", Id: 24 },
    { letter: "Y", Id: 25 },
    { letter: "Z", Id: 26 },
    { letter: "Å", Id: 27 },
    { letter: "Ä", Id: 28 },
    { letter: "Ö", Id: 29 },
]

interface IAlphabet {
    originalData: ISupportItem[]
    setFilteredData: React.Dispatch<React.SetStateAction<ISupportItem[]>>
    selectedLetter: string
    setLetter: dispatchString
}

const FilterAlphabet = ({ originalData, setFilteredData, selectedLetter, setLetter }: IAlphabet) => {
    const onLetterClicked = (letter: string) => {
        const filtered = originalData.filter((obj) => obj.Title.toLowerCase().startsWith(letter.toLowerCase()))
        if (selectedLetter === letter) {
            setLetter("")
            setFilteredData([])
        } else {
            setFilteredData(filtered)
            setLetter(letter)
        }
    }

    const checkIfSelected = (letter: string, dataCollection: ISupportItem[]) => {
        const index = dataCollection.findIndex((datapoint) =>
            datapoint.Title.toLowerCase().startsWith(letter.toLowerCase())
        )
        return index > -1
    }

    return (
        <ul className="alphabetsFilter">
            {letterCollection.map((filterAlphabetLetter) => (
                <li key={filterAlphabetLetter.Id} value={filterAlphabetLetter.letter}>
                    <AlphabetButton
                        key={filterAlphabetLetter.Id}
                        onClick={() => onLetterClicked(filterAlphabetLetter.letter)}
                        disabled={!checkIfSelected(filterAlphabetLetter.letter, originalData)}
                        clicked={filterAlphabetLetter.letter === selectedLetter}
                    >
                        {filterAlphabetLetter.letter}
                    </AlphabetButton>
                </li>
            ))}
        </ul>
    )
}

export default FilterAlphabet
