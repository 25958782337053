import "./FlowStep.css"

import { ReactComponent as FlowStepArrow } from "./flowStepArrow.icon.svg"

type FlowStepProps = {
    children: string
    isActive?: boolean
    isDisabled?: boolean
    // onClick?: React.Dispatch<React.SetStateAction<number>>
    onClick?: () => void
}

const FlowStep = ({ children, isActive, isDisabled, onClick }: FlowStepProps) => {
    const active = isActive ? "active" : ""
    const disabled = isDisabled ? "disabled" : ""

    return (
        <div
            className={`ArrowRight ${active} ${disabled}`}
            role="button"
            tabIndex={isDisabled ? -1 : 0}
            onClick={isDisabled ? undefined : onClick}
            onKeyDown={(e) => {
                if (e.key === " " || e.code === "Space" || e.code === "Enter") {
                    if (onClick && !isDisabled) onClick()
                }
            }}
        >
            {children}
            <div className="arrowContainer">
                <FlowStepArrow />
            </div>
        </div>
    )
}

export default FlowStep
