const defaultSetOptions = {
    lang: {
        thousandsSep: " ",
        numericSymbols: [],
        printChart: "Skriv ut",
        downloadPNG: "Ladda ner PNG",
        downloadJPEG: "Ladda ner JPEG",
        downloadPDF: "Ladda ner PDF",
        downloadSVG: "Ladda ner SVG",
    },
    navigation: {
        buttonOptions: {
            enabled: true,
        },
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
            },
        },
    },
}

export default defaultSetOptions
