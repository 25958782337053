import "./Warning.css"

import GENERAL_TEXTS from "constants/text.constants"

import { useLayoutEffect, useRef } from "react"

import { ReactComponent as TrashIcon } from "./trash-solid.icon.svg"
import { ReactComponent as ExclamationIcon } from "../input/circle-exclamation-solid.icon.svg"

import Button from "../button/Button"

type WarningProps = {
    children: string
    onClick: () => void
    showWarning: React.Dispatch<React.SetStateAction<boolean>>
    variant?: "default" | "delete"
    onTop?: boolean
    warningTitle?: string
}

const Warning = ({
    children,
    onClick,
    showWarning,
    variant = "default",
    onTop = false,
    warningTitle,
}: WarningProps) => {
    const deleteButton = variant === "delete"

    const warningRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        warningRef.current?.focus()
    }, [children])

    return (
        <div
            className={`warningContainer warning${variant} ${onTop ? "warning-on-top" : ""}`}
            tabIndex={-1}
            ref={warningRef}
            aria-live="polite"
        >
            <div className="textAndIconContainer gap8">
                <div className="iconOutterDiv">
                    {deleteButton ? (
                        <TrashIcon width="1rem" height="1rem" fill="#9b0a00" className="iconWarning" />
                    ) : (
                        <ExclamationIcon width="1rem" height="1rem" fill="#0D3A64" className="iconWarning" />
                    )}
                </div>
                <div>
                    {warningTitle && <p>{warningTitle}</p>}
                    <p>{children}</p>
                </div>
            </div>
            <div className="wButtonsContainer">
                <div className="marginRight">
                    <Button variant={variant} onClick={onClick} small>
                        {deleteButton ? GENERAL_TEXTS.BUTTON_YES : GENERAL_TEXTS.BUTTON_FORWARD}
                    </Button>
                </div>
                <Button onClick={() => showWarning(false)} small>
                    {deleteButton ? GENERAL_TEXTS.BUTTON_NO : GENERAL_TEXTS.BUTTON_ABORT}
                </Button>
            </div>
        </div>
    )
}

export default Warning
