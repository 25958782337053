import { GROUPS_TEXTS } from "constants/text.constants"
import { IRegister } from "types/sharedTypes"
import { useNavigate } from "react-router-dom"
import Button from "components/atoms/button/Button"

type SelectedRowObject = {
    Antal: number
    GroupId: string
    Grupp: string
    Grupptyp: string
}

type TableButtonModifyProps = {
    data: SelectedRowObject
    registryId: string
    registries: IRegister[]
}

const TableButtonModify = ({ data, registryId, registries }: TableButtonModifyProps) => {
    const navigate = useNavigate()

    const changeGroup = () => {
        navigate("/groups/createmanually/edit", {
            state: {
                locationRegistryId: registryId,
                locationRegistryName: registries.find((r) => r.Id === registryId)?.Name,
                locationGroupId: data.GroupId,
                locationGroupName: data.Grupp,
            },
        })
    }

    return (
        <span>
            <Button onClick={() => changeGroup()}>{GROUPS_TEXTS.BUTTON_MODIFY_GROUP}</Button>
        </span>
    )
}

export default TableButtonModify
