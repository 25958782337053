import "./MenuFooter.css"
import arbetsgivarverketLogo from "./arbetsgivarverket_logo_white.png"

// Get date for latest release, with global variables (process.env)
// const VERSION = `Version: ${process.env.REACT_APP_VERSION}, 2022-09-13`
const VERSION = `Version 122312: ${process.env.NODE_ENV}`

const MenuFooter = () => (
    <div>
        <div className="versionContainer">
            <p className="versionText">{VERSION}</p>
        </div>
        <div className="imgContainer">
            <img src={arbetsgivarverketLogo} alt="" />
        </div>
    </div>
)

export default MenuFooter
