import { getRequest } from "services/apiService"

// TY Louise (a)
const downloadCSV = (api: string) => {
    getRequest(api, "application/csv").then((response) => {
        const filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1]

        response.blob().then((blob: any) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement("a")
            link.setAttribute("href", url)
            if (filename.startsWith('"')) link.setAttribute("download", filename.slice(1, -1))
            else link.setAttribute("download", filename)
            document.body.appendChild(link)
            link.click()
        })
    })
}

export default downloadCSV
