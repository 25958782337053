import { tSalaryEvolutionSeriesData } from "types/sharedTypes"

const getToolTipInfoFromBarChartData = (
    seriesData: tSalaryEvolutionSeriesData[],
    currentPoint: Highcharts.TooltipFormatterContextObject,
    boolIsGroup: boolean,
    salaryType: string
) => {
    if (currentPoint.series.type === "bar") {
        return false
    }

    const allMatching: Array<any> = []
    seriesData.forEach((s) => {
        s.PeriodData.forEach((p) => {
            if (boolIsGroup) {
                if (p.x === currentPoint.point.x && p.y === currentPoint.point.y) {
                    allMatching.push({ Info: p.Info, SeriesName: s.PeriodName })
                }
            } else if (
                // p.Info.CurrentSalary.Key === currentPoint.point.Info.CurrentSalary.Key &&
                p.x === currentPoint.point.x &&
                p.y === currentPoint.point.y
            ) {
                allMatching.push({ Info: p.Info, SeriesName: s.PeriodName })
            }
        })
    })

    const tooltips = allMatching.map((m) => {
        let resultString = boolIsGroup ? "<b>Grupp</b>: " : "<b>Namn</b>: "
        resultString += m.Info.Name
        resultString += "<br/>"
        resultString += "<b>Period</b>: "
        resultString += m.SeriesName
        resultString += "<br/><b>"
        resultString += boolIsGroup ? "Medellön" : salaryType
        resultString += "</b>: "
        resultString += m.Info.CurrentSalary.Value
        resultString += " kr<br/>"
        if (m.Info.PreviousPeriod !== "") {
            resultString += `<b>Förändring från föregående period: ${m.Info.PreviousPeriod}<br/>`
        }
        if (m.Info.PreviousPeriod !== "") {
            resultString += `<b>Total utveckling</b>: ${m.Info.FirstPeriod}`
        }
        return resultString
    })

    return tooltips.join("<br/>")
}

export default getToolTipInfoFromBarChartData
