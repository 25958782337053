import { ICellRendererParams } from "ag-grid-community"

import { dispatchBool, tSavedSelectionsRow } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import Button from "components/atoms/button/Button"

interface ITableButtonRemove extends ICellRendererParams {
    data: tSavedSelectionsRow
    setShowCommentsModal: dispatchBool
    setShowDiagramModal: dispatchBool
    setCurrentGroupInfo: React.Dispatch<React.SetStateAction<{ Name: string; Id: string }>>
}

const EqualWorkTableButtons = (props: ITableButtonRemove) => {
    const { data, setShowCommentsModal, setShowDiagramModal, setCurrentGroupInfo } = props

    const commentEqualWorkGroups = () => {
        setCurrentGroupInfo({ Name: data.Grupp, Id: data.Id })
        setShowCommentsModal(true)
    }

    const showDiagramForGroup = () => {
        setCurrentGroupInfo({ Name: data.Grupp, Id: data.Id })
        setShowDiagramModal(true)
    }

    return (
        <div className="flex gap8">
            <Button onClick={commentEqualWorkGroups}>{ANALYZE_WAGE_GAP.S3_TABLE_BUTTON_COMMENT}</Button>
            <Button onClick={showDiagramForGroup}>{ANALYZE_WAGE_GAP.S3_TABLE_BUTTON_SHOW_DIAGRAM}</Button>
        </div>
    )
}

export default EqualWorkTableButtons
