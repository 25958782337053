import { CSSProperties } from "react";
import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

interface IExportTablePercentiles {
    tableRows: string[][]
    selectedRegistryPeriods: string
    salaryType: string
}

const SalaryEvolutionType3ExportTable = ({ tableRows, selectedRegistryPeriods, salaryType }: IExportTablePercentiles) => {
    const { S4_TABLE3_TH1, S4_TABLE3_TH2, S4_TABLE3_TH3, S4_TABLE3_TH4, S4_TABLE3_TH7, S4_TABLE3_TH8 } =
        ANALYZE_SALARY_EVOLUTION

    const periods = selectedRegistryPeriods.split(" - ")

    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold",
            width: "100%",
        }
    };

    return (
        <table className="invisible" style={styles.tableStyle}>
            <thead>
                <tr style={styles.theadTrTh}>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH1}</th>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH2}</th>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH3}</th>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH4}</th>
                    <th style={styles.theadTrTh}>{`${salaryType} ${periods[0]}`}</th>
                    <th style={styles.theadTrTh}>{`${salaryType} ${periods[1]}`}</th>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH7}</th>
                    <th style={styles.theadTrTh}>{S4_TABLE3_TH8}</th>
                </tr>
            </thead>
            <tbody>
                {tableRows.map((row) => (
                    <tr key={row.join("-") + Math.random()}>
                        <td style={styles.tbodyTd}>{row[0]}</td>
                        <td style={styles.tbodyTd}>{row[1]}</td>
                        <td style={styles.tbodyTd}>{row[2]}</td>
                        <td style={styles.tbodyTd}>{row[3]}</td>
                        <td style={styles.tbodyTd}>{row[4]}</td>
                        <td style={styles.tbodyTd}>{row[5]}</td>
                        <td style={styles.tbodyTd}>{row[6]}</td>
                        <td style={styles.tbodyTd}>{row[7]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SalaryEvolutionType3ExportTable
