import "./SearchBar.css"

import { dispatchString, ISupportItem } from "types/sharedTypes"

import Input from "components/atoms/input/Input"

interface ISearchBar {
    originalData: ISupportItem[]
    label: string
    filter: string
    setFilter: dispatchString
    setFilteredData: React.Dispatch<React.SetStateAction<ISupportItem[]>>
}

const SearchBar = ({ originalData, label, filter, setFilter, setFilteredData }: ISearchBar) => (
    <div className="searchBar">
        <div className="marginBottom32">
            <label htmlFor={`searchBar ${label}`}>{label}</label>
        </div>
        <Input
            type="search"
            name={`searchBar ${label}`}
            id={`searchBar ${label}`}
            value={filter}
            onChange={(e) => {
                setFilter(e.target.value)
                const filtered = originalData.filter((obj) => obj.Title.toLowerCase().includes(filter.toLowerCase()))
                setFilteredData(filtered)
            }}
        />
    </div>
)

export default SearchBar
