import "./ContentSkeleton.css"

// https://egghead.io/lessons/aria-use-wai-aria-attributes-to-improve-web-accessibility-of-your-skeleton-loader
// https://adrianroselli.com/2020/11/more-accessible-skeletons.html
// https://ux.stackexchange.com/questions/135956/does-a-skeleton-loader-need-to-be-accessible

function ContentSkeleton() {
    return (
        <div>
            <div className="marginTop24">
                <span className="skeleton-loader contentSection" />
            </div>
            <div>
                <span className="skeleton-loader contentLowerSection" />
            </div>
        </div>
    )
}

export default ContentSkeleton
