import { CSSProperties } from "react"

import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"
import getWages from "utils/Highcharts/getWages"
import getWageDistribution from "utils/Highcharts/getWageDistribution"

interface IExportTableScatter {
    tableData: tComparisonstatisticsTableViewObject[]
    amounts: { Men: number; Women: number }
    salaries: { Men: number; Women: number }
}

const RefstatScatterExportTable = ({ tableData, amounts, salaries }: IExportTableScatter) => {
    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold",
            width: "100%",
        },
    }

    const dataRow = [
        {
            employeeCount: tableData[0].Count,
            amountsMen: `${amounts.Men}%`,
            amountsWomen: `${amounts.Women}%`,
            salaryDistribution: getWageDistribution(tableData[0]),
            referenceStatisticCount: thousandSeparator(tableData[1].Count.toString()),
            relativeWage: getWages(tableData, salaries.Men, salaries.Women),
        },
    ]

    return (
        <table className="invisible" style={styles.tableStyle}>
            <thead style={styles.theadTrTh}>
                <tr style={styles.theadTrTh}>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_EMPLOYEES_COUNT}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_MEN_AMOUNT}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_WOMEN_AMOUNT}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_REFERENCESTATISTICS_COUNT}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_RELATIVE_WAGESTATUS}</th>
                </tr>
            </thead>
            <tbody>
                {dataRow.map((row) => (
                    <tr key={`${row.employeeCount}${Math.random() * 1600}`}>
                        <td style={styles.tbodyTd}>{row.employeeCount}</td>
                        <td style={styles.tbodyTd}>{row.amountsMen}</td>
                        <td style={styles.tbodyTd}>{row.amountsWomen}</td>
                        <td style={styles.tbodyTd}>{row.referenceStatisticCount}</td>
                        <td style={styles.tbodyTd}>{row.relativeWage}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default RefstatScatterExportTable
