/* eslint-disable */

import apiEndpoints  from "constants/endpoints.constants"

import deleteAllCookies from "utils/deleteAllCookies"

/**
 * Authentication handler
 * A singleton responsible for queueing messages while waiting on new authentication and
 * requesting new authentication when needed.
 */
export class AuthHandler {
    /**
     * _ins is the instance of AuthHandler that is returned
     * when an instance is requested
     */
    private static _ins: AuthHandler

    // Set to true if the AuthHandler is updating the auth token
    private updatingAuth: boolean

    // Timestamp when latest authentication token were fetched
    private updatedAuthTimestamp: number

    // Message queue array, contains messages waiting for new authentication.
    private requestQueue: Array<QueueItem>

    private constructor() {
        this.requestQueue = []
        this.updatingAuth = false
        this.updatedAuthTimestamp = -1
    }

    /**
     * Get access to the AuthHandler instance
     * If this is the first request to get the instance initiate
     * the Singleton and return the instance.
     */
    static ins(): AuthHandler {
        return this._ins || (this._ins = new this())
    }

    /**
     *
     * @param {RequestApiFunction<any>} requestFn // Api request function
     * @param logName // Api call name (optional)
     * @returns {Promise<any>}
     */
    public request(requestFn: RequestApiFunction<any>, logName: string = ""): Promise<any> {
        return new Promise((resolve, reject) => {
            this.innerRequest(requestFn, resolve, reject, logName, true)
        })
    }

    /**
     *
     * @param {RequestApiFunction<any>} api
     * @param {Function} resolve //
     * @param {Function} reject // Reject function will be called on errors or if retry fails
     * @param {string} logName // Used to add a name for the call in the log (optional)
     * @param {boolean} retry // Used to prevent a retry loop
     */
    private innerRequest(
        api: RequestApiFunction<any>,
        resolve: Function,
        reject: Function,
        logName: string = "",
        retry: boolean
    ): void {
        const timestamp = Date.now()
        // If authentication is updating put message in queue to be called when authentication is renewed.
        if (this.updatingAuth) {
            this.requestQueue.push({
                request: api,
                resolve: resolve,
                reject: reject,
                logName: logName,
            })
            return
        }
        /**
         * call the api function and await the response,
         * if response is ok resolve the response
         * else if status is 401 add request to queue and request new token
         * else reject
         */

        api()
            .then((response) => {
                if (response.ok) {
                    resolve(response)
                } else if (response.status === 401 && retry) {
                    if (this.updatedAuthTimestamp > timestamp) {
                        this.innerRequest(api, resolve, reject, logName, false)
                    } else {
                        this.requestQueue.push({
                            request: api,
                            resolve: resolve,
                            reject: reject,
                            logName: logName,
                        })
                        this.updateAuth()
                    }
                } else {
                    // response.json().then((unknownState: any) => {
                    // response behöver inte ha metoden .json()
                    console.error("Status & Status Text:", response.status, response.statusText)
                    reject(response)
                }
            })
            .catch((apiError) => {
                console.error(`Error when fetching data${logName ? " for " + logName : ""}`, apiError)
                reject(apiError)
            })
    }

    /**
     * Update authentication
     */
    private updateAuth(): void {
        if (this.updatingAuth) return
        this.updatingAuth = true
        fetch(apiEndpoints().refreshToken, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                authorization: `Refresh ${localStorage.getItem("refreshToken")}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then((tokens) => {
                        localStorage.setItem("authToken", tokens.accessToken)
                        localStorage.setItem("refreshToken", tokens.refreshToken)
                        this.updatedAuthTimestamp = Date.now()
                        // trigger authentication token updated
                        this.authTokenUpdated()
                    })
                } else {
                    return Promise.reject(response)
                }
            })
            .catch(() => {
                deleteAllCookies()
                localStorage.clear()
                window.location.reload()
            })
    }

    /**
     * Authentication token updated
     * Removes updatingAuth and try all messages in the queue.
     */
    private authTokenUpdated(): void {
        this.updatingAuth = false
        let message: QueueItem | undefined
        while ((message = this.requestQueue.pop())) {
            this.innerRequest(message.request, message.resolve, message.reject, message.logName, false)
        }
    }
}

interface QueueItem {
    request: RequestApiFunction<any>
    resolve: Function
    reject: Function
    logName: string
}

/**
 * The RequestApiFunction expect a fetch command returned in an arrow function
 * Example:
 * ()=>{return fetch("https://google.com");}
 */
export type RequestApiFunction<E> = () => Promise<E>
