import "./SimpleAmountTable.css"

import { CSSProperties } from "react"

import { REGISTER_TEXTS } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"

type SimpleAmountTableProps = {
    data: string[][]
    caption: string
}

// The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
// We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
// When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
const styles: { [key: string]: CSSProperties } = {
    simpleAmountTableCaption: {
        textAlign: "left",
    },
    simpleAmountTableStyle: {
        borderSpacing: 0,
        borderCollapse: "collapse",
        textAlign: "left",
        height: "fit-content",
        width: 200,
    },
    tableBodyTD: {
        border: "1px solid #ddd",
        borderSpacing: 0,
        padding: 8,
    },
    tableHeadTrTh: {
        padding: 8,
        background: "#ddd",
        border: "1px solid #ddd",
        borderSpacing: 0,
        fontFamily: "Open Sans Bold",
    },
}

const SimpleAmountTable = ({ data, caption }: SimpleAmountTableProps) => (
    <div>
        <table className="SimpleAmountTableStyle" style={styles.simpleAmountTableStyle}>
            <caption className="SimpleAmountTableCaption" style={styles.simpleAmountTableCaption}>
                {caption}
            </caption>
            <thead className="SimpleAmountTableHeadStyle">
                <tr>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.EMPTY_CELL}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.T_QUAD_HEADER}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((categories) => (
                    <tr key={`${categories}${Math.random() * 1600}`}>
                        {categories.map((arrayElement) => (
                            <td style={styles.tableBodyTD} key={`${arrayElement}${Math.random() * 1600}`}>
                                {thousandSeparator(arrayElement)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
)

export default SimpleAmountTable
