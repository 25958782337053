import "./SelectRegistry.css"

import { REGISTER_TEXTS } from "constants/text.constants"

import { IRegister } from "types/sharedTypes"

type SelectRegistryProps = {
    registries: IRegister[]
    setRegistryId: React.Dispatch<React.SetStateAction<string>>
    name?: string
    flexDirection?: "" | "column"
    defaultValue?: string
    noDefaultValue?: boolean
}

const SelectRegistry = ({
    registries,
    setRegistryId,
    name = REGISTER_TEXTS.SELECT_REGISTRY,
    flexDirection = "",
    defaultValue,
    noDefaultValue,
}: SelectRegistryProps) => (
    <div className={`selectRegistryContainer ${flexDirection}`}>
        <label htmlFor="selectRegistry">{name}</label>
        <select
            onChange={(e) => setRegistryId(e.target.value)}
            defaultValue={defaultValue}
            name="selectRegistry"
            id="selectRegistry"
            className="registryDropdown"
        >
            {noDefaultValue && <option value="none" />}
            {registries.map((registry) => (
                <option key={registry.Id} value={registry.Id}>
                    {registry.Name}
                </option>
            ))}
        </select>
    </div>
)

export default SelectRegistry
