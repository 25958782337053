import { createContext } from "react"

import { dispatchBool, IRegister } from "types/sharedTypes"

export type RegistriesContent = {
    registries: IRegister[]
    setRegistries: (r: IRegister[]) => void
    registriesFetched: boolean
    setRegistriesFetched: dispatchBool
}
export const RegistriesContext = createContext<RegistriesContent>({
    registries: new Array<IRegister>(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setRegistries: () => {},
    registriesFetched: false,
    setRegistriesFetched: () => false,
})

export default RegistriesContext
