import { CSSProperties } from "react"

import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"

interface IExportTablePercentiles {
    percentileTableData: tComparisonstatisticsTableViewObject[]
    showAgeGroups?: boolean
}

const RefstatPercentilesExportTable = ({ percentileTableData, showAgeGroups }: IExportTablePercentiles) => {
    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold",
            width: "100%",
        },
    }
    const ageGroups = ["-", "-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60+"]
    const tableData: (tComparisonstatisticsTableViewObject & { AgeGroupFormatted?: string })[] = [
        ...percentileTableData,
    ]
    if (showAgeGroups) {
        tableData.forEach(
            // eslint-disable-next-line no-return-assign, no-param-reassign
            (tableViewObj) => ((tableViewObj as any).AgeGroupFormatted = ageGroups[tableViewObj.AgeGroup])
        )
    }

    return (
        <table className="invisible" style={styles.tableStyle}>
            <thead style={styles.theadTrTh}>
                <tr style={styles.theadTrTh}>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH1}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH2}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH3}</th>
                    {showAgeGroups && <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH_AGE_GRP}</th>}
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH4}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH5}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH6}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH7}</th>
                    <th style={styles.theadTrTh}>{ANALYZE_REFERENCESTATISTICS.S4_TH8}</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((row) => (
                    <tr key={`${row.BestaGroup}${Math.random() * 1600}`}>
                        <td style={styles.tbodyTd}>{row.BestaGroup}</td>
                        <td style={styles.tbodyTd}>{row.Type}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.Count.toString())}</td>
                        {showAgeGroups && <td style={styles.tbodyTd}>{row.AgeGroupFormatted}</td>}
                        <td style={styles.tbodyTd}>{thousandSeparator(row.FirstTenthPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.FirstQuarterPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.MedianPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastQuarterPercentile.toString())}</td>
                        <td style={styles.tbodyTd}>{thousandSeparator(row.LastTenthPercentile.toString())}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default RefstatPercentilesExportTable
