import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { useEffect, useState } from "react"
import { SalaryEvolutionGroup, tSalaryEvolutionSelectionGroup } from "types/sharedTypes"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"
import useScrollToTop from "hooks/useScrollToTop"

import Flow from "components/cores/flow/Flow"
import FlowStep from "components/cores/flow/flowStep/FlowStep"
import PageHeader from "components/cores/pageHeader/PageHeader"

import SelectAnalysisType from "./step1/SelectAnalysisType"
import SelectRegistryAndSelection from "./step2/SelectRegistryAndSelection"
import ManageSalaryEvolutionSelection from "./step3/ManageSalaryEvolutionSelection"
import AnalyzeSelection from "./step4/AnalyzeSelection"

const AnalyzeSalaryEvolutionPage = () => {
    const [selectedRegistries, setSelectedRegistries] = useState<Map<string, string>>(new Map())
    const [activeStep, setActiveStep] = useState(1)
    const [activeSteps, setActiveSteps] = useState<number[]>([1])
    const [salaryEvolutionGroups, setSalaryEvolutionGroups] = useState<SalaryEvolutionGroup[]>([])
    const [selectedGroupIds, setSelectedGroupIds] = useState<{ name: string; ids: string[] }[]>([])
    const [selectedTypeOfSalary, setSelectedTypeOfSalary] = useState<string[]>([])
    const [selectedTypeOfPopulation, setSelectedTypeOfPopulation] = useState<string[]>([])
    const [preselectedGroups, setPreselectedGroups] = useState<tSalaryEvolutionSelectionGroup[]>([])
    const [selectedAnalysisTypeObject, setSelectedAnalysisTypeObject] = useState({
        Key: 0,
        Value: "",
    })
    const [selection, setSelection] = useState({ name: "", id: "" })
    const [selectedGroup, setSelectedGroup] = useState({ name: "", count: 0 })

    useEffect(() => {
        if (activeStep === 1) {
            setPreselectedGroups([])
            setSelection({ name: "", id: "" })
            setSelectedRegistries(new Map())
            setActiveSteps([1])
            setSelectedAnalysisTypeObject({ Key: 0, Value: "" })
            setSelectedTypeOfSalary([])
            setSelectedTypeOfPopulation([])
            setSelectedGroupIds([])
        }
    }, [activeStep])

    useEffect(() => {
        if (activeStep === 2) {
            setPreselectedGroups([])
            setSelectedGroupIds([])
            setSelection({ name: "", id: "" })
            setSelectedRegistries(new Map())
            setSelectedGroupIds([])
            setSelectedTypeOfSalary([])
            setSelectedTypeOfPopulation([])
            setActiveSteps([2])
        }
    }, [activeStep])

    useEffect(() => {
        if (selectedAnalysisTypeObject.Key > 0) {
            setActiveSteps([1, 2])
        }
    }, [selectedAnalysisTypeObject.Key])

    useUpdatePageTitle(pageTitles.ANALYZE_SALARY_EVOLUTION_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.ANALYZE_SALARY_EVOLUTION_PAGE_ID} />
            <div className="mb16">
                <Flow
                    currentStep={activeStep}
                    setCurrentStep={setActiveStep}
                    activeSteps={activeSteps}
                    setActiveSteps={setActiveSteps}
                >
                    <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_SALARY_EVOLUTION.FLOWSTEP_1}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_SALARY_EVOLUTION.FLOWSTEP_2}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_SALARY_EVOLUTION.FLOWSTEP_3}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(4)}>{ANALYZE_SALARY_EVOLUTION.FLOWSTEP_4}</FlowStep>
                </Flow>
            </div>
            {activeStep === 1 && (
                <SelectAnalysisType
                    setSelectedAnalysisTypeObject={setSelectedAnalysisTypeObject}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 2 && (
                <SelectRegistryAndSelection
                    selectedRegistries={selectedRegistries}
                    setSelectedRegistries={setSelectedRegistries}
                    selectedAnalysisTypeObject={selectedAnalysisTypeObject}
                    setSelectedTypeOfPopulation={setSelectedTypeOfPopulation}
                    setSelectedTypeOfSalary={setSelectedTypeOfSalary}
                    setPreselectedGroups={setPreselectedGroups}
                    setActiveSteps={setActiveSteps}
                    setActiveStep={setActiveStep}
                    setSelection={setSelection}
                />
            )}
            {activeStep === 3 && (
                <ManageSalaryEvolutionSelection
                    selectedAnalysisTypeObject={selectedAnalysisTypeObject}
                    setSalaryEvolutionGroups={setSalaryEvolutionGroups}
                    salaryEvolutionGroups={salaryEvolutionGroups}
                    selectedRegistries={selectedRegistries}
                    selectedGroups={selectedGroupIds}
                    setSelectedGroups={setSelectedGroupIds}
                    selectedTypeOfSalary={selectedTypeOfSalary}
                    setSelectedTypeOfSalary={setSelectedTypeOfSalary}
                    setActiveSteps={setActiveSteps}
                    selectedTypeOfPopulation={selectedTypeOfPopulation}
                    setSelectedTypeOfPopulation={setSelectedTypeOfPopulation}
                    preselectedGroups={preselectedGroups}
                    selection={selection}
                    setSelectedGroup={setSelectedGroup}
                />
            )}
            {activeStep === 4 && (
                <AnalyzeSelection
                    selectedRegistries={selectedRegistries}
                    selectedGroupIds={selectedGroupIds}
                    selectedAnalysisTypeObject={selectedAnalysisTypeObject}
                    selectedTypeOfSalary={selectedTypeOfSalary}
                    selectedTypeOfPopulation={selectedTypeOfPopulation}
                    selectedGroup={selectedGroup}
                />
            )}
        </div>
    )
}

export default AnalyzeSalaryEvolutionPage
