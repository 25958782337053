import { ICellRendererParams } from "ag-grid-community"
import { useEffect, useRef } from "react"

import { tGapMenWomenAssessmentGroup, tGapMenWomenGroupAssessment } from "types/sharedTypes"

type tAdjustedAssessmentGroup = tGapMenWomenAssessmentGroup & { FormattedWeightedValue?: number }

interface ITableSelect extends ICellRendererParams {
    Name: string
    adjustedAssessmentGroups: tAdjustedAssessmentGroup[]
    setAdjustedAssessmentsGroups: React.Dispatch<React.SetStateAction<tAdjustedAssessmentGroup[]>>
    clearValues: boolean
}

const EquivalentWorkValueTableSelect = (props: ITableSelect) => {
    const { data, Name, adjustedAssessmentGroups, setAdjustedAssessmentsGroups, clearValues } = props

    const selectRef = useRef<HTMLSelectElement>(null)

    // more correct way of doing it in react but for some reason does not update when clearing all fields - fix it pls
    const getDefaultValue = () => {
        const assesmentIx = data.GroupAssessments.findIndex(
            (assessment: tGapMenWomenGroupAssessment) => assessment.AssessmentText === Name
        )
        return data.GroupAssessments[assesmentIx].Value
    }

    const setValue = (value: string) => {
        const groupIx = adjustedAssessmentGroups.findIndex((group) => group.GroupId === data.GroupId)
        const assesmentIx = adjustedAssessmentGroups[groupIx].GroupAssessments.findIndex(
            (assessment) => assessment.AssessmentText === Name
        )
        const newState = [...adjustedAssessmentGroups]
        newState[groupIx].GroupAssessments[assesmentIx].Value = Number(value)
        setAdjustedAssessmentsGroups(newState)
    }
    const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    useEffect(() => {
        if (clearValues && selectRef.current) {
            selectRef.current.value = "0"
        }
    }, [clearValues])

    return (
        <div className="flex gap8">
            <select
                ref={selectRef}
                onChange={(e) => setValue(e.target.value)}
                defaultValue={getDefaultValue()} // see row 23
                name="select"
                id="select"
                className="selectDropdown"
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {/* <option key={option} value={option}> // see row 23 */}
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default EquivalentWorkValueTableSelect
