function editBodyDataToSave(body: string) {
    const width = /width="([0-9]*")/g
    const height = /height="([0-9]*")/g
    const bodyData = body
        .replaceAll("&Auml;", "Ä")
        .replaceAll("&auml;", "ä")
        .replaceAll("&Aring;", "Å")
        .replaceAll("&aring;", "å")
        .replaceAll("&Ouml;", "Ö")
        .replaceAll("&ouml;", "ö")
        .replaceAll(width, 'width="80%"')
        .replaceAll(height, "")
    return bodyData
}

export default editBodyDataToSave
