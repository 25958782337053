import "./Error.css"

import { useLayoutEffect, useRef } from "react"

import { ReactComponent as ExclamationMarkIcon } from "./circle-exclamation-solid.icon.svg"

const Error = ({ children }: { children: string }) => {
    const errorRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        errorRef.current?.focus()
    }, [children])

    return (
        <div className="errorContainer" tabIndex={-1} ref={errorRef} aria-live="polite">
            <div className="textAndIconContainer">
                <ExclamationMarkIcon width="1rem" height="1rem" fill="#aa0e4c" className="icon" />
                <p>{children}</p>
            </div>
        </div>
    )
}

export default Error
