// import { TodoContextType, ITodo } from '../@types/todo'
import { createContext } from "react"

import { textObject } from "types/sharedTypes"

export type TextsContent = {
    texts: textObject[]
    setTexts: (r: textObject[]) => void
}

const TextContext = createContext<TextsContent>({
    texts: new Array<textObject>(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTexts: () => {},
})

export default TextContext
