import "./MultiselectDropdown.css"

import { useState } from "react"
import { IRegister } from "types/sharedTypes"

import GENERAL_TEXTS from "constants/text.constants"

import { ReactComponent as ChevronDownIcon } from "components/menu/icons/chevron-down.icon.svg"

type MultiselectDropdownProps = {
    options: IRegister[]
    label?: string
    setSelectedOptions: React.Dispatch<React.SetStateAction<Map<string, string>>>
    selectedOptions: Map<string, string>
}

const MultiselectDropdown = ({ options, label, setSelectedOptions, selectedOptions }: MultiselectDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOptionToggle = (option: IRegister) => {
        if (selectedOptions.has(option.Id)) {
            const o = new Map(Array.from(selectedOptions))
            o.delete(option.Id)
            setSelectedOptions(o)
        } else {
            const o = new Map(Array.from(selectedOptions))
            o.set(option.Id, option.Name)
            setSelectedOptions(o)
        }
    }

    return (
        <div className="multiSelectDropdownContainer">
            <label className="mb8" htmlFor="multiSelectDropdown">
                {label}
            </label>
            <div
                id="multiSelectDropdown"
                className={`multiSelectDropdownHeader ${selectedOptions.size > 0 ? "jc-space-between" : "jc-flex-end"}`}
                role="button"
                tabIndex={0}
                onClick={() => setIsOpen(!isOpen)}
                aria-describedby={GENERAL_TEXTS.ARIA_DESCRIBEDBY_MULTISELECT_DROPDOWN}
                onKeyDown={(e) => {
                    if (e.key === " " || e.code === "Space" || e.code === "Enter") {
                        e.preventDefault()
                        setIsOpen(!isOpen)
                    }
                }}
            >
                {selectedOptions.size > 0 ? Array.from(selectedOptions.values()).join(", ") : ""}
                <div className="multiselectDropdownIconContainer">
                    {isOpen ? (
                        <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" />
                    ) : (
                        <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" transform="rotate(180)" />
                    )}
                </div>
            </div>
            {isOpen && (
                <div className="multiSelectDropdownBody">
                    {options.map((option) => (
                        <label key={option.Id} className="multiSelectDropdownOption">
                            <input
                                type="checkbox"
                                checked={selectedOptions.has(option.Id)}
                                onChange={() => handleOptionToggle(option)}
                            />
                            {option.Name}
                        </label>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MultiselectDropdown
