import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { useState, useEffect } from "react"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchMatchingGroups } from "services/fetchers"

import { tGapMenWomenMatchingGroup } from "types/sharedTypes"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as downloadIcon } from "assets/file-arrow-down.icon.svg"
import { ReactComponent as DiagramIcon } from "assets/diagram.icon.svg"
import { ReactComponent as VenusIcon } from "assets/venus.icon.svg"
import { ReactComponent as CommentsIcon } from "assets/comment-dots.icon.svg"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"
import CommentsModal from "components/cores/commentsModal/CommentsModal"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import MatchingGroupsTable from "./matchingGroupsTable/MatchingGroupsTable"
import DiagramModal from "./diagramModal/DiagramModal"

interface IMatchingGroups {
    analysisId: string
    salaryType: number
    registryId: string
    registryName: string
}

const MatchingGroups = ({ analysisId, salaryType, registryId, registryName }: IMatchingGroups) => {
    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [matchingGroupsArray, setMatchingGroupsArray] = useState<tGapMenWomenMatchingGroup[][]>([])
    const [groupIds, setGroupIds] = useState<string[]>([])
    const [fetchingMatchingGroups, setFetchingMatchingGroups] = useState(true)
    const [selectionNamesArray, setSelectionNamesArray] = useState<string[]>([])
    const [groupWithMajorityWomen, setGroupWithMajorityWomen] = useState<string[]>([])

    const [showCommentsModal, setShowCommentsModal] = useState(false)
    const [showDiagramModal, setShowDiagramModal] = useState(false)
    const [currentGroupInfo, setCurrentGroupInfo] = useState({ Name: "", Id: "" })

    const [downloadingCSV, setDownloadingCSV] = useState(false)
    const [savingMatching, setSavingMatching] = useState(false)

    const downloadCSV = () => {
        setPostError("")
        setDownloadingCSV(true)

        const filename = ANALYZE_WAGE_GAP.S7_EXPORT_FILENAME
        const body = { AnalyseId: analysisId }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeWageGapStepSevenExportCSV, csrfToken, JSON.stringify(body), true)
                .then((response) => {
                    response.blob().then((blob: any) => {
                        // const url = window.URL.createObjectURL(new Blob([blob], { type: "text/plain;charset=utf-8" }))
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement("a")
                        link.setAttribute("href", url)
                        link.setAttribute("download", filename)
                        document.body.appendChild(link)
                        link.click()
                    })
                    setDownloadingCSV(false)
                })
                .catch(() => {
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setDownloadingCSV(false)
                })
        )
    }

    const checkDisabled = (ix: number) => !selectionNamesArray[ix]

    const saveMatching = (matchingGroups: tGapMenWomenMatchingGroup[], ix: number) => {
        setPostError("")
        setShowConfirmation(false)
        setSavingMatching(true)

        const body = {
            Id: analysisId,
            RegistryId: registryId,
            Name: selectionNamesArray[ix],
            GroupIds: matchingGroups.map((group) => group.GroupId),
            SalaryType: salaryType,
        }
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                idMapper(apiEndpoints().analyzeWageGapSaveMatchingById, analysisId),
                csrfToken,
                JSON.stringify(body)
            )
                .then((newAnalysisId) => {
                    sessionStorage.setItem("analysisId", newAnalysisId)
                    setPostError("")
                    setShowConfirmation(true)
                    setSavingMatching(false)
                })
                .catch((err) => {
                    setSavingMatching(false)
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName") setPostError(ANALYZE_WAGE_GAP.S7_POST_ERROR_INVALID_NAME)
                            else setPostError(ANALYZE_WAGE_GAP.S7_POST_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    useEffect(() => {
        fetchMatchingGroups(analysisId, setMatchingGroupsArray, setFetchingMatchingGroups, setFetchError)
    }, [analysisId])

    useEffect(() => {
        if (matchingGroupsArray.length) {
            matchingGroupsArray.forEach((matchGroup) => {
                matchGroup.forEach((group) => {
                    // eslint-disable-next-line no-param-reassign
                    group.FormattedSumPoints = group.SumPoints.toFixed(1)
                })
            })
        }
    }, [matchingGroupsArray])

    // adding a decimal to the weightedvalue and adding it to the new formatted version

    return (
        <>
            {fetchingMatchingGroups && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!matchingGroupsArray[0]?.length && <h2>{ANALYZE_WAGE_GAP.S7_H2_NO_MATCHINGS}</h2>}
            {!fetchingMatchingGroups && !fetchError && !!matchingGroupsArray[0]?.length && (
                <div>
                    <h2>{ANALYZE_WAGE_GAP.S7_H2}</h2>
                    {showConfirmation && <Confirmation>{ANALYZE_WAGE_GAP.S5_CONFIRMATION_SAVED_VALUES}</Confirmation>}
                    {postError && (
                        <div className="mb24">
                            <Error>{postError}</Error>
                        </div>
                    )}
                    <div>
                        <p>{ANALYZE_WAGE_GAP.S7_STEP_EXPLANATION}</p>
                        <p>{ANALYZE_WAGE_GAP.S7_STEP_EXPLANATION_2}</p>
                        <p>{ANALYZE_WAGE_GAP.S7_STEP_EXPLANATION_3}</p>
                        <div className="pl16 mb32">
                            <p className="flex gap8 ai-center margin0">
                                <VenusIcon
                                    width="1rem"
                                    height="1rem"
                                    fill="#003a64"
                                    title={ANALYZE_WAGE_GAP.S7_VENUS_ICON_ALT}
                                />
                                {ANALYZE_WAGE_GAP.S7_VENUS_ICON_EXPLANATION}
                            </p>
                            <p className="flex gap8 ai-center margin0">
                                <CommentsIcon
                                    width="1rem"
                                    height="1rem"
                                    fill="#003a64"
                                    title={ANALYZE_WAGE_GAP.S7_COMMENTS_ICON_ALT}
                                />
                                {ANALYZE_WAGE_GAP.S7_COMMENTS_ICON_EXPLANATION}
                            </p>
                        </div>
                        <div className="flex jc-flex-end mb32">
                            <Button Icon={downloadIcon} onClick={downloadCSV} isLoading={downloadingCSV}>
                                {ANALYZE_WAGE_GAP.S7_BUTTON_EXPORT_CSV}
                            </Button>
                        </div>
                        <div>
                            {!!matchingGroupsArray.length &&
                                matchingGroupsArray.map((matchingGroups, ix) => (
                                    <div className="mb32" key={matchingGroups[0].GroupId}>
                                        <div className="mb16">
                                            <MatchingGroupsTable
                                                matchingGroups={matchingGroups}
                                                setShowCommentsModal={setShowCommentsModal}
                                                setCurrentGroupInfo={setCurrentGroupInfo}
                                            />
                                            <div className="flex gap16 jc-space-between ai-flex-start">
                                                <div className="flex gap8 ai-flex-start">
                                                    <label className="mt10" htmlFor={`saveMatching${ix}`}>
                                                        {ANALYZE_WAGE_GAP.S7_LABEL_MATCHING}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id={`saveMatching${ix}`}
                                                        name={`saveMatching${ix}`}
                                                        value={selectionNamesArray[ix]}
                                                        onChange={(e) => {
                                                            const newSelectionNames =
                                                                structuredClone(selectionNamesArray)
                                                            newSelectionNames[ix] = e.target.value
                                                            setSelectionNamesArray(newSelectionNames)
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex gap8 mt6">
                                                    <Button
                                                        Icon={SaveIcon}
                                                        onClick={() => saveMatching(matchingGroups, ix)}
                                                        disabled={checkDisabled(ix)}
                                                        isLoading={savingMatching}
                                                    >
                                                        {ANALYZE_WAGE_GAP.S7_BUTTON_SAVE_MATCHING}
                                                    </Button>
                                                    <Button
                                                        Icon={DiagramIcon}
                                                        onClick={() => {
                                                            setShowDiagramModal(true)
                                                            setGroupIds(matchingGroups.map((group) => group.GroupId))
                                                            const majorityWomenGroup = matchingGroups
                                                                .filter((group) => group.IsMajorityWomen)
                                                                .map((group) => group.GroupName)
                                                            setGroupWithMajorityWomen(majorityWomenGroup)
                                                        }}
                                                    >
                                                        {ANALYZE_WAGE_GAP.S7_BUTTON_SHOW_DIAGRAM}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {showCommentsModal && (
                            <CommentsModal
                                analysisId={analysisId}
                                workType="equivalent"
                                groupInfo={currentGroupInfo}
                                showModal={showCommentsModal}
                                setShowModal={setShowCommentsModal}
                                skipAllComments
                            />
                        )}
                        {showDiagramModal && (
                            <DiagramModal
                                groupIds={groupIds}
                                salaryType={salaryType}
                                registryName={registryName}
                                showModal={showDiagramModal}
                                setShowModal={setShowDiagramModal}
                                groupWithMajorityWomen={groupWithMajorityWomen}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default MatchingGroups
