import "./ImportReferenceStatisticPage.css"

import apiEndpoints  from "constants/endpoints.constants"
import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import { REFERENCESTATISTICS_TEXTS } from "constants/text.constants"

import { Link } from "react-router-dom"
import { useState, useContext } from "react"
import Dropzone from "react-dropzone"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { postFormdataWithCsrf } from "services/apiService"
import prepareCsrfToken from "utils/prepareCsrfToken"
import RolesContext from "context/RolesContext"

import { ReactComponent as uploadIcon } from "assets/upload.icon.svg"
import { ReactComponent as DocumentIcon } from "assets/file-lines.icon.svg"

import Select from "components/atoms/select/Select"
import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"
import ValidationErrorsModal from "components/cores/validationErrorsModal/ValidationErrorsModal"

const ImportReferenceStatisticPage = () => {
    const { agvAdmin } = useContext(RolesContext)

    const [errorMessage, setErrorMessage] = useState("")
    const [showConfirmation, setShowConfirmation] = useState("")

    const [showCheckErrorsButton, setShowCheckErrorsButton] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [unparsedValidationError, setUnparsedValidationError] = useState("")
    const [importingStastic, setImportingStatstic] = useState(false)

    // Remove if new design accepted
    const [selectedAccountId, setSelectedAccountId] = useState("")

    const [dropzoneKey, setDropzoneKey] = useState(Date.now())

    const getCurrentMonth = () => {
        const today = new Date()
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, "0")

        return `${year}-${month}`
    }

    const [currentFile, setCurrentFile] = useState(REFERENCESTATISTICS_TEXTS.UPLOADAREA)
    const [date, setDate] = useState(new Date().toISOString().slice(0, 7))
    const [selectedTypeOfStatistic, setSelectedTypeOfStatistic] = useState<string[]>([])
    const [selectedEmployeeCategory, setSelectedEmployeeCategory] = useState<string[]>([])

    // const [submitDisabled, setSubmitDisabled] = useState(true)

    const [file, setFile] = useState<File>()

    const resetDropzone = () => {
        setDropzoneKey(Date.now())
        setCurrentFile(REFERENCESTATISTICS_TEXTS.UPLOADAREA)
        setFile(undefined)
        setDate(new Date().toISOString().slice(0, 10))
    }

    const handelUploadFile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const formdata = new FormData()

        if (!file) return
        formdata.append("fileUpload", file)
        formdata.append("ValidFor", date)

        // import category: 0 = Partgemensam, 1 = Medlemsanpassad, 2 = Chefer, 3 = ""
        // 2 is interesting in old BESTA
        if (selectedTypeOfStatistic[0] === REFERENCESTATISTICS_TEXTS.IMPORT_TYPE_GENERAL)
            formdata.append("importCategory", "0")
        else formdata.append("importCategory", "1")

        // employee type: new BESTA - 0 = Medarbetare, 1 = Chefer
        // old BESTA: 0 = Medarbetare, 1 = Samtliga
        if (selectedEmployeeCategory[0] === REFERENCESTATISTICS_TEXTS.EMPLOYEES) formdata.append("employeeType", "0")
        if (selectedEmployeeCategory[0] === REFERENCESTATISTICS_TEXTS.MANAGERS) formdata.append("employeeType", "2")
        if (selectedEmployeeCategory[0] === REFERENCESTATISTICS_TEXTS.ALL) formdata.append("employeeType", "1")

        setImportingStatstic(true)
        prepareCsrfToken().then((csrfToken) =>
            postFormdataWithCsrf(apiEndpoints().importReferencestatistics, csrfToken, formdata)
                .then((response) => {
                    setImportingStatstic(false)
                    setShowConfirmation(response)
                    setShowCheckErrorsButton(false)
                    setErrorMessage("")
                    setUnparsedValidationError("")
                    resetDropzone()
                })
                .catch((err) => {
                    setImportingStatstic(false)
                    if (err)
                        err.then((errorText: string) => {
                            if (errorText.split(":errors:")[0] === REFERENCESTATISTICS_TEXTS.CHECK_ERRORS) {
                                setErrorMessage(REFERENCESTATISTICS_TEXTS.CHECK_ERRORS)
                                setUnparsedValidationError(errorText.split(":errors:")[1])
                                setShowCheckErrorsButton(true)
                            } else {
                                setErrorMessage(errorText)
                            }
                        })
                })
        )
    }

    const checkDisabled = () => currentFile === REFERENCESTATISTICS_TEXTS.UPLOADAREA || !selectedEmployeeCategory[0]

    const newDesign = true

    useUpdatePageTitle(pageTitles.IMPORT_REFERENCESTATISTICS_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.IMPORT_REFERENCESTATISTICS_PAGE_ID} />
            <form id="form" onSubmit={handelUploadFile}>
                <p>{REFERENCESTATISTICS_TEXTS.EXPLANATION}</p>
                {agvAdmin && (
                    <div>
                        <p className="labelAlike">{REFERENCESTATISTICS_TEXTS.LABEL_STATISTIC_TYPE}</p>
                        <FilterRadioButtonsRow
                            selectedFilter={selectedTypeOfStatistic}
                            setSelection={setSelectedTypeOfStatistic}
                        >
                            {REFERENCESTATISTICS_TEXTS.IMPORT_TYPE_GENERAL}
                            {REFERENCESTATISTICS_TEXTS.IMPORT_TYPE_MEMBERS}
                        </FilterRadioButtonsRow>
                    </div>
                )}
                <div className="fileUploadArea">
                    <div>
                        <label className="inputLabel">{REFERENCESTATISTICS_TEXTS.UPLOAD}</label>
                        <Dropzone
                            key={dropzoneKey}
                            multiple={false}
                            accept={{ "text/csv": [".csv"] }}
                            onDrop={(acceptedFiles) => {
                                setErrorMessage("")
                                setShowConfirmation("")
                                setCurrentFile(acceptedFiles[0].name)
                                setFile(acceptedFiles[0])
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="dragNdropSection">
                                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                    <div {...getRootProps()} className="dragNdrop">
                                        <input
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...getInputProps()}
                                            name="fileUpload"
                                            id="fileUpload"
                                            accept=".csv"
                                        />
                                        <p className="dragNdropText">{currentFile}</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div>
                        <label className="inputLabel">{REFERENCESTATISTICS_TEXTS.CHOOSE_PERIOD}</label>
                        <input
                            className="monthInput"
                            type="month"
                            lang="sv-SE"
                            id="date"
                            name="date"
                            value={date.slice(0, 7)}
                            onChange={(e) => {
                                setSelectedEmployeeCategory([])
                                setDate(e.target.value)
                            }}
                            max={getCurrentMonth()}
                        />
                    </div>
                </div>
                {!newDesign && (
                    <div className="paddingBottom24">
                        <div className="maxWidth260">
                            <Select
                                optionsArray={[REFERENCESTATISTICS_TEXTS.EMPLOYEES, REFERENCESTATISTICS_TEXTS.MANAGERS]}
                                setSelectedOption={setSelectedAccountId}
                                label={REFERENCESTATISTICS_TEXTS.IMPORT_TYPE_LABEL}
                            />
                        </div>
                    </div>
                )}
                {newDesign && (
                    <div>
                        <p className="labelAlike">{REFERENCESTATISTICS_TEXTS.LABEL_EMPLOYEE_TYPE}</p>
                        <FilterRadioButtonsRow
                            selectedFilter={selectedEmployeeCategory}
                            setSelection={setSelectedEmployeeCategory}
                        >
                            {REFERENCESTATISTICS_TEXTS.EMPLOYEES}
                            {date < "2019-09" ? REFERENCESTATISTICS_TEXTS.ALL : REFERENCESTATISTICS_TEXTS.MANAGERS}
                        </FilterRadioButtonsRow>
                    </div>
                )}
                <div className="buttonsContainer">
                    <Button type="submit" disabled={checkDisabled()} isLoading={importingStastic} Icon={uploadIcon}>
                        {REFERENCESTATISTICS_TEXTS.BUTTON_IMPORT}
                    </Button>
                    {showCheckErrorsButton && (
                        <Button onClick={() => setShowModal(true)}>
                            {REFERENCESTATISTICS_TEXTS.BUTTON_CHECK_ERRORS}
                        </Button>
                    )}
                    {showConfirmation && (
                        <Link to="/referencestatistics/show" className="buttonAlike">
                            <DocumentIcon className="iconLeft" fill="#FFFFFF" width="16px" height="16px" />
                            {REFERENCESTATISTICS_TEXTS.BUTTON_SHOW_IMPORTED}
                        </Link>
                    )}
                </div>
            </form>
            {showConfirmation && <Confirmation>{showConfirmation}</Confirmation>}
            {errorMessage && <Error>{errorMessage}</Error>}
            {showModal && (
                <ValidationErrorsModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    errors={unparsedValidationError}
                />
            )}
        </div>
    )
}

export default ImportReferenceStatisticPage
