import "./CreateGroupsUsingFilterPage.css"

import pageTitles from "constants/pageTitles.constants.json"
import pageIds from "constants/pageIds.constants.json"
import apiEndpoints  from "constants/endpoints.constants"
import { GROUPS_TEXTS, REGISTER_TEXTS } from "constants/text.constants"

import prepareCsrfToken from "utils/prepareCsrfToken"
import idMapper from "utils/idMapper"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"
import { RegistriesContext } from "context/RegistriesContext"
import { useContext, useEffect, useState } from "react"
import { AgGridReact } from "ag-grid-react"

import { fetchRegistries, fetchValidFilters } from "services/fetchers"
import { postWithBodyAndCsrf } from "services/apiService"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"
import { ReactComponent as SearchIcon } from "assets/search-solid.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

import PageHeader from "components/cores/pageHeader/PageHeader"
import SelectRegistry from "components/cores/selectRegistry/SelectRegistry"
import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import FilterButtonsRow from "components/cores/filterButtonsRow/FilterButtonsRow"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import Confirmation from "components/atoms/confirmation/Confirmation"

import GroupsMultiselectDropdown from "./groupsMultiselectDropdown/GroupsMultiselectDropdown"

interface IFilter {
    FilterColumnType: { Key: number; Value: string }
    Id: string
    Name: string
    Tooltip: string
    Values: { Key: number; Value: string }[]
}

type SelectedColumnValue = {
    key: number
    value: string
}

type SelectedColumnObject = {
    Id: string
    Name: string
    FilterColumnType: { key: number; value: string }
    SelectedColumnValues: SelectedColumnValue[]
}

type ColumnValuesObject = {
    RegistryId: string
    SelectedColumnObjects: SelectedColumnObject[]
}

interface IFilterGroupPreview {
    Name: string
    Count: number
}

type groupTable = {
    [key: string]: string | number
}

const CreateGroupsUsingFilterPage = () => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [registryId, setRegistryId] = useState(registriesFetched && registries.length ? registries[0].Id : "")
    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [generalError, setGeneralError] = useState("")
    const [errorFetchingRegistries, setErrorFetchingRegistries] = useState("")
    const [errorCreatingGroups, setErrorCreatingGroups] = useState("")
    const [validFilters, setValidFilters] = useState<IFilter[]>([])
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)
    const [allValuesSelected, setAllValuesSelected] = useState(new Map<string, boolean>())
    const [columnValuesObject, setColumnValuesObject] = useState<ColumnValuesObject>({
        RegistryId: "",
        SelectedColumnObjects: [],
    })
    const [fieldValues, setFieldValues] = useState(new Map<string, string>())
    const [creationIsLoading, setCreationIsLoading] = useState(false)
    const [previewIsLoading, setPreviewIsLoading] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [tableDataPreview, setTableDataPreview] = useState<groupTable[]>([])
    const [groupsPreviewData, setGroupsPreviewData] = useState<IFilterGroupPreview[]>([])

    const [activeIndex, setActiveIndex] = useState(-1)

    const [validFilterNames, setValidFilterNames] = useState<string[]>([])
    const [fetchingValidFilters, setFetchingValidFilters] = useState(true)

    const [columnDefs] = useState([
        {
            field: GROUPS_TEXTS.GROUPS_TH1,
            sortable: true,
            filter: true,
            flex: 2,
        },
        {
            field: GROUPS_TEXTS.GROUPS_TH3,
            sortable: true,
            filter: true,
            flex: 2,
        },
    ])

    const handlePreview = () => {
        setPreviewIsLoading(true)
        setGeneralError("")

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                idMapper(apiEndpoints().filterGroupPreviewById, columnValuesObject.RegistryId),
                csrfToken,
                JSON.stringify({
                    RegistryId: columnValuesObject.RegistryId,
                    SelectedColumnObjects: columnValuesObject.SelectedColumnObjects,
                })
            )
                .then((previewData: IFilterGroupPreview[]) => {
                    setPreviewIsLoading(false)
                    setGroupsPreviewData(previewData)
                    const groupsTable: groupTable[] = []

                    previewData.forEach((group) => {
                        groupsTable.push({
                            [GROUPS_TEXTS.GROUPS_TH1]: group.Name,
                            [GROUPS_TEXTS.GROUPS_TH3]: group.Count,
                        })
                    })

                    if (previewData.length === 0) setGeneralError(GROUPS_TEXTS.NO_FILTER_GROUPS_PREVIEW)

                    setTableDataPreview(groupsTable)
                })
                .catch((error) => {
                    setPreviewIsLoading(false)
                    error.text().then((response: string) => {
                        setGeneralError(`${GROUPS_TEXTS.ERROR_FILTER_GROUPS_PREVIEW} ${response}`)
                    })
                })
        )
    }

    const handleCreate = () => {
        setCreationIsLoading(true)
        setErrorCreatingGroups("")
        setGeneralError("")

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                idMapper(apiEndpoints().createFilterGroupsById, columnValuesObject.RegistryId),
                csrfToken,
                JSON.stringify({
                    RegistryId: columnValuesObject.RegistryId,
                    SelectedColumnObjects: columnValuesObject.SelectedColumnObjects,
                }),
                true
            )
                .then(() => {
                    setCreationIsLoading(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                    setSelectedFilters([])
                    setFieldValues(new Map())
                    setColumnValuesObject({ ...columnValuesObject, SelectedColumnObjects: [] })
                })
                .catch((error) => {
                    setCreationIsLoading(false)
                    setTableDataPreview([])
                    error.text().then((response: string) => {
                        const errorMessage = response.split(";")
                        if (errorMessage[0] === "UnvalidName") {
                            errorMessage.splice(0, 1)
                            const groups = errorMessage.join(", ")
                            if (errorMessage.length === 1) {
                                setErrorCreatingGroups(`${GROUPS_TEXTS.FILTER_GROUP_UNVALID_NAME}${groups}`)
                            } else {
                                setErrorCreatingGroups(`${GROUPS_TEXTS.FILTER_GROUPS_UNVALID_NAME}${groups}`)
                            }
                        } else setGeneralError(`${GROUPS_TEXTS.ERROR_CREATING_FILTER_GROUPS} ${response}`)
                    })
                })
        )
    }

    const createFieldValues = (name: string, selectedColumnObjects: SelectedColumnObject[]) => {
        const selectedColumnValues = selectedColumnObjects.find((v) => v.Name === name)?.SelectedColumnValues

        // Create comma separated string of selectedColumnValues
        const fieldValue = selectedColumnValues?.map((v) => v.value).join(", ")

        // Make a copy of fieldValues and update value of key name
        const m = new Map(Array.from(fieldValues))
        m.set(name, fieldValue || "")
        setFieldValues(m)
    }

    // Update SelectedColumnValues of SelectedColumnObject that represents filter
    const updateSelectedColumnValues = (name: string, allSelected: boolean) => {
        const selectedColumnObjects = columnValuesObject.SelectedColumnObjects.map((v) => {
            if (v.Name === name) {
                const newSelectedColumnObject = { ...v }
                if (allSelected) {
                    const validFilter = validFilters.find((fv) => fv.Name === name)
                    if (validFilter) {
                        newSelectedColumnObject.SelectedColumnValues = validFilter.Values.map((fv) => {
                            const selectedColumnValue: SelectedColumnValue = {
                                key: fv.Key,
                                value: fv.Value,
                            }
                            return selectedColumnValue
                        })
                    }
                } else {
                    newSelectedColumnObject.SelectedColumnValues = []
                }

                const newFieldValues = new Map(Array.from(fieldValues))
                newFieldValues.set(
                    newSelectedColumnObject.Name,
                    newSelectedColumnObject.SelectedColumnValues.map((cv) => cv.value).join(", ")
                )
                setFieldValues(newFieldValues)

                return newSelectedColumnObject
            }
            return v
        })

        // Copy old state and overwrite updated field
        setColumnValuesObject({
            ...columnValuesObject,
            SelectedColumnObjects: selectedColumnObjects,
        })

        return selectedColumnObjects
    }

    const updateColumnValuesObject = (name: string, inputValue: string, selectedColumnValueIndex: number) => {
        // Check if filtertype is flerval or intervall, update SelectedColumnValues
        if (selectedColumnValueIndex >= 0) {
            // Use findIndex instead?
            const newSelectedColumnObjects = columnValuesObject.SelectedColumnObjects.map((selectedColumnObject) => {
                // Update correct filter based on param name
                if (selectedColumnObject.Name !== name) {
                    return selectedColumnObject
                }

                // Update SelectedColumnValues with inputValue for correct index
                const newSelectedColumnValues = selectedColumnObject.SelectedColumnValues.map((v, index) => {
                    if (index !== selectedColumnValueIndex) {
                        return v
                    }
                    return { ...v, value: inputValue }
                })
                return { ...selectedColumnObject, SelectedColumnValues: newSelectedColumnValues }
            })
            setColumnValuesObject({ ...columnValuesObject, SelectedColumnObjects: newSelectedColumnObjects })
        } else {
            const newSelectedColumnObjects = columnValuesObject.SelectedColumnObjects.map((selectedColumnObject) => {
                if (selectedColumnObject.Name !== name) {
                    return selectedColumnObject
                }

                // Update SelectedColumnValues with inputValue for correct index
                const newSelectedColumnValues = inputValue
                    .split(",")
                    .map((v, index) => ({ key: index + 1, value: v.trim() }))

                const newFieldValues = new Map(Array.from(fieldValues))
                newFieldValues.set(selectedColumnObject.Name, newSelectedColumnValues.map((v) => v.value).join(", "))
                setFieldValues(newFieldValues)

                if (newSelectedColumnValues.length !== validFilters.values.length) {
                    const newAllValuesSelected = new Map(Array.from(allValuesSelected))
                    newAllValuesSelected.set(selectedColumnObject.Name, false)
                    setAllValuesSelected(newAllValuesSelected)
                }

                return { ...selectedColumnObject, SelectedColumnValues: newSelectedColumnValues }
            })

            setColumnValuesObject({ ...columnValuesObject, SelectedColumnObjects: newSelectedColumnObjects })
        }
    }

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                setRegistryId,
                setFetchingRegistries,
                setRegistriesFetched,
                setErrorFetchingRegistries
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched, registryId])

    useEffect(() => {
        if (registryId) {
            fetchValidFilters(registryId, setValidFilters, setValidFilterNames, setFetchingValidFilters)
            setSelectedFilters([])
        }
    }, [registryId])

    // When registry is selected, initialize related states
    useEffect(() => {
        setColumnValuesObject({
            RegistryId: registryId,
            SelectedColumnObjects: [],
        })
        setAllValuesSelected(
            validFilters.reduce((accumulator, currentValue) => {
                accumulator.set(currentValue.Name, false)
                return accumulator
            }, new Map<string, boolean>())
        )
        setFieldValues(
            validFilters.reduce((accumulator, currentValue) => {
                accumulator.set(currentValue.Name, "")
                return accumulator
            }, new Map<string, string>())
        )
    }, [registryId, validFilters])

    // When filter is selected or unselected
    useEffect(() => {
        let columnValuesObjectMustBeUpdated = false
        const newSelectedColumnObjects: SelectedColumnObject[] = []
        const newSelectedFilters = [...selectedFilters]

        // Update SelectedColumnObjects based on selectedFilters
        columnValuesObject.SelectedColumnObjects.forEach((selectedColumnObject: SelectedColumnObject) => {
            // If selectedFilter and selectedColumnObject differs, ColumnValuesObject needs to be updated
            if (selectedFilters.find((v) => v === selectedColumnObject.Name)) {
                newSelectedColumnObjects.push(selectedColumnObject)

                // Remove from newSelectedFilters, to later know which selectedColumnObjects to create
                const index = newSelectedFilters.indexOf(selectedColumnObject.Name)
                if (index > -1) {
                    newSelectedFilters.splice(index, 1)
                }
            } else {
                // Filter is unselected
                columnValuesObjectMustBeUpdated = true

                const newFieldValues = new Map(Array.from(fieldValues))
                newFieldValues.delete(selectedColumnObject.Name)
                setFieldValues(newFieldValues)

                const newAllValuesSelected = new Map(Array.from(allValuesSelected))
                newAllValuesSelected.set(selectedColumnObject.Name, false)
                setAllValuesSelected(newAllValuesSelected)
            }
        })

        // Filter is selected
        if (newSelectedFilters.length > 0) {
            columnValuesObjectMustBeUpdated = true

            // Create new SelectedColumnObjects, when filter has been added to selectedFilters
            newSelectedFilters.forEach((filterName: string) => {
                const validFilter = validFilters.find((v) => v.Name === filterName)
                if (validFilter) {
                    const selectedColumnValues: SelectedColumnValue[] = []
                    if (validFilter.FilterColumnType.Value === "Intervall") {
                        selectedColumnValues.push({
                            key: validFilter.Values[0].Key,
                            value: validFilter.Values[0].Value,
                        })
                        selectedColumnValues.push({
                            key: validFilter.Values[1].Key,
                            value: validFilter.Values[1].Value,
                        })
                    }
                    const selectedColumnObject: SelectedColumnObject = {
                        Id: validFilter.Id,
                        Name: validFilter.Name,
                        FilterColumnType: {
                            key: validFilter.FilterColumnType.Key,
                            value: validFilter.FilterColumnType.Value,
                        },
                        SelectedColumnValues: selectedColumnValues,
                    }
                    newSelectedColumnObjects.push(selectedColumnObject)

                    const newFieldValues = new Map(Array.from(fieldValues))
                    newFieldValues.set(selectedColumnObject.Name, "")
                    setFieldValues(newFieldValues)
                }
            })
        }

        if (columnValuesObjectMustBeUpdated) {
            setColumnValuesObject({
                RegistryId: columnValuesObject.RegistryId,
                SelectedColumnObjects: newSelectedColumnObjects,
            })
        }

        setTableDataPreview([])
    }, [
        allValuesSelected,
        columnValuesObject,
        columnValuesObject.RegistryId,
        columnValuesObject.SelectedColumnObjects,
        fieldValues,
        selectedFilters,
        validFilters,
    ])

    useUpdatePageTitle(pageTitles.CREATE_FILTERGROUPS_PAGE_TITLE)
    useScrollToTop()

    return (
        <>
            <PageHeader pageId={pageIds.CREATE_GROUPS_USING_FILTER_PAGE_ID} />
            {!errorFetchingRegistries && !fetchingRegistries && registries && (
                <SelectRegistry registries={registries} setRegistryId={setRegistryId} />
            )}
            {!registries && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {errorFetchingRegistries && <Error>{errorFetchingRegistries}</Error>}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {!generalError && (fetchingRegistries || fetchingValidFilters) && !noRegisters && <ContentSkeleton />}
            {!fetchingValidFilters && validFilters.length && validFilterNames.length && (
                <>
                    <p>{GROUPS_TEXTS.SELECT_FILTER}</p>
                    <div className="filters">
                        <FilterButtonsRow
                            selectedFilters={selectedFilters}
                            setSelection={setSelectedFilters}
                            maxSelectionAllowed={5}
                        >
                            {validFilterNames}
                        </FilterButtonsRow>
                    </div>
                </>
            )}
            <div className="validationMessage">
                {showConfirmation && <Confirmation>{GROUPS_TEXTS.FILTER_GROUPS_CREATED}</Confirmation>}
                {errorCreatingGroups && <Error>{errorCreatingGroups}</Error>}
                {generalError && <Error>{generalError}</Error>}
            </div>
            {/* 345-369 komponent som tar in name och type: IntervalFilter, en annan komponent för Flerval */}
            {selectedFilters.map((name: string, index: number) =>
                // eslint-disable-next-line no-nested-ternary
                name === "OMF" ? (
                    <div key={name} className="selectValues">
                        {GROUPS_TEXTS.SELECT_FILTER_INTERVAL}
                        {name}
                        {` (${validFilters.find((v) => v.Name === name)?.Tooltip})`}:
                        <div className="intervall">
                            <label htmlFor="mininput">{GROUPS_TEXTS.MIN_VALUE}</label>
                            <input
                                className="noArrows"
                                defaultValue={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                id="mininput"
                                name="mininput"
                                type="number"
                                min={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                max={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                onChange={(e) => updateColumnValuesObject(name, e.target.value, 0)}
                            />
                            <label htmlFor="maxinput">{GROUPS_TEXTS.MAX_VALUE}</label>
                            <input
                                className="noArrows"
                                defaultValue={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                id="maxinput"
                                name="maxinput"
                                type="number"
                                min={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                max={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                onChange={(e) => updateColumnValuesObject(name, e.target.value, 1)}
                            />
                        </div>
                    </div>
                ) : name === "ANST" ? (
                    <div key={name} className="selectValues">
                        {GROUPS_TEXTS.SELECT_FILTER_INTERVAL}
                        {name}
                        {`${" "}(${validFilters.find((v) => v.Name === name)?.Tooltip})`}:
                        <div className="intervall">
                            <label htmlFor="mininput">{GROUPS_TEXTS.MIN_VALUE}</label>
                            <input
                                defaultValue={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                id="mininput"
                                name="mininput"
                                type="date"
                                min={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                max={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                onChange={(e) => updateColumnValuesObject(name, e.target.value, 0)}
                            />
                            <label htmlFor="maxinput">{GROUPS_TEXTS.MAX_VALUE}</label>
                            <input
                                defaultValue={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                id="maxinput"
                                name="maxinput"
                                type="date"
                                min={validFilters.find((v) => v.Name === name)?.Values[0].Value}
                                max={validFilters.find((v) => v.Name === name)?.Values[1].Value}
                                onChange={(e) => updateColumnValuesObject(name, e.target.value, 1)}
                            />
                        </div>
                    </div>
                ) : (
                    <div key={name} className="selectValues">
                        {GROUPS_TEXTS.SELECT_FILTER_VALUES}
                        {name}
                        {`${" "}(${validFilters.find((v) => v.Name === name)?.Tooltip})`}:
                        <GroupsMultiselectDropdown
                            selectedOptions={fieldValues}
                            options={validFilters.find((v) => v.Name === name)}
                            setSelectedOptions={(input) => {
                                updateColumnValuesObject(name, input, -1)
                            }}
                            isActive={activeIndex === index}
                            onToggle={() => setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index))}
                        />
                        <div className="selectAllCheckbox">
                            <input
                                checked={allValuesSelected.get(name)}
                                type="checkbox"
                                name={`checkbox ${name}`}
                                id={`checkbox ${name}`}
                                onChange={() => {
                                    const allSelected = !allValuesSelected.get(name)
                                    const selectedColumnObjects = updateSelectedColumnValues(name, allSelected)
                                    createFieldValues(name, selectedColumnObjects)
                                    const newAllValuesSelected = new Map(Array.from(allValuesSelected))
                                    newAllValuesSelected.set(name, allSelected)
                                    setAllValuesSelected(newAllValuesSelected)
                                }}
                            />
                            <label htmlFor={`checkbox ${name}`}>{GROUPS_TEXTS.SELECT_ALL_VALUES}</label>
                        </div>
                    </div>
                )
            )}
            {selectedFilters.length > 0 && (
                <div className="filterGroupButtons">
                    <Button
                        Icon={CleanIcon}
                        type="button"
                        onClick={() => {
                            setSelectedFilters([])
                            setFieldValues(new Map())
                            setAllValuesSelected(new Map())
                        }}
                    >
                        {GROUPS_TEXTS.CLEAR_FILTER_SELECTION}
                    </Button>
                    <div className="groupCreationButtons">
                        <Button
                            Icon={SearchIcon}
                            isLoading={previewIsLoading}
                            onClick={handlePreview}
                            disabled={
                                !!columnValuesObject.SelectedColumnObjects.find(
                                    (v) => v.SelectedColumnValues.length === 0
                                )
                            }
                        >
                            {GROUPS_TEXTS.PREVIEW}
                        </Button>
                        <Button
                            Icon={CirclePlusIcon}
                            isLoading={creationIsLoading}
                            onClick={handleCreate}
                            disabled={tableDataPreview.length === 0}
                        >
                            {`${GROUPS_TEXTS.BUTTON_CREATE_FILTER_1} ${tableDataPreview.length} ${GROUPS_TEXTS.BUTTON_CREATE_FILTER_2}`}
                        </Button>
                    </div>
                </div>
            )}
            {tableDataPreview.length > 0 && (
                <div
                    className="ag-theme-alpine"
                    style={{ width: "100%", height: "50vh", marginTop: "16px", maxWidth: "800px" }}
                >
                    <AgGridReact
                        rowData={tableDataPreview}
                        columnDefs={columnDefs}
                        animateRows
                        alwaysShowHorizontalScroll
                        alwaysShowVerticalScroll
                        suppressMenuHide
                    />
                </div>
            )}
        </>
    )
}

export default CreateGroupsUsingFilterPage
