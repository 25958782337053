import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"

import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

interface ISalaryEvolutionType3Table {
    tableRows: string[][]
    selectedRegistryPeriods: string
    salaryType: string
}

const SalaryEvolutionType3Table = ({ tableRows, selectedRegistryPeriods, salaryType }: ISalaryEvolutionType3Table) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const { S4_TABLE3_TH1, S4_TABLE3_TH2, S4_TABLE3_TH3, S4_TABLE3_TH4, S4_TABLE3_TH7, S4_TABLE3_TH8 } =
        ANALYZE_SALARY_EVOLUTION

    const periods = selectedRegistryPeriods.split(" - ")

    const defaultColumnDefs = [
        { field: "Ssn", headerName: S4_TABLE3_TH1, flex: 1, sortable: true, resizable: true },
        { field: "Name", headerName: S4_TABLE3_TH2, flex: 1, sortable: true, resizable: true },
        { field: "Employment", headerName: S4_TABLE3_TH3, width: 80, flex: 0.5, sortable: true, resizable: true },
        {
            field: "Scope",
            headerName: S4_TABLE3_TH4,
            width: 80,
            flex: 0.5,
            sortable: true,
            resizable: true,
        },
        {
            field: "SalaryPeriod1",
            headerName: `${salaryType} ${periods[0]}`,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        {
            field: "SalaryPeriod2",
            headerName: `${salaryType} ${periods[1]}`,
            flex: 1,
            sortable: true,
            resizable: true,
        },
        { field: "DeltaKr", headerName: S4_TABLE3_TH7, width: 80, flex: 0.5, sortable: true, resizable: true },
        { field: "DeltaPercent", headerName: S4_TABLE3_TH8, width: 80, flex: 0.5, sortable: true, resizable: true },
    ]

    const [columnDefs] = useState(defaultColumnDefs)

    // Instead of array with values it would be better to get an object from BE right away
    const tableRowData = tableRows.map((row) => {
        const tableRow = {
            Ssn: row[0],
            Name: row[1],
            Employment: row[2],
            Scope: row[3],
            SalaryPeriod1: row[4],
            SalaryPeriod2: row[5],
            DeltaKr: row[6],
            DeltaPercent: row[7],
        }

        return tableRow
    })

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "50vh" }}>
            <AgGridReact
                rowData={tableRowData}
                columnDefs={columnDefs}
                localeText={localeText}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default SalaryEvolutionType3Table
