import "./GroupsMultiselectDropdown.css"

import { useState } from "react"
import { IRegister, IFilter } from "types/sharedTypes"

import GENERAL_TEXTS from "constants/text.constants"

import { ReactComponent as ChevronDownIcon } from "components/menu/icons/chevron-down.icon.svg"

type MultiselectDropdownProps = {
    options?: IFilter
    label?: string
    setSelectedOptions(o: any): void
    selectedOptions: Map<string, string>
    isActive: boolean
    onToggle: () => void
}

const GroupsMultiselectDropdown = ({
    options,
    label,
    setSelectedOptions,
    selectedOptions,
    isActive,
    onToggle,
}: MultiselectDropdownProps) => {
    const handleOptionToggle = (option: any) => {
        const oldValues = selectedOptions.get(options?.Name ?? "")
        const array = oldValues
            ?.split(",")
            .map((item) => item.trim())
            .filter((item) => item !== "")

        if (selectedOptions.get(options?.Name ?? "")?.includes(option.Value)) {
            const filteredArray = array?.filter((item) => item !== option.Value)

            const newValue = filteredArray?.join(", ")
            setSelectedOptions(newValue)
        } else {
            array?.push(option.Value)
            const newValue = array?.join(", ")
            setSelectedOptions(newValue)
        }
    }

    return (
        <div className="groupsMultiSelectDropdownContainer">
            <label className="mb8" htmlFor="groupsMultiSelectDropdown">
                {label}
            </label>
            <div
                id="groupsMultiSelectDropdown"
                className={`groupsMultiSelectDropdownHeader 
                ${selectedOptions?.size > 0 ? "jc-space-between" : "jc-flex-end"}
                `}
                role="button"
                tabIndex={0}
                onClick={() => onToggle()}
                aria-describedby={GENERAL_TEXTS.ARIA_DESCRIBEDBY_MULTISELECT_DROPDOWN}
                onKeyDown={(e) => {
                    if (e.key === " " || e.code === "Space" || e.code === "Enter") {
                        e.preventDefault()
                        onToggle()
                    }
                }}
            >
                {selectedOptions.get(options?.Name ?? "")?.length ?? "".length > 0
                    ? selectedOptions.get(options?.Name ?? "")
                    : ""}
                <div className="groupsMultiselectDropdownIconContainer">
                    {isActive ? (
                        <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" />
                    ) : (
                        <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" transform="rotate(180)" />
                    )}
                </div>
            </div>
            {isActive && (
                <div className="groupsMultiSelectDropdownBody">
                    {options?.Values.map((option) => (
                        <label key={option?.Key} className="groupsMultiSelectDropdownOption">
                            <input
                                type="checkbox"
                                checked={selectedOptions.get(options.Name)?.includes(option.Value)}
                                onChange={() => handleOptionToggle(option)}
                            />
                            {option.Value}
                        </label>
                    ))}
                </div>
            )}
        </div>
    )
}

export default GroupsMultiselectDropdown
