import "./Footer.css"

import GENERAL_TEXTS from "constants/text.constants"

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer>
            <span className="copyright">{`© ${currentYear} ${GENERAL_TEXTS.COPYRIGHT_TEXT}`}</span>
            <p className="cookieText">{GENERAL_TEXTS.FOOTER_TEXT}</p>
        </footer>
    )
}

export default Footer
