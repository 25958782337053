import "./MoreInformationModal.css"

import ReactModal from "react-modal"

import { MORE_INFO_MODAL_TEXTS } from "constants/text.constants"

import Button from "components/atoms/button/Button"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

type MoreInformationModalProps = {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    registries: string[]
    groups: { Key: number; Value: string }[]
    name: string
    created: string
    hideExistsIn?: boolean
}

const MoreInformationModal = ({
    showModal,
    setShowModal,
    registries,
    groups,
    name,
    created,
    hideExistsIn = false,
}: MoreInformationModalProps) => {
    const translateKey = (key: number) => {
        if (key === registries.length) return "Alla register"
        return `${key} av ${registries.length} register`
    }

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={MORE_INFO_MODAL_TEXTS.MODAL_CONTENT}
        >
            <div className="headingAndCloseButtonFlex">
                <h1>{MORE_INFO_MODAL_TEXTS.HEADING}</h1>
                <Button Icon={xMark} iconRight onClick={() => setShowModal(false)}>
                    {MORE_INFO_MODAL_TEXTS.BUTTON_CLOSE}
                </Button>
            </div>
            <p className="flex wrap">
                <strong>{MORE_INFO_MODAL_TEXTS.LABEL_SELECTION}</strong>
                {name}
            </p>
            <p className="flex wrap">
                <strong>{MORE_INFO_MODAL_TEXTS.LABEL_CREATED}</strong>
                {created}
            </p>
            <table className="tableMoreInformationRegistries">
                <thead className="moreInformationRegistriesTHead">
                    <tr>
                        <th className="moreInformationRegistriesTH">{MORE_INFO_MODAL_TEXTS.REGISTERS_TH1}</th>
                    </tr>
                </thead>
                <tbody>
                    {registries.map((register) => (
                        <tr className="moreInformationRegistriesTR" key={`${register}${Math.random() * 1600}`}>
                            <td className="moreInformationRegistriesTD">{register}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className="tableMoreInformationGroups">
                <thead className="moreInformationGroupsTHead">
                    <tr>
                        <th className="moreInformationGroupsTH">{MORE_INFO_MODAL_TEXTS.GROUPS_TH1}</th>
                        {!hideExistsIn && (
                            <th className="moreInformationGroupsTH">{MORE_INFO_MODAL_TEXTS.GROUPS_TH2}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {groups.map((group) => (
                        <tr className="moreInformationGroupsTR" key={`${group}${Math.random() * 1600}`}>
                            <td className="moreInformationGroupsTD">{group.Value}</td>
                            {!hideExistsIn && <td className="moreInformationGroupsTD">{translateKey(group.Key)}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </ReactModal>
    )
}

export default MoreInformationModal
