import { ICellRendererParams } from "ag-grid-community"

import { dispatchBool, tEquivalentWork } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import Button from "components/atoms/button/Button"

interface ITableButtonComment extends ICellRendererParams {
    data: tEquivalentWork
    setShowCommentsModal: dispatchBool
    setCurrentGroupInfo: React.Dispatch<React.SetStateAction<{ Name: string; Id: string }>>
}

const EquivalentWorkReviewTableButton = (props: ITableButtonComment) => {
    const { data, setShowCommentsModal, setCurrentGroupInfo } = props

    const commentEqualWorkGroups = () => {
        setCurrentGroupInfo({ Name: data.GroupName, Id: data.GroupId })
        setShowCommentsModal(true)
    }

    return (
        <div>
            <Button onClick={commentEqualWorkGroups}>{ANALYZE_WAGE_GAP.S6_TABLE_BUTTON_COMMENT}</Button>
        </div>
    )
}

export default EquivalentWorkReviewTableButton
