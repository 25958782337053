import { useEffect, useState } from "react"

import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import { tSalaryEvolutionSeriesData } from "types/sharedTypes"

import getToolTipInfoFromBarChartData from "utils/Highcharts/getTooltipInfoFromBarChartData"

type DiagramView = {
    Categories: string[]
    SeriesData: tSalaryEvolutionSeriesData[]
}

type BarDiagramProps = {
    diagramView: DiagramView
    diagramTitle: string
    showMinorGridLines: boolean
}

const BarDiagram = ({ diagramView, diagramTitle, showMinorGridLines }: BarDiagramProps) => {
    HighchartsMore(Highcharts)
    Highcharts.setOptions({
        lang: {
            thousandsSep: " ",
            numericSymbols: [],
        },
    })

    const scatterColor = [
        "rgba(68,68,68,1)",
        "rgba(98,159,105,1)",
        "rgba(57,127,168,1)",
        "rgba(165,81,104,1)",
        "rgba(224,176,154,1)",
        "rgba(151,196,196,1)",
        "rgba(149,118,128,1)",
        "rgba(163,65,43,1)",
        "rgba(4,97,150,1)",
        "rgba(70,110,102,1)",
    ]

    const barColor = [
        "rgba(130,215,140,1)",
        "rgba(189,215,231,1)",
        "rgba(235,175,190,1)",
        "rgba(254,229,217,1)",
        "rgba(200,255,255,1)",
        "rgba(250,235,240,1)",
        "rgba(251,106,74,1)",
        "rgba(107,174,214,1)",
        "rgba(70,170,150,1)",
    ]

    const scatterShort = scatterColor.slice(0, diagramView.SeriesData.length).reverse()
    const barShort = barColor.slice(0, diagramView.SeriesData.length - 1).reverse()

    const sortedSeriesData = diagramView.SeriesData.sort((a, b) => {
        if (a.PeriodName && b.PeriodName) {
            if (a.PeriodName < b.PeriodName) return 1
            if (b.PeriodName < a.PeriodName) return -1
        }
        return 0
    })

    const periodData: Highcharts.SeriesOptionsType[] = sortedSeriesData.map((data, index) => ({
        id: index.toString(),
        type: "scatter",
        name: data.PeriodName,
        data: data.PeriodData.map((pData) => [pData.x, pData.y]),
        marker: {
            symbol: "circle",
            fillColor: scatterShort[index],
        },
        zIndex: 1,
    }))

    const barData: Highcharts.SeriesOptionsType[] = sortedSeriesData.map((data, index) => ({
        id: index.toString(),
        type: "bar",
        name: data.PeriodName,
        data: data.BarData?.length ? data.BarData.map((bData) => [bData.x, bData.y]) : [""],
        color: barShort[index],
        showInLegend: false,
        stacking: "normal",
        borderColor: scatterShort[index],
    }))

    const setChartHeight = (categories: string[]) => {
        if (categories.length > 10) {
            return "800px"
        }
        return "400px"
    }

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "bar",
            // height: setChartHeight(diagramView.Categories),
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: "Löneutveckling i procent",
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            title: {
                text: "",
            },
            categories: diagramView.Categories,
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                return getToolTipInfoFromBarChartData(diagramView.SeriesData, this, true, "")
            },
        },
        legend: {
            reversed: true,
            itemStyle: {
                cursor: "default",
            },
        },
        series: periodData.concat(barData),
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function legendItemClick() {
                        return false
                    },
                },
                borderColor: "black",
                borderWidth: 1,
            },
        },
        credits: {
            enabled: false,
        },
    })

    useEffect(() => {
        setChartOptions({
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        })
    }, [showMinorGridLines])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default BarDiagram
