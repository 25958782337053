import "./Button.css"
import Spinner from "../spinner/Spinner"

type ButtonProps = {
    children: string
    onClick?: () => void
    type?: "button" | "submit" | "reset"
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    variant?: "default" | "delete"
    disabled?: boolean
    iconRight?: boolean
    small?: boolean
    isLoading?: boolean
    useCommentBtn?: boolean
}

const Button = ({
    children,
    onClick,
    type = "button",
    Icon,
    variant = "default",
    disabled = false,
    iconRight = false,
    small = false,
    isLoading = false,
    useCommentBtn,
}: ButtonProps) => {
    const deleteButton = variant === "delete"
    const smallClassName = small ? "small" : ""
    const useCommentBtnClassName = useCommentBtn ? "useCommentBtn" : ""
    return (
        <button
            className={
                deleteButton
                    ? `sharedButton delete ${smallClassName}`
                    : `sharedButton ${smallClassName} ${useCommentBtnClassName}`
            }
            // eslint-disable-next-line react/button-has-type
            type={type}
            disabled={isLoading ? true : disabled}
            onClick={onClick}
        >
            {Icon && !iconRight && !small && <Icon className="iconLeft" fill="#FFFFFF" width="16px" height="16px" />}
            {children}
            {Icon && iconRight && !small && <Icon className="iconRight" fill="#FFFFFF" width="16px" height="16px" />}
            {isLoading && <Spinner />}
        </button>
    )
}

export default Button
