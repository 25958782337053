import "./ButtonContainer.css"

import { Employees, Header, IRegister } from "types/sharedTypes"
import TableButtonModify from "./TableButtonModify"
import TableButtonShow from "./TableButtonShow"

type SelectedRowObject = {
    Antal: number
    GroupId: string
    Grupp: string
    Grupptyp: string
}

type ButtonContainerProps = {
    data: SelectedRowObject
    registryId: string
    registries: IRegister[]
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setGroupName: React.Dispatch<React.SetStateAction<string>>
    setEmployees: React.Dispatch<React.SetStateAction<Employees[]>>
    setEmployeesTableHeaders: React.Dispatch<React.SetStateAction<Header>>
    setFetchError: React.Dispatch<React.SetStateAction<string>>
    setFetchingEmployeesInAGroup: React.Dispatch<React.SetStateAction<boolean>>
}

const ButtonContainer = ({
    data,
    registryId,
    registries,
    setShowModal,
    setGroupName,
    setEmployees,
    setEmployeesTableHeaders,
    setFetchError,
    setFetchingEmployeesInAGroup,
}: ButtonContainerProps) => (
    <div className="buttonContainer">
        <TableButtonShow
            data={data}
            setShowModal={setShowModal}
            setGroupName={setGroupName}
            setEmployees={setEmployees}
            setEmployeesTableHeaders={setEmployeesTableHeaders}
            setFetchError={setFetchError}
            setFetchingEmployeesInAGroup={setFetchingEmployeesInAGroup}
        />
        <TableButtonModify data={data} registryId={registryId} registries={registries} />
    </div>
)

export default ButtonContainer
