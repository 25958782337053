// import "./AnalyzeSalaryDistributionPage.css"

import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import { ANALYZE_SALARY_DISTRIBUTION } from "constants/text.constants"

import { useContext, useEffect, useState } from "react"

import { salaryGroup } from "types/sharedTypes"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { RegistriesContext } from "context/RegistriesContext"

import Flow from "components/cores/flow/Flow"
import FlowStep from "components/cores/flow/flowStep/FlowStep"
import PageHeader from "components/cores/pageHeader/PageHeader"

import SalaryRegistriesAndSelections from "./step1/SalaryRegistriesAndSelections"
import ManageSelection from "./step2/ManageSelection"
import SalarySelection from "./step3/SalarySelection"

const AnalyzeSalaryDistributionPage = () => {
    const { registries, registriesFetched } = useContext(RegistriesContext)
    const [selectedRegistryId, setSelectedRegistryId] = useState(
        registriesFetched && registries.length ? registries[0].Id : ""
    )

    const [activeStep, setActiveStep] = useState(1)
    const [activeSteps, setActiveSteps] = useState<number[]>([1, 2])

    const [preselectedGroups, setPreselectedGroups] = useState<salaryGroup[]>([])
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([])
    const [diagramType, setDiagramType] = useState(0)
    const [salaryType, setSalaryType] = useState(0)

    const [selectionObject, setSelectionObject] = useState({ name: "", id: "" })

    // reset states if we start from step 1
    useEffect(() => {
        if (activeStep === 1) {
            setPreselectedGroups([])
            setSelectedGroupIds([])
            setDiagramType(0)
            setSalaryType(0)
            setActiveSteps([1, 2])
            setSelectionObject({ name: "", id: "" })
        }
    }, [activeStep])

    useUpdatePageTitle(pageTitles.ANALYZE_SALARY_DISTRIBUTION_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.ANALYZE_SALARY_DISTRIBUTION_PAGE_ID} />
            <div className="mb16">
                <Flow
                    currentStep={activeStep}
                    setCurrentStep={setActiveStep}
                    activeSteps={activeSteps}
                    setActiveSteps={setActiveSteps}
                >
                    <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_SALARY_DISTRIBUTION.FLOWSTEP_1}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_SALARY_DISTRIBUTION.FLOWSTEP_2}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_SALARY_DISTRIBUTION.FLOWSTEP_3}</FlowStep>
                </Flow>
            </div>
            {activeStep === 1 && (
                <SalaryRegistriesAndSelections
                    selectedRegistryId={selectedRegistryId}
                    setSelectedRegistryId={setSelectedRegistryId}
                    setSelectedGroups={setPreselectedGroups}
                    setActiveStep={setActiveStep}
                    setDiagramType={setDiagramType}
                    setSalaryType={setSalaryType}
                    setSelection={setSelectionObject}
                />
            )}
            {activeStep === 2 && (
                <ManageSelection
                    registryId={selectedRegistryId}
                    setActiveSteps={setActiveSteps}
                    preselectedGroups={preselectedGroups}
                    selectedGroups={selectedGroupIds}
                    setSelectedGroups={setSelectedGroupIds}
                    diagramType={diagramType}
                    setDiagramType={setDiagramType}
                    salaryType={salaryType}
                    setSalaryType={setSalaryType}
                    savedSelection={selectionObject}
                />
            )}
            {activeStep === 3 && !!diagramType && !!salaryType && !!selectedGroupIds.length && (
                <SalarySelection
                    salaryType={salaryType}
                    selectedRegisteryId={selectedRegistryId}
                    diagramType={diagramType}
                    selectedGroups={selectedGroupIds}
                />
            )}
        </div>
    )
}

export default AnalyzeSalaryDistributionPage
