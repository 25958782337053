import { AuthHandler } from "./authHandler"

export function postWithBodyAndCsrf(api: string, token: string, bodyJson: BodyInit, pureResponse = false) {
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        // RequestVerificationToken: token,
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                    body: bodyJson,
                }),
            api
        )
        .then((response) => {
            if (response.ok && pureResponse) return response
            if (response.ok) return response.json()
            return Promise.reject(response)
        })
}

export function putWithBodyAndCsrf(api: string, token: string, bodyJson: BodyInit, pureResponse = false) {
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        // RequestVerificationToken: token,
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                    body: bodyJson,
                }),
            api
        )
        .then((response) => {
            if (response.ok && pureResponse) return response
            if (response.ok) return response.json()
            return Promise.reject(response)
        })
}

export function postFormdataWithCsrf(api: string, token: string, formdata: BodyInit) {
    return fetch(api, {
        method: "POST",
        credentials: "include",
        headers: { 
            // RequestVerificationToken: token, 
            Authorization: `Bearer ${localStorage.getItem("authToken")}` },
        body: formdata,
    }).then((response) => {
        if (response.ok) return response.json()
        return Promise.reject(response.json())
    })
}

export function deleteWithBodyAndCsrf(api: string, token: string, body: BodyInit) {
    // We don't need to wrap this in authHandler because we always use prepareCsrfToken() before
    // prepareCsrfToken() is wrapped in authHandler
    return fetch(api, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            // RequestVerificationToken: token,
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body,
    }).then((response) => {
        if (response.ok) return response
        return Promise.reject(response)
    })
}

export function deleteWithCsrf(api: string, token: string) {
    return fetch(api, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            // RequestVerificationToken: token,
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    }).then((response) => {
        if (response.ok) return response
        return Promise.reject(response)
    })
}

export function getJSONrequestWithCsrf(api: string, token: string) {
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        // RequestVerificationToken: token,
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }),
            api
        )
        .then((response) => {
            if (response.ok) return response.json()
            return Promise.reject(response)
        })
}

// cannot have body i ett GET request, get rekt
export function getJSONrequestWithBodyAndCsrf(api: string, token: string, body: BodyInit) {
    console.log("fetching inside")
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        // RequestVerificationToken: token,
                        authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                    body,
                }),
            api
        )
        .then((response) => {
            if (response.ok) return response.json()
            return Promise.reject(response)
        })
}

export function getJSONrequest(api: string): Promise<any> {
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }),
            api
        )
        .then((response) => {
            if (response.ok) return response.json()
            return Promise.reject(response)
        })
}

export function getRequest(api: string, contentType: string): Promise<any> {
    return AuthHandler.ins()
        .request(
            () =>
                fetch(api, {
                    credentials: "include",
                    headers: {
                        "Content-Type": contentType,
                        authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }),
            api
        )
        .then((response) => {
            if (response.ok) return response
            return Promise.reject(response)
        })
}

export default getJSONrequest
