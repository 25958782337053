import { useEffect, useState } from "react"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

type SalaryEvolutionDiagramViewAnalysisType2 = {
    Categories: string[]
    CountShare: [{ Key: number; Value: number }]
    Data: number[]
    SelectedRegistryPeriods: string
}

type ColumnDiagramProps = {
    diagramView: SalaryEvolutionDiagramViewAnalysisType2
    diagramTitle: string
    showMinorGridLines: boolean
    isPercent: boolean
}

const ColumnDiagram = ({ diagramView, diagramTitle, showMinorGridLines, isPercent }: ColumnDiagramProps) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const unit = isPercent ? "procent" : "kronor"

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "column",
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: `Andel individer i ${unit}`,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            title: {
                text: `Löneutveckling i ${unit}`,
            },
            categories: diagramView.Categories,
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const { x, y } = this
                return `${x}<br/>Andel individer: ${y}`
            },
        },
        legend: {
            enabled: false,
        },
        series: [{ type: "column", data: diagramView.Data }],
        credits: {
            enabled: false,
        },
    })

    useEffect(() => {
        setChartOptions((options) => ({
            ...options,

            series: [{ type: "column", data: diagramView.Data }],
        }))
    }, [diagramView.Data])

    useEffect(() => {
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
                title: {
                    text: `Andel individer i ${unit}`,
                },
            },
            xAxis: {
                title: {
                    text: `Löneutveckling i ${unit}`,
                },
                categories: diagramView.Categories,
            },
        }))
    }, [diagramView.Categories, showMinorGridLines, unit])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default ColumnDiagram
