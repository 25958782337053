export const AG_GRID_LOCALE_SV = {
    // Set Filter
    selectAll: "(Välj alla)",
    selectAllSearchResults: "(Välj alla sökresultat)",
    searchOoo: "Sök...",
    blanks: "(Tomma)",
    noMatches: "Inga träffar",

    // Number Filter & Text Filter
    filterOoo: "Filtrera...",
    equals: "Lika med",
    notEqual: "Inte lika med",
    blank: "Tom",
    notBlank: "Inte tomt",
    empty: "Choose One",

    // Number Filter
    lessThan: "Mindre än",
    greaterThan: "Större än",
    lessThanOrEqual: "Mindre än eller lika",
    greaterThanOrEqual: "Större än eller lika med",
    inRange: "Mellan",
    inRangeStart: "från",
    inRangeEnd: "till",

    // Text Filter
    contains: "Innehåller",
    notContains: "Innehåller inte",
    startsWith: "Börjar med",
    endsWith: "Slutar med",

    // Date Filter
    dateFormatOoo: "åååå-mm-dd",

    // Filter Conditions
    andCondition: "OCH",
    orCondition: "ELLER",

    // Filter Buttons
    applyFilter: "Tillämpa",
    resetFilter: "Återställa",
    clearFilter: "Rensa",
    cancelFilter: "Avbryt",

    // Filter Titles
    textFilter: "Textfilter",
    numberFilter: "Nummerfilter",
    dateFilter: "Datumfilter",
    setFilter: "Ställ in filter",

    // Group Column Filter
    groupFilterSelect: "Välj fält:",

    // Side Bar
    columns: "Kolumner",
    filters: "Filter",

    // columns tool panel
    pivotMode: "Pivotläge",
    groups: "Radgrupper",
    rowGroupColumnsEmptyMessage: "Drag hit för att ställa in radgrupper",
    values: "Values",
    valueColumnsEmptyMessage: "Drag hit för att samla",
    pivots: "Kolumnetiketter",
    pivotColumnsEmptyMessage: "Drag hit för att ställa in kolumnetiketter",

    // Header of the Default Group Column
    group: "Grupp",

    // Row Drag
    rowDragRow: "Rad",
    rowDragRows: "Rader",

    // Other
    loadingOoo: "Laddar...",
    loadingError: "ERR",
    noRowsToShow: "Inga rader att visa",
    enabled: "Aktiverad",

    // Menu
    pinColumn: "Fäst kolumn",
    pinLeft: "Fäst till vänster",
    pinRight: "Fäst till höger",
    noPin: "Ingen fäst kolumn",
    valueAggregation: "Sammanslagning",
    noAggregation: "Ingen sammanslagning",
    autosizeThiscolumn: "Automatisk storlek på denna kolumn",
    autosizeAllColumns: "Automatisk storlek på alla kolumner",
    groupBy: "Grupp på",
    ungroupBy: "Avgruppera på",

    resetColumns: "Återställ kolumner",
    expandAll: "Expandera alla",
    collapseAll: "Stäng alla",
    copy: "Kopiera",
    ctrlC: "Ctrl+C",
    ctrlX: "Ctrl+X",
    copyWithHeaders: "Kopiera Med Rubriker",
    copyWithGroupHeaders: "Kopiera med grupprubriker",
    cut: "Klippa",
    paste: "Klistra",
    ctrlV: "Ctrl+V",
    export: "Exportera",
    csvExport: "CSV Exportera",
    excelExport: "Excel Exportera",

    // Enterprise Menu Aggregation and Status Bar
    sum: "Summa",
    first: "Först",
    last: "Sist",
    min: "Min",
    max: "Max",
    none: "Ingen",
    count: "Antal",
    avg: "Genomsnitt",
    filteredRows: "Filtrerad",
    selectedRows: "Valda",
    totalRows: "Totalt antal rader",
    totalAndFilteredRows: "Rader",
    more: "Mer",
    to: "Till",
    of: "Av",
    page: "Sida",
    nextPage: "Nästa sida",
    lastPage: "Sista sida",
    firstPage: "Första sida",
    previousPage: "Föregående sida",

    // Pivoting
    pivotColumnGroupTotals: "Total",

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: "Pivotdiagram & Pivotläge",
    pivotChart: "Pivotdiagram",
    chartRange: "Diagramintervall",

    columnChart: "Kolumn",
    groupedColumn: "Grupperad",
    stackedColumn: "Staplad",
    normalizedColumn: "100% Staplade",

    barChart: "Stapel",
    groupedBar: "Grupperad",
    stackedBar: "Staplad",
    normalizedBar: "100% Staplade",

    pieChart: "Cirkel",
    pie: "Andel",
    doughnut: "Munk",

    line: "Linje",

    xyChart: "X Y (spridning)",
    scatter: "Spridning",
    bubble: "Bubbla",

    areaChart: "Område",
    area: "Område",
    stackedArea: "Staplad",
    normalizedArea: "100% Staplade",

    histogramChart: "Histogram",
    histogramFrequency: "Frekvens",

    combinationChart: "Kombination",
    columnLineCombo: "Kolumn & Linje",
    AreaColumnCombo: "Område och kolumn",

    // Charts
    pivotChartTitle: "Pivotdiagram",
    rangeChartTitle: "Intervalldiagram",
    settings: "Inställningar",
    data: "Data",
    format: "Format",
    categories: "Kategorier",
    defaultCategory: "(Ingen)",
    series: "Serier",
    xyValues: "X Y-värden",
    paired: "Parläge",
    axis: "Axel",
    navigator: "Navigator",
    color: "Färg",
    thickness: "Tjocklek",
    xType: "X Typ",
    automatic: "Automatisk",
    category: "Kategori",
    number: "Nummer",
    time: "Tid",
    autoRotate: "Automatisk rotering",
    xRotation: "X Rotation",
    yRotation: "Y Rotation",
    ticks: "Steg",
    width: "Bredd",
    height: "Höjd",
    length: "Längd",
    padding: "Stoppning",
    spacing: "Mellanrum",
    chart: "Diagram",
    title: "Titel",
    titlePlaceholder: "Diagramtitel - dubbelklicka för att redigera",
    background: "Bakgrund",
    font: "Typsnitt",
    top: "Topp",
    right: "Höger",
    bottom: "Bottom",
    left: "Vänster",
    labels: "Etiketter",
    size: "Storlek",
    minSize: "Minsta storlek",
    maxSize: "Maximal storlek",
    legend: "Legend",
    position: "Position",
    markerSize: "Markörstorlek",
    markerStroke: "Markördrag",
    markerPadding: "Markörstoppning",
    itemSpacing: "Objektsavstånd",
    itemPaddingX: "Objektsstoppning X",
    itemPaddingY: "Objektsstoppning Y",
    layoutHorizontalSpacing: "Horizontellt mellanrum",
    layoutVerticalSpacing: "Vertikalt mellanrum",
    strokeWidth: "Dragbredd",
    lineDash: "Linjehopp",
    offset: "Utjämning",
    offsets: "Utjämningar",
    tooltips: "Informationsrutor",
    callout: "Callout",
    markers: "Markörer",
    shadow: "Skugga",
    blur: "Suddig",
    xOffset: "X Offset",
    yOffset: "Y Offset",
    lineWidth: "Line Width",
    normal: "Normal",
    bold: "Fet",
    italic: "Kursiv",
    boldItalic: "Fetkursiv",
    predefined: "Fördefinierad",
    fillOpacity: "Fyll transparens",
    strokeOpacity: "Linjetransparens",
    histogramBinCount: "Bin count",
    columnGroup: "Kolumn",
    barGroup: "Stapelgrupp",
    pieGroup: "Pajgrupp",
    lineGroup: "Linjegrupp",
    scatterGroup: "X Y (Spridning)",
    areaGroup: "Area",
    histogramGroup: "Histogram",
    combinationGroup: "Kombination",
    groupedColumnTooltip: "Grupperade",
    stackedColumnTooltip: "Staplade",
    normalizedColumnTooltip: "100% Stacked",
    groupedBarTooltip: "Grupperade",
    stackedBarTooltip: "Stacked",
    normalizedBarTooltip: "100% Stacked",
    pieTooltip: "Pie",
    doughnutTooltip: "Doughnut",
    lineTooltip: "Line",
    groupedAreaTooltip: "Område",
    stackedAreaTooltip: "Stacked",
    normalizedAreaTooltip: "100% Stacked",
    scatterTooltip: "Utsoridning",
    bubbleTooltip: "Bubbla",
    histogramTooltip: "Histogram",
    columnLineComboTooltip: "Column & Line",
    areaColumnComboTooltip: "område & kolumn",
    customComboTooltip: "Anpassad kombination",
    noDataToChart: "Inga tillgängliga data att kartläggas.",
    pivotChartRequiresPivotMode: "Pivotdiagram kräver pivotläge aktiverat.",
    chartSettingsToolbarTooltip: "Meny",
    chartLinkToolbarTooltip: "Länkad till rutnät",
    chartUnlinkToolbarTooltip: "Bortkopplad från rutnät",
    chartDownloadToolbarTooltip: "Ladda ned diagram",
    seriesChartType: "Seriediagramtyp",
    seriesType: "Serietyp",
    secondaryAxis: "Sekundär axel",

    // ARIA
    ariaChecked: "Iklickad",
    ariaColumn: "Kolumn",
    ariaColumnGroup: "KolumnGrupp",
    ariaColumnList: "KolumnLista",
    ariaColumnSelectAll: "Växla Välj alla kolumner",
    ariaDateFilterInput: "Datumfilterinmatning",
    ariaDefaultListName: "Lista",
    ariaFilterColumnsInput: "Filter kolumninmatning",

    ariaFilterFromValue: "Filter från värde",
    ariaFilterInput: "Filterinmatning",
    ariaFilterList: "Filterlista",
    ariaFilterToValue: "Filter till värde",
    ariaFilterValue: "Filtervärde",
    ariaFilteringOperator: "Filtreringsoperatör",
    ariaHidden: "Dold",
    ariaIndeterminate: "Obestämd",
    ariaInputEditor: "Inmatningsredigerare",
    ariaMenuColumn: "Tryck på CTRL ENTER för att öppna kolumnmenyn.",
    ariaRowDeselect: "Tryck på MELLANSLAG för att avmarkera den här raden",
    ariaRowSelectAll: "Tryck på blanksteg för att växla val av alla rader",
    ariaRowToggleSelection: "Tryck på blanksteg för att växla radval",
    ariaRowSelect: "Tryck på MELLANSLAG för att välja denna rad",
    ariaSearch: "Sök",
    ariaSortableColumn: "Tryck på ENTER för att sortera",
    ariaToggleVisibility: "Tryck på ENTER för att sortera",
    ariaUnchecked: "Omarkerad",
    ariaVisible: "Synlig",
    ariaSearchFilterValues: "Sökfiltervärden",

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: "Radgrupper",
    ariaValuesDropZonePanelLabel: "Värden",
    ariaPivotDropZonePanelLabel: "Kolumnetiketter",
    ariaDropZoneColumnComponentDescription: "Tryck på DELETE för att ta bort",
    ariaDropZoneColumnValueItemDescription: "Tryck på ENTER för att ändra aggregeringstypen",
    ariaDropZoneColumnGroupItemDescription: "Tryck på ENTER för att sortera",
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: " av ",
    ariaDropZoneColumnComponentSortAscending: "stigande",
    ariaDropZoneColumnComponentSortDescending: "fallande",

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: "Kolumnmeny",
    ariaLabelCellEditor: "Cellredigerare",
    ariaLabelDialog: "Dialog",
    ariaLabelSelectField: "Välj fält",
    ariaLabelTooltip: "Verktygstips",
    ariaLabelContextMenu: "Informationsruta",
    ariaLabelSubMenu: "Undermeny",

    ariaLabelAggregationFunction: "Aggregationsfunktion",

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ",",
    decimalSeparator: ".",
}

export default AG_GRID_LOCALE_SV
