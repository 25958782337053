import "./ShowRegisterPage.css"

import pageIds from "constants/pageIds.constants.json"
import { REGISTER_TEXTS } from "constants/text.constants"
import pageTitles from "constants/pageTitles.constants.json"

import { IRegisterOverview } from "types/sharedTypes"

import { useContext, useEffect, useState } from "react"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"
import { RegistriesContext } from "context/RegistriesContext"
import { fetchRegisterOverview, fetchRegistries } from "services/fetchers"

import { ReactComponent as ChevronDownIcon } from "components/menu/icons/chevron-down.icon.svg"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion"

import Error from "components/atoms/error/Error"
import PageHeader from "components/cores/pageHeader/PageHeader"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

import RegisterContent from "./components/registerContent/RegisterContent"

const ShowRegisterPage = () => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)

    const [fetchError, setFetchError] = useState("")
    const [fetchingRegistries, setFetchingRegistries] = useState(!registriesFetched)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)

    const [fetchingRegisterOverview, setFetchingRegisterOverview] = useState(true)
    const [fetchRegisterOverviewError, setFetchRegisterOverviewError] = useState("")
    const [registerOverview, setRegisterOverview] = useState<IRegisterOverview>()
    const [diagramData, setDiagramData] = useState<IRegisterOverview>()

    const [expandedId, setExpandedId] = useState("")

    const handleFetchRegister = (id: string) => {
        if (expandedId === id) setExpandedId("")
        else setExpandedId(id)

        fetchRegisterOverview(
            id,
            setRegisterOverview,
            setDiagramData,
            setFetchingRegisterOverview,
            setFetchRegisterOverviewError
        )
    }

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                undefined,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched])

    useUpdatePageTitle(pageTitles.SHOW_REGISTER_PAGE_TITLE)
    useScrollToTop()

    return (
        <>
            <PageHeader pageId={pageIds.SHOW_REGISTER_PAGE_ID} />
            {!noRegisters && fetchingRegistries && !fetchError && <ContentSkeleton />}
            {noRegisters && !fetchingRegistries && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {fetchError && <Error>{fetchError}</Error>}
            {showDeleteConfirmation && (
                <div className="marginBottom32 maxWidth1000">
                    <Confirmation>{REGISTER_TEXTS.CONFIRMATION_DELETED}</Confirmation>
                </div>
            )}
            {!fetchingRegistries && !noRegisters && !fetchError && (
                <div className="maxWidth1000">
                    <Accordion allowZeroExpanded>
                        {registries.map((register) => (
                            <AccordionItem key={register.Id}>
                                <AccordionItemHeading
                                    onKeyDown={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            handleFetchRegister(register.Id)
                                        }
                                    }}
                                    onClick={() => handleFetchRegister(register.Id)}
                                >
                                    <AccordionItemButton>
                                        <div className="flex jc-space-between">
                                            <div className="headingTextContainer gap32">
                                                <p className="headingText">
                                                    <strong>{REGISTER_TEXTS.REGISTER}</strong>
                                                    {register.Name}
                                                </p>
                                                <p className="headingText">
                                                    <strong>{REGISTER_TEXTS.CREATED}</strong>
                                                    {register.Created}
                                                </p>
                                                <p className="headingText">
                                                    <strong>{REGISTER_TEXTS.IMPORTED_BY}</strong>
                                                    {register.ImportedBy}
                                                </p>
                                            </div>
                                            <div className="iconContainer">
                                                {expandedId === register.Id ? (
                                                    <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" />
                                                ) : (
                                                    <ChevronDownIcon
                                                        width="1rem"
                                                        height="1rem"
                                                        fill="#333333"
                                                        transform="rotate(180)"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <RegisterContent
                                        id={register.Id}
                                        name={register.Name}
                                        registeryOverview={registerOverview}
                                        diagramData={diagramData}
                                        fetchingRegisterOverview={fetchingRegisterOverview}
                                        fetchError={fetchRegisterOverviewError}
                                        setShowConfirmation={setShowDeleteConfirmation}
                                        isInActiveSalarySimulation={register.IsInActiveSalarySimulation}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            )}
        </>
    )
}

export default ShowRegisterPage
