import "./FilterRadioButton.css"

type FilterRadioButtonProps = {
    label: string
    onClick: () => void
    checked: boolean
}

const FilterRadioButton = ({ label, onClick, checked }: FilterRadioButtonProps) => {
    const handleOnClick = () => {
        onClick()
    }

    return (
        <div
            className={`filterRadioButton ${checked ? "checked" : ""}`}
            role="radio"
            aria-checked={checked}
            tabIndex={0}
            onClick={handleOnClick}
            onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "Space") {
                    e.preventDefault()
                    handleOnClick()
                }
            }}
        >
            <input type="radio" checked={checked} readOnly />
            <label>{label}</label>
        </div>
    )
}

export default FilterRadioButton
