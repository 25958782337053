import { IboxplotDiagramData } from "types/sharedTypes"

function prepareBoxplotDiagramWithComparison(
    unpreparedData: string[],
    chartTitle: string,
    yAxisTitle: string,
    groupIds: string[]
) {
    const preparedDiagramData: IboxplotDiagramData = {
        ChartTitle: chartTitle,
        YAxisTitle: yAxisTitle,
        Data: [],
        Categories: [],
    }
    const preparedComparisonData: any = []
    const diagData: any[] = []
    const compData: any[] = []

    unpreparedData.forEach((row) => {
        const parsedRow = JSON.parse(row)

        if (groupIds.some((id) => id === parsedRow[0].ID)) {
            diagData.push({
                Name: parsedRow[0].Categories[0],
                Data: parsedRow[0].Data,
            })
        } else {
            compData.push(parsedRow[0].Data)
        }
    })

    diagData.forEach((group, index) => {
        // twice to include refStat categories too
        preparedDiagramData.Categories.push(group.Name)
        preparedDiagramData.Categories.push(group.Name)
        group.Data.forEach((arr: any) => {
            arr.splice(0, 0, index * 2)
            preparedDiagramData.Data.push(arr)
        })
    })

    compData.forEach((group, index) => {
        group.forEach((arr: any) => {
            arr.slice()
            arr.splice(0, 0, index * 2 + 1) // adding a position value to the group
            preparedComparisonData.push(arr)
        })
    })

    preparedDiagramData.Data.forEach((d) => {
        d.sort((a, b) => a - b)
    })

    // making sure the comparison data are not missing any data point values and if so fill it up with existing values
    const updatePreparedComparisonData = (data: number[][]) => {
        const compDataCopy: number[][] = [...data]
        const updatedCompData: number[][] = []

        // going through all the comparison groups
        compDataCopy.forEach((group: number[], i: number) => {
            // if the array doesn't contains a zero on index 1 it will have all 5 values then do nothing
            if (group[1] !== 0) {
                updatedCompData.push(group)
            } else {
                // if there are two missing values total. [position, 0, value1, value2, value3, 0]
                const groupCopy = [...group]
                if (groupCopy[2] !== 0) {
                    const [, , c, , e] = groupCopy
                    groupCopy[1] = c
                    groupCopy[5] = e
                } else {
                    // if there are four missing values total. [position, 0, 0, value, 0, 0]
                    const [, , , median] = group
                    group.forEach((value: number, j: number) => {
                        if (j !== 0) {
                            // skipping the position index
                            groupCopy[j] = median // replacing all the 0:s with the median value
                        }
                    })
                }
                updatedCompData.push(groupCopy)
            }
        })

        return updatedCompData
    }

    return [preparedDiagramData, updatePreparedComparisonData(preparedComparisonData)]
}

export default prepareBoxplotDiagramWithComparison
