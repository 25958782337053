import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"
import { ValueFormatterParams } from "ag-grid-community"

import { useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"

import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { tComparisonstatisticsTableViewObject } from "types/sharedTypes"

// import thousandSeparator from "utils/thousandSeparator"

interface IRefstatPercentilesTable {
    percentileTableData: tComparisonstatisticsTableViewObject[]
    showAgeGroups?: boolean
}

const RefstatPercentilesTable = ({ percentileTableData, showAgeGroups = false }: IRefstatPercentilesTable) => {
    const { S4_TH1, S4_TH2, S4_TH3, S4_TH4, S4_TH5, S4_TH6, S4_TH7, S4_TH8, S4_TH_AGE_GRP } =
        ANALYZE_REFERENCESTATISTICS

    const ageGroups = ["-", "-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60+"]
    if (showAgeGroups) {
        const tableData = [...percentileTableData]
        tableData.forEach(
            // eslint-disable-next-line no-return-assign, no-param-reassign
            (tableViewObj) => ((tableViewObj as any).AgeGroupFormatted = ageGroups[tableViewObj.AgeGroup])
        )
    }

    const defaultColumnDefs = [
        { field: "BestaGroup", headerName: S4_TH1, width: 80, sortable: true, resizable: true },
        { field: "Type", headerName: S4_TH2, width: 120, sortable: true, resizable: true }, // flex: 1
        { field: "Count", headerName: S4_TH3, width: 80, sortable: true, resizable: true },
        {
            field: "AgeGroupFormatted",
            headerName: S4_TH_AGE_GRP,
            width: 120,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
            hide: !showAgeGroups,
        },
        {
            field: "FirstTenthPercentile",
            headerName: S4_TH4,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "FirstQuarterPercentile",
            headerName: S4_TH5,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "MedianPercentile",
            headerName: S4_TH6,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastQuarterPercentile",
            headerName: S4_TH7,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastTenthPercentile",
            headerName: S4_TH8,
            width: 90,
            flex: 1,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
    ]

    const [columnDefs] = useState(defaultColumnDefs)
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", maxHeight: "50vh" }}>
            <AgGridReact
                rowData={percentileTableData}
                columnDefs={columnDefs}
                localeText={localeText}
                domLayout="autoHeight"
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default RefstatPercentilesTable
