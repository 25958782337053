import { ICellRendererParams } from "ag-grid-community"

import { tGapMenWomenMatchingGroup } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { ReactComponent as VenusIcon } from "assets/venus.icon.svg"
import { ReactComponent as CommentsIcon } from "assets/comment-dots.icon.svg"

interface IMatchingGroupsTableObserve extends ICellRendererParams {
    data: tGapMenWomenMatchingGroup
}

const MatchingGroupsTableObserve = (props: IMatchingGroupsTableObserve) => {
    const { data } = props
    const { S7_VENUS_ICON_ALT, S7_COMMENTS_ICON_ALT } = ANALYZE_WAGE_GAP

    return (
        <div className="flex gap4 pt16 pb16">
            {data.IsMajorityWomen && <VenusIcon width="1rem" height="1rem" fill="#003a64" title={S7_VENUS_ICON_ALT} />}
            {data.HaveComment && (
                <CommentsIcon width="1rem" height="1rem" fill="#003a64" title={S7_COMMENTS_ICON_ALT} />
            )}
        </div>
    )
}

export default MatchingGroupsTableObserve
