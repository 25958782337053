function convertToComparableString(input: string) {
    // decoding text to use åäö
    const element = document.createElement("textarea")
    element.innerHTML = input
    const decodedHTMLEntity = element.value

    // stripping HTML tags
    const tmp = document.createElement("div")
    tmp.innerHTML = decodedHTMLEntity
    const result = tmp.textContent || tmp.innerText || ""

    return result.trim()
}

export default convertToComparableString
