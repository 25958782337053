import { getapiendpoint, getIdpApiendpoint } from "config/config";
 
const apiEndpoints = () => {
    const baseurlIdp = getIdpApiendpoint()
    const baseurl = getapiendpoint()
// const baseurl = "https://localhost:44300"
// const baseurlIdp =  "https://localhost:5001"

    if (!baseurl)
    {
        throw new Error("baseUrl not set")
    }
    return {
    login: `${baseurlIdp}/api/auth/login`,
    resetPassword: `${baseurl}/api/administrera/aterstallLosenord`,
    verifyPassword: `${baseurl}/api/administrera/VerifieraLosenord`,
    logout:  `${baseurl}/api/auth/logout`,
    submitOtp: `${baseurlIdp}/api/auth/otp`,
    resendOtp: `${baseurlIdp}/api/auth/resendotp`,
    refreshToken: `${baseurlIdp}/api/auth/refresh`,
    getUserresources: `${baseurl}/api/auth/userresources`,
    agvAdministrator: `${baseurl}/api/auth/resource?resource=AgvAdministration`,
    getCSRFtoken: `${baseurl}/api/auth/csrftoken`,
    getMainAccounts: `${baseurl}/api/accounts`,
    getMainAccountsRegistersHealthById: `${baseurl}/api/accounts/HealthRegistries/:id`,
    deleteMainAccountById: `${baseurl}/api/accounts/:id`,
    getMainAccountMembers: `${baseurl}/api/accounts/members`,
    postMainAccount: `${baseurl}/api/accounts`,
    getTexts: `${baseurl}/api/text/getall`,
    updateTexts: `${baseurl}/api/text/update`,
    getRegistries: `${baseurl}/api/accounts/registries?query.excludeDeleted=true`,
    importRegistries: `${baseurl}/api/register/readFileFromStream`,
    exportRegisterById: `${baseurl}/api/register/export?regID=:id`,
    deleteRegisterById: `${baseurl}/api/registry/:id/false`,
    deleteRegisterAndCommentsById: `${baseurl}/api/registry/:id/true`,
    getCustomColumnsById: `${baseurl}/api/registry/:id/customColumns`,
    updateCustomColumnsById: `${baseurl}/api/registry/:id/customColumns`,
    getRegisterById: `${baseurl}/api/registry/:id/overview`,
    rebuildRegisterViewsById: `${baseurl}/api/registry/:id/views`,
    getEmployees: `${baseurl}/api/registry/getEmployees`,
    getGroupsForRegistryById: `${baseurl}/api/registry/%7BId%7D/groups?RegistryId=:id`,
    importReferencestatistics: `${baseurl}/api/referensstatistik/readFileFromStream`,
    exportReferencestatisticsById: `${baseurl}/api/referensstatistik/export?id=:id&forImport=false`,
    exportReferencestatisticsForImportById: `${baseurl}/api/referensstatistik/export?id=:id&forImport=true`,
    deleteCustomReferencestatisticsById: `${baseurl}/api/ComparisonStatistic/CustomStatistic/:id`,
    deleteNationalReferencestatisticsById: `${baseurl}/api/ComparisonStatistic/NationalStatistic/:id`,
    getComparisonstatistics: `${baseurl}/api/accounts/comparisonstatistics`,
    getTableViewById: `${baseurl}/api/comparisonstatistic/:id/tableView`,
    getManual: `${baseurl}/api/support/manual`,
    editManualById: `${baseurl}/api/support/manual/:id`,
    getFAQ: `${baseurl}/api/support/faq`,
    editFAQById: `${baseurl}/api/support/faq/:id`,
    getConceptsAndDefinitions: `${baseurl}/api/support/conceptsAndDefinitions`,
    editConceptsAndDefinitionsById: `${baseurl}/api/support/conceptsAndDefinitions/:id`,
    deleteAnySupportItemById: `${baseurl}/api/support/:id`,
    getValidFiltersForRegistryById: `${baseurl}/api/group/:id/ValidColumns`,
    filterGroupPreviewById: `${baseurl}/api/group/:id/FilterGroupPreview`,
    createFilterGroupsById: `${baseurl}/api/group/:id/createFilterGroups`,
    getGroupEmployeesById: `${baseurl}/api/group/getEmployees/:id`,
    getGroupEmployeesById2: `${baseurl}/api/group/:id/GetGroupEmployees`,
    deleteGroups: `${baseurl}/api/group/GroupIds`,
    createManualGroup: `${baseurl}/api/group/AddNewManualGroup`,
    updateManualGroup: `${baseurl}/api/group/UpdateGroup`,
    getUsers: `${baseurl}/api/accounts/users`,
    getRoles: `${baseurl}/api/users/roles`,
    deleteUserById: `${baseurl}/api/users/:id`,
    editUser: `${baseurl}/api/users/edit`,
    createUser: `${baseurl}/api/users/add`,
    // Analyse salary distribution:
    analyzeSalaryDiagram: `${baseurl}/api/salaryAnalyze/diagram`,
    analyzeGetSalarySelectionsById: `${baseurl}/api/salaryAnalyze/:id/selection`,
    analyzeSaveSalarySelection: `${baseurl}/api/salaryAnalyze/saveSelection`,
    analyzeDeleteSalarySelection: `${baseurl}/api/salaryAnalyze/selection`,
    analyzeGetSalaryTableData: `${baseurl}/api/salaryAnalyze/table`,
    analyzeSalaryExportPdf: `${baseurl}/api/salaryAnalyze/export/pdf`,
    // Analyse wage gap:
    analyzeGetSavedSelectionsById: `${baseurl}/api/salary/gapMenWomen/registry/:id`,
    analyzeGetSalaryGapGroupsById: `${baseurl}/api/registry/:id/salaryGapGroups`,
    analyzeSelectionDraft: `${baseurl}/api/salary/gapMenWomen/selectionDraft`,
    analyzeAnalysisSelection: `${baseurl}/api/salary/gapMenWomen/analysis`,
    analyzeGetAnalysisSelectionById: `${baseurl}/api/salary/gapMenWomen/analysisSelection/:id`,
    analyzeDeleteWageGapSelectionById: `${baseurl}/api/salary/gapMenWomen/:id`,
    analyzeGetMixedGroupsById: `${baseurl}/api/salary/gapMenWomen/:id/mixedGroups/1`, // This endpoint should be refactured in BE, no /2 etc
    analyzeCommentsForAllGroups: `${baseurl}/api/salary/gapMenWomen/latestCommentForAllGroups`,
    analyzeGetCommentForGroup: `${baseurl}/api/salary/gapMenWomen/:analysisId/group/:groupId/comment/:commentType`,
    analyzeGetAllCommentsForGroup: `${baseurl}/api/salary/gapMenWomen/allcomments/group/:groupId/comment/:commentType`,
    analyzeCommentForGroup: `${baseurl}/api/salary/gapMenWomen/commentForGroup`,
    analyzeExportTableStep3: `${baseurl}/api/wageGapExport/exportTableFromStepThree`,
    analyzeGetAssessmentsById: `${baseurl}/api/salary/gapMenWomen/:id/assessments`,
    analyzeSaveAssessmentGroup: `${baseurl}/api/salary/gapMenWomen/analysis/:id/assessments/`,
    analyzeSaveAssessmentGroupDraft: `${baseurl}/api/salary/gapMenWomen/analysis/:id/assessmentsDraft/`,
    analyzeGetAllAssessmentGroupsById: `${baseurl}/api/salary/gapMenWomen/:id/allAssessmentGroups/`,
    analyzeGetAssessmentGroupById: `${baseurl}/api/salary/gapMenWomen/:id/groupAssessments/`,
    analyzeSalaryIncreaseRegistries: `${baseurl}/api/salary/gapMenWomen/group/:id/salaryIncreaseRegistries`,
    analyzeWageGapRegistryComparison: `${baseurl}/api/Salary/GapMenWomen/Group/:groupId/ComparisonRegistry/:previousRegistryId`,
    analyzeWageGapStepFiveExportCSV: `${baseurl}/api/WageGapExport/ExportTableFromStepFive`,
    analyzeSaveGroupAssessmentsDraft: `${baseurl}/api/salary/gapMenWomen/analysis/:id/groupAssessmentsDraft`,
    analyzeSaveGroupAssessments: `${baseurl}/api/salary/gapMenWomen/analysis/:id/groupAssessments`,
    analyzeGetEquivalentWork: `${baseurl}/api/salary/gapMenWomen/:analysisId/:isNewBesta/:employeeType/equivalentWork`,
    analyzeWageGapStepSixExportCSV: `${baseurl}/api/WageGapExport/exportTableFromStepSix`,
    analyzeWageGapGetMatchingGroupsById: `${baseurl}/api/salary/gapMenWomen/:id/matchingGroups`,
    analyzeWageGapStepSevenExportCSV: `${baseurl}/api/WageGapExport/exportTableFromStepSeven`,
    analyzeWageGapSaveMatchingById: `${baseurl}/api/salary/gapMenWomen/analysis/:id/saveMatchings`,
    // Analyse ComparisonStatistic / Referencestatistics
    analyzeGetCurrentSalarySimulation: `${baseurl}/api/accounts/currentSalarySimulation?GetSimulationStatus=true`,
    analyzeComparisonSavedAnalysis: `${baseurl}/api/registry/:id/comparisonStatisticAnalysis`,
    analyzeGetComparisonStatisticsForAnalysisById: `${baseurl}/api/comparisonStatistic/comparisonStatisticsForAnalysis/CSF/:id/SF`,
    analyzeGetAllRegionsAndBusinessFocusById: `${baseurl}/api/comparisonStatistic/:id/allRegionsAndBusinessFocus`,
    analyzeGetComparisonStatisticsGroups: `${baseurl}/api/comparisonStatistic/groupsForComparisonAnalysis`,
    analyzeGetStatisticsPerAgeGroupById: `${baseurl}/api/comparisonStatistic/statisticsPerAgeGroups/:comparsionStatisticId/:diagType`,
    analyzeGetComparisonStatisticsAnalysisById: `${baseurl}/api/comparisonStatistic/analysis/:id`,
    analyzeSaveComparisonStatistics: `${baseurl}/api/comparisonStatistic/analysis`,
    analyzeDeleteComparisonStatisticsById: `${baseurl}/api/comparisonStatistic/analysis/:id`,
    analyzeComparisonStatisticsTableNData: `${baseurl}/api/comparisonStatistic/analysis/diagramAndTableData`,
    // Analyse Timeseries
    analyzeGetTimeseriesSavedSelections: `${baseurl}/api/timeSeries/selections`,
    analyzeDeleteTimeseriesSavedSelectionById: `${baseurl}/api/timeSeries/:id`,
    analyzeTimeseriesSaveAnalysisSelection: `${baseurl}/api/timeSeries/analysis`,
    analyzeTimeseriesUpdateSelectionById: `${baseurl}/api/timeSeries/analysis/:id`,
    analyzeGetTimeseriesSavedAnalysisById: `${baseurl}/api/timeSeries/analysis/:id`,
    analyzeGetTimeseriesGroups: `${baseurl}/api/timeSeries/groups`,
    analyzeTimeseriesDiagramAndTable: `${baseurl}/api/timeSeries/diagramAndTable`,
    // Analyse salary evolution:
    analyzeGetSalaryEvolutionAnalysesTypes: `${baseurl}/api/salaryDevelopment/analysesTypes`,
    analyzeGetSalaryEvolutionSelectionsById: `${baseurl}/api/salaryDevelopment/:analysisType/selections`,
    analyzeGetSalaryEvolutionGroups: `${baseurl}/api/salaryDevelopment/groups`,
    analyzeGetSalaryEvolutionSelectionById: `${baseurl}/api/salaryDevelopment/analysis/:selectionId`,
    analyzeSaveSalaryEvolutionSelection: `${baseurl}/api/salaryDevelopment/analysis`,
    analyzeSalaryEvolutionGetMatching: `${baseurl}/api/salaryDevelopment/analysis/matching`,
    analyzeDeleteSalaryEvolutionSelectionById: `${baseurl}/api/salaryDevelopment/:selectionId`,
    analyzeSalaryEvolutionDiagramById: `${baseurl}/api/salaryDevelopment/diagramAndTable/analysisType/:analysisType`,
};
};
export default apiEndpoints;

