/* eslint-disable no-return-assign, no-param-reassign */

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { ColDef, RowHeightParams } from "ag-grid-community"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"
import { useMemo, useRef, useState } from "react"

import { dispatchBool, tEquivalentWork, tGapMenWomenMatchingGroup } from "types/sharedTypes"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"
import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"

import MatchingGroupsTableButton from "./MatchingGroupsTableButton"
import MatchingGroupsTableObserve from "./MatchingGroupsTableObserve"

interface IMatchingGroupsTable {
    matchingGroups: tGapMenWomenMatchingGroup[]
    setShowCommentsModal: dispatchBool
    setCurrentGroupInfo: React.Dispatch<React.SetStateAction<{ Name: string; Id: string }>>
}

const MatchingGroupsTable = ({ matchingGroups, setShowCommentsModal, setCurrentGroupInfo }: IMatchingGroupsTable) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const defaultColDef = KeyboardNavigation()

    const gridRef = useRef<AgGridReact<tEquivalentWork>>(null)

    const { S7_TH1, S7_TH2, S7_TH3, S7_TH4, S7_TH5, S7_TH6, S7_TH7, S7_TH8, S7_TH9, S7_TH10 } = ANALYZE_WAGE_GAP

    const [columnDefs] = useState<ColDef[]>([
        { field: "GroupName", headerName: S7_TH1, width: 100, sortable: true, resizable: true },
        { field: "Count", headerName: S7_TH2, width: 80, sortable: true, resizable: true },
        { field: "NumberOfWomanPercent", headerName: S7_TH3, width: 128, sortable: true, resizable: true },
        { field: "WomanSalaryShare", headerName: S7_TH4, width: 160, sortable: true, resizable: true },
        {
            field: "AverageSalary",
            headerName: S7_TH5,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()), // insane how simple and smooth
        },
        {
            field: "MedianPercentile",
            headerName: S7_TH6,
            width: 110,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastFirstTenthPercentile",
            headerName: S7_TH7,
            width: 90,
            sortable: true,
            resizable: true,
            valueFormatter: (params) => thousandSeparator(params.value.toString()),
        },
        { field: "FormattedSumPoints", headerName: S7_TH8, width: 168, sortable: true, resizable: true },
        {
            headerName: S7_TH9,
            width: 120,
            resizable: true,
            cellRenderer: MatchingGroupsTableObserve,
        },
        {
            headerName: S7_TH10,
            width: 170,
            cellRenderer: MatchingGroupsTableButton,
            cellRendererParams: {
                setShowCommentsModal,
                setCurrentGroupInfo,
            },
        },
    ])

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", marginTop: "16px" }}>
            <AgGridReact
                ref={gridRef}
                rowData={matchingGroups}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                localeText={localeText}
                domLayout="autoHeight"
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default MatchingGroupsTable
