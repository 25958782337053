/* eslint-disable react/no-this-in-sfc */

import { tTimeseriesSalaries, tTimeseriesScatterDiagramData } from "types/sharedTypes"

import { useEffect, useState } from "react"

import getSsnAndNameFromTimeSeriesChartData from "utils/Highcharts/getSsnAndNameFromTimeSeriesChartData"

import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

const createTimesseriesScatterDiagramSeries = (data: { Registry: string; Salaries: tTimeseriesSalaries }[]) => {
    // const timeseriesScatterDiagramSeries: tTimeseriesScatterDiagramSeries = []
    const timeseriesScatterDiagramSeries: any[] = []

    // Sorting the data based on the "Registry" date in ascending order
    data.sort((a, b) => {
        const dateA = new Date(a.Registry)
        const dateB = new Date(b.Registry)
        return dateA.getTime() - dateB.getTime()
    })

    data.forEach((timeseriesdataset, index) => {
        const scatterSeriesObjec = {
            id: index,
            name: timeseriesdataset.Registry,
            type: "scatter",
            data: timeseriesdataset.Salaries,
            grouping: false,
            marker: { symbol: "circle" },
        }
        timeseriesScatterDiagramSeries.push(scatterSeriesObjec)
    })

    return timeseriesScatterDiagramSeries
}

type tTimeseriesScatterDiagramProps = {
    diagramData: tTimeseriesScatterDiagramData
    diagramTitle: string
    showMinorGridLines: boolean
    diagramGrouping: { Key: number; Value: string }[]
    selectedGrouping: string
}

const TimeseriesScatterDiagram = ({
    diagramData,
    diagramTitle,
    showMinorGridLines,
    diagramGrouping,
    selectedGrouping,
}: tTimeseriesScatterDiagramProps) => {
    HighchartsMore(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const index = diagramGrouping.findIndex((el) => el.Value === selectedGrouping)

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: "scatter",
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: "Lön",
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            categories: diagramData.Categories,
            title: {
                text: diagramGrouping[index].Value,
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const x = this.x as number
                const y = this.y as number
                const pointX = this.point.options.x as number

                const seriesDef = createTimesseriesScatterDiagramSeries(diagramData.Data)
                const { ssn, name } = getSsnAndNameFromTimeSeriesChartData(seriesDef, pointX, y)

                return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
            },
        },
        series: createTimesseriesScatterDiagramSeries(diagramData.Data),
        credits: {
            enabled: false,
        },
    })

    // Uppdate data dynamically
    useEffect(() => {
        // get pervios options from setState, return copy of it with only property yAxis updated
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])
    useEffect(() => {
        // get pervios options from setState, return copy of it with only dynamic property updated
        setChartOptions((options) => ({
            ...options,
            xAxis: {
                categories: diagramData.Categories,
                title: { text: selectedGrouping },
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    const x = this.x as number
                    const y = this.y as number
                    const pointX = this.point.options.x as number

                    const seriesDef = createTimesseriesScatterDiagramSeries(diagramData.Data)

                    const { ssn, name } = getSsnAndNameFromTimeSeriesChartData(seriesDef, pointX, y)

                    return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.y} kr`
                },
            },
            series: createTimesseriesScatterDiagramSeries(diagramData.Data),
        }))
    }, [diagramData, selectedGrouping])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default TimeseriesScatterDiagram
