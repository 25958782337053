/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react"

import { dispatchBool, rolesObject } from "types/sharedTypes"

export type RolesContent = {
    roles: rolesObject
    setRoles: React.Dispatch<React.SetStateAction<rolesObject>>
    agvAdmin: boolean
    setAgvAdmin: dispatchBool
    agvAdminFetched: boolean
    setAgvAdminFetched: dispatchBool
}

export const rolesInitialValue = {
    mainaccount: false,
    deletemainaccount: false,
    createmainaccount: false,
    registry: false,
    importregistry: false,
    showreport: false,
    groups: false,
    analyze: false,
    analyzesalaryanddistribution: false,
    analyzewagegap: false,
    analyzecomparisonstatistic: false,
    administrateusers: false,
    administratesalarytree: false,
    administrate: false,
    helpandsupport: true,
    importcomparissonfile: false,
    analyzenewsalary: false,
    rolesFetched: false,
}

// const [roles, setRoles] = useState(rolesInitialValue)
// const [agvAdmin, setAgvAdmin] = useState(false)

const RolesContext = createContext<RolesContent>({
    roles: rolesInitialValue,
    setRoles: () => {},
    agvAdmin: false,
    setAgvAdmin: () => false,
    agvAdminFetched: false,
    setAgvAdminFetched: () => false,
})

export default RolesContext
