import "./ValidationErrorsModal.css"

import ReactModal from "react-modal"
import { AgGridReact } from "ag-grid-react"
import { useMemo } from "react"

import { VAL_ERRORS_MODAL_TEXTS } from "constants/text.constants"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import Button from "components/atoms/button/Button"

import { ReactComponent as xMark } from "assets/xmark.icon.svg"

type ValidationErrorsModalProps = {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    errors: string
}

type valErrors = {
    Radnummer: string
    Felmeddelande: string
    "Raden från filen": string
}[]

const ValidationErrorsModal = ({ showModal, setShowModal, errors }: ValidationErrorsModalProps) => {
    const columnDefs = [
        { field: VAL_ERRORS_MODAL_TEXTS.COLUMN1_HEADING, sortable: true, filter: true, resizable: true },
        { field: VAL_ERRORS_MODAL_TEXTS.COLUMN2_HEADING, sortable: true, filter: true, resizable: true },
        { field: VAL_ERRORS_MODAL_TEXTS.COLUMN3_HEADING, sortable: true, flex: 1, filter: true, resizable: true },
    ]

    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const validationErrors: valErrors = []

    // \n between rows and * between columns
    const errorRows = errors.split("\n")
    errorRows.map((row) => {
        const columns = row.split("*")
        return validationErrors.push({
            Radnummer: columns[0],
            Felmeddelande: columns[1],
            "Raden från filen": columns[2],
        })
    })

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={VAL_ERRORS_MODAL_TEXTS.MODAL_CONTENT}
        >
            <div className="headingAndCloseButtonFlex">
                <h1>{VAL_ERRORS_MODAL_TEXTS.HEADING}</h1>
                <Button Icon={xMark} iconRight onClick={() => setShowModal(false)}>
                    {VAL_ERRORS_MODAL_TEXTS.BUTTON_CLOSE}
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ width: "100%", height: "90%", marginTop: "16px" }}>
                <AgGridReact
                    rowData={validationErrors}
                    columnDefs={columnDefs}
                    animateRows
                    rowSelection="multiple"
                    localeText={localeText}
                    alwaysShowHorizontalScroll
                    alwaysShowVerticalScroll
                    suppressMenuHide
                />
            </div>
        </ReactModal>
    )
}

export default ValidationErrorsModal
