import "./Tab.css"

export interface ITab {
    index: number
    activeIndex: number
    setActiveIndex: (index: number) => void
    name: string
}

const Tab = ({ index, activeIndex, setActiveIndex, name }: ITab) => {
    const checkActive = () => (index === activeIndex ? "active" : "")

    return (
        <button type="button" className={`tab ${checkActive()}`} onClick={() => setActiveIndex(index)}>
            {name}
        </button>
    )
}

export default Tab
