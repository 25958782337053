import "./AccordionItem.css"

import { Link, useLocation } from "react-router-dom"

type AccordionItemProps = {
    path: string
    name: string
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export interface IAccordionItem {
    path: string
    name: string
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const AccordionItem = ({ path, name, Icon }: AccordionItemProps) => {
    const { pathname } = useLocation()
    const userOnThisPage = pathname.toLocaleLowerCase().includes(path)

    return (
        <li role="menuitem" className={userOnThisPage ? "accordionItem accordionItemClicked" : "accordionItem"}>
            <Link
                className={userOnThisPage ? "accordionLink accordionLinkClicked" : "accordionLink"}
                to={path}
                onClick={(e) => e.stopPropagation()} // this prevents the whole button containing ul to get clicked
            >
                {!userOnThisPage && <Icon width="1rem" height="1rem" fill="#333333" className="icon" />}
                {userOnThisPage && <Icon width="1rem" height="1rem" fill="#AA0E4C" className="icon" />}
                {name}
            </Link>
        </li>
    )
}

export default AccordionItem
