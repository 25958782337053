import "./SelectMainAccount.css"

import { IMainAccount } from "types/sharedTypes"

interface ISelectMainAccount {
    mainAccountMembers: IMainAccount[]
    setSelectedMainAccountId: React.Dispatch<React.SetStateAction<string>>
    labelText: string
}

const SelectMainAccount = ({ mainAccountMembers, setSelectedMainAccountId, labelText }: ISelectMainAccount) => (
    <div className="selectMemberContainer">
        <label htmlFor="selectMember">{labelText}</label>
        <select
            onChange={(e) => setSelectedMainAccountId(e.target.value)}
            name="selectMember"
            id="selectMember"
            className="selectMainAccountDropdown"
        >
            <option value="none" />
            {mainAccountMembers.map((mainAccount) => (
                <option key={mainAccount.Id} value={mainAccount.Id}>
                    {mainAccount.Name}
                </option>
            ))}
        </select>
    </div>
)

export default SelectMainAccount
