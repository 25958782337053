import { rolesObject } from "types/sharedTypes"

import { ReactComponent as userPlusIcon } from "components/menu/icons/user-plus.icon.svg"
import { ReactComponent as deleteIcon } from "components/menu/icons/trash-can.icon.svg"
import { ReactComponent as editIcon } from "components/menu/icons/pen-to-square.icon.svg"

import { ReactComponent as importIcon } from "components/menu/icons/upload.icon.svg"
import { ReactComponent as documentIcon } from "components/menu/icons/file-lines.icon.svg"

import { ReactComponent as usersIcon } from "components/menu/icons/users.icon.svg"
import { ReactComponent as filterIcon } from "components/menu/icons/filter.icon.svg"
import { ReactComponent as userGearIcon } from "components/menu/icons/user-gear.icon.svg"

import { ReactComponent as lineChartIcon } from "components/menu/icons/line-chart.icon.svg"

import { ReactComponent as userIcon } from "components/menu/icons/user.icon.svg"
import { ReactComponent as sitemapIcon } from "components/menu/icons/sitemap.icon.svg"

import { ReactComponent as bookIcon } from "components/menu/icons/book.icon.svg"
import { ReactComponent as questionmarkIcon } from "components/menu/icons/cirkle-question.icon.svg"
import { ReactComponent as tagsIcon } from "components/menu/icons/tags.icon.svg"
import { ReactComponent as mailIcon } from "components/menu/icons/envelop.icon.svg"

// the idea is to have a dumb menu component and render menu based on what data you provide
function menuBuilder(roles: rolesObject) {
    const userMenu = []
    if (roles.mainaccount) {
        const submenus = []
        if (roles.createmainaccount) submenus.push({ name: "Skapa konto", path: "/account/create", icon: userPlusIcon })
        if (roles.deletemainaccount) submenus.push({ name: "Radera konto", path: "/account/delete", icon: deleteIcon })
        if (roles.createmainaccount)
            submenus.push({ name: "Administrera konto", path: "/account/administer", icon: editIcon })

        userMenu.push({ name: "Huvudanvändarkonto", path: "/account", submenus })
    }
    if (roles.registry) {
        const submenus = []
        if (roles.importregistry)
            submenus.push({ name: "Importera register", path: "/register/import", icon: importIcon })
        if (roles.showreport) submenus.push({ name: "Visa register", path: "/register/show", icon: documentIcon })

        userMenu.push({ name: "Register", path: "/register", submenus })
    }
    if (roles.importcomparissonfile) {
        const submenus = []
        submenus.push({ name: "Importera referensstatistik", path: "/referencestatistics/import", icon: importIcon })
        submenus.push({ name: "Visa referensstatistik", path: "/referencestatistics/show", icon: documentIcon })

        userMenu.push({ name: "Referensstatistik", path: "/referencestatistics", submenus })
    }
    if (roles.groups) {
        const submenus = []
        submenus.push({ name: "Visa grupper", path: "/groups/show", icon: usersIcon })
        submenus.push({ name: "Skapa grupper med filter", path: "/groups/createusingfilters", icon: filterIcon })
        submenus.push({ name: "Skapa grupper manuellt", path: "/groups/createmanually", icon: userGearIcon })

        userMenu.push({ name: "Grupper", path: "/groups", submenus })
    }
    if (roles.analyze) {
        const submenus = []
        if (roles.analyzesalaryanddistribution)
            submenus.push({
                name: "Löneläge & Lönespridning",
                path: "/analyze/salarydistribution",
                icon: lineChartIcon,
            })
        if (roles.analyzewagegap)
            submenus.push({
                name: "Löneskillnader kvinnor & män",
                path: "/analyze/salarydifference",
                icon: lineChartIcon,
            })
        if (roles.analyzecomparisonstatistic)
            submenus.push({
                name: "Referensstatistik",
                path: "/analyze/referencestatistics",
                icon: lineChartIcon,
            })
        // if (roles.analyzenewsalary)
        //     submenus.push({ name: "Nya löner", path: "/analyze/newsalaries", icon: lineChartIcon })
        if (roles.analyzesalaryanddistribution)
            submenus.push({
                name: "Tidsserier",
                path: "/analyze/timeseries",
                icon: lineChartIcon,
            })
        if (roles.analyzesalaryanddistribution)
            submenus.push({
                name: "Löneutveckling",
                path: "/analyze/salaryevolution",
                icon: lineChartIcon,
            })

        userMenu.push({ name: "Analysfunktioner", path: "/analyze", submenus })
    }
    if (roles.administrate) {
        const submenus = []
        if (roles.administrateusers)
            submenus.push({
                name: "Administrera användare",
                path: "/administrate/users",
                icon: userIcon,
            })
        // if (roles.administratesalarytree)
        //     submenus.push({
        //         name: "Löneorganisation",
        //         path: "/administrate/organisation",
        //         icon: sitemapIcon,
        //     })

        userMenu.push({ name: "Administration", path: "/administrate", submenus })
    }
    if (roles.helpandsupport) {
        const submenus = []
        submenus.push({ name: "Handbok", path: "/help/manual", icon: bookIcon })
        submenus.push({ name: "Vanliga frågor & svar", path: "/help/faq", icon: questionmarkIcon })
        submenus.push({ name: "Begrepp & Definitioner", path: "/help/concepts", icon: tagsIcon })
        submenus.push({ name: "Kontakt", path: "/help/contact", icon: mailIcon })

        userMenu.push({ name: "Hjälp & Support", path: "/help", submenus })
    }

    return userMenu
}

export default menuBuilder
