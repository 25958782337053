import apiEndpoints  from "constants/endpoints.constants"
import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { useEffect, useState } from "react"

import { getJSONrequest } from "services/apiService"

import { tSalaryEvolutionAnalysisType } from "types/sharedTypes"

import Error from "components/atoms/error/Error"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

interface ISelectAnalysisType {
    setSelectedAnalysisTypeObject: React.Dispatch<React.SetStateAction<tSalaryEvolutionAnalysisType>>
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const SelectAnalysisType = ({ setSelectedAnalysisTypeObject, setActiveSteps }: ISelectAnalysisType) => {
    const [analysesTypes, setAnalysesTypes] = useState<tSalaryEvolutionAnalysisType[]>([])
    const [selectedAnalysisType, setSelectedAnalysisType] = useState<string[]>([])
    const [fetchingAnalysesTypes, setFetchingAnalysesTypes] = useState(false)
    const [errorFetchingAnalysesTypes, setErrorFetchingAnalysesTypes] = useState("")

    useEffect(() => {
        setFetchingAnalysesTypes(true)
        getJSONrequest(apiEndpoints().analyzeGetSalaryEvolutionAnalysesTypes)
            .then((types: tSalaryEvolutionAnalysisType[]) => {
                setAnalysesTypes(types)
                setFetchingAnalysesTypes(false)
            })
            .catch(() => {
                setErrorFetchingAnalysesTypes(ANALYZE_SALARY_EVOLUTION.ERROR_FETCHING_ANALYSES_TYPES)
            })
    }, [])

    useEffect(() => {
        const keyValueOfSelectedAnalysisType = analysesTypes.find((v) => v.Value === selectedAnalysisType[0])
        if (keyValueOfSelectedAnalysisType) {
            setSelectedAnalysisTypeObject(keyValueOfSelectedAnalysisType)
        } else {
            setSelectedAnalysisTypeObject({
                Key: 0,
                Value: "",
            })
            setActiveSteps([1])
        }
    }, [analysesTypes, selectedAnalysisType, setActiveSteps, setSelectedAnalysisTypeObject])

    return (
        <div>
            <div className="mb32">
                <p className="labelAlike">{ANALYZE_SALARY_EVOLUTION.S1_LABEL}</p>
            </div>
            {fetchingAnalysesTypes && !errorFetchingAnalysesTypes && <ContentSkeleton />}
            {errorFetchingAnalysesTypes && <Error>{errorFetchingAnalysesTypes}</Error>}
            {!fetchingAnalysesTypes && !errorFetchingAnalysesTypes && (
                <FilterRadioButtonsRow selectedFilter={selectedAnalysisType} setSelection={setSelectedAnalysisType}>
                    {analysesTypes.map((v) => v.Value)}
                </FilterRadioButtonsRow>
            )}
        </div>
    )
}

export default SelectAnalysisType
