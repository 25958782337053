// For inspo, or if the page is deleted should be under components -> cell renderer on AgGrids page
// https://www.ag-grid.com/react-data-grid/component-cell-renderer/

import { GROUPS_TEXTS } from "constants/text.constants"
import { fetchEmployeesInAGroup } from "services/fetchers"
import { Employees, Header } from "types/sharedTypes"
import Button from "components/atoms/button/Button"

type SelectedRowObject = {
    Antal: number
    GroupId: string
    Grupp: string
    Grupptyp: string
}

type TableButtonShowProps = {
    data: SelectedRowObject
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setGroupName: React.Dispatch<React.SetStateAction<string>>
    setEmployees: React.Dispatch<React.SetStateAction<Employees[]>>
    setEmployeesTableHeaders: React.Dispatch<React.SetStateAction<Header>>
    setFetchError: React.Dispatch<React.SetStateAction<string>>
    setFetchingEmployeesInAGroup: React.Dispatch<React.SetStateAction<boolean>>
}

const TableButtonShow = ({
    data,
    setShowModal,
    setGroupName,
    setEmployees,
    setEmployeesTableHeaders,
    setFetchError,
    setFetchingEmployeesInAGroup,
}: TableButtonShowProps) => {
    const fetchGroupsContent = (id: string) => {
        setShowModal(true)
        setGroupName(data.Grupp)

        fetchEmployeesInAGroup(id, setEmployees, setEmployeesTableHeaders, setFetchingEmployeesInAGroup, setFetchError)
    }

    return (
        <span>
            <Button onClick={() => fetchGroupsContent(data.GroupId)}>{GROUPS_TEXTS.BUTTON_SHOW_GROUP}</Button>
        </span>
    )
}

export default TableButtonShow
