import "./CommentsModal.css"

import { useEffect, useState } from "react"

import ReactModal from "react-modal"

import apiEndpoints  from "constants/endpoints.constants"
import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import { workGroupComments, tWorkGroupComment, tEquivalentWork } from "types/sharedTypes"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"
import { fetchAllWorkGroupComments, fetchEquivalentWorkGroups, fetchWorkGroupComment } from "services/fetchers"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Select from "components/atoms/select/Select"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"

type ValidationErrorsModalProps = {
    analysisId: string
    groupInfo: { Name: string; Id: string }
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    workType?: "equal" | "equivalent"
    skipAllComments?: boolean
    setHasCommentChanged?: React.Dispatch<React.SetStateAction<boolean>>
    isNewBesta?: boolean
    employeeType?: "ExcludingManagers" | "Managers" | "All"
    setEquivalentWorkGroups?: React.Dispatch<React.SetStateAction<tEquivalentWork[]>>
    setFetchingEquivalentWorkGroups?: React.Dispatch<React.SetStateAction<boolean>>
}

const CommentsModal = ({
    analysisId,
    groupInfo,
    showModal,
    setShowModal,
    workType = "equal",
    skipAllComments = false,
    setHasCommentChanged,
    isNewBesta = true,
    employeeType,
    setEquivalentWorkGroups,
    setFetchingEquivalentWorkGroups,
}: ValidationErrorsModalProps) => {
    const [fetchError, setFetchError] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    // the active comment in the specific group
    const [workGroupComment, setWorkGroupComment] = useState<tWorkGroupComment>({ Comment: null, CommentId: "" })
    const [fetchingWorkGroupComment, setFetchingWorkGroupComment] = useState(true)

    // all comments in the dropdown in the specific group
    const [allWorkGroupComments, setAllWorkGroupComments] = useState<workGroupComments>({ Comments: [] })
    const [fetchingAllWorkGroupComments, setFetchingAllWorkGroupComments] = useState(true)

    const [postingComment, setPostingComment] = useState(false)

    const [commentText, setCommentText] = useState("")
    const [selectedOldComment, setSelectedOldComment] = useState("")

    const saveComment = () => {
        setShowConfirmation(false)
        setPostingComment(true)

        const updatedComments = allWorkGroupComments.Comments.filter((comment) => comment !== "")
        setAllWorkGroupComments({ Comments: updatedComments })

        const body = {
            AnalyseId: analysisId,
            GroupId: groupInfo.Id,
            // GroupName: "string",
            Comment: commentText,
            CommentId: workGroupComment.CommentId,
            CommentType: workType === "equal" ? 1 : 2,
            // LastModified: "2023-02-08T13:56:09.707Z",
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeCommentForGroup, csrfToken, JSON.stringify(body))
                .then(() => {
                    setPostError("")
                    setShowConfirmation(true)
                    setPostingComment(false)
                    // send this new changed state to the parent equalwork page.
                    setHasCommentChanged?.(true)
                    // fetching comments for the specific group and use it in the select element
                    fetchAllWorkGroupComments(groupInfo.Id, workType).then((allComments) => {
                        const updatedComments2 = allComments.Comments.filter((comment) => comment !== "")
                        setAllWorkGroupComments({ Comments: updatedComments2 })
                        setFetchingAllWorkGroupComments(false)
                        setFetchError("")
                    })
                    if (employeeType && setEquivalentWorkGroups) {
                        fetchEquivalentWorkGroups(
                            analysisId,
                            isNewBesta,
                            employeeType,
                            setEquivalentWorkGroups,
                            setFetchingEquivalentWorkGroups,
                            setFetchError
                        )
                    }
                })
                .catch((err) => {
                    setPostingComment(false)
                    setPostError(`${ANALYZE_WAGE_GAP.COMMENTS_MODAL_ERROR}${err.text()}`)
                })
        )
    }

    // when commentsmodal are rendered
    useEffect(() => {
        fetchWorkGroupComment(
            analysisId,
            groupInfo.Id,
            workType,
            setWorkGroupComment,
            setCommentText,
            setFetchingWorkGroupComment,
            setFetchError
        )
    }, [analysisId, groupInfo.Id, workType])

    // when commentsmodal are rendered
    useEffect(() => {
        // In the last step of WageGap there is only one comment allowed on the matching
        if (!skipAllComments) {
            fetchAllWorkGroupComments(
                groupInfo.Id,
                workType
                // setAllWorkGroupComments,
                // setFetchingAllWorkGroupComments,
                // setFetchError
            ).then((allComments) => {
                const updatedComments = allComments.Comments.filter((comment) => comment !== "")
                setAllWorkGroupComments({ Comments: updatedComments })
                setFetchingAllWorkGroupComments(false)
                setFetchError("")
            })
        } else setFetchingAllWorkGroupComments(false)
    }, [groupInfo.Id, workType, skipAllComments])

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={ANALYZE_WAGE_GAP.COMMENTS_MODAL_CONTENT}
        >
            <div className="mb24">
                <div className="headingAndCloseButtonFlex">
                    <h1>{`${ANALYZE_WAGE_GAP.COMMENTS_MODAL_HEADING}${groupInfo.Name}`}</h1>
                    <Button iconRight onClick={() => setShowModal(false)}>
                        {ANALYZE_WAGE_GAP.BUTTON_CLOSE}
                    </Button>
                </div>
            </div>
            {showConfirmation && (
                <div className="commentsConfirmationContainer">
                    <Confirmation>{ANALYZE_WAGE_GAP.COMMENTS_MODAL_CONFIRMATION}</Confirmation>
                </div>
            )}
            {postError && <Error>{postError}</Error>}
            {(fetchingWorkGroupComment || fetchingAllWorkGroupComments) && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {!fetchingWorkGroupComment && !fetchingAllWorkGroupComments && !fetchError && (
                <div>
                    <div className="mb24 maxWidth80vw">
                        {/* <p className="maxWidth800">{ANALYZE_WAGE_GAP.COMMENTS_MODAL_EXPLANATION}</p> */}
                        <textarea
                            id="textareaComments"
                            className="textareaComments"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                        />
                    </div>
                    <div className="flex commentModalButtons">
                        {commentText !== "" && <Button onClick={() => setCommentText("")}>Rensa</Button>}
                        <Button
                            // disabled={commentText === ""}
                            onClick={saveComment}
                            isLoading={postingComment}
                            useCommentBtn
                        >
                            {ANALYZE_WAGE_GAP.BUTTON_SAVE}
                        </Button>
                    </div>
                    {!!allWorkGroupComments.Comments.length && !skipAllComments && (
                        <div className="mb16 commentSelectContainer">
                            <Select
                                label={ANALYZE_WAGE_GAP.COMMENTS_MODAL_LABEL}
                                optionsArray={allWorkGroupComments.Comments}
                                setSelectedOption={setSelectedOldComment}
                                noDefaultValue
                            />
                            {!skipAllComments && selectedOldComment !== "" && selectedOldComment !== "none" && (
                                <Button onClick={() => setCommentText(selectedOldComment)} useCommentBtn>
                                    Använd
                                </Button>
                            )}
                        </div>
                    )}
                    {!skipAllComments && selectedOldComment !== "" && selectedOldComment !== "none" && (
                        <div>
                            <span className="tip tip-up" />
                            <div className="commentArea">
                                <p className="textCommentModal">{selectedOldComment}</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </ReactModal>
    )
}

export default CommentsModal
