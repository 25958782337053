// import "./AnalyzeReferencestatisticsPage.css"

import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import { ANALYZE_REFERENCESTATISTICS } from "constants/text.constants"

import { useContext, useEffect, useMemo, useState } from "react"

import { tComparisonstatisticsAnalysisObject } from "types/sharedTypes"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { RegistriesContext } from "context/RegistriesContext"

import Flow from "components/cores/flow/Flow"
import FlowStep from "components/cores/flow/flowStep/FlowStep"
import PageHeader from "components/cores/pageHeader/PageHeader"

import ReferencestatisticsRegistriesAndSelections from "./step1/ReferencestatisticsRegistriesAndSelections"
import ChooseReferencestatisticsSelection from "./step2/ChooseReferencestatisticsSelection"
import ManageReferencestatisticsSelection from "./step3/ManageReferencestatisticsSelection"
import ReferencestatisticsDiagram from "./step4/ReferencestatisticsDiagram"

// I use ReferenceStatistics and ComparisonStatistics as synonims and mix those here and there
const AnalyzeReferencestatisticsPage = () => {
    const { registries, registriesFetched } = useContext(RegistriesContext)
    const [selectedRegistryId, setSelectedRegistryId] = useState(
        registriesFetched && registries.length ? registries[0].Id : ""
    )

    const [activeStep, setActiveStep] = useState(1)
    const [activeSteps, setActiveSteps] = useState<number[]>([1, 2])

    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([])
    const [selectedReferenceRegisterId, setSelectedReferenceRegisterId] = useState("")
    const [diagramType, setDiagramType] = useState(0)

    const defaultAnalysisObjValues = useMemo(
        () => ({
            statisticsType: "",
            name: "",
            selectionName: "",
            period: "",
            individualsType: "",
            region: { Code: 0, Value: "Alla" },
            businessArea: { Code: 0, Value: "Alla" },
        }),
        []
    )
    const [analysisObject, setAnalysisObject] = useState<tComparisonstatisticsAnalysisObject>(defaultAnalysisObjValues)

    // reset states if we start from step 1
    useEffect(() => {
        if (activeStep === 1) {
            setSelectedGroupIds([])
            setDiagramType(0)
            setAnalysisObject(defaultAnalysisObjValues)
            setActiveSteps([1, 2])
            // reset the state for diagramType on step 2 to avoid state issue for the buttons on step 3 when going back and forward
        } else if (activeStep === 2) {
            setDiagramType(0)
        }
    }, [activeStep, defaultAnalysisObjValues])

    useUpdatePageTitle(pageTitles.ANALYZE_REFERENCESTATISTICS_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.ANALYZE_REFERENCESTATISTICS_PAGE_ID} />
            <div className="mb16">
                <Flow
                    currentStep={activeStep}
                    setCurrentStep={setActiveStep}
                    activeSteps={activeSteps}
                    setActiveSteps={setActiveSteps}
                >
                    <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_REFERENCESTATISTICS.FLOWSTEP_1}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_REFERENCESTATISTICS.FLOWSTEP_2}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_REFERENCESTATISTICS.FLOWSTEP_3}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(4)}>{ANALYZE_REFERENCESTATISTICS.FLOWSTEP_4}</FlowStep>
                </Flow>
            </div>
            {activeStep === 1 && (
                <ReferencestatisticsRegistriesAndSelections
                    selectedRegistryId={selectedRegistryId}
                    setSelectedRegistryId={setSelectedRegistryId}
                    setActiveStep={setActiveStep}
                    setAnalysisObject={setAnalysisObject}
                    setSelectedDiagramType={setDiagramType}
                    setPreselectedGroups={setSelectedGroupIds}
                    setSelectedReferenceRegisterId={setSelectedReferenceRegisterId}
                />
            )}
            {activeStep === 2 && (
                <ChooseReferencestatisticsSelection
                    selectedReferenceRegisterId={selectedReferenceRegisterId}
                    setSelectedReferenceRegisterId={setSelectedReferenceRegisterId}
                    analysisObject={analysisObject}
                    setAnalysisObject={setAnalysisObject}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 3 && !!analysisObject.statisticsType && (
                <ManageReferencestatisticsSelection
                    registryId={selectedRegistryId}
                    setActiveSteps={setActiveSteps}
                    selectedGroups={selectedGroupIds}
                    setSelectedGroups={setSelectedGroupIds}
                    diagramType={diagramType}
                    setDiagramType={setDiagramType}
                    comparisonStaticsticsId={selectedReferenceRegisterId}
                    analysisObject={analysisObject}
                />
            )}
            {activeStep === 4 && !!analysisObject.statisticsType && diagramType && !!selectedGroupIds.length && (
                <ReferencestatisticsDiagram
                    diagramType={diagramType}
                    selectedRegisteryId={selectedRegistryId}
                    analysisObject={analysisObject}
                    selectedGroups={selectedGroupIds}
                    comparisonStaticsticsId={selectedReferenceRegisterId}
                    comparisonAnalysisId="1337"
                />
            )}
        </div>
    )
}

export default AnalyzeReferencestatisticsPage
