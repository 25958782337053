import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"

import { useContext, useEffect, useState } from "react"

import textMatcher from "utils/textMatcher"
import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"
import { fetchAgvAdmin, fetchTexts } from "services/fetchers"

import TextContext from "context/TextContext"
import RolesContext from "context/RolesContext"

import Skeleton from "components/atoms/skeleton/Skeleton"
import EditableText from "components/cores/editableText/EditableText"

const HomePage = () => {
    const { texts, setTexts } = useContext(TextContext)
    const { agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched } = useContext(RolesContext)

    const [fetchingTexts, setFetchingTexts] = useState(!texts.length)
    const [fetchingAgvAdmin, setFetchingAgvAdmin] = useState(!agvAdminFetched)

    const pageId = pageIds.HOME_PAGE_ID

    // Make a custom hook of those two and use here, on PageHeader and ContactUs page
    useEffect(() => {
        if (!texts.length) {
            fetchTexts(setTexts, setFetchingTexts)
        } else setFetchingTexts(false)
    }, [texts, setTexts])

    useEffect(() => {
        if (!agvAdminFetched) {
            fetchAgvAdmin(setAgvAdmin, setFetchingAgvAdmin, setAgvAdminFetched)
        } else setFetchingAgvAdmin(false)
    }, [agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched])

    useUpdatePageTitle(pageTitles.HOME_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="textWrapper">
            {(fetchingAgvAdmin || fetchingTexts) && <Skeleton />}
            {!fetchingAgvAdmin && !fetchingTexts && (
                <>
                    <EditableText
                        text={textMatcher(texts, pageId, "title")}
                        pageId={pageId}
                        elementType="title"
                        canEdit={agvAdmin}
                    />
                    <EditableText
                        pageId={pageId}
                        text={textMatcher(texts, pageId, "paragraph")}
                        elementType="paragraph"
                        canEdit={agvAdmin}
                    />
                </>
            )}
        </div>
    )
}

export default HomePage
