import { textObject } from "types/sharedTypes"

// type XMLresponse = {
//     id: string | null
//     pageId: string | null
//     textId: string | null
//     textValue: string | null
// }

// function matchText(xmlArray: Array<XMLresponse>, pageId: string, type: "title" | "paragraph") {
//     const matchingObject = xmlArray.find((object) => object.pageId === pageId && object.textId === type)
//     return matchingObject?.textValue
// }
type avaliableTextTypes =
    | "title"
    | "paragraph"
    | "agvPhone"
    | "agvMail"
    | "agvParagraph"
    | "knowitPhone"
    | "knowitMail"
    | "knowitParagraph"

function matchText(textArray: Array<textObject>, pageId: string, type: avaliableTextTypes) {
    const matchingObject = textArray.find((object) => object.PageId === pageId && object.TextId === type)
    return matchingObject?.TextValue
}

export default matchText
