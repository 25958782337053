import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

import { FirstDataRenderedEvent, RowClickedEvent } from "ag-grid-community"
import { AgGridReact } from "ag-grid-react"

import { useCallback, useEffect, useRef, useState } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"

import { tSalaryEvolutionAnalysisType, SalaryEvolutionGroup, tSalaryEvolutionSelectionGroup } from "types/sharedTypes"

import { ReactComponent as SaveIcon } from "assets/save.icon.svg"
import { ReactComponent as CleanIcon } from "assets/broom-cleaning-icon.svg"

import Input from "components/atoms/input/Input"
import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import FilterRadioButtonsRow from "components/cores/filterRadioButtonsRow/FilterRadioButtonsRow"

interface IManageSalaryEvolutionSelection {
    selectedAnalysisTypeObject: tSalaryEvolutionAnalysisType
    setSalaryEvolutionGroups: React.Dispatch<React.SetStateAction<SalaryEvolutionGroup[]>>
    salaryEvolutionGroups: SalaryEvolutionGroup[]
    selectedRegistries: Map<string, string>
    selectedGroups: { name: string; ids: string[] }[]
    setSelectedGroups: React.Dispatch<React.SetStateAction<{ name: string; ids: string[] }[]>>
    selectedTypeOfSalary: string[]
    setSelectedTypeOfSalary: React.Dispatch<React.SetStateAction<string[]>>
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    selectedTypeOfPopulation: string[]
    setSelectedTypeOfPopulation: React.Dispatch<React.SetStateAction<string[]>>
    preselectedGroups: tSalaryEvolutionSelectionGroup[] | []
    selection: { name: string; id: string }
    setSelectedGroup: React.Dispatch<React.SetStateAction<{ name: string; count: number }>>
}

type SalaryEvolutionGroupRow = {
    Count: number
    GroupIds: string[]
    GroupType: string
    MainRegistry: string
    Name: string
    IsIn: string
}

const ManageSalaryEvolutionSelection = ({
    selectedAnalysisTypeObject,
    setSalaryEvolutionGroups,
    salaryEvolutionGroups,
    selectedRegistries,
    selectedGroups,
    setSelectedGroups,
    selectedTypeOfSalary,
    setSelectedTypeOfSalary,
    setActiveSteps,
    selectedTypeOfPopulation,
    setSelectedTypeOfPopulation,
    preselectedGroups,
    selection,
    setSelectedGroup,
}: IManageSalaryEvolutionSelection) => {
    const { S3_TH1, S3_TH2, S3_TH3, S3_TH4 } = ANALYZE_SALARY_EVOLUTION
    const [selectionName, setSelectionName] = useState(selection.name)
    const [savingSelection, setSavingSelection] = useState(false)
    const [fetchingSalaryEvolutionGroups, setFetchingSalaryEvolutionGroups] = useState(false)
    const [savedSelection, setSavedSelection] = useState("")
    const [salaryEvolutionGroupRows, setSalaryEvolutionGroupRows] = useState<SalaryEvolutionGroupRow[]>([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const [columnDefs] = useState(
        [
            {
                field: "Name",
                headerName: S3_TH1,
                flex: 1,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                sortable: true,
                filter: true,
            },
            {
                field: "GroupType",
                headerName: S3_TH2,
                flex: 1,
                sortable: true,
                filter: true,
            },
            { field: "Count", headerName: S3_TH3, flex: 0.5, sortable: true, filter: true },
        ].concat(
            selectedAnalysisTypeObject.Key !== 1
                ? []
                : [{ field: "IsIn", headerName: S3_TH4, flex: 0.5, sortable: true, filter: true }]
        )
    )

    const getSalaryType = (salaryType: string) => {
        if (salaryType === "Grundlön") {
            return 1
        }
        if (salaryType === "Fast lön") {
            return 4
        }
        return 5
    }

    const getPopulation = (analysisType: number) => {
        if (analysisType === 1) {
            return selectedTypeOfPopulation[0] === "Kollektiv" ? 1 : 2
        }
        return 0
    }

    const saveSelection = () => {
        setSavingSelection(true)
        const groupIds: string[] = []
        selectedGroups.forEach((group) => group.ids.forEach((id) => groupIds.push(id)))

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                apiEndpoints().analyzeSaveSalaryEvolutionSelection,
                csrfToken,
                JSON.stringify({
                    AnalysisType: selectedAnalysisTypeObject.Key,
                    GroupIds: groupIds,
                    MainRegistryId: "",
                    Name: selectionName,
                    Population: getPopulation(selectedAnalysisTypeObject.Key),
                    RegistryIds: Array.from(selectedRegistries.keys()),
                    SalaryType: getSalaryType(selectedTypeOfSalary[0]),
                })
            )
                .then((response: string) => {
                    setSavedSelection(response)
                    setSavingSelection(false)
                    setShowConfirmation(true)
                    setPostError("")
                })
                .catch((err) => {
                    setSavingSelection(false)
                    if (err.status === 403) {
                        err.text().then((text: string) => {
                            if (text === "UnvalidName")
                                setPostError(ANALYZE_SALARY_EVOLUTION.S3_SAVE_SELECTION_UNVALIDNAME)
                            else setPostError(ANALYZE_SALARY_EVOLUTION.S3_SAVE_SELECTION_ERROR)
                        })
                    } else setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const notChanged = () =>
        !selectionName ||
        !selectedTypeOfSalary[0] ||
        !selectedGroups[0] ||
        (selectedAnalysisTypeObject.Key === 1 && !selectedTypeOfPopulation[0])

    const onSelectionChanged = (event: RowClickedEvent) => {
        const groupIds: { name: string; ids: string[] }[] = []

        const selectedRows = event.api.getSelectedRows()
        selectedRows.forEach((group: SalaryEvolutionGroup) => {
            setSelectedGroup({ name: group.Name, count: group.Count })
            groupIds.push({ name: group.Name, ids: group.GroupIds })
        })
        setSelectedGroups(groupIds)
    }

    const gridRef = useRef<AgGridReact<SalaryEvolutionGroup>>(null)

    const deselectAllRows = () => gridRef.current?.api.forEachNode((node) => node.setSelected(false))

    const onFirstDataRendered = useCallback(
        (params: FirstDataRenderedEvent<SalaryEvolutionGroup>) => {
            // If we come back from step 4
            if (selectedGroups.length) {
                const selectedGroupNames = selectedGroups.map((group) => group.name)
                gridRef.current?.api.forEachNode((node) => {
                    node.setSelected(!!node.data && selectedGroupNames.includes(node.data.Name))
                })
            } else {
                // If we come from step 2 by saved selection
                const preselectedGroupNames: string[] = preselectedGroups.map((group) => group.Value)
                gridRef.current?.api.forEachNode((node) => {
                    node.setSelected(!!node.data && preselectedGroupNames.includes(node.data.Name))
                })
            }
        },
        [preselectedGroups, selectedGroups]
    )

    useEffect(() => {
        if (selectedAnalysisTypeObject.Key === 1) {
            if (selectedGroups?.length > 0 && selectedTypeOfSalary.length > 0 && selectedTypeOfPopulation.length > 0) {
                setActiveSteps([1, 2, 3, 4])
            } else {
                setActiveSteps([1, 2, 3])
            }
        }
        if (selectedAnalysisTypeObject.Key !== 1) {
            if (selectedGroups?.length > 0 && selectedTypeOfSalary.length > 0) {
                setActiveSteps([1, 2, 3, 4])
            } else {
                setActiveSteps([1, 2, 3])
            }
        }
    }, [
        selectedAnalysisTypeObject.Key,
        selectedGroups?.length,
        selectedTypeOfPopulation,
        selectedTypeOfSalary,
        setActiveSteps,
    ])

    useEffect(() => {
        setFetchingSalaryEvolutionGroups(true)
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(
                apiEndpoints().analyzeGetSalaryEvolutionGroups,
                csrfToken,
                JSON.stringify({
                    AnalysisType: selectedAnalysisTypeObject.Key,
                    MainRegistry: "",
                    RegistryIds: Array.from(selectedRegistries.keys()),
                })
            )
                .then((response: SalaryEvolutionGroup[]) => {
                    setSalaryEvolutionGroups(response)
                    setSalaryEvolutionGroupRows(
                        response.map((salaryEvolutionGroup) => ({
                            Name: salaryEvolutionGroup.Name,
                            GroupType: salaryEvolutionGroup.GroupType,
                            Count: salaryEvolutionGroup.Count,
                            GroupIds: salaryEvolutionGroup.GroupIds,
                            MainRegistry: salaryEvolutionGroup.MainRegistry,
                            IsIn:
                                selectedRegistries.size === salaryEvolutionGroup.GroupIds.length
                                    ? "Alla register"
                                    : `${salaryEvolutionGroup.GroupIds.length} av ${selectedRegistries.size} register`,
                        }))
                    )
                    setFetchingSalaryEvolutionGroups(false)
                })
                .catch(() => {
                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                    setFetchingSalaryEvolutionGroups(false)
                })
        )
    }, [selectedAnalysisTypeObject.Key, selectedRegistries, setSalaryEvolutionGroups])

    return (
        <div>
            <p className="labelAlike">{ANALYZE_SALARY_EVOLUTION.S3_LABEL}</p>
            <div className="mb32">
                <p className="flex wrap">
                    <strong> {ANALYZE_SALARY_EVOLUTION.S3_LABEL_ANALYSIS_TYPE} </strong>
                    {selectedAnalysisTypeObject?.Value}
                </p>
                {fetchingSalaryEvolutionGroups && !postError && <ContentSkeleton />}
                {!salaryEvolutionGroups.length && !fetchingSalaryEvolutionGroups && (
                    <p>{ANALYZE_SALARY_EVOLUTION.S3_NO_GROUPS}</p>
                )}
            </div>
            {!!salaryEvolutionGroups.length && !fetchingSalaryEvolutionGroups && (
                <>
                    {selectedAnalysisTypeObject.Key === 1 && (
                        <div className="mb32">
                            <p className="flex wrap">
                                <strong> {ANALYZE_SALARY_EVOLUTION.S3_LABEL_MAIN_REGISTRY} </strong>
                                {salaryEvolutionGroups[0]?.MainRegistry}
                            </p>
                            <p>{ANALYZE_SALARY_EVOLUTION.S3_MAIN_REGISTRY_DESCRIPTION}</p>
                        </div>
                    )}
                    <div className="flex column-gap72 wrap">
                        <div>
                            <p className="labelAlike">{ANALYZE_SALARY_EVOLUTION.S3_SELECT_SALARY_TYPE}</p>
                            <FilterRadioButtonsRow
                                setSelection={setSelectedTypeOfSalary}
                                selectedFilter={selectedTypeOfSalary}
                            >
                                {ANALYZE_SALARY_EVOLUTION.S3_SALARY_TYPE_1}
                                {ANALYZE_SALARY_EVOLUTION.S3_SALARY_TYPE_2}
                                {ANALYZE_SALARY_EVOLUTION.S3_SALARY_TYPE_3}
                            </FilterRadioButtonsRow>
                        </div>
                        {selectedAnalysisTypeObject.Key === 1 && (
                            <div>
                                <p className="labelAlike">{ANALYZE_SALARY_EVOLUTION.S3_SELECT_POPULATION}</p>
                                <FilterRadioButtonsRow
                                    setSelection={setSelectedTypeOfPopulation}
                                    selectedFilter={selectedTypeOfPopulation}
                                >
                                    {ANALYZE_SALARY_EVOLUTION.S3_POPULATION_TYPE_1}
                                    {ANALYZE_SALARY_EVOLUTION.S3_POPULATION_TYPE_2}
                                </FilterRadioButtonsRow>
                            </div>
                        )}
                    </div>
                    <div>
                        <div>
                            <div className="maxWidth300">
                                <label className="gray" htmlFor="createSelection">
                                    {ANALYZE_SALARY_EVOLUTION.S3_LABEL_NAME}
                                </label>
                                <Input
                                    type="text"
                                    id="createSelection"
                                    name="createSelection"
                                    value={selectionName}
                                    onChange={(e) => setSelectionName(e.target.value)}
                                    required
                                />
                            </div>
                            {selectedAnalysisTypeObject.Key === 1 && (
                                <div>
                                    <h3>{ANALYZE_SALARY_EVOLUTION.S3_SELECT_GROUPS}</h3>
                                    <p>{ANALYZE_SALARY_EVOLUTION.S3_GROUPS_DESCRIPTION}</p>
                                </div>
                            )}
                            {selectedAnalysisTypeObject.Key !== 1 && (
                                <div>
                                    <label>{ANALYZE_SALARY_EVOLUTION.S3_SELECT_GROUP}</label>
                                    <p>{ANALYZE_SALARY_EVOLUTION.S3_GROUPS_DESCRIPTION_2}</p>
                                </div>
                            )}
                            {!fetchingSalaryEvolutionGroups && !!salaryEvolutionGroupRows.length && (
                                <div
                                    className="ag-theme-alpine"
                                    style={{ width: "100%", height: "600px", marginBottom: "32px" }}
                                >
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={salaryEvolutionGroupRows}
                                        columnDefs={columnDefs}
                                        rowSelection={selectedAnalysisTypeObject.Key === 1 ? "multiple" : "single"}
                                        onFirstDataRendered={onFirstDataRendered}
                                        onSelectionChanged={onSelectionChanged}
                                        rowMultiSelectWithClick
                                        alwaysShowHorizontalScroll
                                        alwaysShowVerticalScroll
                                        suppressMenuHide
                                    />
                                </div>
                            )}
                            <div>
                                {showConfirmation && (
                                    <div className="mb24">
                                        <Confirmation>
                                            {ANALYZE_SALARY_EVOLUTION.S3_CONFIRMATION_SAVED_SELECTION}
                                        </Confirmation>
                                    </div>
                                )}
                                {postError && (
                                    <div className="mb24">
                                        <Error>{postError}</Error>
                                    </div>
                                )}
                            </div>
                            <div className="flex jc-space-between">
                                <Button
                                    onClick={deselectAllRows}
                                    disabled={selectedGroups.length === 0}
                                    Icon={CleanIcon}
                                >
                                    {ANALYZE_SALARY_EVOLUTION.S2_BUTTON_CLEAR_SELECTION}
                                </Button>
                                <Button
                                    Icon={SaveIcon}
                                    disabled={notChanged()}
                                    onClick={() => saveSelection()}
                                    isLoading={savingSelection}
                                >
                                    {ANALYZE_SALARY_EVOLUTION.S3_BUTTON_SAVE_SELECTION}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ManageSalaryEvolutionSelection
