// import "./ValidationErrorsModal.css"

import ReactModal from "react-modal"

import GENERAL_TEXTS, { HELP_TEXTS } from "constants/text.constants"

import { dispatchBool, dispatchString, ISupportModalTextObject } from "types/sharedTypes"

import { useRef, useState } from "react"

import Button from "components/atoms/button/Button"
import Input from "components/atoms/input/Input"

import convertToComparableString from "./convertToComparableString"

import Editor from "../tinymceEditor/Editor"

type HelpAndSupportModalProps = {
    showModal: boolean
    setShowModal: dispatchBool
    title: string
    setTitle: dispatchString
    content: string
    modalTexts: ISupportModalTextObject
    onClick: (content: string) => void
    posting: boolean
}

// Modal to edit och create a new support item, takes in texts and onClick method
// returns title and modal content value, using tinyMCE editor
const HelpAndSupportModal = ({
    showModal,
    setShowModal,
    title,
    setTitle,
    content,
    modalTexts,
    onClick,
    posting = false,
}: HelpAndSupportModalProps) => {
    const editorRef = useRef<any>(null)

    const [newContent, setNewContent] = useState(content)
    const [originalTitle] = useState(title)

    const handleClick = () => {
        onClick(editorRef.current.getContent())
    }

    return (
        <ReactModal
            appElement={document.getElementById("root") as HTMLElement}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel={modalTexts.modalContent}
        >
            <div className="headingAndCloseButtonFlex mb16">
                <h1>{modalTexts.h1}</h1>
                {/* update button to another */}
                <Button iconRight onClick={() => setShowModal(false)}>
                    {GENERAL_TEXTS.BUTTON_CLOSE}
                </Button>
            </div>

            <label htmlFor={modalTexts.labelTitle}>{modalTexts.labelTitle}</label>
            <Input
                id={modalTexts.labelTitle}
                name={modalTexts.labelTitle}
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                required
            />

            <label>
                <div className="mb16">{modalTexts.labelContent}</div>
                <Editor editorRef={editorRef} initialContentValues={content} setContent={setNewContent} />
            </label>
            <p>{HELP_TEXTS.ALL_FIELDS_REQ}</p>
            <div className="flex gap16">
                <Button
                    isLoading={posting}
                    disabled={
                        (convertToComparableString(newContent) === convertToComparableString(content) &&
                            originalTitle === title.trim()) ||
                        convertToComparableString(newContent) === "" ||
                        title.trim() === ""
                    }
                    onClick={handleClick}
                >
                    {modalTexts.submit}
                </Button>
                <Button onClick={() => setShowModal(false)}>{GENERAL_TEXTS.BUTTON_ABORT}</Button>
            </div>
        </ReactModal>
    )
}

export default HelpAndSupportModal
