import "./BarDiagramIndividual.css"

import { useEffect, useState } from "react"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import { tSalaryEvolutionSeriesData } from "types/sharedTypes"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

import getToolTipInfoFromBarChartData from "utils/Highcharts/getTooltipInfoFromBarChartData"

type DiagramView = {
    Categories: string[]
    SeriesData: tSalaryEvolutionSeriesData[]
}

type BarDiagramProps = {
    diagramView: DiagramView
    showMinorGridLines: boolean
    salaryType: string
}

const createIndividualSeries = (
    sortedSeries: tSalaryEvolutionSeriesData[],
    scatterShort: string[],
    barShort: string[]
) => {
    const seriesIndividual: any = []

    sortedSeries.forEach((s, index) => {
        let barData: any = []
        let scatterData = []
        if (s.BarData) barData = s.BarData
        scatterData = s.PeriodData
        if (barData.length === 0) {
            seriesIndividual.push({
                type: "scatter",
                name: s.PeriodName,
                stacking: null,
                drilldown: s.PeriodName,
                data: s.PeriodData,
                zIndex: 1,
                color: scatterShort[index],
                marker: { symbol: "diamond" },
            })
        } else {
            seriesIndividual.push(
                {
                    type: "bar",
                    name: s.PeriodName,
                    showInLegend: false,
                    stacking: "normal",
                    data: barData,
                    color: barShort[index],
                    borderColor: scatterShort[index],
                },
                {
                    type: "scatter",
                    name: s.PeriodName,
                    drilldown: s.PeriodName,
                    stacking: null,
                    data: scatterData,
                    color: scatterShort[index],
                    zIndex: 1,
                    marker: { symbol: "diamond" },
                }
            )
        }
    })
    return seriesIndividual
}

const BarDiagramIndividual = ({ diagramView, showMinorGridLines, salaryType }: BarDiagramProps) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const sortedSeriesData = diagramView.SeriesData.sort((a, b) => {
        if (a.PeriodName && b.PeriodName) {
            if (a.PeriodName < b.PeriodName) return 1
            if (b.PeriodName < a.PeriodName) return -1
        }
        return 0
    })

    const scatterColor = [
        "rgba(68,68,68,1)",
        "rgba(98,159,105,1)",
        "rgba(57,127,168,1)",
        "rgba(165,81,104,1)",
        "rgba(224,176,154,1)",
        "rgba(151,196,196,1)",
        "rgba(149,118,128,1)",
        "rgba(163,65,43,1)",
        "rgba(4,97,150,1)",
        "rgba(70,110,102,1)",
    ]
    const barColor = [
        "rgba(130,215,140,1)",
        "rgba(189,215,231,1)",
        "rgba(235,175,190,1)",
        "rgba(254,229,217,1)",
        "rgba(200,255,255,1)",
        "rgba(250,235,240,1)",
        "rgba(251,106,74,1)",
        "rgba(107,174,214,1)",
        "rgba(70,170,150,1)",
    ]

    const scatterShort = scatterColor.slice(0, diagramView.SeriesData.length).reverse()
    const barShort = barColor.slice(0, diagramView.SeriesData.length - 1).reverse()

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            type: "bar",
        },
        title: { text: "Löneutveckling per individ" },
        yAxis: {
            title: {
                text: "Löneutveckling i procent",
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            categories: diagramView.Categories,
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                return getToolTipInfoFromBarChartData(diagramView.SeriesData, this, false, salaryType)
            },
        },
        legend: {
            reversed: true,
            itemStyle: {
                cursor: "default",
            },
        },
        series: createIndividualSeries(sortedSeriesData, scatterShort, barShort),
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function legendItemClick() {
                        return false
                    },
                },
                borderColor: "black",
                borderWidth: 1,
            },
        },
        credits: {
            enabled: false,
        },
    })
    useEffect(() => {
        setChartOptions({
            chart: {
                height: diagramView.Categories.length > 10 ? 40 * diagramView.Categories.length : 400,
            },
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
            xAxis: {
                categories: diagramView.Categories,
            },
            series: createIndividualSeries(sortedSeriesData, scatterShort, barShort),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramView, showMinorGridLines])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <p className="individualCounter flex wrap margin0">{diagramView.Categories.length} individer visas</p>
        </div>
    )
}

export default BarDiagramIndividual
