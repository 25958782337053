import "./Accordion.css"

import { ReactComponent as ChevronDownIcon } from "components/menu/icons/chevron-down.icon.svg"

type AccordionProps = {
    name: string
    children: JSX.Element | JSX.Element[]
    isActive: boolean
    onToggle: () => void
    // children: IAccordionItem
}

const Accordion = ({ name, children, isActive, onToggle }: AccordionProps) => (
    <button
        type="button"
        role="menuitem"
        aria-haspopup="true"
        onClick={() => {
            // setExpanded(!isActive)
            onToggle()
        }}
        className={!isActive ? "navAccordion" : "navAccordion expanded"}
    >
        <div className={!isActive ? "navAccordionHeader" : "navAccordionHeader expanded"}>
            <div className="nameContainer">{name}</div>
            <div className="iconContainer">
                {isActive ? (
                    <ChevronDownIcon width="1rem" height="1rem" fill="#aa0e4c" />
                ) : (
                    <ChevronDownIcon width="1rem" height="1rem" fill="#333333" transform="rotate(180)" />
                )}
            </div>
        </div>
        <ul
            className={!isActive ? "list" : "list expanded"}
            aria-hidden={!isActive}
            role="menu"
            aria-label={`${name} submenu`}
        >
            {children}
        </ul>
    </button>
)

export default Accordion
