import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"
import { ANALYZE_TIMESERIES } from "constants/text.constants"

import { useEffect, useState } from "react"

import { tTimeseriesPreselectedGroup } from "types/sharedTypes"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import Flow from "components/cores/flow/Flow"
import FlowStep from "components/cores/flow/flowStep/FlowStep"
import PageHeader from "components/cores/pageHeader/PageHeader"

import TimeseriesRegistriesAndSelections from "./step1/TimeseriesRegistriesAndSelections"
import ManageTimeseriesSelection from "./step2/ManageTimeseriesSelection"
import AnalyseTimeseries from "./step3/AnalyseTimeseries"

const AnalyzeTimeseriesPage = () => {
    const [selectedRegistries, setSelectedRegistries] = useState<Map<string, string>>(new Map())

    const [activeStep, setActiveStep] = useState(1)
    const [activeSteps, setActiveSteps] = useState<number[]>([1])

    const [preselectedGroups, setPreselectedGroups] = useState<tTimeseriesPreselectedGroup[]>([])
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([])
    const [diagramType, setDiagramType] = useState(0)
    const [salaryType, setSalaryType] = useState(0)
    const [mainRegisterName, setMainRegisterName] = useState("")
    const [fetchingSavedAnalysis, setFetchingSavedAnalysis] = useState(false)

    const [selectionObject, setSelectionObject] = useState({ name: "", id: "" })

    // reset states if we start from step 1
    useEffect(() => {
        if (activeStep === 1) {
            setPreselectedGroups([])
            setSelectedGroupIds([])
            setSelectedRegistries(new Map())
            setDiagramType(0)
            setSalaryType(0)
            setActiveSteps([1])
            setSelectionObject({ name: "", id: "" })
        }
    }, [activeStep])

    useUpdatePageTitle(pageTitles.ANALYZE_TIMESERIES_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.ANALYZE_TIMESERIES_PAGE_ID} />
            <div className="mb16">
                <Flow
                    currentStep={activeStep}
                    setCurrentStep={setActiveStep}
                    activeSteps={activeSteps}
                    setActiveSteps={setActiveSteps}
                >
                    <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_TIMESERIES.FLOWSTEP_1}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_TIMESERIES.FLOWSTEP_2}</FlowStep>
                    <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_TIMESERIES.FLOWSTEP_3}</FlowStep>
                </Flow>
            </div>
            {activeStep === 1 && (
                <TimeseriesRegistriesAndSelections
                    selectedRegistries={selectedRegistries}
                    setSelectedRegistries={setSelectedRegistries}
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                    setPreselectedGroups={setPreselectedGroups}
                    setSelectedDiagramType={setDiagramType}
                    setSelectedSalaryType={setSalaryType}
                    setSelection={setSelectionObject}
                    fetchingSavedAnalysis={fetchingSavedAnalysis}
                    setFetchingSavedAnalysis={setFetchingSavedAnalysis}
                />
            )}
            {activeStep === 2 && !!selectedRegistries.size && !fetchingSavedAnalysis && (
                <ManageTimeseriesSelection
                    selectedRegistries={selectedRegistries}
                    setActiveSteps={setActiveSteps}
                    preselectedGroups={preselectedGroups}
                    selectedGroups={selectedGroupIds}
                    setSelectedGroups={setSelectedGroupIds}
                    diagramType={diagramType}
                    setDiagramType={setDiagramType}
                    salaryType={salaryType}
                    setSalaryType={setSalaryType}
                    savedSelection={selectionObject}
                    setMainRegisterName={setMainRegisterName}
                    mainRegisterName={mainRegisterName}
                />
            )}
            {activeStep === 3 && !!diagramType && !!salaryType && !!selectedGroupIds.length && (
                <AnalyseTimeseries
                    salaryType={salaryType}
                    selectedRegistries={selectedRegistries}
                    diagramType={diagramType}
                    selectedGroups={selectedGroupIds}
                    mainRegisterName={mainRegisterName}
                />
            )}
        </div>
    )
}

export default AnalyzeTimeseriesPage
