import "./FilterRadioButtonsRow.css"

import React from "react"

import FilterRadioButton from "components/atoms/filterRadioButton/FilterRadioButton"

type FilterRadioButtonsRowProps = {
    children: string[]
    selectedFilter: string[]
    setSelection: React.Dispatch<React.SetStateAction<string[]>>
}

const FilterRadioButtonsRow = ({ children, setSelection, selectedFilter }: FilterRadioButtonsRowProps) => {
    const handleOnClick = (identifier: string) => {
        if (selectedFilter) {
            selectedFilter.splice(0, 1, identifier)
            setSelection([...selectedFilter])
        }
    }

    return (
        <div className="filterRadioButtonsRow" role="radiogroup">
            {children.map((radioButton: string) => (
                <FilterRadioButton
                    key={radioButton}
                    onClick={() => handleOnClick(radioButton)}
                    label={radioButton}
                    checked={selectedFilter[0] === radioButton}
                />
            ))}
        </div>
    )
}

export default FilterRadioButtonsRow
