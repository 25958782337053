import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"
import { ValueFormatterParams } from "ag-grid-community"

import { useEffect, useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"

import { ANALYZE_SALARY_DISTRIBUTION } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

export type tGroupPercentileData = {
    RegistryPeriod: null
    GroupId: string
    SalaryType: { Key: number; Value: string }
    GroupType: { Key: number; Value: string }
    Name: string
    Count: number
    AverageSalary: number
    MinSalary: number
    FirstTenthPercentile: number
    FirstQuarterPercentile: number
    MedianPercentile: number
    LastQuarterPercentile: number
    LastTenthPercentile: number
    MaxSalary: number
    LastFirstTenthPercentile: string
    LastTenthMedianPercentile: string
    MedianFirstTenthPercentile: string
}

interface IGroupPercentilesTable {
    groupPercentileData: tGroupPercentileData[]
    showMinMax: boolean
}

const GroupPercentilesTable = ({ groupPercentileData, showMinMax }: IGroupPercentilesTable) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const { S3_TH1, S3_TH2, S3_TH3, S3_TH4, S3_TH5, S3_TH6, S3_TH7, S3_TH8, S3_TH9, S3_TH10, S3_TH11, S3_TH12 } =
        ANALYZE_SALARY_DISTRIBUTION

    // check formating through ag grid instead
    // groupPercentileData.forEach((datapoint) => {
    //     if (typeof datapoint === "number") datapoint = thousandSeparator(datapoint)
    // })

    const defaultColumnDefs = [
        { field: "Name", headerName: S3_TH1, width: 100, sortable: true, resizable: true },
        { field: "SalaryType.Value", headerName: S3_TH2, width: 150, sortable: true, resizable: true },
        { field: "Count", headerName: S3_TH3, width: 100, sortable: true, resizable: true },
        {
            field: "FirstTenthPercentile",
            headerName: S3_TH4,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "FirstQuarterPercentile",
            headerName: S3_TH5,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "MedianPercentile",
            headerName: S3_TH6,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastQuarterPercentile",
            headerName: S3_TH7,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastTenthPercentile",
            headerName: S3_TH8,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "AverageSalary",
            headerName: S3_TH9,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastFirstTenthPercentile",
            headerName: S3_TH10,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "LastTenthMedianPercentile",
            headerName: S3_TH11,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
        {
            field: "MedianFirstTenthPercentile",
            headerName: S3_TH12,
            width: 100,
            sortable: true,
            resizable: true,
            valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
        },
    ]
    const [columnDefs, setColumnDefs] = useState(defaultColumnDefs)

    const { S3_TH_MIN, S3_TH_MAX } = ANALYZE_SALARY_DISTRIBUTION
    useEffect(() => {
        // We add "MinSalary" and "MaxSalary" fields
        if (showMinMax) {
            setColumnDefs([
                { field: "Name", headerName: S3_TH1, width: 100, sortable: true, resizable: true },
                { field: "SalaryType.Value", headerName: S3_TH2, width: 150, sortable: true, resizable: true },
                { field: "Count", headerName: S3_TH3, width: 100, sortable: true, resizable: true },
                {
                    field: "MinSalary",
                    headerName: S3_TH_MIN,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "FirstTenthPercentile",
                    headerName: S3_TH4,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "FirstQuarterPercentile",
                    headerName: S3_TH5,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "MedianPercentile",
                    headerName: S3_TH6,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "LastQuarterPercentile",
                    headerName: S3_TH7,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "LastTenthPercentile",
                    headerName: S3_TH8,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "MaxSalary",
                    headerName: S3_TH_MAX,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "AverageSalary",
                    headerName: S3_TH9,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "LastFirstTenthPercentile",
                    headerName: S3_TH10,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "LastTenthMedianPercentile",
                    headerName: S3_TH11,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
                {
                    field: "MedianFirstTenthPercentile",
                    headerName: S3_TH12,
                    width: 100,
                    sortable: true,
                    resizable: true,
                    valueFormatter: (params: ValueFormatterParams) => thousandSeparator(params.value.toString()),
                },
            ])
        } else setColumnDefs(defaultColumnDefs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMinMax])

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", maxHeight: "50vh" }}>
            <AgGridReact
                rowData={groupPercentileData}
                columnDefs={columnDefs}
                localeText={localeText}
                domLayout="autoHeight"
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default GroupPercentilesTable
