import "./TimeseriesRegistriesAndSelections.css"

import { useContext, useEffect, useState } from "react"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { ANALYZE_TIMESERIES, REGISTER_TEXTS } from "constants/text.constants"

import {
    dispatchBool,
    dispatchNumber,
    tTimeseriesAnalysis,
    tTimeseriesPreselectedGroup,
    tTimeseriesSavedSelection,
} from "types/sharedTypes"

import { deleteWithBodyAndCsrf } from "services/apiService"
import { fetchRegistries, fetchTimeseriesSavedAnalysis, fetchTimeseriesSavedSelections } from "services/fetchers"

import { RegistriesContext } from "context/RegistriesContext"

import idMapper from "utils/idMapper"
import prepareCsrfToken from "utils/prepareCsrfToken"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import Warning from "components/atoms/warning/Warning"
import Confirmation from "components/atoms/confirmation/Confirmation"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import MultiselectDropdown from "components/cores/multiselectDropdown/MultiselectDropdown"
import MoreInformationModal from "components/cores/moreInformationModal/MoreInformationModal"

interface ITimeseriesRegistriesAndSelections {
    selectedRegistries: Map<string, string>
    setSelectedRegistries: React.Dispatch<React.SetStateAction<Map<string, string>>>
    setActiveStep: dispatchNumber
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedDiagramType: dispatchNumber
    setSelectedSalaryType: dispatchNumber
    setPreselectedGroups: React.Dispatch<React.SetStateAction<tTimeseriesPreselectedGroup[]>>
    setSelection: React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
    fetchingSavedAnalysis: boolean
    setFetchingSavedAnalysis: dispatchBool
}

const TimeseriesRegistriesAndSelections = ({
    selectedRegistries,
    setSelectedRegistries,
    setActiveStep,
    setActiveSteps,
    setPreselectedGroups,
    setSelectedDiagramType,
    setSelectedSalaryType,
    setSelection,
    fetchingSavedAnalysis,
    setFetchingSavedAnalysis,
}: ITimeseriesRegistriesAndSelections) => {
    const { registries, setRegistries, registriesFetched, setRegistriesFetched } = useContext(RegistriesContext)
    const [noRegisters, setNoRegisters] = useState(!registries.length && registriesFetched)

    const [fetchError, setFetchError] = useState("")

    const [fetchingRegistries, setFetchingRegistries] = useState(!registries.length)
    const [savedSelections, setSavedSelections] = useState<tTimeseriesSavedSelection[]>([])
    const [fetchingSavedSelections, setFetchingSavedSelections] = useState(true)

    const [currentSelectionObj, setCurrentSelection] = useState({
        Name: "",
        Id: "",
        Registries: [""],
        Groups: [{ Key: 0, Value: "" }],
        Created: "",
    })

    const [savedAnalysis, setSavedAnalysis] = useState<tTimeseriesAnalysis>()

    const [showModal, setShowModal] = useState(false)

    const [showWarning, setShowWarning] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [postError, setPostError] = useState("")

    const tooFewRegisters = registries.length < 2

    const handleOpenMoreInfoModal = (selection: tTimeseriesSavedSelection) => {
        setCurrentSelection(selection)
        setShowModal(true)
    }

    const handleDeleteSelection = (selection: tTimeseriesSavedSelection) => {
        setCurrentSelection(selection)
        setShowWarning(true)
    }

    const deleteSelection = (Id: string) => {
        prepareCsrfToken().then((csrfToken) =>
            deleteWithBodyAndCsrf(
                idMapper(apiEndpoints().analyzeDeleteTimeseriesSavedSelectionById, Id),
                csrfToken,
                JSON.stringify({})
            )
                .then(() => {
                    setSavedSelections((prevState) => {
                        const index = prevState.findIndex((item) => item.Id === Id)
                        if (index > -1) {
                            prevState.splice(index, 1)
                        }
                        return prevState
                    })
                    setPostError("")
                    setShowWarning(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setShowWarning(false)
                    if (err) setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                })
        )
    }

    const handleChooseSelection = (id: string, name: string) => {
        setFetchingSavedAnalysis(true)

        fetchTimeseriesSavedAnalysis(
            id,
            setSavedAnalysis,
            setPreselectedGroups,
            setSelectedRegistries,
            setSelectedDiagramType,
            setSelectedSalaryType,
            setFetchingSavedAnalysis,
            setFetchError
        )
        setSelection({ name, id })

        setActiveStep(2)
    }

    useEffect(() => {
        if (selectedRegistries.size > 1 && selectedRegistries.size < 21) setActiveSteps([1, 2])
        else setActiveSteps([1])
    }, [selectedRegistries, setActiveSteps])

    useEffect(() => {
        if (!registries.length && !registriesFetched)
            fetchRegistries(
                setRegistries,
                setNoRegisters,
                undefined,
                setFetchingRegistries,
                setRegistriesFetched,
                setFetchError
            )
    }, [registries, setRegistries, registriesFetched, setRegistriesFetched])

    useEffect(() => {
        if (!noRegisters) fetchTimeseriesSavedSelections(setSavedSelections, setFetchingSavedSelections, setFetchError)
        if (noRegisters) setFetchingSavedSelections(false)
    }, [noRegisters])

    return (
        <div>
            {(fetchingSavedSelections || fetchingRegistries || fetchingSavedAnalysis) &&
                !fetchError &&
                !noRegisters && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {noRegisters && <p>{REGISTER_TEXTS.NO_REGISTERS}</p>}
            {registries && !noRegisters && registriesFetched && (
                <div>
                    <h2>{ANALYZE_TIMESERIES.S1_H2}</h2>
                    {tooFewRegisters && registriesFetched && <p>{ANALYZE_TIMESERIES.S1_TOO_FEW_REGISTRIES}</p>}
                    {!tooFewRegisters && (
                        <div className="mb32">
                            <MultiselectDropdown
                                selectedOptions={selectedRegistries}
                                setSelectedOptions={setSelectedRegistries}
                                options={registries}
                                label={ANALYZE_TIMESERIES.S1_MULTISELECT_LABEL}
                            />
                        </div>
                    )}
                    {showWarning && (
                        <Warning
                            onClick={() => deleteSelection(currentSelectionObj.Id)}
                            showWarning={setShowWarning}
                            variant="delete"
                        >
                            {`${ANALYZE_TIMESERIES.S1_WARNING_DELETE_SELECTION} ${currentSelectionObj.Name}`}
                        </Warning>
                    )}
                    {showConfirmation && (
                        <Confirmation>{ANALYZE_TIMESERIES.S1_CONFIRMATION_DELETED_SELECTION}</Confirmation>
                    )}
                    {postError && <Error>{postError}</Error>}
                    {!savedSelections.length && !fetchingSavedSelections && !tooFewRegisters && (
                        <p>{ANALYZE_TIMESERIES.S1_NO_SELECTIONS}</p>
                    )}
                    {!!savedSelections.length && !fetchingSavedSelections && !tooFewRegisters && (
                        <div>
                            <h3>{ANALYZE_TIMESERIES.S1_H3}</h3>
                            <table className="timeseriesSelectionsTable">
                                <thead>
                                    <tr>
                                        <th className="th-bb-1">{ANALYZE_TIMESERIES.S1_TH1}</th>
                                        <th className="th-bb-1">{ANALYZE_TIMESERIES.S1_TH2}</th>
                                        <th className="th-bb-1 timeseriesCellWidth260">{ANALYZE_TIMESERIES.S1_TH3}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {savedSelections.map((selection) => (
                                        <tr key={selection.Id}>
                                            <td>{selection.Name}</td>
                                            <td>{selection.Created}</td>
                                            <td>
                                                <div className="flex gap8">
                                                    <Button small onClick={() => handleOpenMoreInfoModal(selection)}>
                                                        {ANALYZE_TIMESERIES.S1_BUTTON_MORE_INFO}
                                                    </Button>
                                                    <Button
                                                        small
                                                        onClick={() =>
                                                            handleChooseSelection(selection.Id, selection.Name)
                                                        }
                                                    >
                                                        {ANALYZE_TIMESERIES.S1_BUTTON_CHOOSE}
                                                    </Button>
                                                    <Button
                                                        variant="delete"
                                                        small
                                                        onClick={() => handleDeleteSelection(selection)}
                                                    >
                                                        {ANALYZE_TIMESERIES.S1_BUTTON_DELETE}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {showModal && (
                                <MoreInformationModal
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    registries={currentSelectionObj.Registries}
                                    groups={currentSelectionObj.Groups}
                                    name={currentSelectionObj.Name}
                                    created={currentSelectionObj.Created}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default TimeseriesRegistriesAndSelections
