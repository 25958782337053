import { useState, useEffect, useRef, useMemo } from "react"
import { AgGridReact } from "ag-grid-react"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { ANALYZE_SALARY_EVOLUTION } from "constants/text.constants"

interface ISalaryEvolutionType2Table {
    tableRows: string[][]
    showKr?: boolean
}

const SalaryEvolutionType2Table = ({ tableRows, showKr = false }: ISalaryEvolutionType2Table) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const { S4_TABLE2_TH1, S4_TABLE2_TH2, S4_TABLE2_TH3, S4_TABLE2_TH1_SUFFIX_KR, S4_TABLE2_TH1_SUFFIX_PERCENT } =
        ANALYZE_SALARY_EVOLUTION

    const TH1_SUFFIX = showKr ? S4_TABLE2_TH1_SUFFIX_KR : S4_TABLE2_TH1_SUFFIX_PERCENT

    const defaultColumnDefs = [
        {
            field: "Interval",
            headerName: `${S4_TABLE2_TH1} ${TH1_SUFFIX}`,
            flex: 1,
            sortable: true,
        },
        { field: "Amount", headerName: S4_TABLE2_TH2, flex: 1, sortable: true },
        { field: "AmountPercentage", headerName: S4_TABLE2_TH3, flex: 1, sortable: true },
    ]

    const [columnDefs, setColumnDefs] = useState(defaultColumnDefs)
    const gridApi = useRef<any>(null)

    // im doing this to update the headerNames in the tables. ag-grid stores the first version which makes it difficult to update
    // using useRef and .current.api we can get around this. see below
    useEffect(() => {
        if (gridApi.current) {
            const columnApi = gridApi.current.api

            if (columnApi && columnApi.setColumnDefs) {
                // Update headerName dynamically
                const updatedDefs = columnDefs.map((def) => {
                    if (def.field === "Interval") {
                        return {
                            ...def,
                            headerName: `${S4_TABLE2_TH1} ${TH1_SUFFIX}`,
                        }
                    }
                    return def
                })

                columnApi.setColumnDefs(updatedDefs)
            }
        }
    }, [showKr, S4_TABLE2_TH1, TH1_SUFFIX, columnDefs])

    const onGridReady = (params: any) => {
        gridApi.current = params
    }

    const tableRowData = tableRows.map((row) => {
        const tableRow = {
            Interval: row[0],
            Amount: row[1],
            AmountPercentage: row[2],
        }

        return tableRow
    })

    return (
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <AgGridReact
                rowData={tableRowData}
                columnDefs={columnDefs}
                localeText={localeText}
                domLayout="autoHeight"
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
                ref={gridApi}
                onGridReady={onGridReady}
            />
        </div>
    )
}

export default SalaryEvolutionType2Table
