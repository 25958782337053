import "./Flow.css"

import { cloneElement, Children } from "react"

import GENERAL_TEXTS from "constants/text.constants"

import { dispatchNumber } from "types/sharedTypes"

import Button from "components/atoms/button/Button"

import { ReactComponent as arrowLeft } from "./arrow-left-solid.svg"
import { ReactComponent as arrowRight } from "./arrow-right-solid.svg"

type FlowProps = {
    children: JSX.Element[]
    onClick?: () => void
    currentStep: number
    setCurrentStep: dispatchNumber
    activeSteps: number[]
    setActiveSteps: React.Dispatch<React.SetStateAction<number[]>>
}

const Flow = ({ children, onClick, currentStep, setCurrentStep, activeSteps, setActiveSteps }: FlowProps) => {
    const showBackButton = currentStep !== 1
    const showForwardButton = currentStep !== children.length

    const rowRewerse = !showBackButton ? "row-reverse" : ""
    const disabled = activeSteps.slice(-1)[0] - currentStep <= 0

    const handleForward = () => setCurrentStep((prevValue) => prevValue + 1)
    const handleBack = () => setCurrentStep((prevValue) => prevValue - 1)

    const checkDisabled = (step: number) => !activeSteps.includes(step)
    const checkActive = (step: number) => currentStep === step

    return (
        <div className="flow">
            <div className="flowStepsContainer">
                {Children.map(children, (child, index) =>
                    cloneElement(child, {
                        isActive: checkActive(index + 1),
                        isDisabled: checkDisabled(index + 1),
                    })
                )}
            </div>
            <div className={`flowStepButtonsFlex ${rowRewerse}`}>
                {showBackButton && (
                    <Button onClick={handleBack} Icon={arrowLeft}>
                        {GENERAL_TEXTS.BUTTON_BACK}
                    </Button>
                )}
                {showForwardButton && (
                    <Button onClick={handleForward} iconRight Icon={arrowRight} disabled={disabled}>
                        {GENERAL_TEXTS.BUTTON_FORWARD2}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default Flow
