import { IboxplotDiagramData } from "types/sharedTypes"

type tGroupAndRegistryMatch = { Key: string; Value: { Key: string; Value: string } }

type tDiagDataRow = [
    {
        YAxisTitle: string
        ID: string
        Categories: string[]
        Data: any
    }
]

type tDiagramData = {
    Name: string
    Data: any
    GroupType: { Key: number; Value: string }
    Period: { Key: string; Value: string }
}

export interface ItimeseriesBoxplotDiagramData extends IboxplotDiagramData {
    Categories2: [{ Key: string; Value: string }]
}

function prepareTimeseriesBoxplotDiagramData(
    unpreparedData: string[],
    groupTypes: { Key: number; Value: string }[],
    groupAndRegistryMatch: tGroupAndRegistryMatch[],
    chartTitle: string,
    yAxisTitle: string
) {
    const getPeriod = (ID: string, groupAndRegistryMatchData: tGroupAndRegistryMatch[]) => {
        let period = { Key: "", Value: "" }
        const index = groupAndRegistryMatchData.findIndex((element) => element.Key === ID)
        if (index !== -1) period = groupAndRegistryMatchData[index].Value
        return period
    }

    const diagData: tDiagramData[] = []
    unpreparedData.forEach((row, index) => {
        const result: tDiagDataRow = JSON.parse(row)
        diagData.push({
            Name: result[0].Categories[0],
            Data: result[0].Data,
            GroupType: groupTypes[index],
            Period: getPeriod(result[0].ID, groupAndRegistryMatch),
        })
    })

    const sortedDiagData = diagData.sort((a, b) => {
        const nameCompare = a.Name.localeCompare(b.Name)
        if (nameCompare !== 0) {
            return nameCompare
        }
        const periodCompare = b.Period.Value.localeCompare(a.Period.Value)
        if (periodCompare !== 0) {
            return periodCompare
        }
        if (
            (a.GroupType.Key === 3 && b.GroupType.Key === 4) ||
            (a.GroupType.Key === 3 && b.GroupType.Key === 2) ||
            (a.GroupType.Key === 3 && b.GroupType.Key === 1) ||
            (a.GroupType.Key === 4 && b.GroupType.Key === 2) ||
            (a.GroupType.Key === 4 && b.GroupType.Key === 1) ||
            (a.GroupType.Key === 2 && b.GroupType.Key === 1)
        ) {
            return -1
        }
        if (a.GroupType.Key === b.GroupType.Key) {
            return 0
        }
        return 1
    })

    const preparedDiagramData: ItimeseriesBoxplotDiagramData = {
        ChartTitle: chartTitle,
        YAxisTitle: yAxisTitle,
        Data: [],
        Categories: [],
        Categories2: [{ Key: "", Value: "" }],
    }

    sortedDiagData.forEach((group, index) => {
        preparedDiagramData.Categories.push(group.Name)
        preparedDiagramData.Categories2.push(group.Period)
        group.Data.forEach((arr: any[]) => {
            arr.splice(0, 0, index)
            arr.push(group.Period)
            preparedDiagramData.Data.push(arr)
        })
    })

    preparedDiagramData.Categories2.splice(0, 1)

    return preparedDiagramData
}

export default prepareTimeseriesBoxplotDiagramData
