import "./Editor.css"

import { Editor as TinyEditor } from "@tinymce/tinymce-react"

// import { ReactComponent as xMark } from "./xmark.icon.svg"

type EditorProps = {
    editorRef: any
    initialContentValues?: string
    setContent?: (value: string) => void
}

const Editor = ({ editorRef, initialContentValues = "", setContent }: EditorProps) => (
    <div>
        <TinyEditor
            tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
            // eslint-disable-next-line no-return-assign, no-param-reassign
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={initialContentValues}
            init={{
                statusbar: false,
                content_css: false,
                menubar: true,
                placeholder: "Skriv innehåll här...",
                language: "sv_SE",
                plugins: "image media link code editimage lists",
                toolbar:
                    "image | link | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                file_picker_callback(cb, value, meta) {
                    const input = document.createElement("input")
                    input.setAttribute("type", "file")
                    input.onchange = function () {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const file = input.files!
                        const reader = new FileReader()

                        // FormData
                        const fd = new FormData()

                        fd.append("filetype", meta.filetype)
                        fd.append("file", file[0])

                        const filename = ""

                        reader.onload = function (e) {
                            cb(filename)
                        }
                        reader.readAsDataURL(file[0])
                    }

                    input.click()
                },
                promotion: false,
                // setup for registration of changes to enable/disable change button
                setup: (editor) => {
                    editor.on("input", () => {
                        // using setContent to pass the new content to the parent (the modal)
                        setContent?.(editor.getContent())
                    })
                },
            }}
        />
    </div>
)
export default Editor
