// Get all info for the tooltip for each point in a scatterdiagram.
// This method is used SalaryEvolutionScatterDiagram and possible could have been used in several places
function getSsnAndNameFromDataSimple(seriesArray: [number, number, string, string][], x: number, y: number) {
    const result = { ssn: "", name: "" }

    if (x === -1 || y === -1) return result // something went wrong

    seriesArray.forEach((individual) => {
        if (individual[0] === x && individual[1] === y) {
            result.ssn = individual[2] as string
            result.name = individual[3] as string
        }
    })

    return result
}

export default getSsnAndNameFromDataSimple
