import "./ForgotPasswordPage.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { LOGIN_TEXTS } from "constants/text.constants"
import pageTitles from "constants/pageTitles.constants.json"

import { useState } from "react"

import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { forgotPasswordValidationSchema } from "utils/yupSchemas"

import Error from "components/atoms/error/Error"
import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"
import Confirmation from "components/atoms/confirmation/Confirmation"

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState("")
    const [postError, setPostError] = useState("")
    const [showEmailConfirmation, setShowEmailConfirmation] = useState(false)
    const [validationError, setValidationError] = useState(false)
    const [resettingPassword, setResettingPassword] = useState(false)

    const handelSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        setResettingPassword(true)
        e.preventDefault()
        const bodyJson = { email }

        forgotPasswordValidationSchema
            .validate(bodyJson, { abortEarly: false })
            .then(() => {
                fetch(apiEndpoints().resetPassword, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(bodyJson),
                })
                    .then((response) => {
                        if (response.ok) return response
                        return Promise.reject(response)
                    })
                    .then(() => {
                        setPostError("")
                        setShowEmailConfirmation(true)
                        setResettingPassword(false)
                    })
                    .catch(() => {
                        setPostError(LOGIN_TEXTS.FORGOT_PASSWORD_POST_ERROR)
                    })
            })
            .catch(() => {
                setValidationError(true)
                setResettingPassword(false)
            })
    }

    useUpdatePageTitle(pageTitles.FORGOT_PASSWORD_PAGE_TITLE)

    return (
        <div className="maxWidth1000">
            <div className="forgotPasswordPageHeader">
                <h1>{LOGIN_TEXTS.FORGOT_PASSWORD_H1}</h1>
                <p>{LOGIN_TEXTS.FORGOT_PASSWORD_EXPLANATION}</p>
            </div>
            {postError && <Error>{postError}</Error>}
            {showEmailConfirmation && (
                <Confirmation>{`${LOGIN_TEXTS.FORGOT_PASSWORD_EMAIL_SENT} ${email}`}</Confirmation>
            )}
            {showEmailConfirmation && (
                <div>
                    <p>{LOGIN_TEXTS.FORGOT_PASSWORD_EMAIL_SENT_EXPL_P1}</p>
                    <p>{LOGIN_TEXTS.FORGOT_PASSWORD_EMAIL_SENT_EXPL_P2}</p>
                    <p>{LOGIN_TEXTS.FORGOT_PASSWORD_EMAIL_SENT_EXPL_P3}</p>
                    <section className="forgotPasswordPanel">
                        <div className="forgotPasswordContactUsHead">
                            <h2 className="forgotPasswordContactUsPanelTitle">
                                {LOGIN_TEXTS.FORGOT_PASSWORD_HEADING_TECHNICAL_SUPPORT}
                            </h2>
                        </div>
                        <div className="padding12">
                            <p>{LOGIN_TEXTS.FORGOT_PASSWORD_CONTACT_KNOWIT}</p>
                            <span className="span">{LOGIN_TEXTS.FORGOT_PASSWORD_KNOWIT_TEL}</span>
                            <span className="span">{LOGIN_TEXTS.FORGOT_PASSWORD_KNOWIT_EMAIL}</span>
                        </div>
                    </section>
                </div>
            )}
            {!postError && !showEmailConfirmation && (
                <div className="containerForgotPassword">
                    <form onSubmit={handelSubmitForm}>
                        <label htmlFor="email">{LOGIN_TEXTS.FORGOT_PASSWORD_LABEL}</label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={LOGIN_TEXTS.FORGOT_PASSWORD_PLACEHOLDER}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            isErroneous={validationError}
                            errorMessage={GENERAL_TEXTS.VALIDATION_EMAIL}
                        />

                        <div className="FPbutton">
                            <Button isLoading={resettingPassword} type="submit">
                                {LOGIN_TEXTS.BUTTON_SEND}
                            </Button>
                        </div>
                    </form>
                    <p>{LOGIN_TEXTS.FORGOT_PASSWORD_INFOTEXT}</p>
                </div>
            )}
        </div>
    )
}

export default ForgotPasswordPage
