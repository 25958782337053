// import "./ReferencestatisticsDiagram.css"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, {
    ANALYZE_REFERENCESTATISTICS,
    ANALYZE_SALARY_DISTRIBUTION,
    ANALYZE_WAGE_GAP,
} from "constants/text.constants"

import { useState, useEffect, useMemo, useContext, useRef, CSSProperties } from "react"

import prepareCsrfToken from "utils/prepareCsrfToken"
import getAmountMenWomen from "utils/Highcharts/getAmountMenWomen"
import prepareBoxplotDiagramWithComparison from "utils/Highcharts/prepareBoxplotDiagramWithComparison"

import { postWithBodyAndCsrf } from "services/apiService"

import { tComparisonstatisticsAnalysisObject, tComparisonstatisticsDiagramAndTableData } from "types/sharedTypes"

import { RegistriesContext } from "context/RegistriesContext"

import Error from "components/atoms/error/Error"
import Button from "components/atoms/button/Button"
import SliderButton from "components/atoms/sliderButton/SliderButton"
import ContentSkeleton from "components/atoms/contentSkeleton/ContentSkeleton"
import BoxplotDiagram from "components/cores/highcharts/boxplot/BoxplotDiagram"

import { ReactComponent as PDFIcon } from "assets/pdf.icon.svg"

import RefstatPercentilesTable from "./refstatPercentilesTable/RefstatPercentilesTable"
import ComparisonScatterDiagram from "./refstatScatterDiagram/ComparisonScatterDiagram"
import RefStatScatterTable from "./refstatScatterTable/RefStatScatterTable"
import RefstatPercentilesExportTable from "./refstatPercentilesTable/RefstatPercentilesExportTable"
import RefstatScatterExportTable from "./refstatScatterTable/RefStatScatterExportTable"

interface IReferencestatisticsDiagram {
    diagramType: number
    selectedRegisteryId: string
    analysisObject: tComparisonstatisticsAnalysisObject
    selectedGroups: string[]
    comparisonStaticsticsId: string
    comparisonAnalysisId: string
}

const ReferencestatisticsDiagram = ({
    diagramType,
    selectedRegisteryId,
    analysisObject,
    selectedGroups,
    comparisonStaticsticsId,
    comparisonAnalysisId,
}: IReferencestatisticsDiagram) => {
    const { registries } = useContext(RegistriesContext)
    const selectedRegisterName =
        registries[registries.findIndex((register) => register.Id === selectedRegisteryId)].Name

    const [fetchError, setFetchError] = useState("")
    const [postError, setPostError] = useState("")

    const [tableRows, setTableRows] = useState<any[]>([])
    const [fetchingTableRows, setFetchingTableRows] = useState(true)

    const [diagramData, setDiagramData] = useState<any>([])
    const [comparisonDiagramData, setComparisonDiagramData] = useState<any>([])
    const [fetchingDiagramData, setFetchingDiagramData] = useState(true)

    const [downloadingPDF, setDownloadingPDF] = useState(false)

    const [showMinorGridLines, setShowMinorGridLines] = useState(true)

    const [groupName, setGroupName] = useState("")

    const salaryTypes = useMemo(() => [null, "Grundlön", null, null, "Fast lön", "Totallön"], [])

    const allRef = useRef<HTMLDivElement>(null)

    const styles: { [key: string]: CSSProperties } = {
        textStyle: {
            display: "flex",
            flexWrap: "wrap",
            marginTop: 0,
            marginBottom: 0,
            paddingRight: 8,
        },
    }
    // The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
    // We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
    // When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
    const downloadPDF = () => {
        const html: any = []
        let string = ""
        setDownloadingPDF(true)
        setPostError("")

        if (allRef.current) {
            const parent: { id: string }[] = [...allRef.current.children]
            parent.forEach((element) => {
                if (element.id !== "excludeFromPDF") html.push(element)
            })
            html.forEach((el: any) => {
                string += el.outerHTML
            })

            prepareCsrfToken().then((csrfToken) =>
                postWithBodyAndCsrf(apiEndpoints().analyzeSalaryExportPdf, csrfToken, JSON.stringify({ Htmltext: string }), true)
                    .then((response) => {
                        setDownloadingPDF(false)
                        const filename = "Referensstatistik.pdf"

                        response.blob().then((blob: any) => {
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement("a")
                            link.setAttribute("href", url)
                            link.setAttribute("download", filename)
                            document.body.appendChild(link)
                            link.click()
                        })
                    })
                    .catch(() => {
                        setDownloadingPDF(false)
                        setPostError(GENERAL_TEXTS.ERROR_TEXT_SAVING)
                    })
            )
        }
    }

    // Get Diagram data
    useEffect(() => {
        const body = {
            DiagramType: diagramType,
            GroupIds: selectedGroups,
            Region:
                analysisObject.statisticsType !== ("Medlemsanpassad referensstatistik" || "Medlemsanpassad")
                    ? analysisObject.region.Code
                    : "",
            BusinessFocus:
                analysisObject.statisticsType !== ("Medlemsanpassad referensstatistik" || "Medlemsanpassad")
                    ? analysisObject.businessArea.Code
                    : "",
            ComparisonStatisticId: comparisonStaticsticsId,
            ComparisonAnalysisId: comparisonAnalysisId,
        }

        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(apiEndpoints().analyzeComparisonStatisticsTableNData, csrfToken, JSON.stringify(body))
                .then((response: tComparisonstatisticsDiagramAndTableData) => {
                    if (diagramType === 1 && response.AnalysisDiagramView.Diagram.Value === "Lådagram") {
                        const chartTitle = ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_TITLE
                        const yAxisTitle = ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_YAXIS_TITLE

                        const [preparedDiagramData, preparedComparisonData] = prepareBoxplotDiagramWithComparison(
                            response.AnalysisDiagramView.DiagramData,
                            chartTitle,
                            yAxisTitle,
                            selectedGroups
                        )
                        setDiagramData(preparedDiagramData)
                        setComparisonDiagramData(preparedComparisonData)
                    }

                    if (diagramType === 2 && response.AnalysisDiagramView.Diagram.Value === "Punktdiagram") {
                        const empData = JSON.parse(response.AnalysisDiagramView.DiagramData[0])
                        const refStatistikData = JSON.parse(response.AnalysisDiagramView.DiagramData[1])
                        const menWomen = getAmountMenWomen(empData[0])

                        const diagramBData = {
                            Catagories: empData[0].Catagories,
                            SalariesMen: empData[0].SalariesMen,
                            SalariesWomen: empData[0].SalariesWomen,
                            Amounts: menWomen,
                            P10: refStatistikData[0].Salaries[0],
                            P25: refStatistikData[0].Salaries[1],
                            P75: refStatistikData[0].Salaries[2],
                            P90: refStatistikData[0].Salaries[3],
                            xAxisTitle: ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_XAXIS_TITLE,
                        }
                        setGroupName(response.TableViews[0].BestaGroup)
                        setDiagramData(diagramBData)
                    }
                    setTableRows(response.TableViews)
                    setFetchingTableRows(false)
                    setFetchingDiagramData(false)
                    setFetchError("")
                })
                .catch(() => {
                    setFetchingDiagramData(false)
                    setFetchingTableRows(false)
                    setFetchError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                })
        )
    }, [analysisObject, comparisonAnalysisId, comparisonStaticsticsId, diagramType, selectedGroups])

    return (
        <>
            {fetchingDiagramData && fetchingTableRows && !fetchError && <ContentSkeleton />}
            {fetchError && <Error>{fetchError}</Error>}
            {postError && (
                <div style={{ marginBottom: 16 }}>
                    <Error>{postError}</Error>
                </div>
            )}
            {!fetchingDiagramData && !fetchingTableRows && !fetchError && (
                <div ref={allRef}>
                    <h2>{ANALYZE_REFERENCESTATISTICS.S4_H2}</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 8, marginBottom: 32 }}>
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>
                                {ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_REGISTER}
                            </strong>
                            {selectedRegisterName}
                        </p>
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>{ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_PERIOD}</strong>
                            {analysisObject.period}
                        </p>
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>
                                {ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_SALARY_TYPE}
                            </strong>
                            {salaryTypes[4]}
                        </p>
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>
                                {ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_EMPLOYEE_TYPE}
                            </strong>
                            {analysisObject.individualsType}
                        </p>
                        {diagramType === 2 && groupName && (
                            <p style={styles.textStyle}>
                                <strong style={{ paddingRight: 4 }}>
                                    {ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_GROUP}
                                </strong>
                                {groupName}
                            </p>
                        )}
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>{ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_REGION}</strong>
                            {analysisObject.region.Value}
                        </p>
                        <p style={styles.textStyle}>
                            <strong style={{ paddingRight: 4 }}>
                                {ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_BUSINESS_FOCUS}
                            </strong>
                            {analysisObject.businessArea.Value}
                        </p>
                    </div>
                    <div id="excludeFromPDF" className="flex wrap gap36 mb16 jc-space-between ai-felx-start">
                        <div className="flex gap20 ai-felx-start">
                            <div className="sliderButtonsContainer">
                                <SliderButton checked={showMinorGridLines} setChecked={setShowMinorGridLines}>
                                    {ANALYZE_SALARY_DISTRIBUTION.S3_SLIDER_MINOR_GRID}
                                </SliderButton>
                            </div>
                        </div>
                        <Button Icon={PDFIcon} onClick={downloadPDF} isLoading={downloadingPDF}>
                            {ANALYZE_WAGE_GAP.DIAGRAM_MODAL_EXPORT_PDF}
                        </Button>
                    </div>
                    {diagramType === 1 && !fetchingDiagramData && !!diagramData.Data.length && (
                        <BoxplotDiagram
                            diagramData={diagramData}
                            showMinorGridLines={showMinorGridLines}
                            comparisonData={comparisonDiagramData}
                        />
                    )}
                    {diagramType === 2 && !fetchingDiagramData && !!diagramData.Catagories.length && (
                        <>
                            <div style={{ marginBottom: 24 }}>
                                <ComparisonScatterDiagram
                                    diagramData={diagramData}
                                    diagramTitle={ANALYZE_REFERENCESTATISTICS.S4_DIAGRAM_TITLE}
                                    showMinorGridLines={showMinorGridLines}
                                />
                            </div>
                            <div id="excludeFromPDF">
                                <RefStatScatterTable
                                    tableData={tableRows}
                                    amounts={diagramData.Amounts}
                                    salaries={{ Men: diagramData.SalariesMen, Women: diagramData.SalariesWomen }}
                                />
                            </div>
                            <div className="invisible">
                                <RefstatScatterExportTable
                                    tableData={tableRows}
                                    amounts={diagramData.Amounts}
                                    salaries={{ Men: diagramData.SalariesMen, Women: diagramData.SalariesWomen }}
                                />
                            </div>
                        </>
                    )}
                    {tableRows && (
                        <div id="excludeFromPDF" className="mb48">
                            <RefstatPercentilesTable
                                percentileTableData={tableRows}
                                showAgeGroups={diagramType === 2}
                            />
                        </div>
                    )}
                    {tableRows && (
                        <div className="invisible">
                            <RefstatPercentilesExportTable percentileTableData={tableRows} showAgeGroups={diagramType === 2}/>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default ReferencestatisticsDiagram
