/* eslint-disable no-return-assign, no-param-reassign */

import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridReact } from "ag-grid-react"
import { RowHeightParams } from "ag-grid-community"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"
import { useEffect, useMemo, useRef, useState } from "react"

import { dispatchBool, tGapMenWomenAssessmentGroup, tGroupAssessmentsArray } from "types/sharedTypes"
import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import KeyboardNavigation from "utils/keyboardNavigation/KeyboardNavigation"
import EquivalentWorkValueTableSelect from "./EquivalentWorkValueTableSelect"

interface ISavedSelectionsTable {
    assessmentGroups: tGapMenWomenAssessmentGroup[]
    clearValues: boolean
    setClearValues: dispatchBool
    setGroupAssessmentsArray: React.Dispatch<React.SetStateAction<tGroupAssessmentsArray>>
}

const EquivalentWorkValueTable = ({
    assessmentGroups,
    clearValues,
    setClearValues,
    setGroupAssessmentsArray,
}: ISavedSelectionsTable) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])

    const { S5_TH1, S5_TH2, S5_TH3, S5_THLAST } = ANALYZE_WAGE_GAP
    const assessmentNames = assessmentGroups[0].GroupAssessments.map((assessment) => assessment.AssessmentText)

    type tAdjustedAssessmentGroup = tGapMenWomenAssessmentGroup & { WeightedValue?: number }
    const [adjustedAssessmentGroups, setAdjustedAssessmentsGroups] =
        useState<tAdjustedAssessmentGroup[]>(assessmentGroups)
    const gridRef = useRef<AgGridReact<tAdjustedAssessmentGroup>>(null)

    const defaultColDef = KeyboardNavigation()

    // type tDefaultColDefs = {
    //     field: string
    //     headerName: string
    //     width: number
    //     resizable: boolean
    //     pinned: string
    //     flex?: number
    //     editable?: boolean
    //     cellRenderer?: any
    //     cellRendererParams?: any
    // }[]
    // const defaultColDefs: tDefaultColDefs = [
    const defaultColDefs: any = [
        { field: "GroupName", headerName: S5_TH1, width: 100, resizable: true, pinned: "left" },
        { field: "Count", headerName: S5_TH2, width: 100, resizable: true, pinned: "left" },
        { field: "NumberOfWomanPercent", headerName: S5_TH3, width: 150, resizable: true, pinned: "left" },
    ]
    assessmentNames.forEach((Name) =>
        defaultColDefs.push({
            field: "",
            headerName: Name,
            width: 150,
            resizable: true,
            cellRenderer: EquivalentWorkValueTableSelect,
            cellRendererParams: { Name, adjustedAssessmentGroups, setAdjustedAssessmentsGroups, clearValues },
        })
    )
    defaultColDefs.push({
        field: "FormattedWeightedValue",
        headerName: S5_THLAST,
        width: 170,
        resizable: true,
        pinned: "right",
    })

    const [columnDefs] = useState(defaultColDefs)

    adjustedAssessmentGroups.forEach((group) => {
        let weightedSum = 0
        group.GroupAssessments.forEach(
            (assessment) => (weightedSum += +assessment.Value * 100 * (+assessment.AssessmentWeight * 100))
        )
        group.WeightedValue = weightedSum / 10000
    })

    // adding a decimal to the weightedvalue and adding it to the new formatted version
    adjustedAssessmentGroups.forEach((group) => {
        group.FormattedWeightedValue = group.WeightedValue?.toFixed(1)
    })

    gridRef.current?.api?.setColumnDefs(defaultColDefs) // f-n magic

    const IdsOflastGroupsInBestaGrouping = [assessmentGroups[0].GroupId]
    const bestaGroupLevels = [assessmentGroups[0].GroupLevel]
    assessmentGroups.forEach((group) => {
        if (group.GroupLevel === bestaGroupLevels.at(-1)) {
            IdsOflastGroupsInBestaGrouping[IdsOflastGroupsInBestaGrouping.length - 1] = group.GroupId
        } else {
            IdsOflastGroupsInBestaGrouping.push(group.GroupId)
            bestaGroupLevels.push(group.GroupLevel)
        }
    })
    // eslint-disable-next-line consistent-return
    const getRowStyle = (params: any) => {
        if (IdsOflastGroupsInBestaGrouping.includes(params.data.GroupId)) {
            return { borderBottom: "10px solid #e6e6e6" }
        }
    }
    const getRowHeight = (params: RowHeightParams) => {
        let rowContentHeight = 50
        if (IdsOflastGroupsInBestaGrouping.includes(params.data.GroupId)) rowContentHeight = 60
        return rowContentHeight
    }

    useEffect(() => {
        setGroupAssessmentsArray(
            adjustedAssessmentGroups.map((group) => ({
                GroupId: group.GroupId,
                GroupAssessmentId: group.GroupAssessmentId,
                AssessmentValues: group.GroupAssessments.map((GroupAssessment) => ({
                    AssessmentId: GroupAssessment.AssessmentId,
                    AssessmentValueId: GroupAssessment.AssessmentValueId,
                    Value: GroupAssessment.Value,
                    GroupName: group.GroupName,
                })),
            }))
        )
    }, [adjustedAssessmentGroups, setGroupAssessmentsArray])

    useEffect(() => {
        if (clearValues) {
            const clearAssessments = [...adjustedAssessmentGroups]
            clearAssessments.forEach((group) =>
                group.GroupAssessments.forEach((assessment) => {
                    assessment.Value = 0
                })
            )
            setAdjustedAssessmentsGroups(clearAssessments)
            setClearValues(false)
        }
    }, [adjustedAssessmentGroups, clearValues, setClearValues])

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "50vh", marginTop: "16px" }}>
            <AgGridReact
                ref={gridRef}
                rowData={adjustedAssessmentGroups}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                localeText={localeText}
                getRowStyle={getRowStyle}
                getRowHeight={getRowHeight}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default EquivalentWorkValueTable
