import { AgGridReact } from "ag-grid-react"

import { useMemo } from "react"

import { AG_GRID_LOCALE_SV } from "utils/locales/ag-grid.locale.sv"

import { tSalaryEvolutionSeriesData } from "types/sharedTypes"

interface ISalaryEvolutionType1Table {
    seriesData: tSalaryEvolutionSeriesData[]
    tableRows: string[][]
    isIndividual?: boolean
    salaryType?: string
}

const SalaryEvolutionType1Table = ({
    seriesData,
    tableRows,
    isIndividual = false,
    salaryType = "",
}: ISalaryEvolutionType1Table) => {
    const localeText = useMemo(() => AG_GRID_LOCALE_SV, [])
    const tableHeaders: string[] = seriesData.map((data) => data.PeriodName)
    tableHeaders.sort()
    const tableHeaderSection =
        tableHeaders.length < 2
            ? []
            : [...tableHeaders].slice(1).map((tableHeader, index) => ({
                  headerName: tableHeader,
                  resizable: true,
                  children: [
                      {
                          field: `Kr${index}`,
                          headerName: "Δ Kr",
                          width: 85,
                          resizable: true,
                      },
                      {
                          field: `Procent${index}`,
                          headerName: "Δ %",
                          width: 85,
                          resizable: true,
                      },
                      {
                          field: `Medellön${index}`,
                          headerName: salaryType || "Medellön",
                          width: 100,
                          resizable: true,
                      },
                  ],
              }))

    const preHeaders = isIndividual
        ? [
              {
                  field: "Pnr",
                  width: 150,
                  resizable: true,
                  pinned: true,
              },
              {
                  field: "Namn",
                  width: 100,
                  resizable: true,
                  pinned: true,
              },
              {
                  field: "Anst",
                  width: 80,
                  resizable: true,
              },
              {
                  headerName: tableHeaders[0],
                  resizable: true,
                  children: [
                      {
                          field: salaryType,
                          width: 100,
                          resizable: true,
                      },
                  ],
              },
          ]
        : [
              {
                  field: "Grupp",
                  width: 100,
                  resizable: true,
              },
              {
                  field: "Antal",
                  width: 85,
                  resizable: true,
              },
              {
                  headerName: tableHeaders[0],
                  resizable: true,
                  children: [
                      {
                          field: "Medellön",
                          width: 100,
                          resizable: true,
                      },
                  ],
              },
          ]

    const postHeaders = [
        {
            headerName: "Totalt",

            resizable: true,
            children: [
                {
                    field: "KrTotalt",
                    headerName: "Δ Kr",
                    width: 85,
                    resizable: true,
                },
                {
                    field: "ProcentTotalt",
                    headerName: "Δ %",
                    width: 85,
                    resizable: true,
                },
            ],
        },
    ]

    const columnDefs = preHeaders.concat(tableHeaderSection).concat(postHeaders)

    function dividePeriodData(originalArray: string[]) {
        const dividedPeriodRow = []
        const periodSize = 3
        for (let i = 0; i < originalArray.length; i += periodSize) {
            const period = originalArray.slice(i, i + periodSize)
            dividedPeriodRow.push(period)
        }

        return dividedPeriodRow
    }

    const tableRowData = tableRows.map((row) => {
        const rowCopy = [...row]
        const rowInfo = isIndividual ? rowCopy.splice(0, 3) : rowCopy.splice(0, 2)
        const firstPeriod = rowCopy.splice(0, 1)
        const total = rowCopy.splice(-2)
        const periods = dividePeriodData(rowCopy)

        const tableRow: any = isIndividual
            ? {
                  Pnr: rowInfo[0],
                  Namn: rowInfo[1],
                  Anst: rowInfo[2],
                  [salaryType]: firstPeriod,
                  KrTotalt: total[0],
                  ProcentTotalt: total[1],
              }
            : {
                  Grupp: rowInfo[0],
                  Antal: rowInfo[1],
                  Medellön: firstPeriod,
                  KrTotalt: total[0],
                  ProcentTotalt: total[1],
              }

        if (row.length > 5 && !isIndividual) {
            for (let i = 0; i < tableHeaders.length - 1; i += 1) {
                const increaseKr = periods[i][0]
                const increaseProcentage = periods[i][1]
                const salary = periods[i][2]

                tableRow[`Kr${i}`] = increaseKr
                tableRow[`Procent${i}`] = increaseProcentage
                tableRow[`Medellön${i}`] = salary
            }
        }
        if (row.length > 5 && isIndividual) {
            for (let i = 0; i < tableHeaders.length - 1; i += 1) {
                const increaseKr = periods[i][0]
                const increaseProcentage = periods[i][1]
                const salary = periods[i][2]

                tableRow[`Kr${i}`] = increaseKr
                tableRow[`Procent${i}`] = increaseProcentage
                tableRow[`Medellön${i}`] = salary
            }
        }

        return tableRow
    })

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "400px", marginBottom: "64px" }}>
            <AgGridReact
                rowData={tableRowData}
                columnDefs={columnDefs}
                localeText={localeText}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
            />
        </div>
    )
}

export default SalaryEvolutionType1Table
