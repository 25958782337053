import { tTimeseriesScatterDiagramSeries } from "types/sharedTypes"

function getSsnAndNameFromTimeSeriesChartData(seriesArray: tTimeseriesScatterDiagramSeries, x: any, y: number) {
    const result = { ssn: "", name: "" }

    seriesArray.forEach((series) => {
        series.data.forEach((dataset) => {
            if (dataset[0] === x && dataset[1] === y) {
                result.ssn = dataset[2] as string
                result.name = dataset[3] as string
            }
        })
    })

    return result
}

export default getSsnAndNameFromTimeSeriesChartData
