import "./PageHeader.css"

import { useContext, useEffect, useState } from "react"

import TextContext from "context/TextContext"
import RolesContext from "context/RolesContext"

import textMatcher from "utils/textMatcher"
import { fetchAgvAdmin, fetchTexts } from "services/fetchers"

import Skeleton from "components/atoms/skeleton/Skeleton"
import EditableText from "components/cores/editableText/EditableText"

const PageHeader = ({ pageId }: { pageId: string }) => {
    const { texts, setTexts } = useContext(TextContext)
    const { agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched } = useContext(RolesContext)

    const [fetchingTexts, setFetchingTexts] = useState(!texts.length)
    const [fetchingAgvAdmin, setFetchingAgvAdmin] = useState(!agvAdminFetched)

    useEffect(() => {
        if (!texts.length) {
            fetchTexts(setTexts, setFetchingTexts)
        } else setFetchingTexts(false)
    }, [texts, setTexts])

    useEffect(() => {
        if (!agvAdminFetched) {
            fetchAgvAdmin(setAgvAdmin, setFetchingAgvAdmin, setAgvAdminFetched)
        } else setFetchingAgvAdmin(false)
    }, [agvAdmin, setAgvAdmin, agvAdminFetched, setAgvAdminFetched])

    return (
        <div className="pageHeader">
            {(fetchingAgvAdmin || fetchingTexts) && <Skeleton />}
            {!fetchingAgvAdmin && !fetchingTexts && (
                <>
                    <EditableText
                        text={textMatcher(texts, pageId, "title")}
                        pageId={pageId}
                        elementType="title"
                        canEdit={agvAdmin}
                    />
                    <EditableText
                        pageId={pageId}
                        text={textMatcher(texts, pageId, "paragraph")}
                        elementType="paragraph"
                        canEdit={agvAdmin}
                    />
                </>
            )}
        </div>
    )
}

export default PageHeader
