import "./TableBESTA.css"

import { CSSProperties } from "react"

import { REGISTER_TEXTS } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"

type TableBESTAProps = {
    data: string[][]
    caption: string
    forManagers?: boolean
    bestaExtras?: {
        noClassificationCounts: number
        notSuitableCodeCount: number
        secretaryGeneralCount: number
    }
}

// The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
// We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
// When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
const styles: { [key: string]: CSSProperties } = {
    tableBESTACaption: {
        textAlign: "left",
    },
    tableBESTAStyle: {
        borderSpacing: 0,
        borderCollapse: "collapse",
        textAlign: "left",
        height: "fit-content",
        width: 200,
    },
    tableBESTABodyTD: {
        border: "1px solid #ddd",
        borderSpacing: 0,
        padding: 8,
    },
    tableBESTAHeadTrTh: {
        padding: 8,
        background: "#ddd",
        border: "1px solid #ddd",
        borderSpacing: 0,
        fontFamily: "Open Sans Bold",
    },
}

const TableBESTA = ({ data, bestaExtras, caption, forManagers = false }: TableBESTAProps) => (
    <table className="TableBESTAStyle" style={styles.tableBESTAStyle}>
        <caption className="TableBESTACaption" style={styles.tableBESTACaption}>
            {caption}
        </caption>
        <thead className="TableBESTAHeadStyle">
            <tr style={styles.tableBESTAHeadTrTh}>
                <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_TH1}</th>
                <th style={styles.tableBESTAHeadTrTh} colSpan={forManagers ? 4 : 6}>
                    {REGISTER_TEXTS.T_BESTA_TH2}
                </th>
                <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_TH3}</th>
            </tr>
            {!forManagers && (
                <tr style={styles.tableBESTAHeadTrTh}>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.EMPTY_CELL}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_ONE}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_TWO}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_THREE}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_FOUR}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_FIVE}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_EMPLOYEES_SIX}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.EMPTY_CELL}</th>
                </tr>
            )}
            {forManagers && (
                <tr style={styles.tableBESTAHeadTrTh}>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.EMPTY_CELL}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_MANAGERS_ONE}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_MANAGERS_TWO}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_MANAGERS_THREE}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.T_BESTA_MANAGERS_FOUR}</th>
                    <th style={styles.tableBESTAHeadTrTh}>{REGISTER_TEXTS.EMPTY_CELL}</th>
                </tr>
            )}
        </thead>
        <tbody>
            {data.map((categories) => (
                <tr key={`${categories}${Math.random() * 1600}`}>
                    {categories.map((arrayElement) => (
                        <td style={styles.tableBESTABodyTD} key={`${arrayElement}${Math.random() * 1600}`}>
                            {thousandSeparator(arrayElement)}
                        </td>
                    ))}
                </tr>
            ))}
            {!forManagers && bestaExtras && (
                <>
                    <tr>
                        <td className="BESTAextras" style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.T_BESTA_EMPLOYEES_GENERAL_COUNT}
                        </td>
                        <td colSpan={6} style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.EMPTY_CELL}
                        </td>
                        <td style={styles.tableBESTABodyTD}>{bestaExtras.secretaryGeneralCount}</td>
                    </tr>
                    <tr>
                        <td className="BESTAextras" style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.T_BESTA_EMPLOYEES_NO_CLASSIFICATION}
                        </td>
                        <td colSpan={6} style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.EMPTY_CELL}
                        </td>
                        <td style={styles.tableBESTABodyTD}>{bestaExtras.noClassificationCounts}</td>
                    </tr>
                    <tr>
                        <td className="BESTAextras" style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.T_BESTA_EMPLOYEES_NOT_SUITABLE_CODE}
                        </td>
                        <td colSpan={6} style={styles.tableBESTABodyTD}>
                            {REGISTER_TEXTS.EMPTY_CELL}
                        </td>
                        <td style={styles.tableBESTABodyTD}>{bestaExtras.notSuitableCodeCount}</td>
                    </tr>
                </>
            )}
        </tbody>
    </table>
)

export default TableBESTA
