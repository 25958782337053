/* eslint-disable react/no-this-in-sfc, no-param-reassign  */

import { useEffect, useState } from "react"

import { tSalaryEvolutionDiagramViewAnalysisType3 } from "types/sharedTypes"

import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"

import getSsnAndNameFromDataSimple from "utils/Highcharts/getSsnAndNameFromDataSimple"

import defaultSetOptions from "components/cores/highcharts/highchartsDefaultSetOptions"

type tSalaryEvolutionScatterDiagram = {
    diagramData: tSalaryEvolutionDiagramViewAnalysisType3
    diagramTitle: string
    salaryType: string
    showMinorGridLines: boolean
}

const SalaryEvolutionScatterDiagram = ({
    diagramData,
    diagramTitle,
    salaryType,
    showMinorGridLines,
}: tSalaryEvolutionScatterDiagram) => {
    HighchartsMore(Highcharts)
    Exporting(Highcharts)
    Highcharts.setOptions(defaultSetOptions)

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        chart: {
            animation: false,
        },
        title: { text: diagramTitle },
        yAxis: {
            title: {
                text: "Löneutveckling i procent",
            },
            minorTickInterval: showMinorGridLines ? "auto" : undefined,
        },
        xAxis: {
            title: {
                text: salaryType,
            },
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const x = this.x as number
                const y = this.y as number

                const seriesDef = diagramData.SalariesMen.concat(diagramData.SalariesWomen)

                const { ssn, name } = getSsnAndNameFromDataSimple(seriesDef, x, y)
                return `PNR: ${ssn}<br> Namn: ${name} <br><br> Lön: ${this.x} kr <br> Löneutveckling: ${this.y}`
            },
        },
        series: [
            {
                id: "1",
                type: "line",
                name: "Trendlinje",
                animation: false,
                data: diagramData.RegressionLine,
                marker: {
                    enabled: false,
                },
                color: "#715db2",
                enableMouseTracking: false,
                lineWidth: 0.8,
            },
            {
                id: "2",
                type: "scatter",
                name: "Män",
                animation: false,
                data: diagramData.SalariesMen,
                marker: {
                    symbol: "circle",
                },
            },
            {
                id: "3",
                type: "scatter",
                name: "Kvinnor",
                animation: false,
                data: diagramData.SalariesWomen,
                marker: {
                    symbol: "diamond",
                },
            },
        ],
        credits: {
            enabled: false,
        },
    })

    // Uppdate data dynamically
    useEffect(() => {
        // get pervios options from setState, return copy of it with only property yAxis updated
        setChartOptions((options) => ({
            ...options,
            yAxis: {
                minorTickInterval: showMinorGridLines ? "auto" : undefined,
            },
        }))
    }, [showMinorGridLines])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default SalaryEvolutionScatterDiagram
