import React, { CSSProperties } from "react"

import { tSalaryEvolutionSeriesData } from "types/sharedTypes"

interface IExportTablePercentiles {
    seriesData: tSalaryEvolutionSeriesData[]
    tableRows: string[][]
    isIndividual?: boolean
    salaryType?: string
}

const SalaryEvolutionType1ExportTable = ({ 
    seriesData,
    tableRows,
    isIndividual = false,
    salaryType = "", 
}: IExportTablePercentiles) => {

    const styles: { [key: string]: CSSProperties } = {
        tableStyle: {
            marginBottom: 48,
            borderSpacing: 0,
            borderCollapse: "collapse",
            textAlign: "left",
            height: "fit-content",
        },
        tbodyTd: {
            border: "1px solid #ddd",
            borderSpacing: 0,
            padding: 8,
        },
        theadTrTh: {
            padding: 8,
            background: "#ddd",
            border: "1px solid #ddd",
            borderSpacing: 0,
            fontFamily: "Open Sans Bold",
            width: "100%",
            margin: "auto"
        },
    }

    const tableHeaders: string[] = seriesData.map((data) => data.PeriodName)
    tableHeaders.sort()
    const tableHeaderSection =
        tableHeaders.length < 2
            ? []
            : [...tableHeaders].slice(1).map((tableHeader, index) => ({
                  headerName: tableHeader,
                  resizable: true,
                  children: [
                      {
                          field: `Kr${index}`,
                          headerName: "Δ Kr",
                          width: 85,
                          resizable: true,
                      },
                      {
                          field: `Procent${index}`,
                          headerName: "Δ %",
                          width: 85,
                          resizable: true,
                      },
                      {
                          field: `Medellön${index}`,
                          headerName: salaryType || "Medellön",
                          width: 100,
                          resizable: true,
                      },
                  ],
              }))

    const preHeaders = isIndividual
        ? [
              {
                  field: "Pnr",
                  width: 150,
                  resizable: true,
                  pinned: true,
              },
              {
                  field: "Namn",
                  width: 100,
                  resizable: true,
                  pinned: true,
              },
              {
                  field: "Anst",
                  width: 80,
                  resizable: true,
              },
              {
                  headerName: tableHeaders[0],
                  resizable: true,
                  children: [
                      {
                          field: salaryType,
                          width: 100,
                          resizable: true,
                      },
                  ],
              },
          ]
        : [
              {
                  field: "Grupp",
                  width: 100,
                  resizable: true,
              },
              {
                  field: "Antal",
                  width: 85,
                  resizable: true,
              },
              {
                  headerName: tableHeaders[0],
                  resizable: true,
                  children: [
                      {
                          field: "Medellön",
                          width: 100,
                          resizable: true,
                      },
                  ],
              },
          ]

    const postHeaders = [
        {
            headerName: "Totalt",

            resizable: true,
            children: [
                {
                    field: "KrTotalt",
                    headerName: "Δ Kr",
                    width: 85,
                    resizable: true,
                },
                {
                    field: "ProcentTotalt",
                    headerName: "Δ %",
                    width: 85,
                    resizable: true,
                },
            ],
        },
    ]

    const columnDefs = preHeaders.concat(tableHeaderSection).concat(postHeaders)

    function dividePeriodData(originalArray: string[]) {
        const dividedPeriodRow = []
        const periodSize = 3
        for (let i = 0; i < originalArray.length; i += periodSize) {
            const period = originalArray.slice(i, i + periodSize)
            dividedPeriodRow.push(period)
        }

        return dividedPeriodRow
    }

    const tableRowData = tableRows.map((row) => {
        const rowCopy = [...row]
        const rowInfo = isIndividual ? rowCopy.splice(0, 3) : rowCopy.splice(0, 2)
        const firstPeriod = rowCopy.splice(0, 1)
        const total = rowCopy.splice(-2)
        const periods = dividePeriodData(rowCopy)

        const tableRow: any = isIndividual
            ? {
                  Pnr: rowInfo[0],
                  Namn: rowInfo[1],
                  Anst: rowInfo[2],
                  [salaryType]: firstPeriod,
                  KrTotalt: total[0],
                  ProcentTotalt: total[1],
              }
            : {
                  Grupp: rowInfo[0],
                  Antal: rowInfo[1],
                  Medellön: firstPeriod,
                  KrTotalt: total[0],
                  ProcentTotalt: total[1],
              }

        if (row.length > 5 && !isIndividual) {
            for (let i = 0; i < tableHeaders.length - 1; i += 1) {
                const increaseKr = periods[i][0]
                const increaseProcentage = periods[i][1]
                const salary = periods[i][2]

                tableRow[`Kr${i}`] = increaseKr
                tableRow[`Procent${i}`] = increaseProcentage
                tableRow[`Medellön${i}`] = salary
            }
        }
        if (row.length > 5 && isIndividual) {
            for (let i = 0; i < tableHeaders.length - 1; i += 1) {
                const increaseKr = periods[i][0]
                const increaseProcentage = periods[i][1]
                const salary = periods[i][2]

                tableRow[`Kr${i}`] = increaseKr
                tableRow[`Procent${i}`] = increaseProcentage
                tableRow[`Medellön${i}`] = salary
            }
        }

        return tableRow
    })

    return (
        <table className="invisible" style={styles.tableStyle}>
            <thead>
                <tr style={styles.theadTrTh}>
                    {columnDefs.map((col) => (
                        <React.Fragment key={col.headerName || col.field}>
                            {col.children ? (
                                <th colSpan={col.children.length} style={styles.theadTrTh}>
                                    {col.headerName}
                                </th>
                            ) : (
                                <th style={styles.theadTrTh}/>
                            )}
                        </React.Fragment>
                    ))}
                </tr>
                <tr style={styles.theadTrTh}>
                    {columnDefs.map((col) =>
                        col.children ? (
                            col.children.map((child) => (
                                <th key={child.field} style={styles.theadTrTh}>
                                    {child.field}
                                </th>
                            ))
                        ) : (
                            <th key={col.field} style={styles.theadTrTh}>
                                {col.field}
                            </th>
                        )
                    )}
                </tr>
            </thead>
            <tbody>
                {tableRowData.map((row) => (
                    <tr key={row.Pnr || row.Grupp}>
                    {columnDefs.map((col) =>
                        col.children ? (
                        col.children.map((child) => (
                            <td
                            key={`${row.Pnr || row.Grupp}-${child.field}`}
                            style={styles.tbodyTd}
                            >
                            {row[child.field]}
                            </td>
                        ))
                        ) : (
                        <td key={`${row.Pnr || row.Grupp}-${col.field}`} style={styles.tbodyTd}>
                            {row[col.field]}
                        </td>
                        )
                    )}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SalaryEvolutionType1ExportTable
