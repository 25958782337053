import "./AnalyzeSalaryDifferencePage.css"

import pageIds from "constants/pageIds.constants.json"
import pageTitles from "constants/pageTitles.constants.json"

import { useContext, useEffect, useState } from "react"

import useScrollToTop from "hooks/useScrollToTop"
import useUpdatePageTitle from "hooks/useUpdatePageTitle"

import { RegistriesContext } from "context/RegistriesContext"

import PageHeader from "components/cores/pageHeader/PageHeader"
import Flow from "components/cores/flow/Flow"
import FlowStep from "components/cores/flow/flowStep/FlowStep"
import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import RegerstiesAndSelections from "./step1/WageGapRegistriesAndSelections"
import CreateSelection from "./step2/CreateSelection"
import EqualWork from "./step3/EqualWork"
import EquivalentWork from "./step4/EquivalentWork"
import EquivalentWorkValueGroups from "./step5/EquivalentWorkValueGroups"
import EquivalentWorkReviewGroups from "./step6/EquivalentWorkReviewGroups"
import MatchingGroups from "./step7/MatchingGroups"

const AnalyzeSalaryDifferencePage = () => {
    const { registries, registriesFetched } = useContext(RegistriesContext)

    const [selectedRegistryId, setSelectedRegistryId] = useState(
        registriesFetched && registries.length ? registries[0].Id : ""
    )

    const [activeStep, setActiveStep] = useState(1)
    const [activeSteps, setActiveSteps] = useState<number[]>([1, 2])

    const [analysisId, setAnalysisId] = useState("")
    const [selectedSalaryType, setSelectedSalaryType] = useState(0)

    const isNewBesta = registries[registries.findIndex((register) => register.Id === selectedRegistryId)]?.IsNewBESTA
    const selectedRegistryName =
        registries[registries.findIndex((register) => register.Id === selectedRegistryId)]?.Name

    useEffect(() => {
        if (activeStep === 1) {
            setActiveSteps([1, 2])
            setSelectedSalaryType(0)
            setAnalysisId("")
        }
    }, [activeStep])

    useUpdatePageTitle(pageTitles.ANALYZE_SALARY_DIFFERENCE_PAGE_TITLE)
    useScrollToTop()

    return (
        <div className="maxWidth1000">
            <PageHeader pageId={pageIds.ANALYZE_SALARY_DIFFERENCE_PAGE_ID} />
            <div className="mb16">
                {isNewBesta ? (
                    <Flow
                        currentStep={activeStep}
                        setCurrentStep={setActiveStep}
                        activeSteps={activeSteps}
                        setActiveSteps={setActiveSteps}
                    >
                        <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_WAGE_GAP.FLOWSTEP_1}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_WAGE_GAP.FLOWSTEP_2}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_WAGE_GAP.FLOWSTEP_3}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(4)}>{ANALYZE_WAGE_GAP.FLOWSTEP_4}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(5)}>{ANALYZE_WAGE_GAP.FLOWSTEP_5}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(6)}>{ANALYZE_WAGE_GAP.FLOWSTEP_6_EMPLOYEE}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(7)}>{ANALYZE_WAGE_GAP.FLOWSTEP_6_MANAGERS}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(8)}>{ANALYZE_WAGE_GAP.FLOWSTEP_7}</FlowStep>
                    </Flow>
                ) : (
                    <Flow
                        currentStep={activeStep}
                        setCurrentStep={setActiveStep}
                        activeSteps={activeSteps}
                        setActiveSteps={setActiveSteps}
                    >
                        <FlowStep onClick={() => setActiveStep(1)}>{ANALYZE_WAGE_GAP.FLOWSTEP_1}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(2)}>{ANALYZE_WAGE_GAP.FLOWSTEP_2}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(3)}>{ANALYZE_WAGE_GAP.FLOWSTEP_3}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(4)}>{ANALYZE_WAGE_GAP.FLOWSTEP_4}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(5)}>{ANALYZE_WAGE_GAP.FLOWSTEP_5}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(6)}>{ANALYZE_WAGE_GAP.FLOWSTEP_6}</FlowStep>
                        <FlowStep onClick={() => setActiveStep(7)}>{ANALYZE_WAGE_GAP.FLOWSTEP_7}</FlowStep>
                    </Flow>
                )}
            </div>
            {activeStep === 1 && (
                <RegerstiesAndSelections
                    selectedRegistryId={selectedRegistryId}
                    setSelectedRegistryId={setSelectedRegistryId}
                    setSelectedSalaryType={setSelectedSalaryType}
                    analysisId={analysisId}
                    setAnalysisId={setAnalysisId}
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 2 && (
                <CreateSelection
                    registryId={selectedRegistryId}
                    isNewBesta={isNewBesta}
                    analysisId={analysisId}
                    setAnalysisId={setAnalysisId}
                    selectedSalaryType={selectedSalaryType}
                    setSelectedSalaryType={setSelectedSalaryType}
                    setActiveStep={setActiveStep}
                />
            )}
            {activeStep === 3 && analysisId && !!selectedSalaryType && (
                <EqualWork
                    analysisId={analysisId}
                    registryName={selectedRegistryName}
                    salaryType={selectedSalaryType}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 4 && analysisId && (
                <EquivalentWork analysisId={analysisId} setActiveStep={setActiveStep} setActiveSteps={setActiveSteps} />
            )}
            {activeStep === 5 && analysisId && (
                <EquivalentWorkValueGroups
                    analysisId={analysisId}
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 6 && analysisId && isNewBesta && (
                <EquivalentWorkReviewGroups
                    analysisId={analysisId}
                    isNewBesta={isNewBesta}
                    employeeType="ExcludingManagers"
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 7 && analysisId && isNewBesta && (
                <EquivalentWorkReviewGroups
                    analysisId={analysisId}
                    isNewBesta={isNewBesta}
                    employeeType="Managers"
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === 6 && analysisId && !isNewBesta && (
                <EquivalentWorkReviewGroups
                    analysisId={analysisId}
                    isNewBesta={isNewBesta}
                    setActiveStep={setActiveStep}
                    setActiveSteps={setActiveSteps}
                />
            )}
            {activeStep === (isNewBesta ? 8 : 7) && analysisId && !!selectedSalaryType && (
                <MatchingGroups
                    analysisId={analysisId}
                    salaryType={selectedSalaryType}
                    registryId={selectedRegistryId}
                    registryName={selectedRegistryName}
                />
            )}
        </div>
    )
}

export default AnalyzeSalaryDifferencePage
