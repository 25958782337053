// Note that this code has two limitations:

// It will not delete cookies with HttpOnly flag set, as the HttpOnly flag disables Javascript's access to the cookie.
// It will not delete cookies that have been set with a Path value. (This is despite the fact that those cookies will appear in document.cookie,
// but you can't delete it without specifying the same Path value with which it was set.)

function deleteAllCookies() {
    document.cookie.split("; ").forEach((c) => {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
    })
}

export default deleteAllCookies
