import "./UserCard.css"

import { useState } from "react"

import apiEndpoints  from "constants/endpoints.constants"
import GENERAL_TEXTS, { USER_ADMINISTRATION_TEXTS } from "constants/text.constants"

import prepareCsrfToken from "utils/prepareCsrfToken"

import { postWithBodyAndCsrf } from "services/apiService"

import { dispatchBool, dispatchString, tPostUserBody, tUser, tUserRoles } from "types/sharedTypes"

import Input from "components/atoms/input/Input"
import Button from "components/atoms/button/Button"

import { ReactComponent as CirclePlusIcon } from "assets/circle-plus-solid.icon.svg"
import { ReactComponent as SaveIcon } from "assets/save.icon.svg"

export const emptyUser = {
    Id: "",
    Created: "",
    MainAccountId: "",
    Firstname: "",
    Lastname: "",
    Email: "",
    Mobile: "",
    Roles: [{ Name: "", Id: "", Key: "" }],
    ResourceKeys: [],
}

export const roller  = [
    {
    Name: "Lokal administratör",
    Id: "756fc262-b817-eb11-a95e-000d3a27ec8e",
    Key: "LOCAL_ADMIN",   
},
{
    Name: "Användare",
    Id: "746fc262-b817-eb11-a95e-000d3a27ec8e",
    Key: "HR",   
},
]

interface IUserCard {
    user: tUser
    allRoles: tUserRoles[]
    setShowConfirmation: dispatchBool
    setPostError: dispatchString
    setShowWarning?: dispatchBool
    setUserToDelete?: React.Dispatch<React.SetStateAction<{ Id: string; Name: string }>>
    setCreating?: dispatchBool
}

const UserCard = ({
    user,
    allRoles,
    setCreating,
    setShowConfirmation,
    setShowWarning,
    setUserToDelete,
    setPostError,
}: IUserCard) => {
    const [userId, setUserId] = useState(user.Id)
    const [firstName, setFirstName] = useState(user.Firstname)
    const [lastName, setLastName] = useState(user.Lastname)
    const [email, setEmail] = useState(user.Email)
    const [mobileNumber, setMobileNumber] = useState(user.Mobile)

    const [userRoles, setUserRoles] = useState(user.Roles)

    const [creatingUser, setCreatingUser] = useState(JSON.stringify(user) === JSON.stringify(emptyUser))
    const [updatingUser, setUpdatingUser] = useState(false)

    const [submittingCreateUser, setSubmittingCreateUser] = useState(false)
    const [submittingUpdateUser, setSubmittingUpdateUser] = useState(false)

    const userFullName = `${user.Firstname} ${user.Lastname}`
    const userLabelSeparator = creatingUser ? "" : `updating ${user.Id}`

    const checkRoles = (roles: tUserRoles[], checkboxRole: string) => {
        if (creatingUser) return undefined
        const index = roles.findIndex((roleObj) => roleObj.Name === checkboxRole)
        if (index !== -1) return true
        return false
    }

    const handleRoleChange = (role: tUserRoles, checked: boolean) => {
        const index = userRoles.findIndex((userRole) => userRole.Name === role.Name)
        if (checked) {
            const updatedRoles = [...userRoles]
            updatedRoles.push(role)
            setUserRoles(updatedRoles)
        }
        if (!checked) {
            const updatedRoles = [...userRoles]
            updatedRoles.splice(index, 1)
            setUserRoles(updatedRoles)
        }
    }

    const handleDeleteUser = (id: string, name: string) => {
        if (setUserToDelete) setUserToDelete({ Id: id, Name: name })
        if (setShowWarning) setShowWarning(true)
    }
    const handleAbortUserCreating = () => {
        setCreatingUser(false)
        if (setCreating) setCreating(false)
    }

    const handelSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setPostError("")

        const roles = userRoles.map((userRole) => userRole.Id)

        let body: tPostUserBody = {
            Firstname: firstName,
            Lastname: lastName,
            Email: email,
            Mobile: mobileNumber,
            Roles: roles,
        }
        if (updatingUser) body = { ...body, Id: userId }

        const url = creatingUser ? apiEndpoints().createUser : apiEndpoints().editUser

        // createAccountValidationSchema
        //     .validate(requestBody, { abortEarly: false })
        //     .then(() => {
        setSubmittingCreateUser(true)
        setSubmittingUpdateUser(true)
        prepareCsrfToken().then((csrfToken) =>
            postWithBodyAndCsrf(url, csrfToken, JSON.stringify(body), true)
                .then(() => {
                    setSubmittingCreateUser(false)
                    setSubmittingUpdateUser(false)
                    setUpdatingUser(false)
                    if (setCreating) setCreating(false)
                    setShowConfirmation(true)
                    setTimeout(() => {
                        setShowConfirmation(false)
                    }, 5000)
                })
                .catch((err) => {
                    setSubmittingCreateUser(false)
                    setSubmittingUpdateUser(false)
                    if (setCreating) setCreating(false)
                    if (err)
                        err.text()
                            .then((text: string) => {
                                if (text === "UserEmailExist") {
                                    setPostError(USER_ADMINISTRATION_TEXTS.ERROR_USER_EMAIL_EXISTS)
                                } else if (text === "UserCannotRemoveLocalAdminRole") {
                                    setPostError(USER_ADMINISTRATION_TEXTS.ERROR_CANNOT_REMOVE_LOCAL_ADMIN)
                                } else {
                                    setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC)
                                }
                            })
                            .catch(() => setPostError(GENERAL_TEXTS.POST_ERROR_GENERIC))
                })
        )
        // })
        // .catch((valErr) => {
        //     type path = "name" | "firstName" | "lastName" | "email" | "mobile"

        //     valErr.inner.forEach((error: { path: path }) =>
        //         setValidationError({ ...validationError, [error.path]: true })
        //     )
        // })
    }

    return (
        <>
            {!creatingUser && !updatingUser && (
                <div className="userPresentation">
                    <div className="userContainer">
                        {/* <p className="margin0 liUserCard">{user.Firstname}</p> */}
                        {/* <p className="margin0 liUserCard">{user.Lastname}</p> */}
                        <p className="margin0 liUserCard w200 pl4">{userFullName}</p>
                        <p className="margin0 liUserCard w210">{user.Email}</p>
                        <p className="margin0 liUserCard">{user.Mobile}</p>
                        <div className="liUserCard pr16 flex-1pt1">
                            {userRoles.map((role) => (
                                <p className="margin0" key={role.Name}>
                                    {role.Name}
                                </p>
                            ))}
                        </div>
                        <div className="flex gap8">
                            <Button small onClick={() => setUpdatingUser(true)}>
                                {USER_ADMINISTRATION_TEXTS.BUTTON_CHANGE}
                            </Button>
                            <Button variant="delete" small onClick={() => handleDeleteUser(user.Id, userFullName)}>
                                {USER_ADMINISTRATION_TEXTS.BUTTON_REMOVE}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {(creatingUser || updatingUser) && (
                <form className="userForm" onSubmit={handelSubmitForm}>
                    <h2>
                        {creatingUser
                            ? USER_ADMINISTRATION_TEXTS.H2_CREATE_USER
                            : USER_ADMINISTRATION_TEXTS.H2_UPDATE_USER}
                    </h2>
                    <p>{USER_ADMINISTRATION_TEXTS.ALL_FIELDS_ARE_MANDATORY}</p>
                    <div className="flex gap16">
                        <div className="flex1">
                            <label htmlFor={`firstname ${userLabelSeparator}`}>
                                {USER_ADMINISTRATION_TEXTS.LABEL_FIRSTNAME}
                            </label>
                            <Input
                                type="text"
                                name={`firstname ${userLabelSeparator}`}
                                id={`firstname ${userLabelSeparator}`}
                                placeholder={USER_ADMINISTRATION_TEXTS.LABEL_FIRSTNAME}
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                required
                            />
                            <label htmlFor={`efternamn ${userLabelSeparator}`}>
                                {USER_ADMINISTRATION_TEXTS.LABEL_LASTNAME}
                            </label>
                            <Input
                                type="text"
                                name={`efternamn ${userLabelSeparator}`}
                                id={`efternamn ${userLabelSeparator}`}
                                placeholder={USER_ADMINISTRATION_TEXTS.LABEL_LASTNAME}
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                required
                            />
                        </div>
                        <div className="flex1">
                            <label htmlFor={`email ${userLabelSeparator}`}>
                                {USER_ADMINISTRATION_TEXTS.LABEL_EMAIL}
                            </label>
                            <Input
                                type="email"
                                id={`email ${userLabelSeparator}`}
                                name={`email ${userLabelSeparator}`}
                                placeholder={USER_ADMINISTRATION_TEXTS.LABEL_EMAIL}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                                // isErroneous={validationError.email}
                                // errorMessage={GENERAL_TEXTS.VALIDATION_EMAIL}
                            />
                            <label htmlFor={`mobileNumber ${userLabelSeparator}`}>
                                {USER_ADMINISTRATION_TEXTS.LABEL_MOBILE}
                            </label>
                            <Input
                                type="tel"
                                id={`mobileNumber ${userLabelSeparator}`}
                                name={`mobileNumber ${userLabelSeparator}`}
                                placeholder={USER_ADMINISTRATION_TEXTS.LABEL_MOBILE}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                value={mobileNumber}
                                required
                                // isErroneous={validationError.mobile}
                                // errorMessage={GENERAL_TEXTS.VALIDATION_MOBILE}
                            />
                        </div>
                        <fieldset className="userFieldset">
                            <legend className="userLegend">{USER_ADMINISTRATION_TEXTS.LEGEND_ROLES}</legend>
                            {roller.map((role, index) => (
                                <div key={role.Id} className="flex ai-center">
                                    <input
                                        type="checkbox"
                                        name={`role ${index} ${userLabelSeparator}`}
                                        id={`role ${index} ${userLabelSeparator}`}
                                        defaultChecked={checkRoles(user.Roles, role.Name)}
                                        onChange={(e) => handleRoleChange(role, e.target.checked)}
                                    />
                                    <label
                                        className="userCheckboxLabel"
                                        htmlFor={`role ${index} ${userLabelSeparator}`}
                                    >
                                        {role.Name}
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                        {creatingUser && (
                            <div className="flex gap16 ai-center jc-center ac-center wrap flex1">
                                <Button
                                    type="submit"
                                    Icon={CirclePlusIcon}
                                    isLoading={submittingCreateUser}
                                    disabled={!userRoles[1]}
                                >
                                    {USER_ADMINISTRATION_TEXTS.BUTTON_CREATE}
                                </Button>
                                <Button onClick={handleAbortUserCreating}>
                                    {USER_ADMINISTRATION_TEXTS.BUTTON_ABORT}
                                </Button>
                            </div>
                        )}
                        {updatingUser && (
                            <div className="flex gap16 ai-center jc-center ac-center wrap flex1">
                                <Button
                                    type="submit"
                                    Icon={SaveIcon}
                                    isLoading={submittingUpdateUser}
                                    disabled={!userRoles.length}
                                >
                                    {USER_ADMINISTRATION_TEXTS.BUTTON_UPDATE}
                                </Button>
                                <Button onClick={() => setUpdatingUser(false)}>
                                    {USER_ADMINISTRATION_TEXTS.BUTTON_ABORT}
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            )}
        </>
    )
}

export default UserCard
