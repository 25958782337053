import "./AlphabetButton.css"

import { useState } from "react"

type FilterAlphabet = {
    children: string
    onClick: () => void
    clicked: boolean
    disabled?: boolean
}

const AlphabetButton = ({ children, onClick, clicked, disabled = false }: FilterAlphabet) => {
    const clickedLetterStyle = clicked ? "letterClicked" : ""

    const handleLetterClicked = () => {
        if (!disabled) onClick()
    }

    return (
        <button
            className={`filterLetter ${clickedLetterStyle}`}
            onClick={handleLetterClicked}
            type="button"
            disabled={clicked ? false : disabled}
        >
            {children}
        </button>
    )
}
export default AlphabetButton
