// For inspo, or if the page is deleted should be under components -> cell renderer on AgGrids page
// https://www.ag-grid.com/react-data-grid/component-cell-renderer/

import { AgGridReact } from "ag-grid-react"
import { ICellRendererParams } from "ag-grid-community"

import { salaryGapGroupsData } from "types/sharedTypes"

import { ANALYZE_WAGE_GAP } from "constants/text.constants"

import Button from "components/atoms/button/Button"

interface ITableButtonRemove extends ICellRendererParams {
    gridRef: React.RefObject<AgGridReact<salaryGapGroupsData>>
}

const TableButtonRemove = (props: ITableButtonRemove) => {
    // data = [{Grupp: '71C'}] for example
    const { data, gridRef } = props

    const removeCurrentRowFromSelected = () => {
        gridRef.current?.api.forEachNode((node) => {
            if (!!node.data && node.data.Grupp === data.Grupp) node.setSelected(false)
        })
    }

    return (
        <span>
            <Button variant="delete" onClick={removeCurrentRowFromSelected}>
                {ANALYZE_WAGE_GAP.S2_BUTTON_REMOVE}
            </Button>
        </span>
    )
}

export default TableButtonRemove
