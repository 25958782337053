import "./EmployeesPercentilesTable.css"

import { CSSProperties } from "react"

import { REGISTER_TEXTS } from "constants/text.constants"

import thousandSeparator from "utils/thousandSeparator"

interface ITablePercentiles {
    data: { AllEmployees: string[]; OnlyManagers: string[]; WithoutManagers: string[] }
    showMinMax: boolean
}

// The reason why we use style = {} here is due to how exportPDF endpoint works, it will catch css styled with "style" but not className
// We use separate .css files or global css through common.css ; style={} to be used ONLY when you need to export via this endpoint
// When refactoring backend it would be a good idea to rewrite the functionality so it can work with both className and style.
const styles: { [key: string]: CSSProperties } = {
    tableStyle: {
        borderSpacing: 0,
        borderCollapse: "collapse",
        textAlign: "left",
        height: "fit-content",
        marginBottom: 48,
    },
    tableBodyTD: {
        border: "1px solid #ddd",
        borderSpacing: 0,
        padding: 8,
    },
    tableHeadTrTh: {
        padding: 8,
        background: "#ddd",
        border: "1px solid #ddd",
        borderSpacing: 0,
        fontFamily: "Open Sans Bold",
        width: "100%",
    },
}

const EmployeesPercentilesTable = ({ data, showMinMax = false }: ITablePercentiles) => {
    const { AllEmployees, OnlyManagers, WithoutManagers } = data

    // min and max are at positions 1 and 7 respectively, max is at pos 6 after first splice()
    // very ugly, please help (rewrie)
    const AllEmployeesCopy = [...AllEmployees]
    const OnlyManagersCopy = [...OnlyManagers]
    const WithoutManagersCopy = [...WithoutManagers]
    AllEmployeesCopy.splice(1, 1)
    AllEmployeesCopy.splice(6, 1)
    OnlyManagersCopy.splice(1, 1)
    OnlyManagersCopy.splice(6, 1)
    WithoutManagersCopy.splice(1, 1)
    WithoutManagersCopy.splice(6, 1)

    const noMinMaxArray = [AllEmployeesCopy, OnlyManagersCopy, WithoutManagersCopy]
    const fullDataArray = [AllEmployees, OnlyManagers, WithoutManagers]

    const dataArray = showMinMax ? fullDataArray : noMinMaxArray

    return (
        <table className="tableStyle" style={styles.tableStyle}>
            <thead className="tableHeadStyle">
                <tr>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_GROUPS}</th>
                    {showMinMax && <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_MIN}</th>}
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P10}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P25}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P50}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P75}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P90}</th>
                    {showMinMax && <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_MAX}</th>}
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_MEDIAN}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P90_FRAC_10}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P90_FRAC_50}</th>
                    <th style={styles.tableHeadTrTh}>{REGISTER_TEXTS.TB_P50_FRAC_10}</th>
                </tr>
            </thead>
            <tbody>
                {dataArray.map((categories) => (
                    <tr className="row in mainGroupTableData" key={`${categories[0]}${Math.random() * 1600}`}>
                        {categories.map((arrayElement) => (
                            <td style={styles.tableBodyTD} key={`${arrayElement}${Math.random() * 1600}`}>
                                {thousandSeparator(arrayElement)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default EmployeesPercentilesTable
